<script setup lang="ts">
    import QueryStateManager from '@/components/ui/queries/QueryStateManager.vue'
    import InfiniteLoader from '@/components/ui/loaders/InfiniteLoader.vue'
    import UniversalSearchMembersList from '@/components/partials/universal-search/partials/UniversalSearchMembersList.vue'
    import UniversalSearchEventsList from '@/components/partials/universal-search/partials/UniversalSearchEventsList.vue'
    import { inject } from 'vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import { useQuery } from '@tanstack/vue-query'
    import UniversalSearchApi from '@/libs/api/UniversalSearch.api'
    import { QueryName } from '@/vars/QueryAttr'
    import UniversalSearchPartnersList from '@/components/partials/universal-search/partials/UniversalSearchPartnersList.vue'
    import UniversalSearchHistory from '@/components/partials/universal-search/partials/UniversalSearchHistory.vue'
    import { UNIVERSAL_SEARCH_KEY, UniversalSearchInjectType } from '@/vars/UniversalSearchAttr'
    import UniversalSearchResources from './partials/UniversalSearchResources.vue'
    import { toMilliseconds } from '@/libs/helpers/numbers'

    const universalSearch = inject<UniversalSearchInjectType>(UNIVERSAL_SEARCH_KEY)

    const {
        data: results,
        isLoading,
        isError,
        isSuccess
    } = useQuery({
        staleTime: toMilliseconds({ hours: 1 }),
        queryKey: [QueryName.UNIVERSAL_SEARCH, universalSearch?.searchText],
        queryFn: () => {
            universalSearch?.saveHistory()
            return UniversalSearchApi.postSearch(universalSearch?.searchText.value || '')
        }
    })

    const {
        data: resources,
        isLoading: isLoadingResources,
        isError: isErrorResources,
        isSuccess: isSuccessResources
    } = useQuery({
        staleTime: 0,
        queryKey: [QueryName.UNIVERSAL_SEARCH_RESSOURCES, universalSearch?.searchText],
        queryFn: () => {
            universalSearch?.saveHistory()
            return UniversalSearchApi.postSearchArticle({
                searchText: universalSearch?.searchText.value || ''
            })
        }
    })
</script>

<template>
    <div class="results">
        <ContainerTemplate>
            <QueryStateManager
                :is-loading="isLoading || isLoadingResources"
                :is-error="isError || isErrorResources"
                :is-success="isSuccess || isSuccessResources"
            >
                <template #fallback>
                    <div class="results__container">
                        <InfiniteLoader />
                    </div>
                </template>
                <template #success>
                    <div v-if="results" class="results__container">
                        <div v-if="universalSearch?.searchText.value === ''">
                            <UniversalSearchHistory />
                        </div>
                        <div v-else>
                            <UniversalSearchMembersList
                                :search-text="universalSearch?.searchText.value || ''"
                                :members="results.member.results"
                                :count="results.member.count"
                            />
                            <UniversalSearchEventsList
                                class="mt-8"
                                :search-text="universalSearch?.searchText.value || ''"
                                :events="results.event.results"
                                :count="results.event.count"
                            />
                            <UniversalSearchPartnersList
                                class="mt-8"
                                :search-text="universalSearch?.searchText.value || ''"
                                :partners="results.partner.results"
                                :count="results.partner.count"
                            />
                            <UniversalSearchResources
                                class="mt-8"
                                :search-text="universalSearch?.searchText.value || ''"
                                :resources="resources?.results || []"
                                :count="resources?.count || 0"
                            />
                        </div>
                    </div>
                </template>
            </QueryStateManager>
        </ContainerTemplate>
    </div>
</template>

<style scoped lang="scss">
    $boxShadow: 0 0 0.1875em rgba(0, 0, 0, 0.25);
    .results {
        position: absolute;
        top: var(--header-height);
        left: 0;
        right: 0;
        z-index: 2;
        background-color: white;
        box-shadow: $boxShadow;

        &__container {
            padding: var(--scale-8) 0;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
