import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { RouteName } from '@/vars/RouteName'
import { StartApplyInformationsForm } from '@/types/forms/apply/StartApplyInformationsForm.type'

export default function useBpiApply() {
    const route = useRoute()

    const isApplyBpi = computed(() => route.name === RouteName.APPLY_LECLUB_FOR_BPI)

    const applyDefaultConfig = computed<StartApplyInformationsForm>(() => {
        return { isBpi: true }
    })

    return {
        isApplyBpi,
        applyDefaultConfig
    }
}
