<script setup lang="ts">
    import InfiniteLoader from '@/components/ui/loaders/InfiniteLoader.vue'
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import QueryStateManager from '@/components/ui/queries/QueryStateManager.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import { QueryName } from '@/vars/QueryAttr'
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useQuery } from '@tanstack/vue-query'
    import NotifcationItemControl from './notifications/NotifcationItemControl.vue'
    import IndicatorBadge from '@/components/ui/indicators/IndicatorBadge.vue'

    const { t } = useI18n()
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        queryKey: [QueryName.HEADER_NOTIFICATIONS],
        queryFn: ConfigurationApi.getLeClubNotifications
    })

    const notifications = computed(() => data.value && data.value.items)

    const newNotifications = computed(() => {
        if (!notifications.value) return 0

        const unreadNotifications = notifications.value.filter((notification) => !notification.displayed_at)

        return unreadNotifications.length || 0
    })
</script>

<template>
    <DropdownNavigation class="notification">
        <template #activator>
            <div class="notification__activator">
                <div class="notification__inner">
                    <IndicatorBadge
                        v-if="newNotifications"
                        class="notification__badge"
                        :count="newNotifications"
                        :max-count="9"
                    />
                    <slot></slot>
                </div>
            </div>
        </template>
        <template #dropdown>
            <CardWrapper class="notification__dropdown">
                <QueryStateManager :is-loading="isLoading" :is-error="isError" :is-success="isSuccess">
                    <template #fallback>
                        <InfiniteLoader />
                    </template>
                    <template #success>
                        <div v-if="!notifications || notifications.length === 0" class="notification__empty">
                            <img
                                src="@/assets/images/illustrations/notifications_empty.svg"
                                alt=""
                                class="notification__logo"
                            />
                            <p class="notification__description">
                                {{ t('empty') }}<br />
                                {{ t('empty_set') }}
                            </p>
                        </div>
                        <div v-else class="notification__list">
                            <NotifcationItemControl
                                v-for="notification in notifications"
                                :key="notification.id"
                                :notification="notification"
                                @refetch="refetch"
                            />
                        </div>
                    </template>
                </QueryStateManager>
            </CardWrapper>
        </template>
    </DropdownNavigation>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .notification {
        &__activator {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__inner {
            position: relative;
        }

        &__list {
            max-height: 31.25em;
            overflow: auto;
        }

        &__badge {
            position: absolute;
            z-index: 1;
            top: -0.25em;
            right: -0.75em;
        }

        &__dropdown {
            position: absolute;
            bottom: 0em;
            transform: translateY(98%) translateX(40%);
            right: 0;
            width: 20em;
            overflow: hidden;
            @media screen and (min-width: $breakpoints-md) {
                transform: translateY(98%);
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 100%;
                height: 2.5em;
                left: 0;
                right: 0;
            }
        }

        &__description {
            text-align: center;
        }

        &__empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__logo {
            padding: 1em;
            max-width: 6.25em;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "empty": "Pas de nouvelles notifications.",
        "empty_set": "Vous êtes prêt!"
    }
}
</i18n>
