<script setup lang="ts">
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { SmartAction } from '@/types/response/members-api/SmartActions.type'

    const props = defineProps<{
        action: SmartAction
        infos: {
            icon: string
            title: string
            subtitle: string
        }
        useCustomIcon?: boolean
    }>()

    defineEmits<{
        remove: [action: typeof props.action]
        click: []
    }>()

    function getThemeForAction(actionKey: string): string {
        switch (actionKey) {
            case 'linkedin':
                return `blue`
            case 'stakes':
                return `green`
            default:
                return ''
        }
    }
</script>

<template>
    <CardWrapper
        :data-action-key="action.key"
        class="card"
        :class="`card--${getThemeForAction(action.key)}`"
        @click="$emit('click')"
    >
        <Component
            :is="useCustomIcon ? BaseIcon : MaterialIcon"
            :name="infos.icon"
            class="card__accent-color title-2"
        />
        <div class="flex-1">
            <h4 class="text-sm font-medium">{{ infos.title }}</h4>
            <p class="card__accent-color text-sm">{{ infos.subtitle }}</p>
        </div>
        <MaterialIcon
            name="close"
            class="absolute right-1.5 top-1.5 -m-2 cursor-pointer p-2"
            @click.stop="$emit('remove', action)"
        />
    </CardWrapper>
</template>

<style scoped lang="scss">
    .card {
        $card: &;
        @apply flex cursor-pointer items-center gap-2 px-2 py-4;

        &__accent-color {
            @apply text-flagship;
        }

        &--blue {
            @apply bg-flagship-10;
        }

        &--green {
            @apply bg-go-10;
        }

        &__accent-color {
            #{$card}--blue & {
                @apply text-royale;
            }

            #{$card}--green & {
                @apply text-go;
            }
        }
    }
</style>
