<script setup lang="ts">
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import { useTeamStore } from '@/libs/stores/team.store'
    import DefaultLogo from '@/assets/images/companies/empty.svg'
    import { AvatarSize } from '@/vars/AvatarAttr'

    const teamStore = useTeamStore()

    defineProps<{
        collapsed: boolean
    }>()
</script>

<template>
    <CardWrapper class="card" :class="{ 'card--collapsed': collapsed }" :shadowless="collapsed">
        <CardBody class="card__body">
            <AvatarMedia
                class="card__picture"
                :class="{ 'card__picture--collapsed': collapsed }"
                :src="teamStore?.myTeam?.company?.logo || DefaultLogo"
                :size="AvatarSize.NORMAL"
                alt=""
            />
            <div class="card__infos" :class="{ 'card__infos--hidden': collapsed }">
                <span class="card__company card__item">{{ teamStore?.myTeam?.company?.name }}</span>
                <!--
                    <div class="card__badges">
                    <BaseTag class="card__badge card__item">
                    Technology and Services
                    </BaseTag>
                    </div>
                    <div class="card__edition">
                    <div class="card__location card__item">
                    <i class="card__icon-location icon-marker"></i>
                    Paris, France
                    <BaseButton :theme="ButtonTheme.DISCRET">
                    <i class="card__icon-pencil icon-pencil"></i>
                    </BaseButton>
                    </div>
                    <div class="card__team-size card__item">
                    <i class="card__icon-user icon-user-alone"></i>
                    60 collaborateurs
                    <BaseButton :theme="ButtonTheme.DISCRET">
                    <i class="card__icon-pencil icon-pencil"></i>
                    </BaseButton>
                    </div>
                    </div> 
                -->
            </div>
        </CardBody>
    </CardWrapper>
</template>

<style scoped lang="scss">
    .card {
        --avatar-size: 5em;
        margin-top: calc(var(--avatar-size) / 3);

        &--collapsed {
            padding: 0;
        }

        &__infos {
            text-align: center;
            overflow: hidden;
            max-width: 20em;
            transition:
                max-width 0.5s ease-in-out,
                opacity 0.5s ease-in-out;
            opacity: 1;
            white-space: nowrap;

            &--hidden {
                opacity: 0;
                max-width: 0;
                display: none;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__company {
            font-size: 1em;
            font-weight: bold;
            display: block;
        }

        &__badges {
            margin-bottom: 1em;
        }

        &__badge {
            font-size: 0.75em;
            font-weight: bold;
        }

        &__picture {
            margin: 0;
            margin-top: calc(var(--scale-14) * -0.7);
            margin-bottom: 0.5em;
            background-color: var(--theme-background-color);
            padding: 0.8em;
            object-fit: contain;
            transition: all 0.5s ease-in-out;

            &--collapsed {
                border: solid 1px var(--theme-border-color);
                padding: 0.5em;
            }
        }

        &__item {
            margin-bottom: 0.5em;
        }

        &__icon-user,
        &__icon-location {
            font-size: 1.5em;
            margin-right: 0.3em;
        }

        &__icon-pencil {
            font-size: 1.25em;
            color: var(--theme-border-color);
            margin-left: 0.5em;
        }

        &__edition {
            display: flex;
            flex-direction: column;
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 0.875em;
        }

        &__location,
        &__team-size {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>

<i18n lang="json"></i18n>
