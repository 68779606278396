<script setup lang="ts">
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { RouteLocationPotentiallyNamedWithFullUrl } from '@/libs/compositions/useNavigationResolver'
    import { ClubRouteName, RouteName, ProRouteName } from '@/vars/RouteName'
    import { SpaName } from '@/vars/SpaAttr'
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    const SPA_RUNNING = import.meta.env.VITE_SPA_RUNNING

    const props = defineProps<{
        dark?: boolean
        forceRoute?: RouteLocationPotentiallyNamedWithFullUrl
        platform?: SpaName
    }>()

    const { t } = useI18n()
    const routeToRedirect = computed(() => {
        if (props.forceRoute) {
            return props.forceRoute
        }

        switch (SPA_RUNNING) {
            case SpaName.PRO:
                return { name: ProRouteName.HOME }
            case SpaName.LECLUB:
                return { name: ClubRouteName.HOME }
            default:
                return { name: RouteName.HOME }
        }
    })

    const text = computed(() => {
        const spa = props.platform || SPA_RUNNING
        switch (spa) {
            case SpaName.PRO:
                return t('bs')
            case SpaName.LECLUB:
                return t('club')
            default:
                return t('network')
        }
    })
</script>

<template>
    <SpaRouterLink :to="routeToRedirect" class="logo cursor-pointer">
        <LogoMedia short class="max-h-[32px]" :dark="dark" />
        <div class="logo__bar max-h-[32px] min-h-[32px]" :class="{ '!bg-legacy': dark }"></div>
        <div v-white-space>{{ text }}</div>
    </SpaRouterLink>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: $breakpoints-lg) {
            margin-right: var(--scale-4);
        }
        &__bar {
            width: var(--scale-px);
            height: 100%;
            background-color: var(--theme-color-nuage);
            margin-inline: var(--scale-2);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "club": "Club",
        "bs": "Business\nSolutions",
        "network": "french\nfounders"
    },
    "en": {
        "club": "Club",
        "bs": "Business\nSolutions",
        "network": "french\nfounders"
    }
}
</i18n>
