class LocalStorage {
    static get(key: string) {
        const localItem = localStorage.getItem(key)

        if (!localItem) return null

        const item = JSON.parse(localItem)
        if (item.expiration) {
            const now = new Date()

            // compare the expiration time of the item with the current time
            if (now.getTime() > item.expiration) {
                // If the item is expired, delete the item from storage and return null
                this.delete(key)
                return null
            }
        }

        return item.value
    }

    static set(key: string, value: unknown, expiration?: number) {
        const now = new Date()

        // `item` is an object which contains the original value as well as the time when it's supposed to expire
        const item = {
            value,
            expiration: expiration && now.getTime() + expiration
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    static delete(key: string) {
        localStorage.removeItem(key)
    }
}

export default LocalStorage
