import { AboutRouteName } from '@/vars/RouteName'
import { Component } from 'vue'
import { RouteRecordRaw, RouteRecordRedirectOption } from 'vue-router'

type WeWebPathType = {
    name: string
    path: string
    component?: Component
    redirect?: RouteRecordRedirectOption
}
const weWebPaths: WeWebPathType[] = [
    {
        name: AboutRouteName.WEWEB_CHANGEMAKERS,
        path: '/changemakers'
    },
    {
        name: AboutRouteName.WEWEB_MENTORSHIP,
        path: '/mentorship'
    },
    {
        name: AboutRouteName.WEWEB_THINK_INTERNATIONAL,
        path: '/thinkinternational'
    },
    {
        name: AboutRouteName.WEWEB_BUSINESS_SOLUTIONS,
        path: '/business-solutions'
    },
    {
        name: AboutRouteName.WEWEB_CGV,
        path: '/page/cgv'
    },
    {
        name: AboutRouteName.WEWEB_CGV_ENTREPRISE,
        path: '/cgv-bs'
    },
    {
        name: AboutRouteName.WEWEB_INVESTORS_CLUB,
        path: '/investors-club',
        redirect: { name: AboutRouteName.WEWEB_INVESTMENT }
    },
    {
        name: AboutRouteName.WEWEB_LOOKING_JOB,
        path: '/je-cherche-un-job'
    },
    {
        name: AboutRouteName.WEWEB_RECRUTE,
        path: '/je-recrute'
    },
    {
        name: AboutRouteName.WEWEB_AGENCE_RECRUTEMENT,
        path: '/agence-recrutement'
    },
    {
        name: AboutRouteName.WEWEB_CGV_AGENCE_RECRUTEMENT,
        path: '/cgv-agence-recrutement'
    },
    {
        name: AboutRouteName.WEWEB_CLUB,
        path: '/leclub'
    },
    {
        name: AboutRouteName.WEWEB_CLUB_INFO,
        path: '/leclub/info',
        redirect: { name: AboutRouteName.WEWEB_CLUB }
    },
    {
        name: AboutRouteName.WEWEB_CLUB_MEMBERSHIP,
        path: '/le_club_membership',
        redirect: { name: AboutRouteName.WEWEB_CLUB, hash: '#memberships' }
    },
    {
        name: AboutRouteName.WEWEB_LESSUMMITS,
        path: '/lessummits'
    },
    {
        name: AboutRouteName.WEWEB_LEGAL_MENTIONS,
        path: '/mentions-legales'
    },
    {
        name: AboutRouteName.WEWEB_NEWS,
        path: '/news'
    },
    {
        name: AboutRouteName.WEWEB_PRIVACY,
        path: '/page/privacy'
    },
    {
        name: AboutRouteName.WEWEB_FR_RETAILCLUB,
        path: '/fr/retailclub'
    },
    {
        name: AboutRouteName.WEWEB_CONTACT_GROUP,
        path: '/group/contact'
    },
    {
        name: AboutRouteName.WEWEB_CONTACT_BENEFIT,
        path: '/benefits/contact'
    },
    {
        name: AboutRouteName.WEWEB_PROGRAMME_SCALE,
        path: '/programme-scale',
        component: () => import('@/views/network/weweb/WeWebProgrammeScale.vue')
    },
    {
        name: AboutRouteName.WEWEB_EXPATRIATION,
        path: '/expatriation',
        component: () => import('@/views/network/weweb/WeWebExpatriation.vue')
    },
    {
        name: AboutRouteName.WEWEB_INVESTMENT,
        path: '/investissement',
        component: () => import('@/views/network/weweb/WeWebInvest.vue')
    },
    {
        name: AboutRouteName.WEWEB_BS_CONTACT,
        path: '/business-solutions/contact'
    },
    {
        name: AboutRouteName.WEWEB_CLUB_MEET,
        path: '/leclub/prendre-rendez-vous'
    },
    {
        name: AboutRouteName.WEWEB_CLUB_MEET_RM,
        path: '/leclub/prendre-rendez-vous/rm',
        redirect: { name: AboutRouteName.WEWEB_CLUB_MEET }
    },
    {
        name: AboutRouteName.WEWEB_ABOUT,
        path: '/a-propos'
    },
    {
        name: AboutRouteName.WEWEB_ENGAGEMENTS,
        path: '/nos-engagements'
    },
    {
        name: AboutRouteName.WEWEB_VOTE_NEXT_CITY,
        path: '/prochaine-ville-frenchfounders'
    },
    {
        name: AboutRouteName.WEWEB_FR_COOKIES,
        path: '/cookies-fr'
    },
    {
        name: AboutRouteName.WEWEB_EN_COOKIES,
        path: '/cookies-en'
    }
]

const weWebPathsExtern: WeWebPathType[] = [
    {
        name: AboutRouteName.WEWEB_FR_RETAILCLUB,
        path: '/fr/retailclub'
    },
    {
        name: AboutRouteName.WEWEB_EN_RETAILCLUB,
        path: '/en/retailclub'
    }
]

const routes: RouteRecordRaw[] = weWebPaths.map((weWebPath) => {
    if (weWebPath.redirect) {
        return { path: weWebPath.path, name: weWebPath.name, redirect: weWebPath.redirect }
    }
    return {
        path: weWebPath.path,
        name: weWebPath.name,
        component: weWebPath?.component ? weWebPath?.component : () => import('@/views/common/IframeWeweb.vue'),
        beforeEnter: () => {
            window.$crisp?.push(['do', 'chat:hide']) // on cache le notre pour afficher uniquement celui de weweb
        }
    }
})

const externRoute: RouteRecordRaw[] = weWebPathsExtern.map((weWebPath) => {
    return {
        path: weWebPath.path,
        name: weWebPath.name,
        component: () => import('@/views/common/IframeWeweb.vue'),
        beforeEnter: () => {
            window.location.href = `${import.meta.env.VITE_ABOUT_BASE_URL}${weWebPath.path}`
        }
    }
})

const aboutRoutes: RouteRecordRaw[] = [...routes, ...externRoute]

export default aboutRoutes
