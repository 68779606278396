import { computed } from 'vue'
import { AboutRouteName, CompaniesRouteName, RouteName } from '@/vars/RouteName'
import messages from './i18n/main.json'
import { useRouter } from 'vue-router'
import i18n from '@/i18n'

i18n.global.mergeLocaleMessage('fr', messages.fr)
i18n.global.mergeLocaleMessage('en', messages.en)
import { useAuthStore } from '@/libs/stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'

const { t } = i18n.global

export function useMainNavigationItems() {
    const router = useRouter()
    const authStore = useAuthStore()

    const items = computed(() => {
        return [
            {
                label: t('nav.career'),
                children: [
                    {
                        route: authStore.isLoggedIn ? { name: RouteName.TALENT_PROFILE } : undefined,
                        href: authStore.isLoggedIn
                            ? undefined
                            : `${
                                  import.meta.env.VITE_PRIVATE_BASE_URL
                              }/register?redirectUrl=${import.meta.env.VITE_APP_BASE_URL.concat(
                                  router.resolve({
                                      name: RouteName.TALENT_PROFILE
                                  }).fullPath
                              )}`,
                        label: authStore.authentifiedUser?.finishedOnboarding?.talent
                            ? t('career.talent_pool.title_loggedin')
                            : t('career.talent_pool.title'),
                        subtitle: t('career.talent_pool.subtitle'),
                        icon: 'diversity_3'
                    },
                    {
                        route: { name: RouteName.JOB_LIST_SELECTION },
                        label: t('career.jobs.title'),
                        subtitle: t('career.jobs.subtitle'),
                        icon: 'travel_explore'
                    },
                    {
                        route: { name: RouteName.RESOURCES_LIST_ARTICLES, query: { categories: 'People & Culture' } },
                        label: t('career.articles.title'),
                        subtitle: t('career.articles.subtitle'),
                        icon: 'award_star'
                    },
                    {
                        route: { name: RouteName.EVENT_LIST },
                        label: t('career.webinars.title'),
                        subtitle: t('career.webinars.subtitle'),
                        icon: 'auto_videocam'
                    }
                ]
            },
            {
                label: t('nav.expat'),
                children: [
                    {
                        route: { name: RouteName.RESOURCES_LIST, query: { categories: 'Expatriation' } },
                        label: t('expat.resources.title'),
                        subtitle: t('expat.resources.subtitle'),
                        icon: 'flight_takeoff'
                    },
                    {
                        route: { name: CompaniesRouteName.COMPANY_HOME, query: { stakes: 'expatriation' } },
                        label: t('expat.business.title'),
                        subtitle: t('expat.business.subtitle'),
                        icon: 'partner_exchange'
                    },
                    {
                        disabled: true,
                        label: t('expat.good_plans.title'),
                        subtitle: t('expat.good_plans.subtitle'),
                        icon: 'campaign'
                    }
                ]
            },
            {
                label: t('nav.invest'),
                children: [
                    {
                        route: {
                            name: RouteName.RESOURCES_LIST_ARTICLES,
                            query: { categories: 'Investissement & Financement' }
                        },
                        label: t('invest.formation.title'),
                        subtitle: t('invest.formation.subtitle'),
                        icon: 'wallet'
                    },
                    {
                        href: 'https://www.lefonds.vc/',
                        label: t('invest.fonds.title'),
                        subtitle: t('invest.fonds.subtitle'),
                        icon: 'account_balance'
                    },
                    {
                        route: authStore.hasRole(RoleName.INVESTORSCLUB)
                            ? undefined
                            : { name: AboutRouteName.WEWEB_INVESTORS_CLUB },
                        href: authStore.hasRole(RoleName.INVESTORSCLUB)
                            ? `${import.meta.env.VITE_PRIVATE_BASE_URL}/clubs/investor`
                            : undefined,
                        label: authStore.hasRole(RoleName.INVESTORSCLUB)
                            ? t('invest.investors_club.title_member_ic')
                            : t('invest.investors_club.title'),
                        subtitle: t('invest.investors_club.subtitle'),
                        icon: 'groups'
                    }
                ]
            },
            {
                label: t('nav.resources'),
                children: [
                    {
                        route: { name: CompaniesRouteName.COMPANY_HOME },
                        label: t('resources.companies'),
                        icon: 'home_work'
                    },
                    {
                        route: { name: RouteName.RESOURCES_LIST },
                        label: t('resources.business'),
                        icon: 'data_info_alert'
                    },
                    {
                        route: { name: RouteName.EVENT_LIST_LIVE },
                        label: t('resources.event_online'),
                        icon: 'book_online'
                    },
                    {
                        disabled: true,
                        label: t('resources.annonces.title'),
                        subtitle: t('resources.annonces.subtitle'),
                        icon: 'front_hand'
                    },
                    {
                        disabled: true,
                        label: t('resources.advanges.title'),
                        subtitle: t('resources.advanges.subtitle'),
                        icon: 'redeem'
                    }
                ]
            }
        ]
    })

    return {
        items
    }
}
