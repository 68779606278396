<script setup lang="ts">
    import { computed } from 'vue'
    import { useRoute } from 'vue-router'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import AsideMenuCompanyInfos from './AsideMenuCompanyInfos.vue'
    import AsideMenuNavigation from './AsideMenuNavigation.vue'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import MenuBackgroundPatternMondrian from './illustrations/menu-background-pattern-mondrian.svg'
    import { ProRouteName } from '@/vars/RouteName'
    import { useSessionStorage } from '@vueuse/core'

    type Link = {
        routeName: typeof ProRouteName
        label: string
        iconClass?: string
        iconV2?: string
        class?: string
        activeRoutes?: (typeof ProRouteName)[]
    }

    const route = useRoute()
    const isCollapsed = useSessionStorage(`bs_menu_collapsed`, route.meta.openSideMenu ? false : true)
    const navigation = computed(() => {
        return (route.meta?.navigation as Link[] | undefined)?.map((link) => {
            if (!link.activeRoutes) {
                link.activeRoutes = [link.routeName]
            }

            return link
        })
    })
</script>

<template>
    <CardWrapper class="aside" tag="aside" :class="{ 'aside--collapsed': isCollapsed }">
        <CardBody class="aside__content">
            <div class="aside__background">
                <img class="aside__background-picture" :src="MenuBackgroundPatternMondrian" alt="" />
            </div>
            <div class="aside__foreground">
                <div class="aside__actions">
                    <BaseButton :theme="ButtonTheme.DISCRET" class="aside__action" @click="isCollapsed = !isCollapsed">
                        <i class="icon-arrow-right aside__action-icon"></i>
                    </BaseButton>
                </div>
                <AsideMenuCompanyInfos class="aside__company" :collapsed="isCollapsed" />
                <AsideMenuNavigation :collapsed="isCollapsed" :navigation="navigation" />
            </div>
        </CardBody>
    </CardWrapper>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    $arrowIconSize: 1.5em;

    .aside {
        $block: &;
        display: none;
        overflow: hidden;
        max-width: 100vw;
        width: 18.75em;
        transition: max-width 0.5s linear;

        @media (min-width: $breakpoints-md) {
            display: block;
        }

        &--collapsed {
            @media (min-width: $breakpoints-md) {
                max-width: 6.25em;
            }
        }

        &__background-picture {
            object-fit: contain;
            transform: scale(0.7);
            transition: opacity 0.5s ease-in-out;
            transform-origin: center top;
            width: 100%;
            opacity: 1;
            padding-top: 1em;

            #{$block}--collapsed & {
                opacity: 0;
            }
        }

        &__actions {
            position: relative;
            min-height: calc($arrowIconSize * 1.5);
            color: var(--theme-background-color);

            #{$block}--collapsed & {
                text-align: center;
            }
        }

        &__content {
            position: relative;
        }

        &__background {
            --radius: 40%;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 8em;
            background: var(--theme-bs-color-bg-alternative, var(--theme-primary-gradient));
            z-index: 0;
            transform: scaleX(1.2);
            border-radius: 0 0 var(--radius) var(--radius);
            transition: height 0.5s ease-in-out;

            #{$block}--collapsed & {
                height: 5.5em;
            }
        }

        &__foreground {
            position: relative;
            z-index: 1;
        }

        &__action {
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.4s ease-in-out;
            transform-origin: center center;
            transform: rotateZ(180deg);

            :deep(.button__inner) {
                line-height: 0;
            }

            #{$block}--collapsed & {
                right: 50%;
                transform: translateX(50%) rotateZ(0deg);

                @media (max-width: $breakpoints-sm) {
                    display: none;
                }
            }
        }

        &__action-icon {
            font-size: $arrowIconSize;
        }

        &__company {
            margin-bottom: 2.75em;

            #{$block}--collapsed & {
                @media (max-width: $breakpoints-sm) {
                    background: transparent;
                    margin-bottom: 0;
                }
            }
        }
    }
</style>
