<script setup lang="ts">
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import SharedStorage from '@/libs/core/SharedStorage'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { computed, inject, onMounted, Ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { StorageKey } from '@/vars/StorageAttr'
    import { DateTime } from 'luxon'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { AboutRouteName } from '@/vars/RouteName'
    import { useRouter } from 'vue-router'

    enum CookieState {
        ACCEPTED = 'accepted',
        REFUSED = 'refused'
    }
    const isInFrenchfoundersApp = !!window.ReactNativeWebView

    const { t } = useI18n()
    const isVisible = inject<Ref<boolean>>('showCookiesConsent')
    const { redirectBounding } = useNavigationResolver()
    const router = useRouter()

    onMounted(() => {
        const cookiesConsent = SharedStorage.get(StorageKey.COOKIES)

        if (isInFrenchfoundersApp) {
            onAccept()
        } else if (cookiesConsent) {
            if (cookiesConsent === CookieState.ACCEPTED) {
                if (window.dataLayer) {
                    window.dataLayer.push({ event: 'accept_cookies' })
                }
            } else {
                if (window.dataLayer) {
                    window.dataLayer.push({ event: 'refuse_cookies' })
                }
            }
        } else {
            if (isVisible) {
                isVisible.value = true
            }
        }
    })

    const storeCookie = (value: CookieState) => {
        SharedStorage.set(StorageKey.COOKIES, value, DateTime.now().plus({ year: 1 }).toJSDate())
    }

    const onAccept = () => {
        storeCookie(CookieState.ACCEPTED)
        if (window.dataLayer) {
            window.dataLayer.push({ event: 'accept_cookies' })
        }
        if (isVisible) {
            isVisible.value = false
        }
    }

    const onRefuse = () => {
        storeCookie(CookieState.REFUSED)
        if (window.dataLayer) {
            window.dataLayer.push({ event: 'refuse_cookies' })
        }
        if (isVisible) {
            isVisible.value = false
        }
    }

    const hrefToLegalMention = computed(() => {
        const { href, to } = redirectBounding({ name: AboutRouteName.WEWEB_PRIVACY })

        if (href) {
            return href
        } else if (to) {
            return router.resolve(to).fullPath
        } else {
            return ''
        }
    })
</script>

<template>
    <div v-if="isVisible" class="ckis" data-testId="modal-consent-cookies">
        <div class="ckis__header">
            <LogoMedia short class="ckis__header-logo" />
            <h3 class="title-4">{{ t('title') }}</h3>
        </div>

        <div v-safe-html="t('details', { href: hrefToLegalMention })" class="text-sm"></div>
        <div class="ckis__footer">
            <BaseButton :theme="ButtonTheme.GHOST" test-id="refuse-cookies" @click="onRefuse">
                {{ t('buttons.refused') }}
            </BaseButton>
            <BaseButton test-id="accept-cookies" @click="onAccept">{{ t('buttons.consent') }}</BaseButton>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .ckis {
        padding: var(--scale-6);
        position: fixed;
        border-top-right-radius: 0.5em;
        border-top-left-radius: 0.5em;
        background: var(--theme-color-legacy);
        color: var(--theme-color-nuage);
        bottom: 0px;
        z-index: 1000;

        @media screen and (min-width: $breakpoints-md) {
            left: 40px;
            bottom: 40px;
            max-width: max-content;
            border-radius: 0.5em;
        }

        &__header {
            display: flex;
            align-items: center;
            gap: var(--scale-4);
            margin-bottom: var(--scale-8);
        }
        &__header-logo {
            display: none;
            @media screen and (min-width: $breakpoints-md) {
                display: initial;
            }
        }

        &__footer {
            display: flex;
            justify-content: center;
            gap: var(--scale-16);
            align-items: center;
            margin-top: var(--scale-8);
            @media screen and (min-width: $breakpoints-md) {
                margin-top: var(--scale-2);
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Votre confidentialité compte beaucoup à nos yeux.",
        "details": "Nous utilisons des cookies pour personnaliser votre expérience sur notre site.<br/>En naviguant sur le site, vous acceptez l’utilisation des cookies pour collecter</br>des informations. Pour en savoir plus, rendez-vous sur notre <a href={href} style='text-decoration: underline' target='_blank'>politique relative<br/> aux cookies</a>.",
        "buttons": {
            "refused": "Tout refuser",
            "consent": "Tout accepter"
        }
    },
    "en": {
        "title": "Your privacy is important to us.",
        "details": "We use cookies to personalize your experience on our site.<br/>By browsing the site, you accept the use of cookies to collect information.<br/>To find out more, go to our <a href={href} style='text-decoration: underline' target='_blank'>cookie policy</a>.",
        "buttons": {
            "refused": "Refuse",
            "consent": "Accept"
        }
    }
}
</i18n>
