import { StakeKey } from '@/types/UpsellStake.type'
import { useI18n } from 'vue-i18n'

const messages: Record<'fr' | 'en', Record<StakeKey, Record<'title' | 'text' | 'action', string>>> = {
    fr: {
        recruit: {
            title: 'Je veux recruter des profils internationaux'
        },
        international_job: {
            title: 'Je recherche un poste à l’international'
        },
        invest: {
            title: 'Je veux investir'
        },
        looking_for_funds: {
            title: 'Je recherche des fonds'
        },
        expatriation: {
            title: 'Je veux m’expatrier'
        },
        knowledge: {
            title: 'Je veux enrichir mes connaissances'
        },
        expand_network: {
            title: 'Je veux rejoindre le Club privé'
        },
        develop_business: {
            title: 'Je veux développer mon business'
        }
    },
    en: {
        recruit: {
            title: 'I want to recruit international profiles'
        },
        international_job: {
            title: 'I am looking for an international job'
        },
        invest: {
            title: 'I want to invest'
        },
        looking_for_funds: {
            title: 'I am looking for funding'
        },
        expatriation: {
            title: 'I want to expatriate'
        },
        knowledge: {
            title: 'I want to enrich my knowledge'
        },
        expand_network: {
            title: 'I want to join the FrenchFounders private Club'
        },
        develop_business: {
            title: 'I want to develop my business'
        }
    }
}

export default function useUpsellStake() {
    const { t } = useI18n({ messages, useScope: 'global' })

    function getIconName(stakeKey: StakeKey) {
        switch (stakeKey) {
            case 'recruit':
                return 'assignment_ind'
            case 'international_job':
                return 'captive_portal'
            case 'invest':
                return 'savings'
            case 'looking_for_funds':
                return 'crowdsource'
            case 'expatriation':
                return 'travel'
            case 'knowledge':
                return 'book_2'
            case 'expand_network':
                return 'hub'
            case 'develop_business':
                return 'trending_up'
            default:
                throw new Error(`${stakeKey} is not implemented`)
        }
    }

    function getWordings(stakeKey?: StakeKey) {
        return {
            title: t(`${stakeKey}.title`)
        }
    }

    function getColor(stakeKey?: StakeKey) {
        switch (stakeKey) {
            case 'recruit':
            case 'international_job':
                return { main: 'var(--theme-color-go)', light: 'var(--theme-color-go--10)' }

            case 'invest':
            case 'looking_for_funds':
                return { main: 'var(--theme-color-ray)', light: 'var(--theme-color-ray--10)' }

            case 'expatriation':
            case 'knowledge':
                return { main: 'var(--theme-color-ace)', light: 'var(--theme-color-ace--10)' }

            case 'expand_network':
            case 'develop_business':
                return { main: 'var(--theme-color-flagship)', light: 'var(--theme-color-flagship--10)' }
            default:
                throw new Error(`${stakeKey} is not implemented`)
        }
    }

    return {
        getColor,
        getIconName,
        getWordings
    }
}
