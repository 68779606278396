<script setup lang="ts">
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import FormDate from '@/components/ui/forms/FormDate.vue'
    import { useField, useForm } from 'vee-validate'
    import { computed, watch } from 'vue'

    const props = defineProps<{
        formEl: {
            subject: string
            options?: {
                range?: string
                info?: string
            }
        }
    }>()

    const emit = defineEmits<{
        input: [value: string]
    }>()

    const options = computed<{
        minDate: Date | undefined
        maxDate: Date | undefined
    }>(() => {
        const date = new Date()
        date.setHours(0, 0, 0)
        if (props.formEl?.options?.range === 'future') {
            return {
                minDate: date,
                maxDate: undefined
            }
        }

        if (props.formEl?.options?.range === 'past') {
            return {
                minDate: undefined,
                maxDate: date
            }
        }

        return {
            minDate: undefined,
            maxDate: undefined
        }
    })

    const randomName = computed(() => {
        return (Math.random() + 1).toString(36).substring(2)
    })

    const onInput = (dateStr: string) => {
        emit('input', dateStr)
    }

    useForm()

    const { value } = useField<Date>(randomName.value, undefined, { syncVModel: true })

    watch(value, () => {
        onInput(value.value)
    })
</script>

<template>
    <div>
        <span class="date__label">
            <label>
                {{ formEl.subject }}
            </label>
            <BaseIcon v-if="formEl?.options?.info" v-tooltip.bottom="formEl?.options?.info" name="info"> </BaseIcon>
        </span>
        <FormDate :name="randomName" :min-date="options?.minDate" :max-date="options?.maxDate" />
    </div>
</template>

<style scoped lang="scss">
    .date {
        &__label {
            text-align: left;
            display: block;
            color: var(--theme-info-color);
            margin-bottom: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
