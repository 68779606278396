<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import FeedbackForm from '@/libs/helpers/feedbackForm'
    import { ButtonType, ButtonTheme } from '@/vars/ButtonAttr'
    import { computed } from 'vue'

    const props = defineProps<{
        form: FeedbackForm
        formEl: {
            subject: string
            options?: {
                requires?: string[]
                href?: string
                design?: string
            }
        }
    }>()

    const emit = defineEmits<{
        click: [value: boolean]
        contact: []
        'see-profile': []
        'ping-admin': []
    }>()

    const disabled = computed(() => {
        if (props.formEl && props.formEl.options && props.formEl.options.requires) {
            const answers = props.form.feedbackAnswersObject.form_elements

            if (props.formEl.options.requires.length > 0 && answers) {
                let disabled = true
                for (const requireId of props.formEl.options.requires) {
                    for (const answer of answers) {
                        if (answer.id == requireId) {
                            disabled = disabled && !answer.answer
                        }
                    }
                }

                return disabled
            }
        }

        return false
    })

    const onClick = () => {
        if (props.formEl?.options?.href) {
            handleCustomAction()
        }

        emit('click', true)
    }

    const handleCustomAction = () => {
        switch (props.formEl?.options?.href) {
            case 'relationship_contact':
                emit('contact')
                break
            case 'relationship_see_profile':
                emit('see-profile')
                break
            case 'relationship_ping_admin':
                emit('ping-admin')
                break
            default:
                return null
        }
    }

    const isLinkButton = computed(() => {
        return props.formEl.options && props.formEl.options.design === 'link'
    })

    const isCardButton = computed(() => {
        return props.formEl.options && props.formEl.options.design === 'card'
    })
</script>

<template>
    <div class="mb-2 mt-4">
        <div v-if="isCardButton" class="button__card pb-4 pt-4" @click="onClick">
            <div>{{ formEl.subject }}</div>
        </div>
        <BaseButton
            v-else
            :theme="isLinkButton ? ButtonTheme.DISCRET : ButtonTheme.SIMPLE"
            :class="{ button__link: isLinkButton }"
            :disabled="disabled"
            :tag="ButtonType.LINK"
            @click="onClick"
        >
            {{ formEl.subject }}
        </BaseButton>
    </div>
</template>

<style scoped lang="scss">
    .button {
        &__link {
            text-decoration: underline;
            cursor: pointer;
        }
        &__card {
            display: flex;
            justify-content: center;
            align-items: center;
            border: var(--scale-px) solid var(--theme-border-color);
            border-radius: var(--scale-2);
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
