import { Team } from '@/types/Team.type'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth.store'

export const useTeamStore = defineStore('team', {
    state() {
        return {
            myTeam: null as Team | null
        }
    },
    getters: {
        myTeamRole: () => {
            const authStore = useAuthStore()

            return authStore.decodedToken?.team?.role
        },

        myTeamFeatures: () => {
            const authStore = useAuthStore()

            return authStore.decodedToken?.team?.features
        }
    }
})
