<script setup lang="ts">
    import { SpaName } from '@/vars/SpaAttr'
    import { useAuthStore } from '@/libs/stores/auth.store'

    withDefaults(
        defineProps<{
            tag?: string
            small?: boolean
            public?: boolean
            fluid?: boolean
            noPadding?: boolean
        }>(),
        {
            tag: 'div'
        }
    )

    const authStore = useAuthStore()
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
</script>

<template>
    <Component
        :is="tag"
        class="container"
        :class="{
            'container--small': small,
            'container--public': public,
            'container--fluid': fluid,
            'container--no-padding': noPadding,
            'container--with-aside-nav': spaRunning === SpaName.LENETWORK && authStore.isLoggedIn
        }"
    >
        <slot></slot>
    </Component>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .container {
        max-width: $container-width; // 71.25em (1140px) - paddings
        width: 100%;
        margin: 0 auto;
        padding: 0 0.75em;

        &--with-aside-nav {
            padding: 0 var(--scale-2r);

            @media screen and (min-width: $breakpoints-md) {
                padding: 0 var(--scale-6r);
            }
        }

        &--small {
            max-width: $container-width--small;
        }

        &--public {
            max-width: $container-width--public;
        }

        &--fluid {
            max-width: none;
            padding: 0 var(--scale-6r);

            @media screen and (min-width: $breakpoints-md) {
                padding: 0 var(--scale-10r);
            }
        }

        &--with-aside-nav {
            padding: 0 var(--scale-6r);
        }

        &--no-padding {
            padding: 0 !important;
        }
    }
</style>
