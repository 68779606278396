import { createI18n } from 'vue-i18n'
import fr from './vars/i18n/fr.json'
import en from './vars/i18n/en.json'
import { Lang } from '@/types/Lang.type'

const fallbackLocale = Lang.FR

const i18n = createI18n({
    legacy: false,
    locale: Lang.FR,
    fallbackLocale,
    messages: {
        fr,
        en
    },
    ...(import.meta.env?.MODE === 'test' && {
        fallbackWarn: false,
        missingWarn: false
    })
})

export default i18n
