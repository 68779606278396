import { useAuthStore } from '@/libs/stores/auth.store'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

let renewalPendingPromise: Promise<string | null> | null = null

export async function renewTokenInterceptor(
    axiosInstance: AxiosInstance,
    error: AxiosError
): Promise<AxiosResponse<unknown, unknown> | undefined> {
    const store = useAuthStore()

    // Force logout si on a pas réussi a renew le token
    if (error?.config?.url?.endsWith('/token')) {
        store.logout()
        throw error
    }

    if (error.response?.status !== 401 || error?.config?.url === '/login_check') {
        throw error
    }

    // On a pas réussi a rénouveler le token on logout directement.
    if (error.config?.url === '/token') {
        store.logout()
        return
    }

    if (!store.isLoggedIn) {
        // Force logout pour clean
        store.logout(`${import.meta.env.VITE_APP_BASE_URL}/login`)
    }

    try {
        if (store.adminOriginalToken) {
            store.exitSwitchToUser()
            return
        }

        if (!renewalPendingPromise) {
            renewalPendingPromise = store.refreshToken()
        }

        const newToken = await renewalPendingPromise

        if (!newToken) {
            throw 'no_token'
        }

        store.$patch({ authToken: newToken })

        if (error.config?.headers) {
            error.config.headers.Authorization = `Bearer ${newToken}`
        }

        if (error.config) {
            return axiosInstance.request(error.config)
        }
    } catch (e) {
        if (e !== 'no_token') {
            console.error(e)
        }

        store.logout()
    }
}

export default renewTokenInterceptor
