<script setup lang="ts">
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import usePublicHeaderNavigation, { Universe } from '@/libs/compositions/usePublicHeaderNavigation'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { SpaName } from '@/vars/SpaAttr'
    import { useI18n } from 'vue-i18n'
    import MainMenuEntry from '../../__partials__/MainMenuEntry.vue'
    import gsap from 'gsap'
    import { watch } from 'vue'

    const selectedUniverse = defineModel<Universe | null>()

    const { universeNavigation } = usePublicHeaderNavigation()
    const { navigate } = useNavigationResolver()
    const { t } = useI18n()

    const currentSpa = import.meta.env.VITE_SPA_RUNNING

    watch(selectedUniverse, (newSelectedUniverse, oldSelectedUniverse) => {
        const newUniverseEl = document.querySelector(`[data-universe="${newSelectedUniverse?.key}"]`)
        const oldUniverseEl = document.querySelector(`[data-universe="${oldSelectedUniverse?.key}"]`)

        if (newUniverseEl) {
            animateElement(newUniverseEl, true)
        }

        if (oldUniverseEl) {
            animateElement(oldUniverseEl, false)
        }
    })

    const animateElement = (el: Element, open: boolean) => {
        const animationOptions = {
            duration: 0.5,
            ease: 'power2'
        }

        if (open) {
            gsap.to(el, { ...animationOptions, height: 'auto', opacity: 1 })
        } else {
            gsap.to(el, { ...animationOptions, height: 0, opacity: 0 })
        }
    }

    const onSelectUniverse = (universe: Universe) => {
        if (universe.hasRole) {
            navigate(universe.to)
        } else {
            selectedUniverse.value = selectedUniverse.value?.key === universe.key ? null : universe
        }
    }

    const isActive = (space: string) => {
        const spa = space === 'club' ? SpaName.LECLUB : space === 'bs' ? SpaName.PRO : SpaName.LENETWORK
        return spa === currentSpa
    }
</script>

<template>
    <div class="menu__universes">
        <h4 class="menu__your-universes font-bold">{{ t('your_universes') }}</h4>
        <div
            v-for="universe in universeNavigation"
            :key="universe.key"
            class="menu__universe"
            :class="{ 'menu__universe--active': isActive(universe.key) }"
        >
            <MainMenuEntry
                :label="universe.label"
                :color="universe.color"
                :expanded="universe.hasRole ? undefined : selectedUniverse?.key === universe.key"
                no-border
                @click="() => onSelectUniverse(universe)"
            />
            <div :data-universe="universe.key" class="menu__universe-details">
                <div class="px-6 py-4">
                    <p class="mb-6 text-sm">{{ universe.text }}</p>
                    <ul class="menu__universe-links">
                        <li v-for="link in universe.items" :key="link.label" class="mb-6">
                            <Component :is="link.to ? SpaRouterLink : 'a'" v-bind="link" class="spaces__item">
                                {{ link.label }}
                            </Component>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    $borderBottom: solid var(--scale-px) var(--theme-color-gray--04);
    .menu {
        &__primary {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        &__your-universes {
            padding: var(--scale-6);
        }

        &__universes {
            margin-bottom: 0;
            margin-top: auto;
        }

        &__universe {
            background-color: var(--theme-color-legacy);
            color: white;
            &--active {
                background-color: var(--theme-color-royale);
            }
        }

        &__universe-details {
            height: 0; // For animation
            opacity: 0;
            overflow: hidden;
        }

        &__universe-links {
            list-style: none;
            padding-left: var(--scale-2);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "your_universes": "Vos espaces privés"
    },
    "en": {
        "your_universes": "Your private spaces"
    }
}
</i18n>
