import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default function trackingUtm(
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    Object.entries(to.query).forEach(([queryParamKey, queryParamValue]) => {
        if (queryParamValue && queryParamKey.startsWith('utm_')) {
            window.sessionStorage.setItem(
                queryParamKey,
                Array.isArray(queryParamValue) ? JSON.stringify(queryParamValue) : queryParamValue
            )
        }
    })

    return next()
}
