import AuthApi from '@/libs/api/Auth.api'
import { useAuthStore } from '@/libs/stores/auth.store'
import { AxiosResponse } from 'axios'

export default function renewTokenInterceptor(response: AxiosResponse) {
    const authStore = useAuthStore()
    const authToken = response.headers?.['ff-refresh-user']

    if (authToken) {
        authStore.updateTokens(authToken)
        if (import.meta.env.VITE_ENABLE_FORCE_LOGIN === 'true') {
            AuthApi.forceLogin()
        }
    }

    return response
}
