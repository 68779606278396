<script setup lang="ts">
    /**
     * Il est possible que nous ayons des pages qui soient communes entre certains SPA ou des composants qui fassent des
     * liens vers des sous-SPA 2 différente de la sous-SPA courrante. Le problème c'est que si nous essayons d'accéder à une page d'une sous-SPA différente avec un
     * <RouterLink/>, il y aura une erreur car la page n'existera pas dans le router. Il faut donc utiliser un <a/> classique.
     * Ce composant s'occupe de créer le bon type de lien (RouterLink ou a) en fonction de la route demandée.
     */
    import { useRouter } from 'vue-router'
    import {
        useNavigationResolver,
        RouteLocationPotentiallyNamedWithFullUrl
    } from '@/libs/compositions/useNavigationResolver'
    import { computed } from 'vue'

    const { redirectBounding } = useNavigationResolver()
    const router = useRouter()

    const props = defineProps<{
        to?: RouteLocationPotentiallyNamedWithFullUrl
        href?: string
        target?: '_blank' | string
    }>()

    const linkBindings = computed(() => {
        if (props.to) {
            const { is, ...bindings } = redirectBounding(props.to)

            if (props.target === '_blank' && bindings.to && !bindings.href) {
                return {
                    is: 'a',
                    bindings: {
                        href: router.resolve(bindings.to).href,
                        target: '_blank'
                    }
                }
            }

            return { is, bindings }
        } else if (props.href) {
            return {
                is: 'a',
                bindings: {
                    href: props.href,
                    target: props.target
                }
            }
        } else {
            return {
                is: 'div'
            }
        }
    })
</script>

<template>
    <Component :is="linkBindings.is" v-bind="linkBindings.bindings">
        <slot />
    </Component>
</template>
