<script setup lang="ts">
    import { ToastType } from '@/vars/ToastAttr'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { computed } from 'vue'

    const props = defineProps<{
        type: ToastType
        title?: string
        text?: string
        action?: {
            text: string
            handler: () => unknown
        }
        close: () => unknown
    }>()

    const iconName = computed(() => {
        switch (props.type) {
            case 'success':
                return 'task_alt'
            case 'error':
                return 'cancel'
            case 'warn':
                return 'info'
            default:
                return ''
        }
    })
</script>

<template>
    <div class="toast">
        <div v-if="iconName" class="toast__type" :class="`toast__type--${type}`">
            <BaseIcon :name="iconName" class="toast__icon" />
        </div>
        <div class="toast__content">
            <div class="toast__header" :class="{ 'mb-1': title && text }">
                <h3 v-if="title" class="toast__title">{{ title }}</h3>
                <p v-else-if="text" class="toast__text">{{ text }}</p>
                <button v-if="action && !title" type="button" class="toast__action" @click="action?.handler">
                    {{ action.text }}
                </button>
                <BaseIcon name="close" class="toast__close" @click="close" />
            </div>
            <p v-if="title && text" class="toast__text">{{ text }}</p>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .toast {
        padding: 1em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1em;
        box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
        margin: 0.25em;
        background-color: var(--theme-color-legacy);
        color: var(--theme-color-nuage);
        border-radius: 0.375em;
        margin-bottom: 0.5em;

        &__action {
            border: none;
            background: none;
            cursor: pointer;
            font-size: 0.625em;
            font-weight: 700;
            text-decoration: underline;
            transition: opacity 300ms;
            color: var(--theme-color-nuage);

            &:hover {
                opacity: 0.5;
            }
        }

        &__type {
            $size: 1em;
            width: $size;
            height: $size;
            color: var(--theme-primary-color);

            &--success {
                color: var(--theme-success-color);
            }

            &--warn {
                color: var(--theme-warn-color);
            }

            &--error {
                color: var(--theme-error-color);
            }
        }

        &__icon {
            font-size: 1.25em;
        }

        &__header {
            display: flex;
            flex-direction: row;
            gap: 1em;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            font-size: 0.875em;
        }

        &__text {
            font-size: 0.75em;
            flex: 1;
        }

        &__content {
            flex: 1;
        }

        &__close {
            font-size: 1.25em;
            cursor: pointer;
        }
    }
</style>

<i18n lang="json"></i18n>
