export enum EventType {
    EVENT = 'event',
    PHYSICAL = 'physical',
    LIVE = 'live',
    NETWORKING = 'networking',
    HYBRID = 'hybrid'
}

export enum SpecialEvent {
    TLF = 4778,
    RETAIL_PARTY = 403500,
    AMBASSADE_FR_MADRID = 4418,
    RETAIL_PARTY_2025_RETAILER = 5314,
    RETAIL_PARTY_2025_PUBLIC = 4662
}
