<script setup lang="ts">
    import { provide } from 'vue'
    import ContainerTemplate from '../templates/ContainerTemplate.vue'
    import ApplyHeader from './leclub/header/apply/ApplyHeader.vue'
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import { ThemeName } from '@/vars/ThemeAttr'

    const theme = ThemeName.LECLUB
    provide('themeName', theme)
</script>

<template>
    <ThemeLayer :theme="theme">
        <div class="template">
            <ApplyHeader />
            <ContainerTemplate class="template__main">
                <RouterView />
            </ContainerTemplate>
        </div>
    </ThemeLayer>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .template {
        background: var(--theme-background-color--important);

        &__main {
            padding: 1em;
            min-height: 90vh;
            background-color: var(--theme-background-color);

            @media screen and (min-width: $breakpoints-md) {
                padding: 2em;
            }
        }
    }
</style>
