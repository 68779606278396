type PhonePrefixCountry = {
    countryName: string
    countryNameFr: string
    phonePrefix: string
    countryCode: string
    countryCode3: string
}

/**
 * @debt quality "Pour harmoniser avec les autres constantes, voir si on le passe avec PascalCase (PhonePrefixList)"
 */

const phonePrefixList: PhonePrefixCountry[] = [
    {
        countryName: 'Afghanistan',
        countryNameFr: 'Afghanistan',
        phonePrefix: '+93',
        countryCode: 'AF',
        countryCode3: 'AFG'
    },
    {
        countryName: 'Aland Islands',
        countryNameFr: 'Îles Åland',
        phonePrefix: '+358',
        countryCode: 'AX',
        countryCode3: 'ALA'
    },
    {
        countryName: 'Albania',
        countryNameFr: 'Albanie',
        phonePrefix: '+355',
        countryCode: 'AL',
        countryCode3: 'ALB'
    },
    {
        countryName: 'Algeria',
        countryNameFr: 'Algérie',
        phonePrefix: '+213',
        countryCode: 'DZ',
        countryCode3: 'DZA'
    },
    {
        countryName: 'AmericanSamoa',
        countryNameFr: 'Samoa américaines',
        phonePrefix: '+1684',
        countryCode: 'AS',
        countryCode3: 'ASM'
    },
    {
        countryName: 'Andorra',
        countryNameFr: 'Andorre',
        phonePrefix: '+376',
        countryCode: 'AD',
        countryCode3: 'AND'
    },
    {
        countryName: 'Angola',
        countryNameFr: 'Angola',
        phonePrefix: '+244',
        countryCode: 'AO',
        countryCode3: 'AGO'
    },
    {
        countryName: 'Anguilla',
        countryNameFr: 'Anguilla',
        phonePrefix: '+1264',
        countryCode: 'AI',
        countryCode3: 'AIA'
    },
    {
        countryName: 'Antarctica',
        countryNameFr: 'Antarctique',
        phonePrefix: '+672',
        countryCode: 'AQ',
        countryCode3: 'ATA'
    },
    {
        countryName: 'Antigua and Barbuda',
        countryNameFr: 'Antigua-et-Barbuda',
        phonePrefix: '+1268',
        countryCode: 'AG',
        countryCode3: 'ATG'
    },
    {
        countryName: 'Argentina',
        countryNameFr: 'Argentine',
        phonePrefix: '+54',
        countryCode: 'AR',
        countryCode3: 'ARG'
    },
    {
        countryName: 'Armenia',
        countryNameFr: 'Arménie',
        phonePrefix: '+374',
        countryCode: 'AM',
        countryCode3: 'ARM'
    },
    {
        countryName: 'Aruba',
        countryNameFr: 'Aruba',
        phonePrefix: '+297',
        countryCode: 'AW',
        countryCode3: 'ABW'
    },
    {
        countryName: 'Australia',
        countryNameFr: 'Australie',
        phonePrefix: '+61',
        countryCode: 'AU',
        countryCode3: 'AUS'
    },
    {
        countryName: 'Austria',
        countryNameFr: 'Autriche',
        phonePrefix: '+43',
        countryCode: 'AT',
        countryCode3: 'AUT'
    },
    {
        countryName: 'Azerbaijan',
        countryNameFr: 'Azerbaïdjan',
        phonePrefix: '+994',
        countryCode: 'AZ',
        countryCode3: 'AZE'
    },
    {
        countryName: 'Bahamas',
        countryNameFr: 'Bahamas',
        phonePrefix: '+1242',
        countryCode: 'BS',
        countryCode3: 'BHS'
    },
    {
        countryName: 'Bahrain',
        countryNameFr: 'Bahreïn',
        phonePrefix: '+973',
        countryCode: 'BH',
        countryCode3: 'BHR'
    },
    {
        countryName: 'Bangladesh',
        countryNameFr: 'Bangladesh',
        phonePrefix: '+880',
        countryCode: 'BD',
        countryCode3: 'BGD'
    },
    {
        countryName: 'Barbados',
        countryNameFr: 'Barbade',
        phonePrefix: '+1246',
        countryCode: 'BB',
        countryCode3: 'BRB'
    },
    {
        countryName: 'Belarus',
        countryNameFr: 'Biélorussie',
        phonePrefix: '+375',
        countryCode: 'BY',
        countryCode3: 'BLR'
    },
    {
        countryName: 'Belgium',
        countryNameFr: 'Belgique',
        phonePrefix: '+32',
        countryCode: 'BE',
        countryCode3: 'BEL'
    },
    {
        countryName: 'Belize',
        countryNameFr: 'Belize',
        phonePrefix: '+501',
        countryCode: 'BZ',
        countryCode3: 'BLZ'
    },
    {
        countryName: 'Benin',
        countryNameFr: 'Bénin',
        phonePrefix: '+229',
        countryCode: 'BJ',
        countryCode3: 'BEN'
    },
    {
        countryName: 'Bermuda',
        countryNameFr: 'Bermudes',
        phonePrefix: '+1441',
        countryCode: 'BM',
        countryCode3: 'BMU'
    },
    {
        countryName: 'Bhutan',
        countryNameFr: 'Bhoutan',
        phonePrefix: '+975',
        countryCode: 'BT',
        countryCode3: 'BTN'
    },
    {
        countryName: 'Bolivia, Plurinational State of',
        countryNameFr: 'Bolivie',
        phonePrefix: '+591',
        countryCode: 'BO',
        countryCode3: 'BOL'
    },
    {
        countryName: 'Bosnia and Herzegovina',
        countryNameFr: 'Bosnie-Herzégovine',
        phonePrefix: '+387',
        countryCode: 'BA',
        countryCode3: 'BIH'
    },
    {
        countryName: 'Botswana',
        countryNameFr: 'Botswana',
        phonePrefix: '+267',
        countryCode: 'BW',
        countryCode3: 'BWA'
    },
    {
        countryName: 'Brazil',
        countryNameFr: 'Brésil',
        phonePrefix: '+55',
        countryCode: 'BR',
        countryCode3: 'BRA'
    },
    {
        countryName: 'British Indian Ocean Territory',
        countryNameFr: "Territoire britannique de l'océan Indien",
        phonePrefix: '+246',
        countryCode: 'IO',
        countryCode3: 'IOT'
    },
    {
        countryName: 'Brunei Darussalam',
        countryNameFr: 'Brunéi Darussalam',
        phonePrefix: '+673',
        countryCode: 'BN',
        countryCode3: 'BRN'
    },
    {
        countryName: 'Bulgaria',
        countryNameFr: 'Bulgarie',
        phonePrefix: '+359',
        countryCode: 'BG',
        countryCode3: 'BGR'
    },
    {
        countryName: 'Burkina Faso',
        countryNameFr: 'Burkina Faso',
        phonePrefix: '+226',
        countryCode: 'BF',
        countryCode3: 'BFA'
    },
    {
        countryName: 'Burundi',
        countryNameFr: 'Burundi',
        phonePrefix: '+257',
        countryCode: 'BI',
        countryCode3: 'BDI'
    },
    {
        countryName: 'Cambodia',
        countryNameFr: 'Cambodge',
        phonePrefix: '+855',
        countryCode: 'KH',
        countryCode3: 'KHM'
    },
    {
        countryName: 'Cameroon',
        countryNameFr: 'Cameroun',
        phonePrefix: '+237',
        countryCode: 'CM',
        countryCode3: 'CMR'
    },
    {
        countryName: 'Canada',
        countryNameFr: 'Canada',
        phonePrefix: '+1',
        countryCode: 'CA',
        countryCode3: 'CAN'
    },
    {
        countryName: 'Cape Verde',
        countryNameFr: 'Cap-Vert',
        phonePrefix: '+238',
        countryCode: 'CV',
        countryCode3: 'CPV'
    },
    {
        countryName: 'Cayman Islands',
        countryNameFr: 'Îles Caïmans',
        phonePrefix: '+345',
        countryCode: 'KY',
        countryCode3: 'CYM'
    },
    {
        countryName: 'Central African Republic',
        countryNameFr: 'République centrafricaine',
        phonePrefix: '+236',
        countryCode: 'CF',
        countryCode3: 'CAF'
    },
    {
        countryName: 'Chad',
        countryNameFr: 'Tchad',
        phonePrefix: '+235',
        countryCode: 'TD',
        countryCode3: 'TCD'
    },
    {
        countryName: 'Chile',
        countryNameFr: 'Chili',
        phonePrefix: '+56',
        countryCode: 'CL',
        countryCode3: 'CHL'
    },
    {
        countryName: 'China',
        countryNameFr: 'Chine',
        phonePrefix: '+86',
        countryCode: 'CN',
        countryCode3: 'CHN'
    },
    {
        countryName: 'Christmas Island',
        countryNameFr: 'Île Christmas',
        phonePrefix: '+61',
        countryCode: 'CX',
        countryCode3: 'CXR'
    },
    {
        countryName: 'Cocos (Keeling) Islands',
        countryNameFr: 'Îles Cocos',
        phonePrefix: '+61',
        countryCode: 'CC',
        countryCode3: 'CCK'
    },
    {
        countryName: 'Colombia',
        countryNameFr: 'Colombie',
        phonePrefix: '+57',
        countryCode: 'CO',
        countryCode3: 'COL'
    },
    {
        countryName: 'Comoros',
        countryNameFr: 'Comores',
        phonePrefix: '+269',
        countryCode: 'KM',
        countryCode3: 'COM'
    },
    {
        countryName: 'Congo',
        countryNameFr: 'Congo',
        phonePrefix: '+242',
        countryCode: 'CG',
        countryCode3: 'COG'
    },
    {
        countryName: 'Congo, The Democratic Republic of the Congo',
        countryNameFr: 'République démocratique du Congo',
        phonePrefix: '+243',
        countryCode: 'CD',
        countryCode3: 'COD'
    },
    {
        countryName: 'Cook Islands',
        countryNameFr: 'Îles Cook',
        phonePrefix: '+682',
        countryCode: 'CK',
        countryCode3: 'COK'
    },
    {
        countryName: 'Costa Rica',
        countryNameFr: 'Costa Rica',
        phonePrefix: '+506',
        countryCode: 'CR',
        countryCode3: 'CRI'
    },
    {
        countryName: "Cote d'Ivoire",
        countryNameFr: "Côte d'Ivoire",
        phonePrefix: '+225',
        countryCode: 'CI',
        countryCode3: 'CIV'
    },
    {
        countryName: 'Croatia',
        countryNameFr: 'Croatie',
        phonePrefix: '+385',
        countryCode: 'HR',
        countryCode3: 'HRV'
    },
    {
        countryName: 'Cuba',
        countryNameFr: 'Cuba',
        phonePrefix: '+53',
        countryCode: 'CU',
        countryCode3: 'CUB'
    },
    {
        countryName: 'Cyprus',
        countryNameFr: 'Chypre',
        phonePrefix: '+357',
        countryCode: 'CY',
        countryCode3: 'CYP'
    },
    {
        countryName: 'Czech Republic',
        countryNameFr: 'République tchèque',
        phonePrefix: '+420',
        countryCode: 'CZ',
        countryCode3: 'CZE'
    },
    {
        countryName: 'Denmark',
        countryNameFr: 'Danemark',
        phonePrefix: '+45',
        countryCode: 'DK',
        countryCode3: 'DNK'
    },
    {
        countryName: 'Djibouti',
        countryNameFr: 'Djibouti',
        phonePrefix: '+253',
        countryCode: 'DJ',
        countryCode3: 'DJI'
    },
    {
        countryName: 'Dominica',
        countryNameFr: 'Dominique',
        phonePrefix: '+1767',
        countryCode: 'DM',
        countryCode3: 'DMA'
    },
    {
        countryName: 'Dominican Republic',
        countryNameFr: 'République dominicaine',
        phonePrefix: '+1849',
        countryCode: 'DO',
        countryCode3: 'DOM'
    },
    {
        countryName: 'Ecuador',
        countryNameFr: 'Équateur',
        phonePrefix: '+593',
        countryCode: 'EC',
        countryCode3: 'ECU'
    },
    {
        countryName: 'Egypt',
        countryNameFr: 'Égypte',
        phonePrefix: '+20',
        countryCode: 'EG',
        countryCode3: 'EGY'
    },
    {
        countryName: 'El Salvador',
        countryNameFr: 'Salvador',
        phonePrefix: '+503',
        countryCode: 'SV',
        countryCode3: 'SLV'
    },
    {
        countryName: 'Equatorial Guinea',
        countryNameFr: 'Guinée équatoriale',
        phonePrefix: '+240',
        countryCode: 'GQ',
        countryCode3: 'GNQ'
    },
    {
        countryName: 'Eritrea',
        countryNameFr: 'Érythrée',
        phonePrefix: '+291',
        countryCode: 'ER',
        countryCode3: 'ERI'
    },
    {
        countryName: 'Estonia',
        countryNameFr: 'Estonie',
        phonePrefix: '+372',
        countryCode: 'EE',
        countryCode3: 'EST'
    },
    {
        countryName: 'Ethiopia',
        countryNameFr: 'Éthiopie',
        phonePrefix: '+251',
        countryCode: 'ET',
        countryCode3: 'ETH'
    },
    {
        countryName: 'Falkland Islands (Malvinas)',
        countryNameFr: 'Îles Malouines',
        phonePrefix: '+500',
        countryCode: 'FK',
        countryCode3: 'FLK'
    },
    {
        countryName: 'Faroe Islands',
        countryNameFr: 'Îles Féroé',
        phonePrefix: '+298',
        countryCode: 'FO',
        countryCode3: 'FRO'
    },
    {
        countryName: 'Fiji',
        countryNameFr: 'Fidji',
        phonePrefix: '+679',
        countryCode: 'FJ',
        countryCode3: 'FJI'
    },
    {
        countryName: 'Finland',
        countryNameFr: 'Finlande',
        phonePrefix: '+358',
        countryCode: 'FI',
        countryCode3: 'FIN'
    },
    {
        countryName: 'France',
        countryNameFr: 'France',
        phonePrefix: '+33',
        countryCode: 'FR',
        countryCode3: 'FRA'
    },
    {
        countryName: 'French Guiana',
        countryNameFr: 'Guyane française',
        phonePrefix: '+594',
        countryCode: 'GF',
        countryCode3: 'GUF'
    },
    {
        countryName: 'French Polynesia',
        countryNameFr: 'Polynésie française',
        phonePrefix: '+689',
        countryCode: 'PF',
        countryCode3: 'PYF'
    },
    {
        countryName: 'Gabon',
        countryNameFr: 'Gabon',
        phonePrefix: '+241',
        countryCode: 'GA',
        countryCode3: 'GAB'
    },
    {
        countryName: 'Gambia',
        countryNameFr: 'Gambie',
        phonePrefix: '+220',
        countryCode: 'GM',
        countryCode3: 'GMB'
    },
    {
        countryName: 'Georgia',
        countryNameFr: 'Géorgie',
        phonePrefix: '+995',
        countryCode: 'GE',
        countryCode3: 'GEO'
    },
    {
        countryName: 'Germany',
        countryNameFr: 'Allemagne',
        phonePrefix: '+49',
        countryCode: 'DE',
        countryCode3: 'DEU'
    },
    {
        countryName: 'Ghana',
        countryNameFr: 'Ghana',
        phonePrefix: '+233',
        countryCode: 'GH',
        countryCode3: 'GHA'
    },
    {
        countryName: 'Gibraltar',
        countryNameFr: 'Gibraltar',
        phonePrefix: '+350',
        countryCode: 'GI',
        countryCode3: 'GIB'
    },
    {
        countryName: 'Greece',
        countryNameFr: 'Grèce',
        phonePrefix: '+30',
        countryCode: 'GR',
        countryCode3: 'GRC'
    },
    {
        countryName: 'Greenland',
        countryNameFr: 'Groenland',
        phonePrefix: '+299',
        countryCode: 'GL',
        countryCode3: 'GRL'
    },
    {
        countryName: 'Grenada',
        countryNameFr: 'Grenade',
        phonePrefix: '+1473',
        countryCode: 'GD',
        countryCode3: 'GRD'
    },
    {
        countryName: 'Guadeloupe',
        countryNameFr: 'Guadeloupe',
        phonePrefix: '+590',
        countryCode: 'GP',
        countryCode3: 'GLP'
    },
    {
        countryName: 'Guam',
        countryNameFr: 'Guam',
        phonePrefix: '+1671',
        countryCode: 'GU',
        countryCode3: 'GUM'
    },
    {
        countryName: 'Guatemala',
        countryNameFr: 'Guatemala',
        phonePrefix: '+502',
        countryCode: 'GT',
        countryCode3: 'GTM'
    },
    {
        countryName: 'Guernsey',
        countryNameFr: 'Guernesey',
        phonePrefix: '+44',
        countryCode: 'GG',
        countryCode3: 'GGY'
    },
    {
        countryName: 'Guinea',
        countryNameFr: 'Guinée',
        phonePrefix: '+224',
        countryCode: 'GN',
        countryCode3: 'GIN'
    },
    {
        countryName: 'Guinea-Bissau',
        countryNameFr: 'Guinée-Bissau',
        phonePrefix: '+245',
        countryCode: 'GW',
        countryCode3: 'GNB'
    },
    {
        countryName: 'Guyana',
        countryNameFr: 'Guyana',
        phonePrefix: '+595',
        countryCode: 'GY',
        countryCode3: 'GUY'
    },
    {
        countryName: 'Haiti',
        countryNameFr: 'Haïti',
        phonePrefix: '+509',
        countryCode: 'HT',
        countryCode3: 'HTI'
    },
    {
        countryName: 'Holy See (Vatican City State)',
        countryNameFr: 'Saint-Siège (État de la Cité du Vatican)',
        phonePrefix: '+379',
        countryCode: 'VA',
        countryCode3: 'VAT'
    },
    {
        countryName: 'Honduras',
        countryNameFr: 'Honduras',
        phonePrefix: '+504',
        countryCode: 'HN',
        countryCode3: 'HND'
    },
    {
        countryName: 'Hong Kong',
        countryNameFr: 'Hong Kong',
        phonePrefix: '+852',
        countryCode: 'HK',
        countryCode3: 'HKG'
    },
    {
        countryName: 'Hungary',
        countryNameFr: 'Hongrie',
        phonePrefix: '+36',
        countryCode: 'HU',
        countryCode3: 'HUN'
    },
    {
        countryName: 'Iceland',
        countryNameFr: 'Islande',
        phonePrefix: '+354',
        countryCode: 'IS',
        countryCode3: 'ISL'
    },
    {
        countryName: 'India',
        countryNameFr: 'Inde',
        phonePrefix: '+91',
        countryCode: 'IN',
        countryCode3: 'IND'
    },
    {
        countryName: 'Indonesia',
        countryNameFr: 'Indonésie',
        phonePrefix: '+62',
        countryCode: 'ID',
        countryCode3: 'IDN'
    },
    {
        countryName: 'Iran, Islamic Republic of Persian Gulf',
        countryNameFr: 'Iran',
        phonePrefix: '+98',
        countryCode: 'IR',
        countryCode3: 'IRN'
    },
    {
        countryName: 'Iraq',
        countryNameFr: 'Irak',
        phonePrefix: '+964',
        countryCode: 'IQ',
        countryCode3: 'IRQ'
    },
    {
        countryName: 'Ireland',
        countryNameFr: 'Irlande',
        phonePrefix: '+353',
        countryCode: 'IE',
        countryCode3: 'IRL'
    },
    {
        countryName: 'Isle of Man',
        countryNameFr: 'Île de Man',
        phonePrefix: '+44',
        countryCode: 'IM',
        countryCode3: 'IMN'
    },
    {
        countryName: 'Israel',
        countryNameFr: 'Israël',
        phonePrefix: '+972',
        countryCode: 'IL',
        countryCode3: 'ISR'
    },
    {
        countryName: 'Italy',
        countryNameFr: 'Italie',
        phonePrefix: '+39',
        countryCode: 'IT',
        countryCode3: 'ITA'
    },
    {
        countryName: 'Jamaica',
        countryNameFr: 'Jamaïque',
        phonePrefix: '+1876',
        countryCode: 'JM',
        countryCode3: 'JAM'
    },
    {
        countryName: 'Japan',
        countryNameFr: 'Japon',
        phonePrefix: '+81',
        countryCode: 'JP',
        countryCode3: 'JPN'
    },
    {
        countryName: 'Jersey',
        countryNameFr: 'Jersey',
        phonePrefix: '+44',
        countryCode: 'JE',
        countryCode3: 'JEY'
    },
    {
        countryName: 'Jordan',
        countryNameFr: 'Jordanie',
        phonePrefix: '+962',
        countryCode: 'JO',
        countryCode3: 'JOR'
    },
    {
        countryName: 'Kazakhstan',
        countryNameFr: 'Kazakhstan',
        phonePrefix: '+77',
        countryCode: 'KZ',
        countryCode3: 'KAZ'
    },
    {
        countryName: 'Kenya',
        countryNameFr: 'Kenya',
        phonePrefix: '+254',
        countryCode: 'KE',
        countryCode3: 'KEN'
    },
    {
        countryName: 'Kiribati',
        countryNameFr: 'Kiribati',
        phonePrefix: '+686',
        countryCode: 'KI',
        countryCode3: 'KIR'
    },
    {
        countryName: "Korea, Democratic People's Republic of Korea",
        countryNameFr: 'Corée du Nord',
        phonePrefix: '+850',
        countryCode: 'KP',
        countryCode3: 'PRK'
    },
    {
        countryName: 'Korea, Republic of South Korea',
        countryNameFr: 'Corée du Sud',
        phonePrefix: '+82',
        countryCode: 'KR',
        countryCode3: 'KOR'
    },
    {
        countryName: 'Kuwait',
        countryNameFr: 'Koweït',
        phonePrefix: '+965',
        countryCode: 'KW',
        countryCode3: 'KWT'
    },
    {
        countryName: 'Kyrgyzstan',
        countryNameFr: 'Kirghizistan',
        phonePrefix: '+996',
        countryCode: 'KG',
        countryCode3: 'KGZ'
    },
    {
        countryName: 'Laos',
        countryNameFr: 'Laos',
        phonePrefix: '+856',
        countryCode: 'LA',
        countryCode3: 'LAO'
    },
    {
        countryName: 'Latvia',
        countryNameFr: 'Lettonie',
        phonePrefix: '+371',
        countryCode: 'LV',
        countryCode3: 'LVA'
    },
    {
        countryName: 'Lebanon',
        countryNameFr: 'Liban',
        phonePrefix: '+961',
        countryCode: 'LB',
        countryCode3: 'LBN'
    },
    {
        countryName: 'Lesotho',
        countryNameFr: 'Lesotho',
        phonePrefix: '+266',
        countryCode: 'LS',
        countryCode3: 'LSO'
    },
    {
        countryName: 'Liberia',
        countryNameFr: 'Libéria',
        phonePrefix: '+231',
        countryCode: 'LR',
        countryCode3: 'LBR'
    },
    {
        countryName: 'Libyan Arab Jamahiriya',
        countryNameFr: 'Libye',
        phonePrefix: '+218',
        countryCode: 'LY',
        countryCode3: 'LBY'
    },
    {
        countryName: 'Liechtenstein',
        countryNameFr: 'Liechtenstein',
        phonePrefix: '+423',
        countryCode: 'LI',
        countryCode3: 'LIE'
    },
    {
        countryName: 'Lithuania',
        countryNameFr: 'Lituanie',
        phonePrefix: '+370',
        countryCode: 'LT',
        countryCode3: 'LTU'
    },
    {
        countryName: 'Luxembourg',
        countryNameFr: 'Luxembourg',
        phonePrefix: '+352',
        countryCode: 'LU',
        countryCode3: 'LUX'
    },
    {
        countryName: 'Macao',
        countryNameFr: 'Macao',
        phonePrefix: '+853',
        countryCode: 'MO',
        countryCode3: 'MAC'
    },
    {
        countryName: 'Macedonia',
        countryNameFr: 'Macédoine',
        phonePrefix: '+389',
        countryCode: 'MK',
        countryCode3: 'MKD'
    },
    {
        countryName: 'Madagascar',
        countryNameFr: 'Madagascar',
        phonePrefix: '+261',
        countryCode: 'MG',
        countryCode3: 'MDG'
    },
    {
        countryName: 'Malawi',
        countryNameFr: 'Malawi',
        phonePrefix: '+265',
        countryCode: 'MW',
        countryCode3: 'MWI'
    },
    {
        countryName: 'Malaysia',
        countryNameFr: 'Malaisie',
        phonePrefix: '+60',
        countryCode: 'MY',
        countryCode3: 'MYS'
    },
    {
        countryName: 'Maldives',
        countryNameFr: 'Maldives',
        phonePrefix: '+960',
        countryCode: 'MV',
        countryCode3: 'MDV'
    },
    {
        countryName: 'Mali',
        countryNameFr: 'Mali',
        phonePrefix: '+223',
        countryCode: 'ML',
        countryCode3: 'MLI'
    },
    {
        countryName: 'Malta',
        countryNameFr: 'Malte',
        phonePrefix: '+356',
        countryCode: 'MT',
        countryCode3: 'MLT'
    },
    {
        countryName: 'Marshall Islands',
        countryNameFr: 'Îles Marshall',
        phonePrefix: '+692',
        countryCode: 'MH',
        countryCode3: 'MHL'
    },
    {
        countryName: 'Martinique',
        countryNameFr: 'Martinique',
        phonePrefix: '+596',
        countryCode: 'MQ',
        countryCode3: 'MTQ'
    },
    {
        countryName: 'Mauritania',
        countryNameFr: 'Mauritanie',
        phonePrefix: '+222',
        countryCode: 'MR',
        countryCode3: 'MRT'
    },
    {
        countryName: 'Mauritius',
        countryNameFr: 'Maurice',
        phonePrefix: '+230',
        countryCode: 'MU',
        countryCode3: 'MUS'
    },
    {
        countryName: 'Mayotte',
        countryNameFr: 'Mayotte',
        phonePrefix: '+262',
        countryCode: 'YT',
        countryCode3: 'MYT'
    },
    {
        countryName: 'Mexico',
        countryNameFr: 'Mexique',
        phonePrefix: '+52',
        countryCode: 'MX',
        countryCode3: 'MEX'
    },
    {
        countryName: 'Micronesia, Federated States of Micronesia',
        countryNameFr: 'Micronésie',
        phonePrefix: '+691',
        countryCode: 'FM',
        countryCode3: 'FSM'
    },
    {
        countryName: 'Moldova',
        countryNameFr: 'Moldavie',
        phonePrefix: '+373',
        countryCode: 'MD',
        countryCode3: 'MDA'
    },
    {
        countryName: 'Monaco',
        countryNameFr: 'Monaco',
        phonePrefix: '+377',
        countryCode: 'MC',
        countryCode3: 'MCO'
    },
    {
        countryName: 'Mongolia',
        countryNameFr: 'Mongolie',
        phonePrefix: '+976',
        countryCode: 'MN',
        countryCode3: 'MNG'
    },
    {
        countryName: 'Montenegro',
        countryNameFr: 'Monténégro',
        phonePrefix: '+382',
        countryCode: 'ME',
        countryCode3: 'MNE'
    },
    {
        countryName: 'Montserrat',
        countryNameFr: 'Montserrat',
        phonePrefix: '+1664',
        countryCode: 'MS',
        countryCode3: 'MSR'
    },
    {
        countryName: 'Morocco',
        countryNameFr: 'Maroc',
        phonePrefix: '+212',
        countryCode: 'MA',
        countryCode3: 'MAR'
    },
    {
        countryName: 'Mozambique',
        countryNameFr: 'Mozambique',
        phonePrefix: '+258',
        countryCode: 'MZ',
        countryCode3: 'MOZ'
    },
    {
        countryName: 'Myanmar',
        countryNameFr: 'Myanmar',
        phonePrefix: '+95',
        countryCode: 'MM',
        countryCode3: 'MMR'
    },
    {
        countryName: 'Namibia',
        countryNameFr: 'Namibie',
        phonePrefix: '+264',
        countryCode: 'NA',
        countryCode3: 'NAM'
    },
    {
        countryName: 'Nauru',
        countryNameFr: 'Nauru',
        phonePrefix: '+674',
        countryCode: 'NR',
        countryCode3: 'NRU'
    },
    {
        countryName: 'Nepal',
        countryNameFr: 'Népal',
        phonePrefix: '+977',
        countryCode: 'NP',
        countryCode3: 'NPL'
    },
    {
        countryName: 'Netherlands',
        countryNameFr: 'Pays-Bas',
        phonePrefix: '+31',
        countryCode: 'NL',
        countryCode3: 'NLD'
    },
    {
        countryName: 'Netherlands Antilles',
        countryNameFr: 'Antilles néerlandaises',
        phonePrefix: '+599',
        countryCode: 'AN',
        countryCode3: 'UNKNOWN'
    },
    {
        countryName: 'New Caledonia',
        countryNameFr: 'Nouvelle-Calédonie',
        phonePrefix: '+687',
        countryCode: 'NC',
        countryCode3: 'NCL'
    },
    {
        countryName: 'New Zealand',
        countryNameFr: 'Nouvelle-Zélande',
        phonePrefix: '+64',
        countryCode: 'NZ',
        countryCode3: 'NZL'
    },
    {
        countryName: 'Nicaragua',
        countryNameFr: 'Nicaragua',
        phonePrefix: '+505',
        countryCode: 'NI',
        countryCode3: 'NIC'
    },
    {
        countryName: 'Niger',
        countryNameFr: 'Niger',
        phonePrefix: '+227',
        countryCode: 'NE',
        countryCode3: 'NER'
    },
    {
        countryName: 'Nigeria',
        countryNameFr: 'Nigeria',
        phonePrefix: '+234',
        countryCode: 'NG',
        countryCode3: 'NGA'
    },
    {
        countryName: 'Niue',
        countryNameFr: 'Niue',
        phonePrefix: '+683',
        countryCode: 'NU',
        countryCode3: 'NIU'
    },
    {
        countryName: 'Norfolk Island',
        countryNameFr: 'Île Norfolk',
        phonePrefix: '+672',
        countryCode: 'NF',
        countryCode3: 'NFK'
    },
    {
        countryName: 'Northern Mariana Islands',
        countryNameFr: 'Îles Mariannes du Nord',
        phonePrefix: '+1670',
        countryCode: 'MP',
        countryCode3: 'MNP'
    },
    {
        countryName: 'Norway',
        countryNameFr: 'Norvège',
        phonePrefix: '+47',
        countryCode: 'NO',
        countryCode3: 'NOR'
    },
    {
        countryName: 'Oman',
        countryNameFr: 'Oman',
        phonePrefix: '+968',
        countryCode: 'OM',
        countryCode3: 'OMN'
    },
    {
        countryName: 'Pakistan',
        countryNameFr: 'Pakistan',
        phonePrefix: '+92',
        countryCode: 'PK',
        countryCode3: 'PAK'
    },
    {
        countryName: 'Palau',
        countryNameFr: 'Palaos',
        phonePrefix: '+680',
        countryCode: 'PW',
        countryCode3: 'PLW'
    },
    {
        countryName: 'Palestinian Territory, Occupied',
        countryNameFr: 'Territoire palestinien occupé',
        phonePrefix: '+970',
        countryCode: 'PS',
        countryCode3: 'PSE'
    },
    {
        countryName: 'Panama',
        countryNameFr: 'Panama',
        phonePrefix: '+507',
        countryCode: 'PA',
        countryCode3: 'PAN'
    },
    {
        countryName: 'Papua New Guinea',
        countryNameFr: 'Papouasie-Nouvelle-Guinée',
        phonePrefix: '+675',
        countryCode: 'PG',
        countryCode3: 'PNG'
    },
    {
        countryName: 'Paraguay',
        countryNameFr: 'Paraguay',
        phonePrefix: '+595',
        countryCode: 'PY',
        countryCode3: 'PRY'
    },
    {
        countryName: 'Peru',
        countryNameFr: 'Pérou',
        phonePrefix: '+51',
        countryCode: 'PE',
        countryCode3: 'PER'
    },
    {
        countryName: 'Philippines',
        countryNameFr: 'Philippines',
        phonePrefix: '+63',
        countryCode: 'PH',
        countryCode3: 'PHL'
    },
    {
        countryName: 'Pitcairn',
        countryNameFr: 'Îles Pitcairn',
        phonePrefix: '+872',
        countryCode: 'PN',
        countryCode3: 'PCN'
    },
    {
        countryName: 'Poland',
        countryNameFr: 'Pologne',
        phonePrefix: '+48',
        countryCode: 'PL',
        countryCode3: 'POL'
    },
    {
        countryName: 'Portugal',
        countryNameFr: 'Portugal',
        phonePrefix: '+351',
        countryCode: 'PT',
        countryCode3: 'PRT'
    },
    {
        countryName: 'Puerto Rico',
        countryNameFr: 'Porto Rico',
        phonePrefix: '+1939',
        countryCode: 'PR',
        countryCode3: 'PRI'
    },
    {
        countryName: 'Qatar',
        countryNameFr: 'Qatar',
        phonePrefix: '+974',
        countryCode: 'QA',
        countryCode3: 'QAT'
    },
    {
        countryName: 'Romania',
        countryNameFr: 'Roumanie',
        phonePrefix: '+40',
        countryCode: 'RO',
        countryCode3: 'ROU'
    },
    {
        countryName: 'Russia',
        countryNameFr: 'Russie',
        phonePrefix: '+7',
        countryCode: 'RU',
        countryCode3: 'RUS'
    },
    {
        countryName: 'Rwanda',
        countryNameFr: 'Rwanda',
        phonePrefix: '+250',
        countryCode: 'RW',
        countryCode3: 'RWA'
    },
    {
        countryName: 'Reunion',
        countryNameFr: 'Réunion',
        phonePrefix: '+262',
        countryCode: 'RE',
        countryCode3: 'REU'
    },
    {
        countryName: 'Saint Barthelemy',
        countryNameFr: 'Saint-Barthélemy',
        phonePrefix: '+590',
        countryCode: 'BL',
        countryCode3: 'BLM'
    },
    {
        countryName: 'Saint Helena, Ascension and Tristan Da Cunha',
        countryNameFr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
        phonePrefix: '+290',
        countryCode: 'SH',
        countryCode3: 'SHN'
    },
    {
        countryName: 'Saint Kitts and Nevis',
        countryNameFr: 'Saint-Christophe-et-Niévès',
        phonePrefix: '+1869',
        countryCode: 'KN',
        countryCode3: 'KNA'
    },
    {
        countryName: 'Saint Lucia',
        countryNameFr: 'Sainte-Lucie',
        phonePrefix: '+1758',
        countryCode: 'LC',
        countryCode3: 'LCA'
    },
    {
        countryName: 'Saint Martin',
        countryNameFr: 'Saint-Martin',
        phonePrefix: '+590',
        countryCode: 'MF',
        countryCode3: 'MAF'
    },
    {
        countryName: 'Saint Pierre and Miquelon',
        countryNameFr: 'Saint-Pierre-et-Miquelon',
        phonePrefix: '+508',
        countryCode: 'PM',
        countryCode3: 'SPM'
    },
    {
        countryName: 'Saint Vincent and the Grenadines',
        countryNameFr: 'Saint-Vincent-et-les-Grenadines',
        phonePrefix: '+1784',
        countryCode: 'VC',
        countryCode3: 'VCT'
    },
    {
        countryName: 'Samoa',
        countryNameFr: 'Samoa',
        phonePrefix: '+685',
        countryCode: 'WS',
        countryCode3: 'WSM'
    },
    {
        countryName: 'San Marino',
        countryNameFr: 'Saint-Marin',
        phonePrefix: '+378',
        countryCode: 'SM',
        countryCode3: 'SMR'
    },
    {
        countryName: 'Sao Tome and Principe',
        countryNameFr: 'Sao Tomé-et-Principe',
        phonePrefix: '+239',
        countryCode: 'ST',
        countryCode3: 'STP'
    },
    {
        countryName: 'Saudi Arabia',
        countryNameFr: 'Arabie saoudite',
        phonePrefix: '+966',
        countryCode: 'SA',
        countryCode3: 'SAU'
    },
    {
        countryName: 'Senegal',
        countryNameFr: 'Sénégal',
        phonePrefix: '+221',
        countryCode: 'SN',
        countryCode3: 'SEN'
    },
    {
        countryName: 'Serbia',
        countryNameFr: 'Serbie',
        phonePrefix: '+381',
        countryCode: 'RS',
        countryCode3: 'SRB'
    },
    {
        countryName: 'Seychelles',
        countryNameFr: 'Seychelles',
        phonePrefix: '+248',
        countryCode: 'SC',
        countryCode3: 'SYC'
    },
    {
        countryName: 'Sierra Leone',
        countryNameFr: 'Sierra Leone',
        phonePrefix: '+232',
        countryCode: 'SL',
        countryCode3: 'SLE'
    },
    {
        countryName: 'Singapore',
        countryNameFr: 'Singapour',
        phonePrefix: '+65',
        countryCode: 'SG',
        countryCode3: 'SGP'
    },
    {
        countryName: 'Slovakia',
        countryNameFr: 'Slovaquie',
        phonePrefix: '+421',
        countryCode: 'SK',
        countryCode3: 'SVK'
    },
    {
        countryName: 'Slovenia',
        countryNameFr: 'Slovénie',
        phonePrefix: '+386',
        countryCode: 'SI',
        countryCode3: 'SVN'
    },
    {
        countryName: 'Solomon Islands',
        countryNameFr: 'Îles Salomon',
        phonePrefix: '+677',
        countryCode: 'SB',
        countryCode3: 'SLB'
    },
    {
        countryName: 'Somalia',
        countryNameFr: 'Somalie',
        phonePrefix: '+252',
        countryCode: 'SO',
        countryCode3: 'SOM'
    },
    {
        countryName: 'South Africa',
        countryNameFr: 'Afrique du Sud',
        phonePrefix: '+27',
        countryCode: 'ZA',
        countryCode3: 'ZAF'
    },
    {
        countryName: 'South Sudan',
        countryNameFr: 'Soudan du Sud',
        phonePrefix: '+211',
        countryCode: 'SS',
        countryCode3: 'SSD'
    },
    {
        countryName: 'South Georgia and the South Sandwich Islands',
        countryNameFr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
        phonePrefix: '+500',
        countryCode: 'GS',
        countryCode3: 'SGS'
    },
    {
        countryName: 'Spain',
        countryNameFr: 'Espagne',
        phonePrefix: '+34',
        countryCode: 'ES',
        countryCode3: 'ESP'
    },
    {
        countryName: 'Sri Lanka',
        countryNameFr: 'Sri Lanka',
        phonePrefix: '+94',
        countryCode: 'LK',
        countryCode3: 'LKA'
    },
    {
        countryName: 'Sudan',
        countryNameFr: 'Soudan',
        phonePrefix: '+249',
        countryCode: 'SD',
        countryCode3: 'SDN'
    },
    {
        countryName: 'Suriname',
        countryNameFr: 'Suriname',
        phonePrefix: '+597',
        countryCode: 'SR',
        countryCode3: 'SUR'
    },
    {
        countryName: 'Svalbard and Jan Mayen',
        countryNameFr: 'Svalbard et Jan Mayen',
        phonePrefix: '+47',
        countryCode: 'SJ',
        countryCode3: 'SJM'
    },
    {
        countryName: 'Swaziland',
        countryNameFr: 'Eswatini',
        phonePrefix: '+268',
        countryCode: 'SZ',
        countryCode3: 'UNKNOWN'
    },
    {
        countryName: 'Sweden',
        countryNameFr: 'Suède',
        phonePrefix: '+46',
        countryCode: 'SE',
        countryCode3: 'SWE'
    },
    {
        countryName: 'Switzerland',
        countryNameFr: 'Suisse',
        phonePrefix: '+41',
        countryCode: 'CH',
        countryCode3: 'CHE'
    },
    {
        countryName: 'Syrian Arab Republic',
        countryNameFr: 'Syrie',
        phonePrefix: '+963',
        countryCode: 'SY',
        countryCode3: 'SYR'
    },
    {
        countryName: 'Taiwan',
        countryNameFr: 'Taïwan',
        phonePrefix: '+886',
        countryCode: 'TW',
        countryCode3: 'TWN'
    },
    {
        countryName: 'Tajikistan',
        countryNameFr: 'Tadjikistan',
        phonePrefix: '+992',
        countryCode: 'TJ',
        countryCode3: 'TJK'
    },
    {
        countryName: 'Tanzania, United Republic of Tanzania',
        countryNameFr: 'Tanzanie',
        phonePrefix: '+255',
        countryCode: 'TZ',
        countryCode3: 'TZA'
    },
    {
        countryName: 'Thailand',
        countryNameFr: 'Thaïlande',
        phonePrefix: '+66',
        countryCode: 'TH',
        countryCode3: 'THA'
    },
    {
        countryName: 'Timor-Leste',
        countryNameFr: 'Timor oriental',
        phonePrefix: '+670',
        countryCode: 'TL',
        countryCode3: 'TLS'
    },
    {
        countryName: 'Togo',
        countryNameFr: 'Togo',
        phonePrefix: '+228',
        countryCode: 'TG',
        countryCode3: 'TGO'
    },
    {
        countryName: 'Tokelau',
        countryNameFr: 'Tokelau',
        phonePrefix: '+690',
        countryCode: 'TK',
        countryCode3: 'TKL'
    },
    {
        countryName: 'Tonga',
        countryNameFr: 'Tonga',
        phonePrefix: '+676',
        countryCode: 'TO',
        countryCode3: 'TON'
    },
    {
        countryName: 'Trinidad and Tobago',
        countryNameFr: 'Trinité-et-Tobago',
        phonePrefix: '+1868',
        countryCode: 'TT',
        countryCode3: 'TTO'
    },
    {
        countryName: 'Tunisia',
        countryNameFr: 'Tunisie',
        phonePrefix: '+216',
        countryCode: 'TN',
        countryCode3: 'TUN'
    },
    {
        countryName: 'Turkey',
        countryNameFr: 'Turquie',
        phonePrefix: '+90',
        countryCode: 'TR',
        countryCode3: 'TUR'
    },
    {
        countryName: 'Turkmenistan',
        countryNameFr: 'Turkménistan',
        phonePrefix: '+993',
        countryCode: 'TM',
        countryCode3: 'TKM'
    },
    {
        countryName: 'Turks and Caicos Islands',
        countryNameFr: 'Îles Turques-et-Caïques',
        phonePrefix: '+1649',
        countryCode: 'TC',
        countryCode3: 'TCA'
    },
    {
        countryName: 'Tuvalu',
        countryNameFr: 'Tuvalu',
        phonePrefix: '+688',
        countryCode: 'TV',
        countryCode3: 'TUV'
    },
    {
        countryName: 'Uganda',
        countryNameFr: 'Ouganda',
        phonePrefix: '+256',
        countryCode: 'UG',
        countryCode3: 'UGA'
    },
    {
        countryName: 'Ukraine',
        countryNameFr: 'Ukraine',
        phonePrefix: '+380',
        countryCode: 'UA',
        countryCode3: 'UKR'
    },
    {
        countryName: 'United Arab Emirates',
        countryNameFr: 'Émirats arabes unis',
        phonePrefix: '+971',
        countryCode: 'AE',
        countryCode3: 'ARE'
    },
    {
        countryName: 'United Kingdom',
        countryNameFr: 'Royaume-Uni',
        phonePrefix: '+44',
        countryCode: 'GB',
        countryCode3: 'GBR'
    },
    {
        countryName: 'United States',
        countryNameFr: 'États-Unis',
        phonePrefix: '+1',
        countryCode: 'US',
        countryCode3: 'USA'
    },
    {
        countryName: 'Uruguay',
        countryNameFr: 'Uruguay',
        phonePrefix: '+598',
        countryCode: 'UY',
        countryCode3: 'URY'
    },
    {
        countryName: 'Uzbekistan',
        countryNameFr: 'Ouzbékistan',
        phonePrefix: '+998',
        countryCode: 'UZ',
        countryCode3: 'UZB'
    },
    {
        countryName: 'Vanuatu',
        countryNameFr: 'Vanuatu',
        phonePrefix: '+678',
        countryCode: 'VU',
        countryCode3: 'VUT'
    },
    {
        countryName: 'Venezuela, Bolivarian Republic of Venezuela',
        countryNameFr: 'Venezuela',
        phonePrefix: '+58',
        countryCode: 'VE',
        countryCode3: 'VEN'
    },
    {
        countryName: 'Vietnam',
        countryNameFr: 'Vietnam',
        phonePrefix: '+84',
        countryCode: 'VN',
        countryCode3: 'VNM'
    },
    {
        countryName: 'Virgin Islands, British',
        countryNameFr: 'Îles Vierges britanniques',
        phonePrefix: '+1284',
        countryCode: 'VG',
        countryCode3: 'VGB'
    },
    {
        countryName: 'Virgin Islands, U.S.',
        countryNameFr: 'Îles Vierges des États-Unis',
        phonePrefix: '+1340',
        countryCode: 'VI',
        countryCode3: 'VIR'
    },
    {
        countryName: 'Wallis and Futuna',
        countryNameFr: 'Wallis-et-Futuna',
        phonePrefix: '+681',
        countryCode: 'WF',
        countryCode3: 'WLF'
    },
    {
        countryName: 'Yemen',
        countryNameFr: 'Yémen',
        phonePrefix: '+967',
        countryCode: 'YE',
        countryCode3: 'YEM'
    },
    {
        countryName: 'Zambia',
        countryNameFr: 'Zambie',
        phonePrefix: '+260',
        countryCode: 'ZM',
        countryCode3: 'ZMB'
    },
    {
        countryName: 'Zimbabwe',
        countryNameFr: 'Zimbabwe',
        phonePrefix: '+263',
        countryCode: 'ZW',
        countryCode3: 'ZWE'
    }
]

export default phonePrefixList
