<script setup lang="ts">
    import InfiniteLoader from '@/components/ui/loaders/InfiniteLoader.vue'
    import BemTransition from '@/components/ui/transitions/BemTransition.vue'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { ButtonTheme, ButtonType, ButtonIconPosition, ButtonSize } from '@/vars/ButtonAttr'
    import { computed, useAttrs } from 'vue'
    import { RouteLocation, RouteLocationNamedRaw, useRouter } from 'vue-router'
    import BaseIcon from '../basics/BaseIcon.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'

    /**
     * @joconde
     */

    const BLOCK_CLASS = 'buttonv2'

    const attrs = useAttrs()
    const { redirectBounding } = useNavigationResolver()
    const router = useRouter()
    const props = withDefaults(
        defineProps<{
            theme?:
                | ButtonTheme.PRIMARY
                | ButtonTheme.SECONDARY
                | ButtonTheme.TERTIARY
                | ButtonTheme.GHOST
                | ButtonTheme.DISCRET
            to?: RouteLocationNamedRaw | RouteLocation
            href?: string
            disabled?: boolean
            pending?: boolean
            iconName?: string
            iconPosition?: ButtonIconPosition
            type?: ButtonType.BUTTON | ButtonType.SUBMIT
            block?: boolean
            testId?: string
            size?: ButtonSize
        }>(),
        {
            theme: ButtonTheme.PRIMARY,
            iconPosition: ButtonIconPosition.LEFT,
            type: ButtonType.BUTTON,
            size: ButtonSize.NORMAL
        }
    )

    const classList = computed(() => [
        BLOCK_CLASS,
        `${BLOCK_CLASS}--${props.size}`,
        {
            [`${BLOCK_CLASS}--disabled`]: props.disabled,
            [`${BLOCK_CLASS}--${props.theme}--disabled`]: props.disabled,
            [`${BLOCK_CLASS}--pending`]: props.pending,
            [`${BLOCK_CLASS}--block`]: props.block,
            [`${BLOCK_CLASS}--primary`]: !props.disabled && props.theme === ButtonTheme.PRIMARY,
            [`${BLOCK_CLASS}--secondary`]: !props.disabled && props.theme === ButtonTheme.SECONDARY,
            [`${BLOCK_CLASS}--tertiary`]: !props.disabled && props.theme === ButtonTheme.TERTIARY,
            [`${BLOCK_CLASS}--ghost`]: !props.disabled && props.theme === ButtonTheme.GHOST,
            [`${BLOCK_CLASS}--discret`]: !props.disabled && props.theme === ButtonTheme.DISCRET
        }
    ])

    const typeAttr = computed(() => {
        if (props.type && [ButtonType.BUTTON, ButtonType.SUBMIT].includes(props.type)) {
            return props.type
        }

        if (!props.href && !props?.to) {
            return ButtonType.BUTTON
        }

        return undefined
    })

    const boundings = computed(() => {
        let href = props.href ? props.href : undefined
        let attrTo: RouteLocation | undefined = undefined
        if (props.to) {
            const { href: hrefFound, to: toFind } = redirectBounding(props.to)
            if (hrefFound) {
                href = hrefFound
            } else if (toFind) {
                attrTo = router.resolve(toFind)
            }
        }

        return {
            ...attrs,
            disabled: props.disabled || props.pending || undefined,
            to: attrTo,
            href,
            type: typeAttr.value,
            class: [attrs.class, ...classList.value]
        }
    })

    const tag = computed(() => {
        if (props.disabled) {
            return ButtonType.BUTTON
        }
        if (props?.href) {
            return ButtonType.LINK
        }
        if (props?.to) {
            const { href } = redirectBounding(props.to)
            if (href) {
                return ButtonType.LINK
            } else {
                return ButtonType.ROUTER_LINK
            }
        }

        return ButtonType.BUTTON
    })
</script>

<template>
    <Component :is="tag === ButtonType.ROUTER_LINK ? SpaRouterLink : tag" v-bind="boundings" :data-testId="testId">
        <span :class="{ [`${BLOCK_CLASS}__inner`]: true, [`${BLOCK_CLASS}__inner--icon`]: iconName }">
            <BaseIcon
                v-if="iconName && iconPosition === ButtonIconPosition.LEFT"
                :name="iconName"
                class="mr-1"
                :class="`${BLOCK_CLASS}__icon`"
            />
            <slot></slot>
            <BaseIcon
                v-if="iconName && iconPosition === ButtonIconPosition.RIGHT"
                :name="iconName"
                class="ml-1"
                :class="`${BLOCK_CLASS}__icon`"
            />
        </span>
        <BemTransition :name="BLOCK_CLASS">
            <span v-if="pending" :class="`${BLOCK_CLASS}__loader`">
                <slot name="loader">
                    <InfiniteLoader />
                </slot>
            </span>
        </BemTransition>
    </Component>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/mixins/button.scss';
    @import '@/libs/sass/main';

    .buttonv2 {
        $block-selector: &;
        --button-text-color: var(--theme-button-color-text);
        --button-background-color: var(--theme-button-background-primary);
        --button-border-color: transparent;

        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: var(--button-background-color);
        color: var(--button-text-color);
        text-align: center;
        text-decoration: none;
        font-family: inherit;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        box-shadow: none;
        border-radius: 0.25em;
        padding: 0.6875em 1em; // height 38px
        overflow: hidden;
        border: none;
        line-height: 1;

        &--primary {
            --button-background-color: var(--theme-button-background-primary);

            @include hover($block-selector) {
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0em;
                    right: 0em;
                    left: 0em;
                    bottom: 0em;
                    opacity: 0.1;
                    background-color: #000;
                }
            }
        }
        &--secondary {
            --button-background-color: var(--theme-button-background-secondary);
            @include hover($block-selector) {
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0em;
                    right: 0em;
                    left: 0em;
                    bottom: 0em;
                    opacity: 0.1;
                    background-color: white;
                }
            }
        }
        &--tertiary {
            --button-background-color: transparent;
            --button-text-color: var(--theme-button-color-text-tertiary);
            --button-border-color: var(--theme-button-color-text-tertiary);
            border: 1px solid var(--button-border-color);
            @include hover($block-selector) {
                opacity: 0.8;
            }
            &--disabled {
                --button-background-color: transparent !important;
                --button-text-color: var(--theme-button-color-disable);
                --button-border-color: var(--theme-button-color-disable);
                border: 1px solid var(--button-border-color);
            }
        }
        &--ghost {
            --button-background-color: transparent;
            --button-text-color: var(--theme-button-color-text);
            --button-border-color: var(--theme-button-color-text);
            border: 1px solid var(--button-border-color);
            @include hover($block-selector) {
                opacity: 0.8;
            }

            &--disabled {
                --button-background-color: transparent !important;
                --button-text-color: var(--theme-button-color-disable);
                --button-border-color: var(--theme-button-color-disable);
                border: 1px solid var(--button-border-color);
            }
        }

        &--discret {
            padding: 0;
            color: inherit;
            background: none;
            border-radius: 0;
            border: 0;
            line-height: 1.5;
            font-weight: normal;
            font-size: inherit;
        }

        &--small {
            font-size: 0.75em;
        }

        &:not([disabled]):not(#{$block-selector}--disabled) {
            cursor: pointer;
        }

        &--disabled {
            cursor: not-allowed;
            --button-background-color: var(--theme-button-background-disable);
        }

        &--pending {
            cursor: wait;
        }

        // other styles
        &--block {
            width: 100%;
        }

        &__inner {
            position: relative;
            z-index: 2;
            transition: all 150ms;

            &--icon {
                display: flex;
                align-items: center;
            }

            #{$block-selector}--block & {
                width: 100%;
            }

            #{$block-selector}--pending & {
                color: transparent;
                fill: transparent;
            }
        }

        &__loader {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: wait;
        }
        &__icon {
            font-size: 1em;
        }
    }
</style>
