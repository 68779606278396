import { HeaderNavigation } from '@/types/Header.type'
import { ClubRouteName, RouteName } from '@/vars/RouteName'
import messages from './i18n/profile.json'
import { useAuthStore } from '@/libs/stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'
import { SpaName } from '@/vars/SpaAttr'
import { useSegment } from '@/libs/vendors/segment/segment'
import { useRouter } from 'vue-router'
import i18n from '@/i18n'
import { computed } from 'vue'
import useGroupsQuery from '@/libs/queries/useGroupsQuery'

i18n.global.mergeLocaleMessage('fr', messages.fr)
i18n.global.mergeLocaleMessage('en', messages.en)

const { t } = i18n.global

const adminUrl = import.meta.env.VITE_ADMIN_BASE_URL
const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL
const appUrl = import.meta.env.VITE_APP_BASE_URL
const currentSpa = import.meta.env.VITE_SPA_RUNNING

export const useProfileNavigation = () => {
    const store = useAuthStore()
    const router = useRouter()

    const { data: myGroupsResults } = useGroupsQuery({ isJoined: true })

    const myCircles = computed(() => myGroupsResults.value?.data || [])

    const navigationsItems = computed<HeaderNavigation[]>(() => {
        const items: HeaderNavigation[] = []

        if (store.hasRole(RoleName.ADMIN)) {
            items.push({
                label: t('nav.admin'),
                children: [
                    {
                        label: t('nav.admin_dashboard'),
                        href: `${adminUrl}/dashboard`
                    }
                ]
            })
        }

        items.push({
            label: t('nav.account'),
            children: getChildrenAccountNavigation()
        })

        if (store.hasRole(RoleName.LECLUB)) {
            items.push({
                label: t('nav.sponsorship_contact'),
                href: router.resolve({ name: ClubRouteName.MEMBER_RECOMMEND }).href,
                subtitle: t('nav.sponsorship_incentive')
            })
        }

        if (myCircles.value.length > 0) {
            items.push({
                label: t('nav.circles'),
                children: myCircles.value.map((circle) => {
                    return {
                        label: circle.name,
                        route: { name: RouteName.CIRCLE_SINGLE, params: { slug: circle.slug } }
                    }
                })
            })
        }

        if (store.isOnSwitchTo) {
            items.push({
                children: [
                    {
                        label: t('nav.back_admin_user'),
                        action: () => {
                            store.exitSwitchToUser()
                        }
                    },
                    {
                        label: t('nav.logout'),
                        action() {
                            store.logout()
                        }
                    }
                ]
            })
        } else {
            items.push({
                children: [
                    {
                        label: t('nav.logout'),
                        action() {
                            store.logout()
                        }
                    }
                ]
            })
        }

        return items
    })
    return {
        items: navigationsItems
    }
}

const getChildrenAccountNavigation = () => {
    const store = useAuthStore()
    const router = useRouter()
    const { analytics } = useSegment()
    const accountChildren: HeaderNavigation[] = []

    if (store.hasRole(RoleName.LECLUB)) {
        accountChildren.push({
            label: t('nav.profile'),
            href: `${privateUrl}/member/me`
        })
    }

    if (
        store.hasRole(RoleName.LECLUB) ||
        store.hasRole(RoleName.LECLUB_LOST) ||
        store.hasRole(RoleName.LECLUB_EXPERT_LOST)
    ) {
        accountChildren.push({
            label: t('nav.membership'),
            href:
                currentSpa === SpaName.LECLUB
                    ? `${appUrl}/account/membership`
                    : router.resolve({
                          name: RouteName.ACCOUNT_MEMBERSHIP
                      }).href
        })
    }

    accountChildren.push({
        label: t('nav.login_details'),
        href: `${appUrl}/account/info`
    })

    if (store.hasRole(RoleName.LECLUB)) {
        accountChildren.push({ label: t('nav.connections'), route: { name: ClubRouteName.LISTING_MER } })
    }

    if (store.hasRole(RoleName.LECLUB) && store.authentifiedUser && store.authentifiedUser.prospect_admin) {
        accountChildren.push({
            label: t('nav.relationship_manager'),
            href: `${privateUrl}/inbox/new/${store.authentifiedUser.prospect_admin.idff}`,
            action: () => {
                analytics.track('leclub:rm')
            }
        })
    }

    if (store.hasRole(RoleName.LECLUB)) {
        accountChildren.push({
            label: t('nav.nft'),
            href: `${privateUrl}/nft/home`
        })
    }

    return accountChildren
}
