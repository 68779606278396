import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default function useLayout() {
    const route = useRoute()

    const props = computed<{ hideMenu: boolean; showBatchBanner: boolean }>(() => {
        const initProps: { hideMenu: boolean; showBatchBanner: boolean } = {
            hideMenu: false,
            showBatchBanner: false
        }
        if (route.meta?.layoutProps && typeof route.meta?.layoutProps === 'object') {
            return { ...initProps, ...route.meta?.layoutProps }
        }
        return initProps
    })

    const showOnlyContent = computed(() => {
        return window.ReactNativeWebView && route.meta.onlyContentOnApp
    })

    return {
        props,
        showOnlyContent
    }
}
