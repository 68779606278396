<script setup lang="ts">
    import { useI18n } from 'vue-i18n'

    const { t } = useI18n()
    defineProps<{
        myFirstName?: string
        connectionDate: string | null
        connectedMemberFullName?: string
        connectedMemberCompany?: string
        formType: string
    }>()
</script>

<template>
    <div class="profile">
        <p class="profile__intro">
            {{ t('hello_you', { firstName: myFirstName }) }}
        </p>
        <p
            class="profile__intro profile__intro--secondary"
            v-html="
                t(
                    formType === 'business_connection_rdv_for_partner_contact'
                        ? 'business_connection_after_rdv'
                        : 'business_connection',
                    {
                        date: connectionDate,
                        fullName: connectedMemberFullName,
                        company: connectedMemberCompany
                    }
                )
            "
        ></p>
    </div>
</template>

<style scoped lang="scss">
    .profile {
        color: var(--theme-color-nuage);
        text-align: center;
        padding: var(--scale-4);

        &__intro {
            text-align: center;
            margin-top: var(--scale-10);
            &--secondary {
                margin-top: var(--scale-2);
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "hello_you": "Bonjour, {firstName} !",
        "business_connection": "Le {date} vous avez eu une <span class='text-secondary'>connexion business</span> avec {fullName} de {company}.<br>Avez-vous eu rendez-vous ensemble ?",
        "business_connection_after_rdv": "Votre rendez-vous du {date} avec {fullName} de {company} a-t-il pu aboutir sur une opportunité business ?"
    }
}
</i18n>
