import {
    UniversalSearchResultArticle,
    UniversalSearchResultBenefits,
    UniversalSearchResultEvent,
    UniversalSearchResultMember,
    UniversalSearchResultReplay,
    UniversalSearchType
} from '@/types/response/UniversalSearch.type'
import Http from './ApiClient'

class UniversalSearchApi {
    static async postSearch(searchText: string, axiosParams?: { signal?: AbortSignal }): Promise<UniversalSearchType> {
        const response = await Http.post(
            `/v2/search/header/autocomplete`,
            {
                search: searchText
            },
            axiosParams
        )

        return response.data
    }
    static async postSearchMember(params: {
        searchText?: string
        page?: number
    }): Promise<UniversalSearchResultMember> {
        const response = await Http.post(`/v2/search/header/search-all/member`, params)

        return response.data
    }
    static async postSearchEvent(params: { searchText?: string; page?: number }): Promise<UniversalSearchResultEvent> {
        const response = await Http.post(`/v2/search/header/search-all/event`, params)

        return response.data
    }
    static async postSearchReplay(params: {
        searchText?: string
        page?: number
    }): Promise<UniversalSearchResultReplay> {
        const response = await Http.post(`/v2/search/header/search-all/replay`, params)

        return response.data
    }
    static async postSearchBenefit(params: {
        searchText?: string
        page?: number
    }): Promise<UniversalSearchResultBenefits> {
        const response = await Http.post(`/v2/search/header/search-all/benefit`, params)

        return response.data
    }
    static async postSearchArticle(
        params: {
            searchText?: string
            page?: number
        },
        axiosParams?: { signal?: AbortSignal }
    ): Promise<UniversalSearchResultArticle> {
        const response = await Http.post(`/v2/search/header/search-all/article`, params, axiosParams)

        return response.data
    }
}

export default UniversalSearchApi
