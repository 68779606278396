<script setup lang="ts">
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { computed } from 'vue'

    const props = withDefaults(
        defineProps<{
            label: string
            expanded?: boolean
            noBorder?: boolean
        }>(),
        {
            expanded: undefined,
            noBorder: false
        }
    )

    defineEmits<{
        click: []
    }>()

    const iconName = computed(() => {
        if (props.expanded === undefined) {
            return `chevron_right`
        }

        if (props.expanded) {
            return `expand_less`
        }

        return `expand_more`
    })
</script>

<template>
    <div class="entry" :class="{ 'entry--no-border': noBorder }" @click="$emit('click')">
        <span class="entry__label">{{ label }}</span>
        <BaseIcon class="entry__icon" :name="iconName" />
    </div>
</template>

<style scoped lang="scss">
    $borderBottom: solid var(--scale-px) var(--theme-color-gray--04);

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--scale-4) var(--scale-6);
        font-weight: 500;
        border-bottom: $borderBottom;
        cursor: pointer;

        &--no-border {
            border-bottom: none;
        }

        &__label {
            font-size: 1.125em;
        }

        &__icon {
            font-size: 1.5rem;
        }
    }
</style>
