<script setup lang="ts">
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { useTheme } from '@/libs/compositions/useTheme'
    import { ProRouteName } from '@/vars/RouteName'
    import { ThemeName, ThemeTooltip } from '@/vars/ThemeAttr'
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    const { t } = useI18n()

    type Link = {
        routeName: ProRouteName
        label: string
        iconClass?: string
        iconV2?: string
        class?: string
        activeRoutes?: ProRouteName[]
    }

    const props = defineProps<{
        collapsed: boolean
        navigation?: Link[]
    }>()

    const route = useRoute()
    const { theme } = useTheme(route)

    const links = computed<Link[]>(() => {
        if (props.navigation) {
            return props.navigation.map((nav) => {
                return {
                    ...nav,
                    class: nav.activeRoutes?.includes(route.name as ProRouteName) ? 'nav__link--active' : ''
                }
            })
        }
        return [
            {
                routeName: ProRouteName.JOBS_ALL,
                label: t('route_label.my_job_offers'),
                iconClass: 'business_center',
                iconV2: theme === ThemeName.MONDRIAN ? 'business_center' : undefined,
                class: [
                    ProRouteName.JOBS_ACTIVE,
                    ProRouteName.JOBS_ALL,
                    ProRouteName.JOBS_DRAFTS,
                    ProRouteName.JOBS_ARCHIVED,
                    ProRouteName.JOB_CANDIDATES,
                    ProRouteName.JOB_CANDIDATE_SINGLE
                ].includes(route.name as ProRouteName)
                    ? 'nav__link--active'
                    : ''
            },
            {
                routeName: ProRouteName.BUSINESS_CONNECTIONS,
                label: t('route_label.my_business_connections'),
                iconClass: 'sms',
                iconV2: theme === ThemeName.MONDRIAN ? 'sms' : undefined,
                class: (route.name as ProRouteName) === ProRouteName.BUSINESS_CONNECTIONS ? 'nav__link--active' : ''
            },
            {
                routeName: ProRouteName.EVENTS,
                label: t('route_label.events'),
                iconClass: 'event_available',
                iconV2: theme === ThemeName.MONDRIAN ? 'event_available' : undefined,
                class: (route.name as ProRouteName) === ProRouteName.EVENTS ? 'nav__link--active' : ''
            },
            {
                routeName: ProRouteName.FUNDRAISING,
                label: t('route_label.my_fund_raised'),
                iconClass: 'monitoring',
                iconV2: theme === ThemeName.MONDRIAN ? 'monitoring' : undefined,
                class: (route.name as ProRouteName) === ProRouteName.FUNDRAISING ? 'nav__link--active' : ''
            }
        ]
    })
</script>

<template>
    <nav class="nav" :class="{ 'nav--collapsed': collapsed }">
        <ul class="nav__links">
            <li v-for="link in links" :key="link.label" class="nav__link-item">
                <RouterLink
                    v-tooltip.right="{
                        theme: ThemeTooltip.DEFAULT,
                        autoHide: true,
                        content: collapsed ? link.label : undefined
                    }"
                    class="nav__link"
                    :class="link.class"
                    :to="{ name: link.routeName }"
                >
                    <BaseIcon v-if="link.iconV2" class="nav__link-icon" :name="link.iconV2" />
                    <BaseIcon v-if="!link.iconV2 && link.iconClass" class="nav__link-icon" :name="link.iconClass" />
                    <span class="nav__link-label">
                        {{ link.label }}
                    </span>
                </RouterLink>
            </li>
        </ul>
    </nav>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    $fontIconSize: 1.4em;
    $transitionDuration: 0.6s;
    .nav {
        $block: &;
        margin: 0 1em;

        &__links {
            display: flex;
            list-style: none;
            white-space: nowrap;
            flex-direction: column;
            padding: 0;
            // align-items: stretch;
            transition: all $transitionDuration;

            &--footer {
                margin-top: 4em;
            }

            #{$block}--collapsed & {
                @media (max-width: $breakpoints-sm) {
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                }
            }
        }

        &__link-icon {
            font-size: 1.5em;
        }

        &__link-label {
            margin-left: 0.5em;
            width: 100%;
            transition: all $transitionDuration;
            opacity: 1;
            // flex: 1;

            #{$block}--collapsed & {
                width: 0;
                opacity: 0;
                overflow: hidden;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.5em;
            font-weight: 500;
            opacity: 0.3;
            width: 100%;

            &--active {
                opacity: 1;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "route_label": {
            "my_job_offers": "Recrutement",
            "my_business_connections": "Connexions business",
            "events": "Visibilité",
            "my_fund_raised": "Levée de fonds"
        }
    },
    "en": {
        "route_label": {
            "my_job_offers": "Recruitment",
            "my_business_connections": "Businness connections",
            "events": "Visibility",
            "my_fund_raised": "Fundraising"
        }
    }
}
</i18n>
