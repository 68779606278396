import { Ref } from 'vue'

export const UNIVERSAL_SEARCH_KEY = 'universalSearch'

export type UniversalSearchInjectType = {
    isExpended: Ref<boolean>
    searchText: Ref<string>
    updateIsExpended: (value: boolean) => void
    history: Ref<{ snapshot: string; timestamp: number }[]>
    saveHistory: () => void
    updateSearchText: (text: string) => void
}
