<template>
    <hr class="hr" />
</template>

<style scoped lang="scss">
    .hr {
        width: 100%;
        border: 0;
        height: 1px;
        background-color: var(--theme-border-color);
        margin-bottom: 1.5em;
    }
</style>
