import { RouteRecordRaw } from 'vue-router'
import { requiredAuthentification } from '@/libs/middlewares/auth'
import { WhatsappRouteName } from '@/vars/RouteName'

const routes: RouteRecordRaw[] = [
    {
        path: '/whatsapp/:slug',
        name: WhatsappRouteName.WHATSAPP,
        component: () => import('@/views/common/whatsapp/WhatsappPage.vue'),
        meta: {
            middleware: [requiredAuthentification],
            hasGridBackground: false
        }
    }
]

export default routes
