import { HeaderNavigation } from '@/types/Header.type'
import { RouteName } from '@/vars/RouteName'
import messages from './i18n/main.json'
import i18n from '@/i18n'

i18n.global.mergeLocaleMessage('fr', messages.fr)
i18n.global.mergeLocaleMessage('en', messages.en)

const { t } = i18n.global

// export const items: HeaderNavigation[] = [
export const getNavigationItems = () => {
    const items: HeaderNavigation[] = []

    items.push(
        {
            label: t('nav.network'),
            route: { name: RouteName.HOME },
            children: []
        },
        {
            label: t('nav.events'),
            route: { name: RouteName.HOME },
            children: [
                {
                    route: { name: RouteName.HOME },
                    label: t('events.all')
                },
                {
                    route: { name: RouteName.HOME },
                    label: t('events.user')
                },
                {
                    route: { name: RouteName.HOME },
                    label: t('events.physical')
                },
                {
                    route: { name: RouteName.HOME },
                    label: t('events.live')
                },
                {
                    route: { name: RouteName.HOME },
                    label: t('events.networking')
                }
            ]
        },
        {
            label: t('nav.resources'),
            route: { name: RouteName.HOME },
            children: [
                {
                    route: { name: RouteName.HOME },
                    label: t('resources.all')
                },
                {
                    route: { name: RouteName.HOME },
                    label: t('resources.article')
                },
                {
                    route: { name: RouteName.HOME },
                    label: t('resources.video')
                }
                // {
                //     route: { name: RouteName.RESOURCES_LIST_PODCASTS },
                //     label: t('resources.podcast'),
                // },
            ]
        },
        {
            label: t('nav.club_plus'),
            route: { name: RouteName.HOME },
            children: []
        }
    )

    return items
    // ];
}
