<script setup lang="ts">
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import { useI18n } from 'vue-i18n'
    import { useMutation, useQueryClient } from '@tanstack/vue-query'
    import { QueryName } from '@/vars/QueryAttr'
    import MemberApi from '@/libs/api/Member.api'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import { GetStartedTodo } from '@/types/response/members-api/GetStartedTodo.type'
    import { AxiosError } from 'axios'
    import { computed, ref, watch } from 'vue'
    import LottieView from '@/components/ui/wrappers/LottieView.vue'
    import useGettingStartedTodoQuery from '@/libs/queries/useGettingStartedTodoQuery'
    import { StorageKey } from '@/vars/StorageAttr'
    import { useLocalStorage } from '@vueuse/core'
    import Sparkles from '@/assets/images/logged-hp/spark.svg'
    import gsap from 'gsap'
    import { RouteName } from '@/vars/RouteName'
    import { useRoute } from 'vue-router'

    const props = defineProps<{
        compact?: boolean
    }>()

    const { t } = useI18n()
    const queryClient = useQueryClient()
    const hasFinishedGetStarted = useLocalStorage(StorageKey.FINISHED_GET_STARTED, 'false')
    const showAllTasks = ref(false)
    const defaultNumberOfTasks = 3
    const route = useRoute()

    const { data: todo, refetch: refetchTasks } = useGettingStartedTodoQuery({ refetchOnMount: true })

    const { mutate: markTaskAsDone } = useMutation<unknown, AxiosError, Pick<GetStartedTodo, 'id'>>({
        mutationFn: (task) => MemberApi.markGetStartedActionAsDone(task.id, { status: 'done' }),
        onMutate: (task) => {
            const newTodoList = { ...todo.value }

            if (newTodoList.data) {
                newTodoList.data = newTodoList.data.map((todo) => ({
                    ...todo,
                    status: todo.id === task.id ? 'done' : todo.status
                }))
            }

            queryClient.setQueryData([QueryName.GETTING_STARTED], newTodoList)
        }
    })

    const triggerAnimation = ref(false)

    const isDarkStyle = computed(() => {
        return route.name === RouteName.HOME
    })

    const displayedTodo = computed(() => {
        if (!todo.value?.data) {
            return []
        }

        const order = {
            todo: 1,
            in_progress: 2,
            done: 3
        }

        if (isDarkStyle.value) {
            return todo.value?.data
        } else {
            return todo.value.data.toSorted((a, b) => {
                return order[a.status] - order[b.status]
            })
        }
    })

    const remainingTasksCount = computed(() => {
        if (!todo.value?.data) {
            return 0
        }
        if (todo.value?.data?.length > defaultNumberOfTasks) {
            return todo.value?.data?.length - defaultNumberOfTasks
        } else {
            return undefined
        }
    })

    watch(
        todo,
        () => {
            const allTasksAreDone = todo.value?.data.every((task) => task.status === 'done')
            triggerAnimation.value = Boolean(todo.value?.data.length && allTasksAreDone && !props.compact)
        },
        { immediate: true, deep: true }
    )

    const onCompletedConfettiAnimation = () => {
        triggerAnimation.value = false
        hasFinishedGetStarted.value = 'true'
    }

    const onEnterCard = () => {
        if (todo.value?.data.every((action) => action.status === 'done')) {
            return null
        }

        refetchTasks()
    }

    const toggleShowAllTasks = () => {
        const hiddenTasks = document.querySelectorAll('[data-more-task="true"]')
        const timeline = gsap.timeline({
            defaults: {
                duration: 0.3,
                ease: 'power2.quad'
            }
        })
        if (showAllTasks.value) {
            timeline.to(hiddenTasks, { opacity: 0 })
            timeline.to(hiddenTasks, { height: 0, padding: 0 })
        } else {
            timeline.to(hiddenTasks, { opacity: 1, height: 'auto', padding: '0.5em' })
        }

        showAllTasks.value = !showAllTasks.value
    }
</script>

<template>
    <Transition class="started" :name="isDarkStyle ? '' : 'fade'" :appear="compact">
        <div v-if="todo?.data?.length">
            <CardWrapper
                tag="section"
                class="started__card relative"
                :class="{ 'started__card--dark': isDarkStyle }"
                @mouseenter="onEnterCard"
            >
                <img v-if="isDarkStyle" :src="Sparkles" alt="Spark" class="started__sparkles" />
                <template v-if="triggerAnimation">
                    <LottieView
                        path="/lottie/confetti.json"
                        autoplay
                        class="started__tada"
                        @completed="onCompletedConfettiAnimation"
                    />
                </template>
                <div class="p-4" :class="{ '!px-2r !py-4r': compact }">
                    <h2 v-if="!compact" class="title-4 mb-2">{{ t('compact.first_actions') }}</h2>
                    <div class="mb-4 text-xs" :class="{ 'text-base font-medium': !isDarkStyle }">
                        {{ compact || !isDarkStyle ? t('compact.first_actions') : t('first_actions') }}
                    </div>
                    <div class="task-list flex flex-col">
                        <TransitionGroup
                            name="list"
                            tag="div"
                            class="task-list flex flex-col"
                            :class="{ 'gap-2': !isDarkStyle }"
                        >
                            <a
                                v-for="(task, index) in displayedTodo"
                                :key="task.cta.title"
                                :href="isDarkStyle && !showAllTasks && index >= 3 ? undefined : task.cta.url"
                                target="_blank"
                                rel="noopener noreferrer"
                                v-bind="{ 'data-more-task': index >= 3 && isDarkStyle }"
                                class="flex cursor-pointer items-center gap-2 rounded p-2 text-xs transition-all duration-300 hover:translate-x-0.5"
                                :class="{
                                    [isDarkStyle ? '' : task.status === 'done' ? 'bg-go-10' : 'bg-flagship-10']: true,
                                    [isDarkStyle ? '' : compact ? 'gap-1 p-2' : 'gap-3 p-4']: true
                                }"
                            >
                                <MaterialIcon
                                    v-tooltip.bottom="{ content: task.status !== 'done' ? t('mark_as_done') : '' }"
                                    class="-mx-1 px-1 text-base"
                                    :name="task.status === 'done' ? 'task_alt' : 'radio_button_unchecked'"
                                    :class="{
                                        'text-nuage': isDarkStyle,
                                        'text-go': task.status === 'done' && !isDarkStyle,
                                        'text-royale': task.status !== 'done' && !isDarkStyle
                                    }"
                                    @click.prevent="markTaskAsDone(task)"
                                />
                                <span
                                    class="block flex-1 text-sm"
                                    :class="{
                                        'line-through': task.status === 'done',
                                        'text-go': task.status === 'done' && !isDarkStyle,
                                        'text-royale': task.status !== 'done' && !isDarkStyle
                                    }"
                                >
                                    {{ task.cta.title }}
                                </span>
                                <MaterialIcon
                                    v-if="task.status !== 'done' && !compact"
                                    name="arrow_forward"
                                    class="text-2xl"
                                />
                            </a>
                        </TransitionGroup>
                        <button
                            v-if="((remainingTasksCount && remainingTasksCount > 0) || showAllTasks) && isDarkStyle"
                            type="button"
                            class="mt-4 flex items-center gap-2 text-sm text-nuage"
                            @click="toggleShowAllTasks"
                        >
                            <MaterialIcon class="title-3" :name="showAllTasks ? 'unfold_less' : 'unfold_more'" />
                            {{ showAllTasks ? t('dont_show') : t('more_actions', { count: remainingTasksCount }) }}
                        </button>
                    </div>
                </div>
            </CardWrapper>
        </div>
    </Transition>
</template>

<style lang="scss" scoped>
    [data-more-task='true'] {
        @apply h-0 p-0 opacity-0;
    }

    .started {
        &__card {
            overflow: hidden;

            &--dark {
                @apply bg-legacy text-nuage;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 35%;
                    transform: translateX(-50%);
                    width: 50%;
                    height: 3.5rem;
                    background: linear-gradient(180deg, rgba(86, 155, 250, 0) 0%, #569bfa 100%);
                    filter: blur(1.8em);
                    opacity: 0.7;
                    z-index: 0;
                    pointer-events: none;
                }
            }
        }

        &__sparkles {
            position: absolute;
            top: 0;
            right: 0.1rem;
            width: 3.5rem;
            height: auto;
        }

        &__tada {
            @apply pointer-events-none fixed inset-0 z-50;

            :deep(svg) {
                width: auto !important;
            }
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all 350ms ease-in-out;
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
            translate: 0 20%;
        }
    }

    /* apply transition to moving elements */
    .list-move {
        transition: all 0.5s ease;
    }

    /* ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
    .list-leave-active {
        position: absolute;
    }
</style>

<i18n lang="json">
{
    "fr": {
        "compact": {
            "first_actions": "Vos premières actions"
        },
        "first_actions": "D’après vos objectifs, voici quelques services sur lequels nous pourrions vous aider.",
        "dont_show": "Ne plus afficher",
        "mark_as_done": "Marquer comme terminé",
        "more_actions": "{count} action supplémentaire | {count} actions supplémentaires"
    },
    "en": {
        "compact": {
            "first_actions": "Your first actions"
        },
        "first_actions": "According to your objectives, here are a some of the services we can offer you.",
        "dont_show": "Ne plus afficher",
        "mark_as_done": "Mark as done",
        "more_actions": "{count} additional action | {count} additional actions"
    }
}
</i18n>
