<script setup lang="ts">
    import { UniversalSearchPartnerType } from '@/types/response/UniversalSearch.type'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { CompaniesRouteName } from '@/vars/RouteName'
    import { useI18n } from 'vue-i18n'

    defineProps<{
        searchText: string
        partners: UniversalSearchPartnerType[]
        count: number
    }>()

    const { t } = useI18n()
</script>

<template>
    <div v-show="count > 0" class="list">
        <h3 class="title-4">{{ t('title') }}</h3>
        <div>
            <div class="list__partners">
                <SpaRouterLink
                    v-for="partner in partners"
                    :key="partner.id"
                    :to="{ name: CompaniesRouteName.COMPANY_DETAILS, params: { id: partner.id, slug: partner.slug } }"
                    class="list__partners__item"
                >
                    <img :src="partner.logo" class="list__partners__item__picture" />
                    <div>
                        <div class="title-4">{{ partner.name }}</div>
                    </div>
                </SpaRouterLink>

                <div class="list__more ml-2 mt-4">
                    <SpaRouterLink :to="{ name: CompaniesRouteName.COMPANY_HOME, query: { search: searchText } }">
                        {{ t('show_more') }}
                    </SpaRouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .list {
        display: grid;
        grid-template-columns: 8.75em 1fr;
        gap: var(--scale-4);

        &__partners {
            border-left: 0.1em solid var(--theme-color-gray--05);
            padding: 0 var(--scale-2);

            &__item {
                padding: var(--scale-2);
                display: grid;
                gap: var(--scale-4);
                grid-template-columns: auto auto;
                justify-content: left;
                align-items: center;

                &__picture {
                    max-width: 4.375em;
                    max-height: 4.375em;
                    height: auto;
                    border: solid 1px var(--theme-color-gray--05);
                }
            }
        }

        &__more {
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Entreprises",
        "show_more": "Voir toutes les entreprises"
    },
    "en": {
        "title": "Partners",
        "show_more": "See all partners"
    }
}
</i18n>
