<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import { ProRouteName } from '@/vars/RouteName'
    import { useTeamStore } from '@/libs/stores/team.store'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import { AvatarSize } from '@/vars/AvatarAttr'

    const { t } = useI18n()

    const teamStore = useTeamStore()

    const hasAccountManager = computed(() => {
        return !!teamStore.myTeam?.company?.accountManager
    })
</script>

<template>
    <footer class="footer">
        <ContainerTemplate class="footer__content">
            <div v-if="teamStore.myTeam" class="footer__column">
                <AvatarMedia
                    class="footer__team-picture"
                    :src="teamStore.myTeam?.company?.logo"
                    :alt="teamStore.myTeam?.company?.name"
                    is-company
                    :size="AvatarSize.NORMAL"
                />

                <h3 v-if="teamStore.myTeam?.company?.name" class="footer__subtitle">
                    {{
                        t('thank_you', {
                            companyName: teamStore.myTeam.company.name
                        })
                    }}
                </h3>

                <ul class="footer__link-list footer__link-list--only-desktop">
                    <li v-if="$route.name !== ProRouteName.JOBS" class="footer__link-list-item">
                        <RouterLink class="footer__link" :to="{ name: ProRouteName.JOBS }">
                            {{ t('your_offers') }}
                        </RouterLink>
                    </li>
                    <li v-if="$route.name !== ProRouteName.MY_COMPANY_PROFILE" class="footer__link-list-item">
                        <RouterLink class="footer__link" :to="{ name: ProRouteName.MY_COMPANY_PROFILE }">
                            {{ t('your_company_profile') }}
                        </RouterLink>
                    </li>
                    <li v-if="$route.name !== ProRouteName.BUSINESS_CONNECTIONS" class="footer__link-list-item">
                        <RouterLink class="footer__link" :to="{ name: ProRouteName.BUSINESS_CONNECTIONS }">
                            {{ t('your_business_connections') }}
                        </RouterLink>
                    </li>
                </ul>
            </div>

            <div class="footer__column">
                <AvatarMedia
                    v-if="hasAccountManager"
                    class="footer__contact-picture"
                    :src="teamStore.myTeam?.company?.accountManager?.picture"
                    :alt="teamStore.myTeam?.company?.accountManager?.fullName || ''"
                    :size="AvatarSize.NORMAL"
                />
                <LogoMedia v-else class="footer__team-picture footer__team-picture--frenchfounders" short />

                <h3 class="footer__subtitle">
                    {{
                        hasAccountManager
                            ? t('your_account_manager', {
                                  name: teamStore.myTeam?.company?.accountManager?.firstName
                              })
                            : t('here_for_you')
                    }}
                </h3>

                <ul class="footer__link-list">
                    <li class="footer__link-list-item">
                        <a
                            class="footer__link"
                            :href="
                                hasAccountManager
                                    ? `mailto:${teamStore.myTeam?.company.accountManager.email}`
                                    : 'mailto:sales@frenchfounders.com'
                            "
                        >
                            {{ t('contact_by_email') }}
                        </a>
                    </li>
                    <li class="footer__link-list-item">
                        <a
                            v-if="hasAccountManager"
                            class="footer__link"
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="teamStore.myTeam?.company.accountManager.calendarLink"
                        >
                            {{ t('ask_meeting') }}
                        </a>
                    </li>
                </ul>
            </div>
        </ContainerTemplate>
        <ContainerTemplate>
            <hr class="footer__separator" />
            <div class="footer__copyright">
                {{ t('copyrights', { year: new Date().getFullYear() }) }}
                <RouterLink
                    :to="{
                        name: ProRouteName.CGV
                    }"
                    class="footer__cgv"
                >
                    {{ t('cgv') }}
                </RouterLink>
            </div>
        </ContainerTemplate>
    </footer>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .footer {
        background-color: var(--theme-bs-color-bg-alternative, var(--theme-primary-color));
        color: var(--theme-primary-text-color);

        &__content {
            --footer-content-space: 3em;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            column-gap: 1em;
            row-gap: 2em;
            padding: var(--footer-content-space) 2em;

            @media (min-width: $breakpoints-sm) {
                --footer-content-space: 5em;
                padding: var(--footer-content-space) 1em;
            }
        }

        &__subtitle {
            grid-area: title;
            display: inline-block;
            font-size: 1.125em;
            font-weight: 700;

            @media (min-width: $breakpoints-sm) {
                font-size: 1.5em;
                display: flex;
            }
        }

        &__column {
            display: grid;
            column-gap: 1em;
            align-items: center;
            row-gap: 1em;
            grid-template-areas:
                'picture title title title'
                'links   links links links';

            @media screen and (min-width: $breakpoints-md) {
                row-gap: 0;
                grid-template-areas:
                    'picture title title title'
                    'picture links links links';
            }
        }

        &__team-picture {
            grid-area: picture;
        }

        &__nav-intro {
            display: block;
            margin-bottom: 1em;
        }

        &__link-list {
            grid-area: links;
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;

            &--only-desktop {
                display: none;

                @media (min-width: $breakpoints-sm) {
                    display: flex;
                }
            }
        }

        &__link-list-item {
            font-size: 0.875em;

            &:not(:first-child)::before {
                content: '|';
                margin: 0 1em;
            }
        }

        &__contact-picture {
            background-color: var(--theme-color-nuage);
            grid-area: picture;
            margin: 0;
        }

        &__separator {
            border: none;
            border-bottom: solid 1px #d9d9d9;
        }

        &__copyright {
            text-align: center;
            padding: 2.5em 0;
            font-size: 0.875em;

            @media (min-width: $breakpoints-md) {
                font-size: 1em;
            }
        }

        &__cgv {
            text-decoration: underline;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "thank_you": "{companyName}, merci pour votre confiance",
        "copyrights": "Copyright {year}, Frenchfounders - Tous droits réservés - ",
        "cgv": "CGV",
        "your_offers": "Vos offres d'emploi",
        "your_company_profile": "Votre page entreprise",
        "your_business_connections": "Vos connexions business",
        "your_account_manager": "{name}, votre contact principal",
        "contact_by_email": "Contacter par email",
        "ask_meeting": "Prendre rendez-vous",
        "here_for_you": "Notre équipe est à votre écoute"
    },
    "en": {
        "thank_you": "{companyName}, thank you for your trust",
        "copyrights": "Copyright {year}, Frenchfounders - All rights Reserved - ",
        "cgv": "CGV",
        "your_offers": "Your job offers",
        "your_company_profile": "Your company profile",
        "your_business_connections": "Your business connections",
        "your_account_manager": "{name}, your main contact",
        "contact_by_email": "Contact by email",
        "ask_meeting": "Book a meeting",
        "here_for_you": "Our team is at your disposal"
    }
}
</i18n>
