<script setup lang="ts">
    import { ref, inject, Ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import gsap from 'gsap'
    import { vIntersectionObserver } from '@vueuse/components'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { AboutRouteName } from '@/vars/RouteName'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'

    const showCookiesConsent = inject<Ref<boolean>>('showCookiesConsent')
    const { t } = useI18n()
    const hasTriggeredAnimation = ref(false)

    function onSectionVisible([{ isIntersecting }]: IntersectionObserverEntry[]) {
        if (isIntersecting && !hasTriggeredAnimation.value) {
            hasTriggeredAnimation.value = true
            gsap.from('[data-animate-bottom-links]', {
                x: 30,
                opacity: 0,
                ease: 'power2.quad',
                stagger: 0.1
            })
        }
    }

    function onManageCookies() {
        if (showCookiesConsent) {
            showCookiesConsent.value = true
        }
    }
</script>

<template>
    <div v-intersection-observer="onSectionVisible" class="flex flex-col items-start gap-10 pt-10 md:flex-row">
        <div class="text-sm">{{ t('copyright', { year: new Date().getFullYear() }) }}</div>
        <nav class="w-full flex-1 @container">
            <ul class="grid grid-cols-2 flex-wrap gap-4 text-xs underline sm:grid-cols-3 lg:flex">
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AboutRouteName.WEWEB_CGV }">{{ t('legal') }}</SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AboutRouteName.WEWEB_CGV }">{{ t('terms') }}</SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AboutRouteName.WEWEB_CGV_ENTREPRISE }">
                        {{ t('business_terms') }}
                    </SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AboutRouteName.WEWEB_PRIVACY }">
                        {{ t('privacy_policy') }}
                    </SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <SpaRouterLink :to="{ name: AboutRouteName.WEWEB_FR_COOKIES }">
                        {{ t('cookie_policy') }}
                    </SpaRouterLink>
                </li>
                <li data-animate-bottom-links>
                    <a class="cursor-pointer" @click="onManageCookies">
                        {{ t('manage_cookies') }}
                    </a>
                </li>
            </ul>
        </nav>
        <nav class="flex items-center justify-end gap-4">
            <a
                data-animate-bottom-links
                href="//www.linkedin.com/company/frenchfounders/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <BaseIcon name="linkedin2" />
            </a>
            <a
                data-animate-bottom-links
                href="//www.instagram.com/frenchfounders_inc/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <BaseIcon name="instagram" />
            </a>
            <a data-animate-bottom-links href="//x.com/frenchfounders" target="_blank" rel="noopener noreferrer">
                <BaseIcon name="x" />
            </a>
            <a
                data-animate-bottom-links
                href="//www.facebook.com/Frenchfounders"
                target="_blank"
                rel="noopener noreferrer"
            >
                <BaseIcon name="facebook" />
            </a>
        </nav>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "copyright": "© {year} Frenchfounders. Tous droits réservés",
        "legal": "Mentions légales",
        "terms": "CGU",
        "business_terms": "CGU Entreprises",
        "privacy_policy": "Politique de confidentialité",
        "cookie_policy": "Politique de cookies",
        "manage_cookies": "Gérer mes cookies"
    },
    "en": {
        "copyright": "© {year} Frenchfounders. Tous droits réservés",
        "legal": "Legal informations",
        "terms": "Terms",
        "business_terms": "Business terms",
        "privacy_policy": "Privacy Policy",
        "cookie_policy": "Cookies policy",
        "manage_cookies": "Manage cookies"
    }
}
</i18n>
