<script setup lang="ts">
    import logoBusinessSolutions from '@/assets/images/logos/logo_business_solutions_light.svg'
    import logoBusinessSolutionsDark from '@/assets/images/logos/logo_business_solutions_dark.svg'
    import logoLargeMondrian from '@/assets/images/logos/logo_ff_large.mondrian.svg'
    import logoLargeDarkMondrian from '@/assets/images/logos/logo_ff_large_dark.mondrian.svg'
    import logoSmallMondrian from '@/assets/images/logos/logo_ff_small.mondrian.svg'
    import logoSmallDarkMondrian from '@/assets/images/logos/logo_ff_small_dark.mondrian.svg'
    import logoLargeVerticalMondrian from '@/assets/images/logos/logo_ff_large--vertical.mondrian.svg'
    import logoLargeDarkVerticalMondrian from '@/assets/images/logos/logo_ff_large_dark--vertical.mondrian.svg'
    import logo10Years from '@/assets/images/logos/logo_ff_10_years.svg'
    import logoClub from '@/assets/images/logos/logo_club_light.svg'
    import logoClubDark from '@/assets/images/logos/logo_club_dark.svg'
    import logoNetwork from '@/assets/images/logos/logo_network_light.svg'
    import logoNetworkDark from '@/assets/images/logos/logo_network_dark.svg'
    import { BusinessLineName } from '@/vars/AuthAttr'
    import { useI18n } from 'vue-i18n'
    import { computed } from 'vue'

    const props = defineProps<{
        short?: boolean
        dark?: boolean
        club?: boolean
        network?: boolean
        admin?: boolean
        businessLine?: BusinessLineName
        forceDisplay?: boolean
        vertical?: boolean
        ephemeral?: boolean // logo éphémère de type opé market
    }>()

    const imgComputed = computed(() => {
        if (props.club || props?.businessLine === BusinessLineName.LECLUB) {
            return {
                src: props.dark ? logoClubDark : logoClub,
                class: 'logo'
            }
        } else if (props.network || props.businessLine === BusinessLineName.NETWORK) {
            return {
                src: props.dark ? logoNetworkDark : logoNetwork,
                class: 'logo'
            }
        } else if (props.admin || props.businessLine === BusinessLineName.BUSINESS) {
            return {
                src: props.dark ? logoBusinessSolutionsDark : logoBusinessSolutions,
                class: 'logo'
            }
        } else if (props.vertical) {
            return {
                src: props.dark ? logoLargeDarkVerticalMondrian : logoLargeVerticalMondrian,
                class: 'logo logo--desktop'
            }
        } else if (props.ephemeral) {
            return {
                src: logo10Years,
                class: 'logo '
            }
        } else if (!props.short) {
            return {
                src: props.dark ? logoLargeDarkMondrian : logoLargeMondrian,
                class: 'logo logo--desktop',
                'logo--force-display': props.forceDisplay
            }
        } else {
            return {
                src: props.dark ? logoSmallDarkMondrian : logoSmallMondrian,
                class: 'logo '
            }
        }
    })
    const { t } = useI18n()
</script>

<template>
    <img :src="imgComputed.src" :alt="t('logo')" :class="imgComputed.class" height="24" />
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .logo {
        max-width: 100%;

        // Je pense que cette notion n'est pas utile en fait. Je la commente au cas où on doit revert rapidement. Mais ça me parent bizarre de cacher des élements sur mobile

        //&--desktop {
        //    display: none;
        //
        //    @media screen and (min-width: $breakpoints-md) {
        //        display: block;
        //    }
        //}
        //
        //&--mobile {
        //    display: block;
        //}
        //
        //&--force-display {
        //    display: block;
        //}
    }
</style>

<i18n lang="json">
{
    "fr": {
        "logo": "Frenchfounders"
    },
    "en": {
        "logo": "Frenchfounders"
    }
}
</i18n>
