import { JobCandidate } from '@/types/Job.type'
import { ResponseV3 } from '@/types/response/ResponseV3.type'
import { QueryName } from '@/vars/QueryAttr'
import { useQuery, UseQueryOptions } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import JobsApi from '../api/Jobs.api'

type QueryReturnType = ResponseV3<JobCandidate, null>

export default function useCandidateMeQuery(
    opts: Omit<
        UseQueryOptions<QueryReturnType, AxiosError, QueryReturnType, [QueryName.TALENT_CANDIDATE_ME]>,
        'queryFn' | 'queryKey'
    > = {}
) {
    return useQuery({
        queryKey: [QueryName.TALENT_CANDIDATE_ME],
        queryFn: JobsApi.getCandidateProfile,
        ...opts
    })
}
