import { DirectiveBinding } from 'vue'

const BLOCK_CLASS = 'ct-loading-placeholder'

interface PlaceholderOptions {
    width?: string | number
    height?: string | number
    multiline?: number
    transparent?: boolean
    margin?: string
    display?: string
    light?: boolean
}

class Placeholder {
    el: HTMLElement
    originalBindings!: DirectiveBinding
    loading!: boolean
    options?: PlaceholderOptions

    constructor(el: HTMLElement, bindings: DirectiveBinding) {
        if (isSelfClosing(el)) {
            console.warn('Placeholder directive does not work on self closing tag')
        }

        this.el = el
        this.bindings = bindings

        if (this.loading) {
            this.init()
        } else {
            this.destroy()
        }
    }

    get lines() {
        return this.options?.multiline || 1
    }

    get lineClass() {
        return `${BLOCK_CLASS}__line`
    }

    get bindings() {
        return this.originalBindings
    }

    set bindings(bindings: DirectiveBinding) {
        this.originalBindings = bindings

        const { value } = bindings
        if (typeof value === 'boolean') {
            this.loading = !!value
        } else {
            this.loading = value.loading
            this.options = value.options
        }
    }

    init() {
        this.destroy()
        this.el.classList.add(BLOCK_CLASS)

        if (this.lines === 1) {
            this.createLine(this.el)
        } else {
            for (let i = 0; i < this.lines; i++) {
                const line = this.createLine()

                this.el.appendChild(line)
            }
        }
    }

    destroy() {
        this.el.classList.remove(BLOCK_CLASS)
        this.el.classList.remove(this.lineClass)
        this.el.style.removeProperty('width')
        this.el.style.removeProperty('height')
        this.el.style.removeProperty('margin')
        this.el.style.removeProperty('display')

        if (this.lines > 1) {
            this.el.querySelectorAll(`:scope > .${this.lineClass}`).forEach((line) => {
                line.remove()
            })
        }
    }

    createLine(el?: HTMLElement) {
        const line = el || document.createElement('span')
        line.classList.add(this.lineClass)

        if (typeof this.options?.width !== 'undefined') {
            line.style.width = typeof this.options.width === 'string' ? this.options.width : `${this.options.width}px`
        }

        if (typeof this.options?.height !== 'undefined') {
            line.style.height =
                typeof this.options.height === 'string' ? this.options.height : `${this.options.height}px`
        }

        if (typeof this.options?.margin !== 'undefined') {
            line.style.margin = this.options?.margin
        }

        if (typeof this.options?.display !== 'undefined') {
            line.style.display = this.options?.display
        }

        if (this.options?.light) {
            line.classList.add(`${this.lineClass}--light`)
        }

        if (this.options?.transparent) {
            line.classList.add(`${this.lineClass}--transparent`)
        }

        return line
    }
}

export const placeholder = (el: HTMLElement, bindings: DirectiveBinding) => new Placeholder(el, bindings)

function isSelfClosing(el: HTMLElement) {
    return [
        'area',
        'base',
        'br',
        'col',
        'command',
        'embed',
        'hr',
        'img',
        'input',
        'keygen',
        'link',
        'meta',
        'param',
        'source',
        'track',
        'wbr'
    ].includes(el.tagName.toLowerCase())
}
