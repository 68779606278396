<script setup lang="ts">
    import StarRate from '@/components/ui/icons/StarRate.vue'
    import { ref } from 'vue'

    const props = withDefaults(
        defineProps<{
            formEl: {
                subject: string
            }
            readOnly?: boolean
            // small: boolean
            rating?: number
        }>(),
        {
            rating: 0
        }
    )

    const vote = ref(props.rating)

    const emit = defineEmits<{
        rating: [value: number]
    }>()

    const onRating = (rating) => {
        vote.value = rating
        emit('rating', rating)
    }
</script>

<template>
    <div class="mb-2 mt-2">
        <h3 class="form__part__question">{{ formEl.subject }}</h3>
        <div>
            <StarRate :vote="vote" :is-editable="!readOnly" @select="onRating" />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .form__part {
        &__question {
            display: block;
            line-height: 20px;
            margin-bottom: var(----scale-2);
            max-width: 80%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
