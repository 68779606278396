<script setup lang="ts">
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import { UniversalSearchMemberType } from '@/types/response/UniversalSearch.type'
    import { ExternalRoute } from '@/vars/RouteName'
    import { useI18n } from 'vue-i18n'
    import { AvatarSize } from '@/vars/AvatarAttr'

    defineProps<{
        searchText: string
        members: UniversalSearchMemberType[]
        count: number
    }>()

    const { t } = useI18n()
</script>

<template>
    <div v-show="count > 0" class="list">
        <h3 class="title-4">{{ t('title') }}</h3>
        <div>
            <div class="list__members">
                <a
                    v-for="member in members"
                    :key="member.id"
                    :href="ExternalRoute.MEMBER_CLUB_PROFILE(member.idff)"
                    class="list__members__item"
                >
                    <AvatarMedia
                        class="list__avatar"
                        :src="member.picture"
                        :size="AvatarSize.SMALL"
                        :alt="member.fullName"
                    />

                    <div>
                        <div class="list__name">{{ member.fullName }}</div>
                        <div class="list__position text-sm">{{ member.position }} @ {{ member.company }}</div>
                    </div>
                </a>

                <div class="mt-4">
                    <a class="list__more" :href="ExternalRoute.CLUB_MEMBER_LIST(searchText)">
                        {{ t('show_more') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .list {
        display: grid;
        grid-template-columns: 8.75em 1fr;
        gap: var(--scale-4);

        &__name {
            font-weight: 600;
        }

        &__members {
            border-left: 0.1em solid var(--theme-color-gray--05);
            padding: 0 var(--scale-4);

            &__item {
                padding: var(--scale-2);
                display: grid;
                gap: var(--scale-4);
                grid-template-columns: auto auto;
                justify-content: left;
            }
        }

        &__avatar {
            --size: 3em;
        }

        &__position {
            color: var(--theme-color-gray--03);
        }

        &__more {
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Membres",
        "show_more": "Voir tous les membres"
    },
    "en": {
        "title": "Members",
        "show_more": "See all members"
    }
}
</i18n>
