<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import GutterWrapper from '@/components/ui/wrappers/GutterWrapper.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { ButtonSize, ButtonTheme, ButtonType } from '@/vars/ButtonAttr'
    import { RouteName } from '@/vars/RouteName'
    import { SegmentEvent } from '@/vars/SegmentAttr'
    import { useI18n } from 'vue-i18n'

    const store = useAuthStore()
    const { t } = useI18n()
    const { analytics } = useSegment()
    const proSpaUrl = import.meta.env.VITE_PRO_BASE_URL

    function trackContact() {
        analytics?.track(SegmentEvent.COMMUNITY_JOBS_CONTACT_RECRUTER)
    }

    async function onOffer() {
        await analytics?.track(SegmentEvent.COMMUNITY_JOBS_SUBMIT_OFFER)

        window.location.href = `${proSpaUrl}`
    }
</script>

<template>
    <div class="page">
        <div class="page__title-page">
            {{ t('page_title') }}
        </div>

        <GutterWrapper class="page__search-hire">
            <CardWrapper class="page__search">
                <div class="page__header">
                    <img class="page__img" src="@/assets/images/community/job/planet_search.svg" alt="" />
                    <div class="page__title">
                        {{ t('active_research') }}
                    </div>
                    <div class="page__intro-search">
                        <div class="page__paragraph page__paragraph--first">
                            <BaseIcon legacy class="page__icon-check-circle" name="check-circle" />
                            <div>
                                {{ t('grey_poste') }}
                            </div>
                        </div>
                        <div class="page__paragraph">
                            <BaseIcon legacy class="page__icon-check-circle" name="check-circle" />
                            <div>{{ t('live_ceo') }}</div>
                        </div>
                    </div>
                    <!--
                        <a
                        class="page__link"
                        :href="`${lesTalentsUrl}/je-cherche-un-job`"
                        >
                        {{ t('see_more') }}
                        <BaseIcon legacy class="page__link-icon" name="arrow-right" />
                        </a>
                    -->
                </div>
                <hr class="page__wall" />

                <div class="page__offer-container page__offer-container--talent">
                    <div class="page__offer-paragraph-wrapper">
                        <p>
                            {{ t('profile_paragraph') }}
                            <span class="page__strong">{{ t('profile_paragraph_strong') }}</span>

                            {{ t('profile_paragrapg_continued') }}
                        </p>
                    </div>
                    <BaseButton
                        v-if="store.authentifiedUser"
                        :theme="ButtonTheme.PRIMARY"
                        :size="ButtonSize.SMALL"
                        :type="ButtonType.BUTTON"
                        :to="{
                            name: RouteName.TALENT_PROFILE
                        }"
                    >
                        {{ t('create_profil') }}
                    </BaseButton>
                </div>
            </CardWrapper>
            <CardWrapper class="page__hire">
                <div class="page__header">
                    <img class="page__img" src="@/assets/images/community/job/diversity.svg" alt="" />
                    <div class="page__title">{{ t('you_hire') }}</div>
                    <div class="page__intro-hire">
                        <div class="page__paragraph page__paragraph--first">
                            <BaseIcon legacy class="page__icon-check-circle" name="check-circle" />
                            {{ t('international_talent') }}
                        </div>
                        <div class="page__paragraph">
                            <BaseIcon legacy class="page__icon-check-circle" name="check-circle" />
                            {{ t('talent_only') }}
                        </div>
                    </div>
                    <!--
                        <a class="page__link" :href="`${lesTalentsUrl}/je-recrute`">
                        {{ t('see_more') }}
                        <BaseIcon legacy class="page__link-icon" name="arrow-right" />
                        </a>
                    -->
                </div>
                <hr class="page__wall" />
                <div class="page__footer">
                    <div class="page__offer-container">
                        <div class="page__offer-paragraph-wrapper page__offer-paragraph-wrapper--duo">
                            <p>
                                <span class="page__strong">
                                    {{ t('recruiter_paragraph_strong') }}
                                </span>
                                {{ t('recruiter_paragraph') }}
                            </p>
                        </div>
                        <BaseButton
                            v-if="store.authentifiedUser"
                            :theme="ButtonTheme.PRIMARY"
                            :size="ButtonSize.SMALL"
                            @click="onOffer"
                        >
                            {{ t('send_offer') }}
                        </BaseButton>
                    </div>
                    <div class="page__wall--vertical" />
                    <div class="page__offer-container">
                        <div class="page__offer-paragraph-wrapper page__offer-paragraph-wrapper--duo">
                            <p>
                                <span class="page__strong">
                                    {{ t('offer_paragraphe_strong') }}
                                </span>
                                {{ t('offer_paragraphe') }}
                            </p>
                        </div>
                        <BaseButton
                            :theme="ButtonTheme.PRIMARY"
                            :size="ButtonSize.SMALL"
                            href="mailto:lestalents@frenchfounders.com"
                            @click="trackContact"
                        >
                            {{ t('contact_recruiter') }}
                        </BaseButton>
                    </div>
                </div>
            </CardWrapper>
        </GutterWrapper>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .page {
        $block-selector: &;
        text-align: center;
        display: flex;
        flex-direction: column;
        color: var(--theme-text-color);

        &__search {
            margin: 0 0 3em 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        &__hire {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 3em 0;

            @media only screen and (min-width: $breakpoints-md) {
                width: 100%;
            }
        }
        &__header {
            margin: 0 0 0 0;
        }
        &__img {
            margin: 1.125em auto;
            font-weight: 100;
        }
        &__title {
            margin: 0 0 1.9375em 0;
            font-weight: 700;
        }
        &__intro-search {
            margin: 0 0 2.1875em 1.5em;

            @media only screen and (min-width: $breakpoints-lg) {
                max-width: 24em;
            }
        }
        &__intro-hire {
            margin: 0 0 2.1875em 1.5em;

            @media only screen and (min-width: $breakpoints-lg) {
                max-width: 24.55em;
            }
        }
        &__link {
            margin: 0 0 1.6875em 0;
            font-weight: 700;
            font-size: 0.875em;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        &__link-icon {
            padding: 0 0 0 0;
            font-size: 2em;
            transition: transform 300ms;

            #{$block-selector}__link:hover & {
                transform: translateX(22%);
            }
        }
        &__footer {
            display: flex;
            flex-flow: row nowrap;
            flex-direction: column;

            @media only screen and (min-width: $breakpoints-md) {
                display: flex;
                flex-flow: row nowrap;
            }
        }

        &__paragraph {
            display: flex;
            align-items: flex-start;
            text-align: left;
            font-weight: 400;
            font-size: 0.875em;
            &--first {
                padding: 0 0 1.15em 0;
            }
        }

        &__wall {
            margin: 0;
            width: 100%;
            opacity: 0.5;
            border-top: solid 1px var(--theme-text-color--secondary);

            &--vertical {
                opacity: 0.5;
                border-top: solid 1px var(--theme-text-color--secondary);
                @media only screen and (min-width: $breakpoints-md) {
                    border-right: solid 1px var(--theme-text-color--secondary);
                }
            }
        }

        &__strong {
            font-weight: 700;
            font-size: 1em;
        }
        &__icon-check-circle {
            color: var(--theme-primary-color);
            font-size: 1.5em;
            margin: 0 0.5em 0 0;
        }
        &__title-page {
            margin: 0 0 2.5em 0;
            font-weight: 700;
            font-size: 1.125em;
        }
        &__search-hire {
            flex-wrap: wrap;
            @media only screen and (min-width: $breakpoints-lg) {
                flex-wrap: nowrap;
            }
        }

        &__offer-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3em 1em;
            flex: 1;
            margin: 0 4em 0 4em;

            @media only screen and (min-width: $breakpoints-md) {
                margin: 0;
                &--talent {
                    margin: 0 4em 0 4em;
                }
            }
        }
        &__offer-paragraph-wrapper {
            flex: 1;
            margin-bottom: 1.142em;
            display: flex;
            align-items: center;
            font-size: 0.875em;
            font-weight: 300;

            &--duo {
                align-items: flex-start;
            }
        }
        &__offer-paragraph {
            font-weight: 300;
            font-size: 1em;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "see_more": "En savoir plus",
        "active_research": "Vous êtes en recherche active ou en veille ?",
        "grey_poste": "Des postes sur le marché gris à New York, disponibles uniquement sur Frenchfounders",
        "live_ceo": "Un accès direct aux CEO des sociétés qui recrutent",
        "profile_paragraph": "En toute",
        "profile_paragraph_strong": "anonymat ou non,",
        "profile_paragrapg_continued": "cela vous permettra d’être visible des entreprises qui recrutent et de nos recruteurs Frenchfounders.",
        "create_profil": "Rejoindre le talent pool",
        "you_hire": "Vous recrutez ?",
        "international_talent": "Des talents francophones aux parcours internationaux",
        "talent_only": "Des décideurs francophones expérimentés disponibles uniquement sur Frenchfounders",
        "recruiter_paragraph_strong": "Gérez vos recrutements",
        "recruiter_paragraph": "en toute autonomie sur la plateforme, auprès du pool de talents francophones actifs du réseau Frenchfounders.",
        "contact_recruiter": "Contactez un recruteur",
        "offer_paragraphe_strong": "Donnez mandat",
        "offer_paragraphe": "à nos recruteurs pour chasser les meilleurs candidats francophones ou anglophones pour vos offres. Success fee de 20% du salaire annuel brut.",
        "send_offer": "Déposez une offre",
        "page_title": "Frenchfounders vous accompagne dans votre recherche :"
    }
}
</i18n>
