<script setup lang="ts">
    import { FieldMeta } from 'vee-validate'
    import { useAttrs } from 'vue'
    type ModelValue = string | number | string[] | null
    const attrs = useAttrs()
    const props = withDefaults(
        defineProps<{
            name: string
            modelValue?: ModelValue
            error?: string
            handleChange: (e: unknown, shouldValidate?: boolean | undefined) => void
            errorMessage?: string
            meta: FieldMeta<
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
            >
            value:
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
        }>(),
        {
            value: null,
            modelValue: '',
            error: '',
            errorMessage: '',
            options: () => []
        }
    )
    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
    }>()
    const onInputChange = (e: Event) => {
        const value: string | null = (e.target as HTMLInputElement).value
        props.handleChange(value)
        emit('update:modelValue', value)
    }
</script>

<template>
    <select
        class="field__input field__input--select"
        :class="{
            'field__input--error': error || (errorMessage && meta.touched),
            'field__input--empty': !modelValue && !value
        }"
        :name="name"
        :value="modelValue || value"
        v-bind="attrs"
        @input="onInputChange"
    >
        <slot name="options"> </slot>
    </select>
</template>

<style lang="scss" scoped>
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;
    .field {
        &__input {
            $placeholderColor: var(--theme-color-gray--04);
            color: var(--theme-text-color);
            width: 100%;
            max-width: 100%;
            font-size: 0.875em;
            border-radius: $inputRadius;
            padding: 0.7em;
            border: solid 1px $placeholderColor;
            outline-color: rgba(var(--theme-text-color--rgb), 0.5);
            background-color: var(--theme-background-color);
            &--select {
                position: relative;
                appearance: none;
                margin-right: 2em;
                padding-right: 2em;
                background-image: linear-gradient(45deg, transparent 50%, var(--theme-text-color) 50%),
                    linear-gradient(135deg, var(--theme-text-color) 50%, transparent 50%),
                    linear-gradient(to right, var(--theme-background-color), var(--theme-background-color));
                background-position:
                    calc(100% - 1.25em) calc(1em + 0.125em),
                    calc(100% - 1em) calc(1em + 0.125em),
                    100% 0;
                background-size:
                    0.3125em 0.3125em,
                    0.3125em 0.3125em,
                    2.5em 2.5em;
                background-repeat: no-repeat;
            }
            // @TODO Warning : bad rendering for Windows users - find a cleaner way to handle placeholder color
            &--empty {
                color: $placeholderColor;
                font-weight: 300;
                opacity: 1;
                :deep(option) {
                    color: var(--theme-text-color);
                }
            }
            &--error {
                border-color: var(--theme-error-color);
            }
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $placeholderColor;
                font-weight: 300;
                opacity: 1; /* Firefox */
            }
            @media (hover: hover) {
                &[type='date']::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }
            }
        }
    }
</style>
