<script setup lang="ts">
    import FeedbackForm from '@/libs/helpers/feedbackForm'
    import { FeedbackFormElement } from '@/types/response/Feedback.type'
    import { computed } from 'vue'
    import FormPartButton from './FormPartButton.vue'
    import FormPartChoice from './FormPartChoice.vue'
    import FormPartComment from './FormPartComment.vue'
    import FormPartDatePicker from './FormPartDatePicker.vue'
    import FormPartRating from './FormPartRating.vue'

    const props = defineProps<{
        form: FeedbackForm
        formEl: FeedbackFormElement
        rating: number
        i: number
    }>()

    const emit = defineEmits<{
        click: [value: boolean]
        contact: []
        'see-profile': []
        'ping-admin': []
        answer: [value: { id: string; answer: unknown }]
        submit: []
    }>()

    const onClick = (value: boolean) => {
        onAnswer(value)
        emit('submit')
    }

    const onAnswer = (value: unknown) => {
        const response = {
            id: props.formEl.id,
            answer: value
        }

        emit('answer', response)
    }

    const isInlineButton = computed(() => {
        return props.formEl.type === 'button' && props.formEl.options?.position === 'inline'
    })
</script>

<template>
    <div class="field" :class="{ 'field--inline': isInlineButton }">
        <FormPartRating v-if="formEl.type === 'rate'" :form-el="formEl" :index="i" @rating="onAnswer" />
        <FormPartRating
            v-if="formEl.type === 'global-rate'"
            :form-el="formEl"
            :index="i"
            :rating="rating"
            @rating="onAnswer"
        />
        <FormPartButton
            v-if="formEl.type === 'button'"
            :form-el="formEl"
            :index="i"
            :form="form"
            @contact="$emit('contact')"
            @see-profile="$emit('see-profile')"
            @ping-admin="$emit('ping-admin')"
            @click="onClick"
        />
        <FormPartComment v-if="formEl.type === 'comment'" :form-el="formEl" :index="i" @input="onAnswer" />
        <FormPartChoice v-if="formEl.type === 'choice'" :form-el="formEl" :index="i" @choice="onAnswer" />
        <p v-if="formEl.type === 'text'" class="field__text" :class="{ '.title-3': formEl.options.size === 'title' }">
            {{ formEl.subject }}
        </p>
        <FormPartDatePicker v-if="formEl.type === 'date'" :form-el="formEl" @input="onAnswer" />
    </div>
</template>

<style scoped lang="scss">
    .field {
        &__text {
            text-align: center;
            margin-bottom: var(--scale-4);
        }
        &--inline {
            display: inline-block;
            padding-inline: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
