<script setup lang="ts">
    import { useI18n } from 'vue-i18n'

    const { t } = useI18n()
    withDefaults(
        defineProps<{
            memberToShow: {
                full_name: string
                company: {
                    name: string
                }
            } | null
            dateConnection: string | null
        }>(),
        {
            memberToShow: null,
            dateConnection: ''
        }
    )
</script>

<template>
    <div class="profile">
        <p class="profile__intro">
            {{ dateConnection }}<br />
            {{ t('we_connected_you') }}
        </p>
        <div class="profile__name title-4 mt-4">
            {{ memberToShow?.full_name }}
        </div>
        <div v-if="memberToShow?.company">@ {{ memberToShow.company.name }}</div>
    </div>
</template>

<style scoped lang="scss">
    .profile {
        color: var(--theme-color-nuage);
        text-align: center;
        padding: var(--scale-6);

        &__intro {
            text-align: center;
            margin-top: var(--scale-8);
        }

        &__name {
            text-align: center;
            margin-top: var(--scale-2);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "we_connected_you": "nous vous avons mis en relation avec"
    }
}
</i18n>
