<template>
    <div class="burger">
        <div class="burger__line"></div>
        <div class="burger__line"></div>
        <div class="burger__line"></div>
    </div>
</template>

<style scoped lang="scss">
    .burger {
        display: block;

        &__line {
            width: 1.25em;
            height: 0.125em;
            margin-bottom: 0.25em;
            position: relative;
            background: currentColor;
            border-radius: 0.1875em;
            z-index: 1;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
