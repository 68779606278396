<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import FormInput from '@/components/ui/forms/FormInput.vue'
    import FormTextArea from '@/components/ui/forms/FormTextArea.vue'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import useNotification from '@/libs/compositions/useNotification'
    import { ButtonTheme, ButtonType } from '@/vars/ButtonAttr'
    import { useMutation } from '@tanstack/vue-query'
    import { useForm } from 'vee-validate'
    import { useI18n } from 'vue-i18n'
    import { ToastType } from '@/vars/ToastAttr'
    import { ref } from 'vue'

    defineProps<{
        modelValue: boolean
    }>()

    defineEmits<{
        (e: 'update:modelValue', value: boolean): void
    }>()

    const { t } = useI18n()
    const { toast } = useNotification()
    const hasSent = ref(false)

    const { meta, handleSubmit } = useForm({
        initialValues: {
            message: '',
            subject: ''
        }
    })
    const { isPending, mutate } = useMutation({
        mutationFn: ({ subject, message }: { subject: string; message: string }) => {
            return ConfigurationApi.contactFrenchFounders(subject, message)
        },
        onSuccess: () => {
            hasSent.value = true
            toast({
                type: ToastType.SUCCESS,
                text: t('success.send')
            })
        },
        onError: () => {
            toast({
                type: ToastType.ERROR,
                text: t('error.send')
            })
        }
    })

    const onSubmit = handleSubmit((values) => {
        if (!isPending.value) {
            mutate(values)
        }
    })
</script>

<template>
    <BaseModal :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
        <template #title>{{ t('title') }}</template>
        <div v-if="hasSent">
            <p class="modal__success">{{ t('success.send') }}</p>
        </div>
        <div v-else>
            <FormInput class="modal__form-input" rules="required" name="subject"> {{ t('subject') }} </FormInput>
            <FormTextArea class="modal__form-input" rules="required" name="message"> {{ t('message') }} </FormTextArea>
            <div class="modal__footer">
                <BaseButton
                    :type="ButtonType.SUBMIT"
                    :theme="ButtonTheme.PRIMARY"
                    :pending="isPending"
                    :disabled="!meta.valid"
                    @click="onSubmit"
                >
                    {{ t('button_submit') }}
                </BaseButton>
            </div>
        </div>
    </BaseModal>
</template>

<style scoped lang="scss">
    .modal {
        &__success {
            text-align: center;
        }
        &__form-input {
            margin-top: var(--scale-4);
        }
        &__footer {
            display: flex;
            justify-content: end;
            padding-top: var(--scale-4);
            padding-bottom: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Contacter Frenchfounders",
        "subject": "Objet",
        "message": "Message",
        "button_submit": "Envoyer",
        "error": {
            "send": "Nous n'avons pas pu envoyer votre demande, veuillez réssayer ultérieurement."
        },
        "success": {
            "send": "Votre message a été envoyé avec succès. L'équipe Frenchfounders vous répondra dans les meilleurs délais."
        }
    },
    "en": {
        "title": "Contact Frenchfounders",
        "subject": "Object",
        "message": "Message",
        "button_submit": "Submit",
        "error": {
            "send": "We could not send your request, please try again later."
        }
    }
}
</i18n>
