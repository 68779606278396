<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import DropdownContent from '@/components/ui/navigations/dropdowns/DropdownContent.vue'
    import DropdownItem from '@/components/ui/navigations/dropdowns/DropdownItem.vue'
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import { isTouchDevice } from '@/libs/helpers/device'
    import { HeaderNavigation } from '@/types/Header.type'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'

    const { t } = useI18n()
    defineProps<{
        navigation: HeaderNavigation[]
    }>()
    const route = useRoute()
</script>

<template>
    <nav class="nav">
        <ul class="nav__list">
            <li v-for="item in navigation" :key="item.label" class="nav__item">
                <DropdownNavigation show-on-hover :auto-close-delay-millis-after-leave="0">
                    <template #activator>
                        <BaseButton
                            :theme="ButtonTheme.DISCRET"
                            :to="isTouchDevice() && item.children ? undefined : item.route"
                            :disabled="item.disabled"
                            class="nav__link"
                            :title="item.disabled && t('coming_soon')"
                            :class="{
                                'nav__link--active': route.name === item.route?.name,
                                'nav__link--disabled': item.disabled
                            }"
                        >
                            {{ item.label }}
                        </BaseButton>
                    </template>
                    <template v-if="item.children && item.children.length > 0" #dropdown>
                        <DropdownContent class="nav__item--dropdown-content">
                            <DropdownItem
                                v-for="dropdownItem in item.children"
                                :key="dropdownItem.label"
                                :to="dropdownItem.route"
                                :href="dropdownItem.href"
                                :target="dropdownItem.target"
                            >
                                <div
                                    class="nav__item--dropdown-item"
                                    :class="{ 'nav__item--dropdown-item-disabled': dropdownItem.disabled }"
                                >
                                    <div>
                                        <BaseIcon
                                            v-if="dropdownItem.icon"
                                            class="nav__item--dropdown-item-icon"
                                            :name="dropdownItem.icon"
                                        />
                                    </div>
                                    <div>
                                        <div class="nav__item--dropdown-item-title">{{ dropdownItem.label }}</div>
                                        <div class="nav__item--dropdown-item-subtitle">{{ dropdownItem.subtitle }}</div>
                                    </div>
                                </div>
                            </DropdownItem>
                        </DropdownContent>
                    </template>
                </DropdownNavigation>
            </li>
        </ul>
    </nav>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .nav {
        $block-selector: &;
        display: flex;

        &__spaces {
            display: none;
            @media screen and (min-width: $breakpoints-lg) {
                display: inherit;
            }
        }
        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            height: 100%;
            justify-content: start;
            display: none;

            @media screen and (min-width: $breakpoints-lg) {
                display: flex;
                gap: var(--scale-8r);
            }
        }

        &__item {
            position: relative;
            &--dropdown {
                &-content {
                    width: 29em;
                    padding: 1.8em 0;

                    .dropdown-item {
                        padding: 0.7rem 2.5rem;
                    }
                }
                &-item {
                    display: flex;
                    gap: 1em;

                    &-disabled {
                        color: var(--theme-color-gray--04);
                    }
                    &-icon {
                        font-size: 1.5em;
                    }
                    &-title {
                        font-size: 1.15em;
                        font-weight: bold;
                        align-items: center;
                        padding-bottom: 4px;
                    }
                    &-subtitle {
                        font-size: 0.85em;
                        color: var(--theme-color-gray--03);
                    }
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            height: 100%;
            color: var(--theme-text-color);
            font-weight: 600;
            padding: 0.375em 0.75em;
            transition: color 50ms;

            @media screen and (min-width: $breakpoints-xl) {
                padding: 0.375em 1.25em;
            }

            &:not(&--disabled):hover,
            &--active {
                //color: var(--theme-text-color--50);
                opacity: 0.5;
            }

            &--disabled {
                cursor: not-allowed;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "coming_soon": "Coming soon!"
    }
}
</i18n>
