import { useAppStore } from '@/libs/stores/app.store'
import { AxiosResponse } from 'axios'

export async function handleMaintenanceInterceptor(response: AxiosResponse) {
    if (response?.status === 210) {
        const store = useAppStore()
        store.inMaintenance = true
    }

    return response
}

export default handleMaintenanceInterceptor
