<script setup lang="ts">
    import { watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import MobileNavigationMenuTemplate from '@/components/templates/public/MobileNavigationMenuTemplate.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import MainMenuEntry from '@/components/layouts/common/__partials__/MainMenuEntry.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { RouteName } from '@/vars/RouteName'
    import BaseButtonV2 from '@/components/ui/actions/BaseButton.v2.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useProfileNetworkNavigation } from '@/libs/navigations/network/profileNavigation'
    import { RouteLocationNamedRaw } from 'vue-router'
    import { AvatarSize } from '@/vars/AvatarAttr'

    const showNavigation = defineModel<boolean>()

    const authStore = useAuthStore()
    const { t } = useI18n()
    const { items } = useProfileNetworkNavigation()

    watch(showNavigation, () => {
        if (showNavigation.value) {
            window.$crisp?.push(['do', 'chat:hide'])
        } else {
            window.$crisp?.push(['do', 'chat:show'])
        }
    })
</script>

<template>
    <MobileNavigationMenuTemplate v-model="showNavigation" class="modal" from="right">
        <template #header>
            <div class="modal__header-info">
                <div class="modal__user">
                    <AvatarMedia
                        class="modal__user-picture"
                        :src="authStore.authentifiedUser?.picture"
                        :size="AvatarSize.SMALL"
                        alt=""
                    />
                    <div v-if="authStore.authentifiedUser">
                        <p class="mb-0">{{ authStore.authentifiedUser.full_name }}</p>
                        <p v-if="authStore.authentifiedUser.position" class="modal__user-position text-sm">
                            {{ authStore.authentifiedUser.position }}
                        </p>
                    </div>
                </div>
            </div>
            <button type="button" class="modal__close" @click="showNavigation = false">
                <BaseIcon name="close" />
            </button>
        </template>
        <template #default>
            <template v-if="authStore.authentifiedUser">
                <div v-for="item in items" :key="item.label">
                    <h6 class="modal__section-title">{{ item.label }}</h6>
                    <Component
                        :is="children.href ? 'a' : SpaRouterLink"
                        v-for="children in item.children"
                        :key="children.label"
                        v-bind="
                            children.route ? { to: children.route as RouteLocationNamedRaw } : { href: children.href }
                        "
                        @click="children.action"
                    >
                        <MainMenuEntry
                            :label="children.label as string"
                            no-border
                            class="ml-4"
                            @click="showNavigation = false"
                        />
                    </Component>
                </div>
            </template>
            <div v-else class="modal__section-anonymous">
                <BaseButtonV2 :theme="ButtonTheme.TERTIARY" :to="{ name: RouteName.LOGIN }">
                    {{ t('login') }}
                </BaseButtonV2>
                <BaseButtonV2 :theme="ButtonTheme.PRIMARY" :to="{ name: RouteName.LOGIN }">
                    {{ t('register') }}
                </BaseButtonV2>
            </div>
        </template>
    </MobileNavigationMenuTemplate>
</template>

<style lang="scss" scoped>
    .modal {
        --avatar-size: 2em;

        &__user {
            display: flex;
            align-items: center;
            gap: var(--scale-4);
        }

        &__user-picture {
            --size: var(--avatar-size);
        }

        &__user-position {
            color: var(--theme-color-gray--03);
        }

        &__anonymous-icon {
            font-size: var(--avatar-size);
        }

        &__section-anonymous {
            display: flex;
            flex-direction: column;
            padding: var(--scale-6);
            gap: var(--scale-4);
            max-width: 24em;
            margin: auto;
        }

        &__close {
            display: inline-flex;
            border: none;
            background: none;
            cursor: pointer;
            font-size: 1.25em;
            color: var(--theme-text-color);
        }

        &__section-title {
            padding: var(--scale-6);
            padding-bottom: var(--scale-2);
            font-size: 1.125em;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "my_account": "Mon compte",
        "my_profile_ff": "Mon profil Frenchfounders",
        "my_ids": "Mes identifiants",
        "login": "Me connecter",
        "register": "M'inscrire",
        "logout": "Déconnexion"
    },
    "en": {
        "my_account": "My account",
        "my_profile_ff": "My Frenchfounders profile",
        "my_ids": "My details",
        "login": "Login",
        "register": "Register",
        "logout": "Logout"
    }
}
</i18n>
