/**
 * Charge un script Javascript dans le corps du HTML s'il n'a jamais été chargé.
 *
 * @param scriptSrc Source du script à charger
 * @param force Si on doit forcer le chargement du script, même s'il est déjà inclus.
 * @returns
 */
export function loadScript(
    scriptSrc: string,
    forceDownload = false,
    attributes?: Record<string, unknown>
): Promise<void> {
    return new Promise((resolve, reject) => {
        const allScripts = document.querySelectorAll('script')
        let isAlreadyLoaded = false

        allScripts.forEach((script) => {
            if (script.src === scriptSrc) {
                isAlreadyLoaded = true
            }
        })

        if (isAlreadyLoaded && !forceDownload) {
            return resolve()
        }

        const script = document.createElement('script')
        script.src = scriptSrc
        script.type = 'text/javascript'

        if (attributes) {
            Object.entries(attributes).forEach(([key, value]) => {
                script[key] = value
            })
        }

        script.onload = () => {
            resolve()
        }

        script.onerror = (e) => {
            reject(e)
        }

        document.body.appendChild(script)
    })
}
