import { useAppStore } from '@/libs/stores/app.store'
import { AxiosError } from 'axios'

export async function handlePendingDeploymentInterceptor(error: AxiosError) {
    if (error.response?.status !== 425) {
        throw error
    }

    const store = useAppStore()
    store.inMaintenance = true
    throw error
}

export default handlePendingDeploymentInterceptor
