import axios from 'axios'

const headers = {
    Origin: import.meta.env.VITE_APP_BASE_URL,
    'Content-Type': 'application/x-www-form-urlencoded'
}

const http = axios.create({
    baseURL: import.meta.env.VITE_STRAPI_BASE_URL,
    headers: headers
})

class ZappierApi {
    static async postEmailNewsLetter(email: string): Promise<void> {
        await http.post(`https://hooks.zapier.com/hooks/catch/2147875/br3h1no/`, {
            email
        })
    }
}

export default ZappierApi
