<script setup lang="ts">
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import useMenuNavigationState from '@/components/layouts/common/header/navigations/profile/partials/useMenuNavigationState'
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import DropdownContent from '@/components/ui/navigations/dropdowns/DropdownContent.vue'
    import DropdownItem from '@/components/ui/navigations/dropdowns/DropdownItem.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import BaseTag from '@/components/ui/basics/BaseTag.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderCity } from '@/types/Header.type'
    import { SpaName } from '@/vars/SpaAttr'
    import { TagType } from '@/vars/TagAttr'
    import { AboutRouteName } from '@/vars/RouteName'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'

    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const store = useAuthStore()
    const { t } = useI18n()
    const { isOpened } = useMenuNavigationState()
    const selectedCity = ref<string>(t('global'))

    const cities: HeaderCity[] = [
        { name: t('global'), available: true },
        { name: 'New York', available: true },
        { name: 'San Francisco', available: false }
    ]

    const selectCity = (city: HeaderCity): void => {
        if (city.available && city.name !== selectedCity.value) {
            selectedCity.value = city.name
        }
    }
</script>

<template>
    <DropdownNavigation
        show-on-click
        class="city"
        :auto-close-delay-millis-after-leave="500"
        @open="isOpened = true"
        @close="isOpened = false"
    >
        <template #activator>
            <div
                class="city__pill"
                :class="{
                    'city__pill--dark': spaRunning === SpaName.LECLUB,
                    'city__pill--network': spaRunning === SpaName.LENETWORK
                }"
            >
                <span class="flex items-center">
                    <BaseIcon name="public" class="city__icon mr-2" />
                    {{ selectedCity }}
                </span>
                <MaterialIcon v-if="isOpened" name="keyboard_arrow_up" class="text-[22px] text-gray-3" />
                <MaterialIcon v-else name="keyboard_arrow_down" class="text-[22px] text-gray-3" />
            </div>
        </template>
        <template #dropdown>
            <DropdownContent
                v-if="store.isLoggedIn"
                class="city__dropdown visible block translate-y-0 opacity-100"
                align="right"
            >
                <section class="section">
                    <div class="section__head">
                        <h5 class="section__title">{{ t('switch') }}</h5>
                    </div>
                    <div class="section__items px-4 py-2">
                        <DropdownItem
                            v-for="city in cities"
                            :key="city"
                            class="section__item mb-2"
                            :hoverable="false"
                            @click="selectCity(city)"
                        >
                            <div class="flex items-center justify-between">
                                <span
                                    class="section__city text-sm text-legacy"
                                    :class="{
                                        'font-medium': city.name === selectedCity,
                                        'text-gray-4': !city.available
                                    }"
                                >
                                    {{ city.name }}
                                </span>
                                <BaseTag v-if="city.name === selectedCity" class="section__tag">
                                    {{ t('you_are_here') }}
                                </BaseTag>
                                <BaseTag v-else-if="!city.available" :type="TagType.NEUTRAL" class="section__tag">
                                    {{ t('coming_soon') }}
                                </BaseTag>
                            </div>
                        </DropdownItem>
                    </div>
                </section>
                <section class="section">
                    <div class="section__head mt-4">
                        <BaseButton
                            :theme="ButtonTheme.TERTIARY"
                            testId="new-group-id"
                            :to="{
                                name: AboutRouteName.WEWEB_VOTE_NEXT_CITY,
                                query: {
                                    firstName: store.authentifiedUser?.first_name,
                                    lastName: store.authentifiedUser?.last_name,
                                    email: store.authentifiedUser?.email
                                }
                            }"
                        >
                            <span class="flex items-center gap-2">
                                <MaterialIcon class="title-4 text-royale" name="thumb_up" />
                                <span class="text-sm font-normal">{{ t('vote') }}</span>
                            </span>
                        </BaseButton>
                        <small v-white-space class="section__subtitle pt-4 text-center" v-html="t('vote_subtitle')" />
                    </div>
                </section>
            </DropdownContent>
        </template>
    </DropdownNavigation>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .city {
        $block-selector: &;
        height: 100%;
        position: relative;
        display: block;
        width: 100%;

        &__pill {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.875rem;
            width: 100%;

            &--network {
                @media screen and (max-width: $breakpoints-lg) {
                    padding: 0 !important;
                    border: none !important;
                }
            }

            @media only screen and (min-width: $breakpoints-lg) {
                padding: 0.375em 0.875em;
                border-radius: (0.5em * 2) + (0.375em * 2);
                background-color: var(--theme-background-color--header-pill);
                border: 1px solid var(--theme-border-color--header-pill);

                &--dark {
                    background-color: rgba(242, 242, 242, 0.1) !important;
                    border: transparent;
                }
            }
        }

        &__icon {
            height: 2rem;
            font-size: 1.25rem;
            * {
                fill: var(--theme-text-color--header);
            }
        }

        &__dropdown {
            width: 18em;
            margin-top: var(--scale-2);
        }
    }

    .section {
        $block-selector: &;
        margin: 0.5rem 0 1em 0;

        &:last-child {
            margin: 0;
        }

        &__head {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0.5rem;
            color: var(--theme-color-legacy);
        }

        &__icon {
            font-size: 1.5rem;
        }

        &__link {
            display: block;
            padding-bottom: 0.5rem;
        }
        &__title {
            font-size: 1em;
            font-weight: 600;
            margin: 0;
            padding: 0;
            white-space: nowrap;

            #{$block-selector}__link & {
                padding-bottom: 0;
            }
        }
        &__subtitle {
            font-size: 0.75em;
        }

        &__city {
            padding: 0.125rem 0;
        }

        &__tag {
            #{$block-selector}__item--disabled & {
                display: none;
            }

            #{$block-selector}__item--disabled:hover & {
                display: block;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "switch": "Changer votre ville",
        "vote": "Voter pour la prochaine ville",
        "vote_subtitle": "Quelle ville devons-nous lancer ensuite ?\nVenez voter !",
        "you_are_here": "Sélectionné",
        "coming_soon": "Coming soon",
        "global": "Monde entier"
    },
    "en": {
        "switch": "Choose a city",
        "vote": "Vote for the next city",
        "vote_subtitle": "Which city should we launch next? Come and vote!",
        "you_are_here": "Selected",
        "coming_soon": "Coming soon",
        "global": "Worldwide"
    }
}
</i18n>
