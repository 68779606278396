import { useQuery, UseQueryOptions } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import { QueryName } from '@/vars/QueryAttr'
import useEkko from '../compositions/useEkko'
import { computed } from 'vue'
import { useAuthStore } from '../stores/auth.store'

export default function useEkkoUnreadMessageCountQuery(
    opts?: Omit<
        UseQueryOptions<number, AxiosError, number, [QueryName.EKKO_UNREAD_MESSAGE_COUNT]>,
        'queryFn' | 'queryKey'
    >
) {
    const { getNewUnreadMessageCount, connectToEkko } = useEkko()
    const authStore = useAuthStore()

    const { data: ekkoUser } = useQuery({
        queryKey: [QueryName.INIT_EKKO, authStore.authentifiedUser],
        queryFn: () => {
            if (!authStore.authentifiedUser) {
                throw new Error('no user connected')
            }

            return connectToEkko(authStore.authentifiedUser.idff, authStore.authentifiedUser.ekko_access_token)
        },
        enabled: !!authStore.authentifiedUser?.idff
    })

    const enabled = computed(() => {
        return !!ekkoUser.value
    })

    return useQuery({
        queryKey: [QueryName.EKKO_UNREAD_MESSAGE_COUNT],
        queryFn: () => getNewUnreadMessageCount(),
        enabled,
        ...opts
    })
}
