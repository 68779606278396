import { RouteContext } from '@/types/Router.type'
import { useAuthStore } from '../stores/auth.store'
import { useNavigationResolver } from '../compositions/useNavigationResolver'
import { SpaName } from '@/vars/SpaAttr'
import { RouteLocation, RouteLocationNamedRaw } from 'vue-router'
import { captureException } from '@sentry/vue'
import { RouteName } from '@/vars/RouteName'

/**
 * Si on est pas connecté,on peut mettre une route de fallback vers laquelle on fait la redirection.
 * - fallbackRouteForAnonymous(SpaName.LENETWORK): tente une redirection vers la même page mais de la SPA passée en parmètre
 * - fallbackRouteForAnonymous(SpaName.LENETWORK, (to: RouteLocation) => ({ name: RouteName.HOME })): tente une redirection vers la même page mais de la SPA passée en paramètre mais on spécifie la route de redirection
 */
export default function fallbackRouteForAnonymous(
    spa: SpaName,
    callback: (to: RouteLocation) => RouteLocationNamedRaw | RouteLocation = (to) => to
) {
    return ({ to, next }: RouteContext) => {
        const authStore = useAuthStore()
        const { redirectBounding, navigate } = useNavigationResolver()

        if (authStore.isLoggedIn || import.meta.env.VITE_SPA_RUNNING === spa) {
            return next()
        }

        const { href, to: fallbackTo } = redirectBounding(callback(to), { spa })

        if (href) {
            const warningMessage = `Unable to redirect to ${spa}: Route ${callback(to)} is not accessible`
            console.warn(warningMessage)
            captureException(warningMessage)
            return next()
        } else if (fallbackTo?.url) {
            window.location.replace(fallbackTo.url)
        } else {
            navigate({ name: RouteName.HOME })
        }
    }
}
