<script setup lang="ts">
    import ThemeLayer from '@/components/ui/layers/ThemeLayer.vue'
    import LeClubMainHeader from '@/components/layouts/leclub/header/MainHeader.vue'
    import { useRoute } from 'vue-router'
    import DefaultFooterLayoutMondrian from '@/components/layouts/leclub/footers/DefaultFooter.vue'
    import useLayout from '@/libs/compositions/useLayout'
    import { computed } from 'vue'

    const route = useRoute()
    const { showOnlyContent } = useLayout()
    const defaultBackgroundHeight = '40em'

    const backgroundHeight = computed(() => {
        if (route.meta.gridBackgroundHeight) {
            return route.meta.gridBackgroundHeight
        }
        return defaultBackgroundHeight
    })
</script>

<template>
    <ThemeLayer>
        <Component
            :is="LeClubMainHeader"
            v-if="!showOnlyContent"
            class="layout__header"
            :class="{ 'layout__header--grid-background': route.meta.hasGridBackground }"
        />

        <main class="layout__main" :class="{ 'layout__main--grid-background': route.meta.hasGridBackground }">
            <div v-if="route.meta.hasGridBackground" class="layout__mask"></div>
            <RouterView v-slot="{ Component: ViewComponent }" class="layout__router">
                <Transition name="page-">
                    <Component :is="ViewComponent" />
                </Transition>
            </RouterView>
        </main>
        <DefaultFooterLayoutMondrian v-if="!showOnlyContent" />
    </ThemeLayer>
</template>

<style scoped lang="scss">
    @mixin grid-pattern($height) {
        height: $height;
        transform: translateY(-12em);
    }

    .layout {
        &__header {
            z-index: 1;
            background-color: var(--theme-text-color);
            &--grid-background {
                background-color: transparent;
                --theme-shadow-color: transparent;
            }
        }

        &__main {
            position: relative;
            z-index: 0;
            background-color: var(--theme-color-flagship--10);
            --theme-slider-background--rgb: var(--theme-background-color--important--rgb);

            &--grid-background {
                &::before {
                    content: '';
                    background: white;
                    -webkit-mask-image: url('@/assets/patterns/squares.svg');
                    mask-image: url('@/assets/patterns/squares.svg');
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    @include grid-pattern(v-bind('backgroundHeight'));
                    z-index: -1;
                    opacity: 0.3;
                    transform: translateY(-14em);
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--theme-color-header-background);
                    @include grid-pattern(v-bind('backgroundHeight'));
                    z-index: -2;
                }
            }
        }

        &__mask {
            background: radial-gradient(
                circle 50dvw at 50% 10%,
                transparent -100%,
                var(--theme-color-header-background) 95%
            );
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include grid-pattern(v-bind('backgroundHeight'));
            z-index: -1;
        }

        &__router {
            min-height: 80dvh;
        }
    }
</style>
