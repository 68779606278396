import { FormRegister, LinkedinAccessToken, RegisterResponse, RegisterTokenInfo, User } from '@/types/Auth.type'
import { ResponseV3 } from '@/types/response/ResponseV3.type'
import Http from './ApiClient'
import { AxiosRequestConfig } from 'axios'
import { LoginForm } from '@/types/forms/auth-api/LoginForm.type'
import { LoginResponse } from '@/types/response/auth-api/LoginResponse.type'
import { GoogleOAuthLoginForm } from '@/types/forms/auth-api/GoogleOAuthLoginForm.type'
import { withTracking } from '../helpers/tracking'

const PRIVATE_URL = import.meta.env.VITE_PRIVATE_BASE_URL

class AuthApi {
    static async getMe(): Promise<User> {
        const response = await Http.get('/member/me')

        return response.data
    }

    static async login(form: LoginForm): Promise<LoginResponse> {
        const response = await Http.post('/login_check', {
            _username: form.email,
            _password: form.password,
            context: form.context
        })

        return response.data
    }

    static async confirmMagicLink(queryParams: Record<string, unknown>): Promise<{ redirect: string }> {
        const response = await Http.post('/v3/auth/magic-link', queryParams)

        return response.data
    }

    /**
     * Verifie si un compte est déjà associé à cette adresse email.
     * @return true - Si l'email est connu
     */
    static async verifyEmail(form: { email: string }): Promise<boolean> {
        const response = await Http.get(`/v3/members/${form.email}/verify`)
        return response.data
    }

    static async refreshToken(currentToken: string, refreshToken: string): Promise<{ token: string }> {
        const response = await Http.post(
            '/token',
            {
                access_token: currentToken,
                refresh_token: refreshToken
            },
            {
                headers: {
                    Authorization: ''
                }
            }
        )

        if (response.status == 210) {
            throw new Error('Maintenance mode')
        }

        return response.data
    }

    static async forceLogin(config?: AxiosRequestConfig): Promise<unknown> {
        if (!config) {
            const response = await Http.post(`${PRIVATE_URL}/auth/force-login`)
            return response?.data
        } else {
            const response = await Http.post(`${PRIVATE_URL}/auth/force-login`, {}, config)
            return response?.data
        }
    }

    static async forceLogout(): Promise<unknown> {
        const response = await Http.get(`${PRIVATE_URL}/auth/force-logout`)
        return response?.data
    }

    static async forceLoginApi(config?: AxiosRequestConfig): Promise<unknown> {
        const response = await Http.post(`/v3/auth/force-login`, {}, config)
        return response?.data
    }

    static async switchTo(email: string): Promise<void> {
        const response = await Http.get(`${import.meta.env.VITE_PRIVATE_BASE_URL}/`, {
            headers: {
                Authorization: ''
            },
            params: {
                _switch_user: email
            }
        })
        return response?.data
    }

    static async exitSwitchTo(): Promise<void> {
        const response = await Http.get(`${import.meta.env.VITE_PRIVATE_BASE_URL}/`, {
            params: {
                _switch_user: '_exit'
            }
        })
        return response?.data
    }

    /**
     * Get the token of the current user connected (or impersonated user)
     */
    static async getAuthToken(): Promise<{ token: string }> {
        const response = await Http.get(`${import.meta.env.VITE_PRIVATE_BASE_URL}/auth/token`, {
            headers: {
                Authorization: ''
            }
        })

        return response?.data
    }

    static async oAuthGoogleLogin(
        form: GoogleOAuthLoginForm
    ): Promise<ResponseV3<RegisterResponse, RegisterTokenInfo>> {
        const { data } = await Http.post(`/v3/signin/google`, withTracking(form))
        return data
    }

    static async register(form: FormRegister): Promise<ResponseV3<RegisterResponse, RegisterTokenInfo>> {
        const { data } = await Http.post(`/v3/register`, withTracking(form))

        return data
    }

    static async getLinkedInAccessToken(form: {
        code: string
        redirectUri: string
    }): Promise<ResponseV3<LinkedinAccessToken, undefined>> {
        const response = await Http.get(`/v3/auth/linkedin/access-token`, { params: form })

        return response.data
    }

    static async oAuthLinkedinLogin(form: {
        context: string
        linkedinToken: string
        linkedinAuthCode: string
    }): Promise<ResponseV3<RegisterResponse, RegisterTokenInfo>> {
        const { data } = await Http.post(`/v3/signin/linkedin`, withTracking(form))

        return data
    }

    static async passwordForgot(params: { username: string }): Promise<void> {
        await Http.post('/password/forgot', {
            username: params.username
        })
    }

    static async resetPassword(params: { token: string; password: string }): Promise<void> {
        await Http.post(`/password/forgot/${params.token}`, {
            plainPassword: params.password
        })
    }
}

export default AuthApi
