import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import frMessages from '@/vars/i18n/public-header/fr.json'
import enMessages from '@/vars/i18n/public-header/en.json'
import { Persona } from '@/types/Persona.type'
import {
    AboutRouteName,
    BenefitRouteName,
    ClubRouteName,
    CompaniesRouteName,
    EventRouteName,
    ProRouteName,
    RouteName
} from '@/vars/RouteName'
import { useAuthStore } from '../stores/auth.store'
import { BusinessLineName, RoleName } from '@/vars/AuthAttr'
import { RouteLocationNamedRaw, useRoute } from 'vue-router'
import useGroupsQuery from '@/libs/queries/useGroupsQuery'
import useBreakpoints from './useBreakpoints'

export type MenuCategory = {
    key: string
    label: string
    items: {
        iconName: string
        title: string
        text: string
        disabled?: boolean
        to?: RouteLocationNamedRaw
        href?: string
    }[]
}

export type MainNavigationItem = {
    label: string
    key: string
    categories: MenuCategory[]
}

export type MainNavigation = MainNavigationItem[]

export type Universe = {
    key: string
    label: string
    text: string
    color: string
    hasRole: boolean
    to: RouteLocationNamedRaw
    items: {
        label: string
        href?: string
        to?: RouteLocationNamedRaw
    }[]
}

type SectionNavigation = {
    title?: string
    links: Link[]
}

export type Link = {
    label: string
    icon: string
    to?: RouteLocationNamedRaw
    href?: string
    active?: boolean
    children?: ChildLink[]
}

export type ChildLink = Omit<Link, 'icon' | 'children'>

export default function usePublicHeaderNavigation() {
    const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL
    const clubUrl = import.meta.env.VITE_CLUB_BASE_URL
    const bsUrl = import.meta.env.VITE_PRO_BASE_URL

    const route = useRoute()
    const groups = useGroupsQuery()
    const { breakpoints } = useBreakpoints()
    const { t } = useI18n({
        messages: {
            fr: frMessages,
            en: enMessages
        }
    })

    const authStore = useAuthStore()

    const universeNavigation = computed<Universe[]>(() => {
        const clubItems: {
            label: string
            href?: string
            to?: RouteLocationNamedRaw
        }[] = [
            {
                label: t('learn_more_club'),
                to: { name: AboutRouteName.WEWEB_CLUB }
            },
            {
                label: authStore.isLostClub ? t('club_candidate_lost') : t('club_candidate'),
                to: { name: RouteName.APPLY_CLUB_PLAN }
            }
        ]

        if (!authStore.isLoggedIn) {
            clubItems.splice(2, 0, {
                label: t('login'),
                to: { name: RouteName.LOGIN, query: { clubUrl } }
            })
        }

        const bsItems: {
            label: string
            href?: string
            to?: RouteLocationNamedRaw
        }[] = [
            {
                label: t('learn_more_bs'),
                to: { name: AboutRouteName.WEWEB_BUSINESS_SOLUTIONS }
            },

            {
                label: t('create_company'),
                href: `${bsUrl}`
            },
            {
                label: t('new_job_onboarding'),
                to: { name: ProRouteName.JOB_OFFER_WIZARD }
            }
        ]

        if (!authStore.isLoggedIn) {
            bsItems.splice(1, 0, {
                label: t('login'),
                to: { name: RouteName.LOGIN, query: { bsUrl } }
            })
        }

        return [
            {
                key: 'public',
                label: t('public'),
                text: '',
                color: 'legacy',
                hasRole: true,
                to: authStore.isLoggedIn ? { name: RouteName.HOME } : { name: RouteName.LOGIN },
                items: []
            },
            {
                key: 'club',
                label: t('club'),
                text: t('club_text'),
                color: 'legacy',
                hasRole: authStore.hasRole(RoleName.LECLUB),
                to: { name: ClubRouteName.HOME },
                items: clubItems
            },
            {
                key: 'bs',
                label: t('bs'),
                text: t('bs_text'),
                color: 'royale',
                hasRole:
                    authStore.hasRole(RoleName.BUSINESS_SOLUTIONS) ||
                    authStore.hasBusinessLine(BusinessLineName.BUSINESS),
                to: { name: ProRouteName.HOME },
                items: bsItems
            }
        ]
    })

    const mainNavigation = computed<MainNavigation>(() => {
        const EXCHANGE_WITH_PAIRS = {
            iconName: 'voice_selection',
            title: t('exchange_with_pairs'),
            text: t('exchange_with_pairs.text'),
            to: { name: AboutRouteName.WEWEB_CLUB }
        }

        return [
            {
                label: t('you_are'),
                key: 'you_are',
                categories: [
                    {
                        key: Persona.CEO,
                        label: t('ceo'),
                        items: [
                            EXCHANGE_WITH_PAIRS,
                            {
                                iconName: 'science',
                                title: t('source_innovations'),
                                text: t('source_innovations.text'),
                                to: { name: AboutRouteName.WEWEB_PROGRAMME_SCALE }
                            },
                            {
                                iconName: 'wallet',
                                title: t('invest'),
                                text: t('invest.text'),
                                to: { name: AboutRouteName.WEWEB_INVESTMENT }
                            },
                            {
                                iconName: 'next_week',
                                title: t('prepare_next_step'),
                                text: t('prepare_next_step.text'),
                                to: { name: AboutRouteName.WEWEB_LOOKING_JOB }
                            },
                            {
                                iconName: 'handshake',
                                title: t('find_partners'),
                                text: t('find_partners.text'),
                                to: { name: CompaniesRouteName.COMPANY_HOME }
                            }
                        ]
                    },
                    {
                        key: Persona.ENTREPRENEUR,
                        label: t('entrepreneur'),
                        items: [
                            EXCHANGE_WITH_PAIRS,
                            {
                                iconName: 'moving',
                                title: t('vest_my_growth'),
                                text: t('vest_my_growth.text'),
                                to: { name: AboutRouteName.WEWEB_INVESTMENT }
                            },
                            {
                                iconName: 'home_work',
                                title: t('develop_my_company'),
                                text: t('develop_my_company.text'),
                                to: { name: AboutRouteName.WEWEB_BUSINESS_SOLUTIONS }
                            },
                            {
                                iconName: 'contract_edit',
                                title: t('recruit_talents'),
                                text: t('recruit_talents.text'),
                                to: { name: AboutRouteName.WEWEB_RECRUTE }
                            },
                            {
                                iconName: 'wallet',
                                title: t('entrepreneur.invest'),
                                text: t('entrepreneur.invest.text'),
                                to: { name: AboutRouteName.WEWEB_INVESTMENT }
                            }
                        ]
                    },
                    {
                        key: Persona.MANAGER,
                        label: t('manager'),
                        items: [
                            {
                                iconName: 'work',
                                title: t('develop_my_career'),
                                text: t('develop_my_career.text'),
                                to: { name: AboutRouteName.WEWEB_LOOKING_JOB }
                            },
                            {
                                iconName: 'group_add',
                                title: t('develop_my_network'),
                                text: t('develop_my_network.text'),
                                to: { name: RouteName.EVENT_LIST }
                            },
                            {
                                iconName: 'home_work',
                                title: t('find_providers'),
                                text: t('find_providers.text'),
                                to: { name: CompaniesRouteName.COMPANY_HOME }
                            },
                            {
                                iconName: 'travel_explore',
                                title: t('recruit_international_talents'),
                                text: t('recruit_international_talents.text'),
                                to: { name: AboutRouteName.WEWEB_RECRUTE }
                            }
                        ]
                    },
                    {
                        key: Persona.YOUNG_TALENT,
                        label: t('talent'),
                        items: [
                            {
                                iconName: 'award_star',
                                title: t('inspire_by_leaders'),
                                text: t('inspire_by_leaders.text'),
                                to: { name: RouteName.RESOURCES_LIST }
                            },
                            {
                                iconName: 'tools_ladder',
                                title: t('give_impulsion_to_my_career'),
                                text: t('give_impulsion_to_my_career.text'),
                                to: { name: AboutRouteName.WEWEB_LOOKING_JOB }
                            },
                            {
                                iconName: 'group_add',
                                title: t('develop_my_network'),
                                text: t('develop_my_network.text_talent'),
                                to: { name: RouteName.EVENT_LIST }
                            },
                            {
                                iconName: 'wallet',
                                title: t('start_investing'),
                                text: t('start_investing.text'),
                                to: {
                                    name: RouteName.RESOURCES_LIST,
                                    query: { categories: 'Investissement & Financement' }
                                }
                            },
                            {
                                iconName: 'luggage',
                                title: t('discover_expatriation'),
                                text: t('discover_expatriation.text'),
                                to: { name: AboutRouteName.WEWEB_EXPATRIATION }
                            }
                        ]
                    }
                ]
            },
            {
                key: 'solutions',
                label: t('solutions'),
                categories: [
                    {
                        key: 'individuals',
                        label: t('individuals'),
                        items: [
                            {
                                iconName: 'groups',
                                title: t('solutions.club'),
                                text: t('solutions.club.text'),
                                to: {
                                    name: AboutRouteName.WEWEB_CLUB
                                }
                            },
                            {
                                iconName: 'toll',
                                title: t('solutions.investors_club'),
                                text: t('solutions.investors_club.text'),
                                ...(authStore.hasRole(RoleName.LECLUB)
                                    ? { href: `${privateUrl}/clubs/investor` }
                                    : { to: { name: AboutRouteName.WEWEB_INVESTMENT } })
                            },
                            {
                                iconName: 'wallet',
                                title: t('solutions.fonds'),
                                text: t('solutions.fonds.text'),
                                to: { name: AboutRouteName.WEWEB_INVESTMENT }
                            },
                            {
                                iconName: 'supervised_user_circle',
                                title: t('solutions.talent_pool'),
                                text: t('solutions.talent_pool.text'),
                                to: { name: AboutRouteName.WEWEB_LOOKING_JOB }
                            },
                            {
                                iconName: 'home_work',
                                title: t('solutions.business_directory'),
                                text: t('solutions.business_directory.text'),
                                to: { name: CompaniesRouteName.COMPANY_HOME }
                            }
                            // {
                            //     iconName: 'cognition',
                            //     title: t('solutions.innovation_program'),
                            //     text: t('solutions.innovation_program.text'),
                            //     to: { name: AboutRouteName.WEWEB_PROGRAMME_SCALE },
                            // },
                        ]
                    },
                    {
                        key: 'companies',
                        label: t('companies'),
                        items: [
                            {
                                iconName: 'stack_star',
                                title: t('solutions.business_solutions'),
                                text: t('solutions.business_solutions.text'),
                                to: { name: AboutRouteName.WEWEB_BUSINESS_SOLUTIONS }
                            },
                            {
                                iconName: 'person_search',
                                title: t('solutions.recrutment'),
                                text: t('solutions.recrutment.text'),
                                to: { name: AboutRouteName.WEWEB_RECRUTE }
                            },
                            {
                                iconName: 'account_balance',
                                title: t('solutions.funding'),
                                text: t('solutions.funding.text'),
                                to: { name: AboutRouteName.WEWEB_INVESTMENT }
                            },
                            {
                                iconName: 'home_work',
                                title: t('solutions.referencing_business_directory'),
                                text: t('solutions.referencing_business_directory.text'),
                                to: { name: CompaniesRouteName.COMPANY_HOME }
                            },
                            {
                                iconName: 'cognition',
                                title: t('solutions.innovation_program'),
                                text: t('solutions.innovation_program.text'),
                                to: { name: AboutRouteName.WEWEB_PROGRAMME_SCALE }
                            }
                        ]
                    }
                ]
            },
            {
                key: 'events',
                label: t('events'),
                categories: [
                    {
                        key: 'events',
                        label: '',
                        items: [
                            {
                                iconName: 'enterprise',
                                title: t('events.for_everybody'),
                                text: t('events.for_everybody.text'),
                                to: { name: RouteName.EVENT_LIST_PUBLIC }
                            },
                            {
                                iconName: 'local_bar',
                                title: t('events.tlf'),
                                text: t('events.tlf.text'),
                                href: `https://tlf.frenchfounders.com/`
                            },
                            {
                                iconName: 'badge',
                                title: t('events.club_members'),
                                text: t('events.club_members.text'),
                                to: { name: RouteName.EVENT_LIST_CLUB }
                            },
                            {
                                iconName: 'local_mall',
                                title: t('events.trs'),
                                text: t('events.trs.text'),
                                href: `https://trs.frenchfounders.com/`
                            }
                        ]
                    }
                ]
            },
            {
                key: 'resources',
                label: t('resources'),
                categories: [
                    {
                        key: 'resources',
                        label: '',
                        items: [
                            {
                                iconName: 'contract_edit',
                                title: t('resources.jobs'),
                                text: t('resources.jobs.text'),
                                to: { name: RouteName.JOB_LIST_SELECTION }
                            },
                            {
                                iconName: 'newsmode',
                                title: t('resources.business_infos'),
                                text: t('resources.business_infos.text'),
                                to: { name: RouteName.RESOURCES_LIST }
                            },
                            {
                                iconName: 'home_work',
                                title: t('resources.business_directories'),
                                text: t('resources.business_directories.text'),
                                to: { name: CompaniesRouteName.COMPANY_HOME }
                            },
                            {
                                iconName: 'redeem',
                                title: t('resources.benefits'),
                                text: t('resources.benefits.text'),
                                disabled: true
                            },
                            {
                                iconName: 'mark_unread_chat_alt',
                                title: t('resources.announces'),
                                text: t('resources.announces.text'),
                                disabled: true
                            }
                        ]
                    }
                ]
            }
        ]
    })

    const mainLoggedNavigation = computed<SectionNavigation[]>(() => {
        const nav = [
            {
                links: [
                    {
                        label: t('home'),
                        icon: 'home',
                        to: { name: RouteName.HOME },
                        active: route.name === RouteName.HOME
                    }
                ]
            },
            {
                title: t('community'),
                links: [
                    {
                        label: t('members'),
                        icon: 'group',
                        to: { name: RouteName.MEMBERS_LIST },
                        active: route.name === RouteName.MEMBERS_LIST
                    },
                    {
                        label: t('groups'),
                        icon: 'groups',
                        to: { name: RouteName.GROUP_LIST },
                        children: groups?.data.value?.data.map((group) => ({
                            label: group.name,
                            to: { name: RouteName.CIRCLE_SINGLE, params: { slug: group.slug } },
                            active: route.name === RouteName.CIRCLE_SINGLE && route.params.slug === group.slug
                        }))
                    },
                    {
                        label: t('events'),
                        icon: 'event',
                        active: route.name === EventRouteName.EVENT_DETAILS,
                        children: [
                            {
                                label: t('events_public'),
                                to: { name: RouteName.EVENT_LIST_PUBLIC },
                                active: route.name === RouteName.EVENT_LIST_PUBLIC
                            },
                            {
                                label: t('events_me'),
                                to: { name: RouteName.EVENT_LIST_USER },
                                active: route.name === RouteName.EVENT_LIST_USER
                            },
                            {
                                label: t('external_events'),
                                to: { name: RouteName.EVENT_LIST_EXTERNAL },
                                active: route.name === RouteName.EVENT_LIST_EXTERNAL
                            }
                        ]
                    },
                    // TODO: a décommenter quand les petites annonce sont prêtes
                    // {
                    //     label: 'Petites annonces',
                    //     icon: 'breaking_news',
                    //     children: []
                    // },
                    {
                        label: t('resources'),
                        icon: 'list',
                        children: [
                            {
                                label: t('resources_latest'),
                                to: { name: RouteName.RESOURCES_LIST },
                                active: route.name === RouteName.RESOURCES_LIST
                            },
                            {
                                label: t('resources_articles'),
                                to: { name: RouteName.RESOURCES_LIST_ARTICLES },
                                active: route.name === RouteName.RESOURCES_LIST_ARTICLES
                            },
                            {
                                label: t('resources_videos'),
                                to: { name: RouteName.RESOURCES_LIST_VIDEOS },
                                active: route.name === RouteName.RESOURCES_LIST_VIDEOS
                            },
                            {
                                label: t('resources_podcasts'),
                                to: { name: RouteName.RESOURCES_LIST_PODCASTS },
                                active: route.name === RouteName.RESOURCES_LIST_PODCASTS
                            },

                            {
                                label: t('resources_thematic_folders'),
                                to: { name: RouteName.RESOURCES_LISTS_THEMATIC_FOLDERS },
                                active: route.name === RouteName.RESOURCES_LISTS_THEMATIC_FOLDERS
                            }
                        ]
                    },
                    {
                        label: t('benefits'),
                        icon: 'loyalty',
                        to: { name: BenefitRouteName.BENEFITS }
                    }
                ]
            },
            {
                title: t('offers'),
                links: [
                    {
                        label: t('offers_jobs'),
                        icon: 'work',
                        to: { name: RouteName.JOBS },
                        active: route.name === RouteName.JOBS
                    },
                    {
                        label: t('business_directory'),
                        icon: 'apartment',
                        to: { name: CompaniesRouteName.COMPANY_HOME },
                        active: route.name === CompaniesRouteName.COMPANY_HOME
                    },
                    {
                        label: t('invest'),
                        icon: 'database',
                        to: { name: AboutRouteName.WEWEB_INVESTMENT },
                        active: route.name === AboutRouteName.WEWEB_INVESTMENT
                    }
                ]
            }
        ]

        if (breakpoints.value.xs || breakpoints.value.sm) {
            nav.push({
                title: t('private_spaces'),
                links: [
                    {
                        label: t('private_club'),
                        icon: 'open_in_new',
                        to: authStore.hasRole(RoleName.LECLUB)
                            ? { name: ClubRouteName.HOME }
                            : { name: RouteName.APPLY_CLUB_PLAN },
                        active: false
                    },
                    {
                        label: t('b2b'),
                        icon: 'open_in_new',
                        href: `${import.meta.env.VITE_PRO_BASE_URL}`,
                        active: false
                    }
                ]
            })
        }

        return nav
    })

    const mainLoggedNavigationV2 = computed<Link[]>(() => {
        const nav = [
            {
                label: t('home'),
                icon: 'home',
                to: { name: RouteName.HOME },
                active: route.name === RouteName.HOME
            },
            {
                label: t('community'),
                icon: 'group',
                to: { name: RouteName.MEMBERS_LIST },
                active: route.name === RouteName.MEMBERS_LIST
            },
            {
                label: t('groups'),
                icon: 'groups',
                to: { name: RouteName.GROUP_LIST },
                active: route.name === RouteName.GROUP_LIST,
                children: groups?.data.value?.data.map((group) => ({
                    label: group.name,
                    to: { name: RouteName.CIRCLE_SINGLE, params: { slug: group.slug } },
                    active: route.name === RouteName.CIRCLE_SINGLE && route.params.slug === group.slug
                }))
            },
            {
                label: t('events'),
                icon: 'event',
                to: { name: RouteName.EVENT_LIST_PUBLIC },
                active: route.name === RouteName.EVENT_LIST_PUBLIC,
                children: [
                    {
                        label: t('events'),
                        to: { name: EventRouteName.EVENT_DETAILS },
                        active: route.name === EventRouteName.EVENT_DETAILS
                    },
                    {
                        label: t('events_me'),
                        to: { name: RouteName.EVENT_LIST_USER },
                        active: route.name === RouteName.EVENT_LIST_USER
                    },
                    {
                        label: t('external_events'),
                        to: { name: RouteName.EVENT_LIST_EXTERNAL },
                        active: route.name === RouteName.EVENT_LIST_EXTERNAL
                    }
                ]
            },
            {
                label: t('offers_jobs'),
                icon: 'business_center',
                to: { name: RouteName.JOBS },
                active: route.name === RouteName.JOB_LIST_SELECTION
            },
            {
                label: t('resources'),
                icon: 'format_list_bulleted',
                to: { name: RouteName.RESOURCES_LIST },
                active: route.name === RouteName.RESOURCES_LIST,
                children: [
                    {
                        label: t('resources_latest'),
                        to: { name: RouteName.RESOURCES_LIST },
                        active: route.name === RouteName.RESOURCES_LIST
                    },
                    {
                        label: t('resources_articles'),
                        to: { name: RouteName.RESOURCES_LIST_ARTICLES },
                        active: route.name === RouteName.RESOURCES_LIST_ARTICLES
                    },
                    {
                        label: t('resources_videos'),
                        to: { name: RouteName.RESOURCES_LIST_VIDEOS },
                        active: route.name === RouteName.RESOURCES_LIST_VIDEOS
                    },
                    {
                        label: t('resources_podcasts'),
                        to: { name: RouteName.RESOURCES_LIST_PODCASTS },
                        active: route.name === RouteName.RESOURCES_LIST_PODCASTS
                    },

                    {
                        label: t('resources_thematic_folders'),
                        to: { name: RouteName.RESOURCES_LISTS_THEMATIC_FOLDERS },
                        active: route.name === RouteName.RESOURCES_LISTS_THEMATIC_FOLDERS
                    }
                ]
            },
            {
                label: t('benefits'),
                icon: 'loyalty',
                to: { name: BenefitRouteName.BENEFITS },
                active: route.name === BenefitRouteName.BENEFITS
            },
            {
                label: t('business_directory'),
                icon: 'apartment',
                to: { name: CompaniesRouteName.COMPANY_HOME },
                active: route.name === CompaniesRouteName.COMPANY_HOME
            }
        ]
        return nav
    })

    return {
        mainNavigation,
        mainLoggedNavigation,
        universeNavigation,
        mainLoggedNavigationV2
    }
}
