import { loadScript } from '@/libs/helpers/load-scripts'

export function initGoogleOAuth() {
    if (window.gapi) {
        return null
    }

    loadScript('https://apis.google.com/js/client.js', false, { async: true })

    window.gapi = window.gapi || undefined
}
