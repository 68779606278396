<script lang="ts" setup>
    import usePublicHeaderNavigation from '@/libs/compositions/usePublicHeaderNavigation'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'

    const { mainLoggedNavigationV2 } = usePublicHeaderNavigation()
    const { navigate } = useNavigationResolver()
</script>

<template>
    <nav class="nav flex flex-col gap-2">
        <ul v-for="section in mainLoggedNavigationV2" :key="section.label" role="list">
            <li
                class="nav__link"
                :class="{ 'nav__link--active': section.active || section?.children?.some((child) => child.active) }"
                @click="navigate(section?.to)"
            >
                <MaterialIcon class="title-4" :name="section.icon" />
                <span class="flex-1">
                    {{ section.label }}
                </span>
            </li>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
    .nav {
        $transition: all 0.2s ease-in-out;
        &__link {
            @apply flex cursor-pointer items-center gap-x-3 rounded-lg p-2  font-medium leading-6;
            transition: $transition;

            &--active {
                @apply bg-flagship-10;
            }

            &:hover {
                @apply bg-flagship-10;
            }
        }
    }
</style>
