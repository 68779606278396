<script setup lang="ts">
    import { computed, useAttrs } from 'vue'
    import { useI18n } from 'vue-i18n'
    import Multiselect from 'vue-multiselect'
    import { Option } from '@/libs/helpers/form'
    type ModelValue = string | number | string[] | (number | string)[] | undefined | null
    const { t } = useI18n()
    const attrs = useAttrs()
    const props = withDefaults(
        defineProps<{
            modelValue?: ModelValue
            options?: Option[]
            multiple?: boolean
            group?: boolean
            search?: boolean
            taggable?: boolean
            handleChange: (e: unknown, shouldValidate?: boolean | undefined) => void
            value:
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
        }>(),
        {
            value: null,
            rules: undefined,
            modelValue: undefined,
            options: () => []
        }
    )
    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
    }>()
    // For multiselect only
    // const taggableOptions = ref<Option[]>(
    //     Array.isArray(value.value)
    //         ? (value.value as string[]).map((name) => ({
    //               id: name,
    //               name,
    //           }))
    //         : []
    // )
    const taggableOptions = computed(() => {
        if (!props.taggable) {
            return []
        }
        return Array.isArray(props.value)
            ? (props.value as string[]).map((name) => ({
                  id: name,
                  name
              }))
            : []
    })
    const flatOptions = computed(() => {
        if (props.group) {
            const options: Option[] = []
            props.options.forEach((group) => {
                group.children?.forEach((opt) => options.push(opt))
            })
            return options
        }
        return props.options
    })
    const fieldModelValue = computed({
        get() {
            const v = props.modelValue || props.value || []
            const mergedOptions: Option[] = [
                ...flatOptions.value,
                ...taggableOptions.value.filter((opt) => !flatOptions.value.find((o) => o.id === opt.id))
            ]
            return mergedOptions.filter((opt) => {
                if (v instanceof Object && 'id' in v) {
                    return (v as unknown as Option)?.id === opt.id
                }
                return (v as (string | Option)[]).find((o) => {
                    if (o instanceof Object && o?.id) {
                        return o.id === opt.id
                    } else {
                        return o === opt.id
                    }
                })
            })
        },
        set(v: Option[]) {
            const mappedValues = v.map((opt) => opt.id)
            props.handleChange(mappedValues)
            emit('update:modelValue', mappedValues)
        }
    })
    const multiselectModelValue = computed(() =>
        flatOptions.value.find((opt) => opt.id === (props.modelValue || props.value))
    )
    function onSelect(selectedOption: Option) {
        if (props.multiple) {
            fieldModelValue.value = [...(fieldModelValue.value as Option[]), selectedOption]
        } else {
            props.handleChange(selectedOption.id)
            emit('update:modelValue', selectedOption.id)
        }
    }
    function onRemove(removedOption: Option) {
        if (props.multiple) {
            fieldModelValue.value = (fieldModelValue.value as Option[]).filter(
                (opt: Option) => opt.id !== removedOption.id
            )
        } else {
            props.handleChange(null)
            emit('update:modelValue', null)
        }
    }
    function getTagId(name: string, i = 0) {
        return taggableOptions.value.find((opt) => opt.id === name)
            ? getTagId(name, i + 1)
            : i > 0
              ? `${name}_${i}`
              : name
    }
    function onTag(name: string) {
        const tag = { id: getTagId(name), name }
        onSelect(tag)
    }
</script>

<template>
    <Multiselect
        v-if="multiple"
        v-bind="attrs"
        :model-value="fieldModelValue"
        multiple
        :close-on-select="false"
        :searchable="search || taggable"
        :taggable="taggable"
        :options="taggable ? taggableOptions : options"
        label="name"
        :group-values="group ? 'children' : undefined"
        :group-label="group ? 'name' : undefined"
        :group-select="false"
        :limit-text="(count) => t('limit_text', { count })"
        :select-label="t('multiselect.select_label')"
        :tag-placeholder="t('multiselect.tag_placeholder')"
        :selected-label="t('multiselect.selected_label')"
        :select-group-label="t('multiselect.select_group_label')"
        :deselect-label="t('multiselect.deselect_label')"
        :deselect-group-label="t('multiselect.deselect_group_label')"
        @select="onSelect"
        @remove="onRemove"
        @tag="onTag"
    >
        <template #noOptions>&nbsp;</template>
    </Multiselect>
    <Multiselect
        v-else
        v-bind="attrs"
        :model-value="multiselectModelValue"
        :options="options"
        :searchable="search"
        :taggable="false"
        label="name"
        track-by="id"
        :group-values="group ? 'children' : undefined"
        :group-label="group ? 'name' : undefined"
        :group-select="false"
        :limit-text="(count) => t('limit_text', { count }, count)"
        :select-label="t('multiselect.select_label')"
        :selected-label="t('multiselect.selected_label')"
        :select-group-label="t('multiselect.select_group_label')"
        :deselect-label="t('multiselect.deselect_label')"
        :deselect-group-label="t('multiselect.deselect_group_label')"
        @select="onSelect"
        @remove="onRemove"
    >
        <template #noOptions>&nbsp;</template>
    </Multiselect>
</template>

<i18n lang="json">
{
    "fr": {
        "no_options": "Aucune option",
        "limit_text": "X | et {count} autre | et {count} autres",
        "multiselect": {
            "selected_label": "",
            "select_label": "",
            "select_group_label": "",
            "deselect_label": "",
            "deselect_group_label": "",
            "tag_placeholder": "",
            "no_options": "La liste est vide"
        }
    }
}
</i18n>
