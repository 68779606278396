<script setup lang="ts">
    import BemTransition from '@/components/ui/transitions/BemTransition.vue'

    withDefaults(
        defineProps<{
            from: 'left' | 'right'
        }>(),
        {
            from: 'left'
        }
    )

    const showMenu = defineModel<boolean>()
</script>

<template>
    <div class="template">
        <BemTransition :name="`slide-in-${from}`">
            <aside v-if="showMenu" class="template__content">
                <header class="template__header">
                    <slot name="header" />
                </header>
                <main class="template__nav">
                    <slot />
                </main>
            </aside>
        </BemTransition>
    </div>
</template>

<style scoped lang="scss">
    .template {
        &__content {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--theme-color-nuage);
            z-index: 1000;
            overflow: scroll;
            display: flex;
            flex-direction: column;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--scale-6);
            padding: var(--scale-4) var(--scale-6);
            border-bottom: solid var(--scale-px) var(--theme-color-gray--05);
        }

        &__nav {
            flex: 1;
        }
    }

    .slide-in-left {
        &--enter-active,
        &--leave-active {
            transition-timing-function: cubic-bezier(0.28, 0.75, 0.52, 0.91);
            transition-property: opacity, transform;
            transition-duration: 500ms;
        }

        &--enter-from,
        &--leave-to {
            transform: translateX(-100%);
        }
    }

    .slide-in-right {
        &--enter-active,
        &--leave-active {
            transition-timing-function: cubic-bezier(0.28, 0.75, 0.52, 0.91);
            transition-property: opacity, transform;
            transition-duration: 500ms;
        }

        &--enter-from,
        &--leave-to {
            transform: translateX(100%);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
