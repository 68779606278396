<script setup lang="ts">
    import HeaderProfileNavigation from '@/components/layouts/common/header/navigations/profile/HeaderProfileNavigation.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderNavigation } from '@/types/Header.type'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { RoleName } from '@/vars/AuthAttr'
    import NotificationControlLayout from '@/components/layouts/leclub/header/controls/NotificationControlLayout.vue'
    import { computed, inject } from 'vue'
    import { UNIVERSAL_SEARCH_KEY, UniversalSearchInjectType } from '@/vars/UniversalSearchAttr'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { ClubRouteName, InboxRouteName } from '@/vars/RouteName'
    import IndicatorBadge from '@/components/ui/indicators/IndicatorBadge.vue'
    import useEkkoUnreadMessageCountQuery from '@/libs/queries/useEkkoUnreadMessageCountQuery'

    const store = useAuthStore()

    const universalSearch = inject<UniversalSearchInjectType>(UNIVERSAL_SEARCH_KEY)

    defineProps<{
        profile: HeaderNavigation[]
    }>()

    const { data: unreadCount } = useEkkoUnreadMessageCountQuery({
        staleTime: 0
    })

    const openUniversalSearchBar = () => {
        universalSearch?.updateIsExpended(true)
    }

    const showUniversalSearch = computed(() => {
        return universalSearch && !universalSearch.isExpended.value
    })
</script>

<template>
    <HeaderProfileNavigation :profile="profile">
        <template #controls>
            <div class="controls">
                <div
                    v-if="store.hasRole(RoleName.LECLUB) && showUniversalSearch"
                    class="controls__item controls__item--desktop"
                >
                    <BaseIcon
                        name="search"
                        class="controls__icon controls__icon--search"
                        @click="openUniversalSearchBar"
                    />
                </div>
                <NotificationControlLayout v-if="store.hasRole(RoleName.LECLUB)" class="controls__item">
                    <BaseIcon name="notifications" class="controls__icon" />
                </NotificationControlLayout>
                <SpaRouterLink :to="{ name: InboxRouteName.INBOX }" class="controls__item">
                    <BaseIcon name="mail" class="controls__icon" />
                    <IndicatorBadge
                        v-if="unreadCount"
                        class="controls__indicator"
                        :count="unreadCount"
                        :max-count="9"
                    />
                </SpaRouterLink>
                <SpaRouterLink
                    v-if="store.hasRole(RoleName.LECLUB)"
                    :to="{ name: ClubRouteName.MEMBER_RECOMMEND }"
                    class="controls__item"
                >
                    <BaseIcon name="redeem" class="controls__icon" />
                </SpaRouterLink>
            </div>
        </template>
    </HeaderProfileNavigation>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .controls {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 0.5rem;

        &__item {
            position: relative;
            z-index: 1;
            color: var(--theme-text-color--header);
            display: inline-block;
            margin: 0 0.75em;

            &--desktop {
                display: none;

                @media only screen and (min-width: $breakpoints-lg) {
                    display: block;
                }
            }
        }

        &__indicator {
            position: absolute;
            top: -0.25em;
            right: -0.75em;
        }

        &__icon {
            font-size: 1.5rem;

            &--search {
                cursor: pointer;
            }
        }
    }
</style>
