import { BusinessLineName } from '@/vars/AuthAttr'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { ThemeName } from '@/vars/ThemeAttr'
import { SpaName } from '@/vars/SpaAttr'

/** @deprecated */
export function useTheme(route?: RouteLocationNormalizedLoaded) {
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    function getThemeToUse(): ThemeName {
        if (import.meta.env.VITE_APP_THEME === 'mondrian') {
            return ThemeName.MONDRIAN
        }
        switch (spaRunning) {
            case SpaName.LECLUB:
                return ThemeName.LECLUB
            default:
                return route?.params?.businessLine === BusinessLineName.LECLUB.toLowerCase()
                    ? ThemeName.LECLUB
                    : ThemeName.DEFAULT
        }
    }
    return {
        getThemeToUse,
        theme: getThemeToUse()
    }
}
