<script setup lang="ts">
    import { HeaderNavigation } from '@/types/Header.type'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BurgerIcon from '@/components/ui/icons/BurgerIcon.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { ref } from 'vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import HeaderMobileProfile from './HeaderMobileProfile.vue'
    import AccordionNavigation from '@/components/ui/navigations/AccordionNavigation.vue'
    import HeaderMobileNavigationTransverse from '../HeaderMobileNavigationTransverse.vue'
    import PlateformLogoNavigation from '@/components/ui/navigations/PlateformLogoNavigation.vue'

    defineProps<{
        navigation: HeaderNavigation[]
    }>()
    const open = ref(false)

    function close() {
        open.value = false
    }
</script>

<template>
    <div class="menu__wrapper">
        <BaseButton :theme="ButtonTheme.DISCRET" class="menu__button" @click="open = true">
            <BurgerIcon />
        </BaseButton>
        <div class="menu" :class="{ 'menu--open': open }" @click="close">
            <div class="menu__body" @click.stop>
                <div class="menu__header">
                    <PlateformLogoNavigation dark />

                    <button type="button" class="menu__close" @click="close">
                        <BaseIcon name="close" />
                    </button>
                </div>
                <HeaderMobileProfile />
                <div class="menu__list">
                    <div v-for="item in navigation" :key="item.label" class="menu__item">
                        <AccordionNavigation v-if="item.children && item.children.length > 0">
                            <template #header>
                                <span class="menu__item-name">
                                    {{ item.label }}
                                </span>
                            </template>
                            <template #default>
                                <div class="menu__item-list">
                                    <BaseButton
                                        v-for="child in item.children"
                                        :key="child.label"
                                        :theme="ButtonTheme.DISCRET"
                                        :to="child.route"
                                        :href="child.href"
                                        :target="child.target"
                                        :disabled="child.disabled"
                                        class="menu__item-sub"
                                        @click="close"
                                    >
                                        <div class="menu__item-sub__content">
                                            <BaseIcon name="arrow_forward" class="menu__item-sub__content__icon" />
                                            <div>
                                                <div class="menu__item-sub__content__title">{{ child.label }}</div>
                                                <div class="menu__item-sub__content__description">
                                                    {{ child?.subtitle }}
                                                </div>
                                            </div>
                                        </div>
                                    </BaseButton>
                                </div>
                            </template>
                        </AccordionNavigation>
                        <BaseButton
                            v-else
                            :theme="ButtonTheme.DISCRET"
                            :to="item.route"
                            :disabled="item.disabled"
                            class="menu__item-name"
                            @click="close"
                        >
                            {{ item.label }}
                        </BaseButton>
                    </div>
                </div>
                <HeaderMobileNavigationTransverse />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .menu {
        $block-selector: &;
        position: fixed;
        z-index: 200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: 150ms;
        transition-delay: 350ms;

        &--open {
            transition-delay: 0s;
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--theme-text-color);
            opacity: 0.3;
        }

        &__button {
            min-width: 5em;
            height: 100%;
            justify-content: flex-start;

            @media only screen and (max-width: $breakpoints-sm) {
                min-width: 2em;
            }
        }

        &__body {
            position: relative;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            background: var(--theme-background-color);
            color: var(--theme-text-color);
            transform: translateX(-100%);
            transition: 350ms;
            transition-delay: 150ms;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            #{$block-selector}--open & {
                transform: translateX(0);
                transition-delay: 0s;
            }

            #{$block-selector}--leveled & {
                overflow-y: hidden;
            }

            &__spaces {
                display: flex;
                margin: auto;
            }
        }

        &__list {
            flex: 1 1 auto;
        }

        &__item {
            border-top: 1px solid var(--theme-border-color);
            padding: 1.5625em;
            min-height: 4.0625em;

            &:last-child {
                border-bottom: 1px solid var(--theme-border-color);
            }

            &--footer {
                display: block;
                padding-top: 0.75em;
                align-items: flex-start;
            }
        }

        &__item-name {
            font-weight: bold;
            margin-bottom: 0.25em;
            display: flex;
            align-items: baseline;
            cursor: pointer;
            text-align: initial;
            justify-content: flex-start;
        }

        &__item-list {
            padding-top: 1.4375em;
        }

        &__item-sub {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1.5625em;
            cursor: pointer;
            text-align: left;

            &:last-child {
                margin-bottom: 0;
            }

            &__content {
                display: flex;
                &__icon {
                    font-size: 1.5em;
                    margin-right: 1em;
                }

                &__title {
                    font-weight: bold;
                }
                &__description {
                    color: var(--theme-text-color--secondary);
                    font-size: 0.675em;
                }
            }
        }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--scale-6);
            padding: var(--scale-4) var(--scale-6);
            border-bottom: solid var(--scale-px) var(--theme-color-gray--05);
        }
        &__logo {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--scale-2);
            @media only screen and (min-width: $breakpoints-lg) {
                margin-right: var(--scale-4);
            }
        }
        &__logo-bar {
            width: var(--scale-px);
            height: 100%;
            background-color: var(--theme-color-nuage);
            margin-inline: var(--scale-2);
        }
        &__close {
            display: inline-flex;
            border: none;
            background: none;
            font-size: 1.5em;
            color: var(--theme-text-color);
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "account": "Mon compte"
    }
}
</i18n>
