export enum ThemeName {
    DEFAULT = 'default',
    LECLUB = 'leclub',
    DARK = 'dark',
    PREMIUM = 'premium',
    PRO = 'pro',
    MONDRIAN = 'mondrian'
}

export enum ThemeTooltip {
    DEFAULT = 'info-tooltip',
    LIGHT = 'light-tooltip'
}
