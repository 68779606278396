import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { RouteName } from '@/vars/RouteName'
import { StartApplyInformationsForm } from '@/types/forms/apply/StartApplyInformationsForm.type'

export default function useOpeTenYears() {
    const route = useRoute()

    const isApplyTenYears = computed(() => route.name === RouteName.APPLY_LECLUB_FOR_OPE_TEN_YEARS)

    const applyDefaultConfig = computed<StartApplyInformationsForm>(() => {
        return { isOpeTenYears: true }
    })

    return {
        isApplyTenYears,
        applyDefaultConfig
    }
}
