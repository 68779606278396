<script setup lang="ts">
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { CompaniesRouteName } from '@/vars/RouteName'

    import FeedbackIllustration from '@/assets/images/pros/candidates/feedback.svg'
    import { onMounted } from 'vue'
    import { useI18n } from 'vue-i18n'

    const props = withDefaults(
        defineProps<{
            text: string | null
            options?: {
                redirect?: string
            }
            relationshipEstablishment?: {
                first_member?: {
                    id?: string
                    company?: {
                        slug: string
                        is_partner?: boolean
                    }
                }
                second_member?: {
                    company?: {
                        slug: string
                        is_partner?: boolean
                    }
                }
            }
        }>(),
        {
            text: null
        }
    )

    defineEmits<{
        close: []
    }>()

    const authStore = useAuthStore()
    const { t } = useI18n()
    const { navigate } = useNavigationResolver()

    onMounted(() => {
        if (props?.options?.redirect === 'company_feedback') {
            const otherMemberCompany =
                props.relationshipEstablishment?.first_member?.id === authStore.authentifiedUser?.id
                    ? props.relationshipEstablishment?.second_member?.company
                    : props.relationshipEstablishment?.first_member?.company

            if (otherMemberCompany?.is_partner) {
                navigate({ name: CompaniesRouteName.COMPANY_DETAILS, params: { slug: otherMemberCompany.slug } })
            }
        }
    })
</script>

<template>
    <div class="modal-finish">
        <div class="modal__finish__illustration">
            <img :src="FeedbackIllustration" />
        </div>
        <div class="mx-6 mb-6 mt-8">{{ text }}</div>
        <div class="modal-finish__close mb-8" @click="$emit('close')">
            <div class="modal-finish__close-text">{{ t('close') }}</div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .modal-finish {
        text-align: center;
        &__illustration {
            display: flex;
            justify-content: center;
        }
        &__close {
            display: flex;
            justify-content: center;
        }
        &__close-text {
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "close": "Fermer"
    },
    "en": {
        "close": "Close"
    }
}
</i18n>
