<script setup lang="ts">
    import { useAuthStore } from '@/libs/stores/auth.store'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import { AvatarSize } from '@/vars/AvatarAttr'

    const store = useAuthStore()
</script>

<template>
    <div class="profile">
        <AvatarMedia :src="store.authentifiedUser?.picture" alt="" :size="AvatarSize.SMALL" class="profile__avatar" />
        <div v-if="store.authentifiedUser" class="profile__name">
            {{ store.authentifiedUser.full_name }}
        </div>
        <div v-if="store.authentifiedUser" class="profile__position">
            {{ store.authentifiedUser?.position }}
            @{{ store.authentifiedUser?.company?.name }}
        </div>
        <!--      @TODO Reactivate -->
        <!--        <RouterLink :to="{ name: RouteName.MEMBER_ME }" class="profile__link"> -->
        <!--            {{ t('profile') }} -->
        <!--        </RouterLink> -->
    </div>
</template>

<style scoped lang="scss">
    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3.75em 1em 1.875em;
        flex-shrink: 0;

        &__avatar {
            font-size: 0.75em;
            margin-bottom: 0.625em;
        }

        &__name {
            font-weight: bold;
            margin-bottom: 0.625em;
        }

        &__position {
            color: var(--theme-text-color--secondary);
            font-size: 0.875em;
            margin-bottom: 1em;
            text-align: center;
        }

        &__link {
            font-weight: bold;
            font-size: 0.875em;
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "profile": "Voir mon profil"
    }
}
</i18n>
