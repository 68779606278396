<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    import { useElementBounding, useTimeoutFn, useWindowSize } from '@vueuse/core'

    export type Position = 'auto' | 'to-left' | 'to-right'

    const props = withDefaults(
        defineProps<{
            position?: Position
            hover?: boolean
            /* Close delay in ms */
            closeDelay?: number
            wrapperClass?: string | string[]
        }>(),
        {
            position: 'auto',
            closeDelay: 500
        }
    )

    const buttonEl = ref<HTMLDivElement>()
    const { x, y } = useElementBounding(buttonEl)
    const { width: windowWidth, height: windowHeight } = useWindowSize()

    const { start: closeDropdown, stop: stopClosingDropdown } = useTimeoutFn((closeFn: () => void) => {
        if (closeFn) {
            closeFn()
        }
    }, props.closeDelay || 0)

    const finalPositionClasses = computed(() => {
        const classes: string[] = []

        if (props.position === 'auto') {
            const MIDDLE_SCREEN_X = windowWidth.value / 2
            const MIDDLE_SCREEN_Y = windowHeight.value / 1.5

            classes.push(x.value < MIDDLE_SCREEN_X ? `left-0` : `right-0`)
            classes.push(y.value < MIDDLE_SCREEN_Y ? 'mt-2' : 'top-0 -translate-y-full -mt-2')
        } else if (props.position === 'to-left') {
            classes.push('right-0')
        } else {
            classes.push('left-0')
        }

        return classes
    })

    function onMouseEnter(e: MouseEvent, open: boolean) {
        const activator = e.target as HTMLButtonElement

        stopClosingDropdown()
        if (props.hover && !open) {
            activator?.click()
        }
    }
</script>

<template>
    <Popover v-slot="{ open, close: closeFn }" as="div" class="relative inline-block">
        <div ref="buttonEl">
            <PopoverButton
                class="outline-none"
                @mouseenter="onMouseEnter($event, open)"
                @mouseleave="closeDropdown(closeFn)"
            >
                <slot name="action" />
            </PopoverButton>
        </div>

        <Transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <PopoverPanel
                v-show="open"
                :class="[finalPositionClasses, wrapperClass]"
                class="absolute z-50 w-max origin-top-right shadow-xl"
                static
                @mouseenter="stopClosingDropdown"
                @mouseleave="closeDropdown(closeFn)"
            >
                <slot />
            </PopoverPanel>
        </Transition>
    </Popover>
</template>
