<script setup lang="ts">
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import { HeaderMenu } from '@/types/Header.type'
    import HeaderDropdownMenu from './navigations/HeaderDropdownMenu.vue'
    import HeaderDropdownNavigation from './navigations/HeaderDropdownNavigation.vue'
    import UniversalSearch from '@/components/partials/universal-search/UniversalSearch.vue'
    import { computed, inject } from 'vue'
    import { UNIVERSAL_SEARCH_KEY, UniversalSearchInjectType } from '@/vars/UniversalSearchAttr'

    defineProps<{
        navigationItems: HeaderMenu[]
    }>()
    const universalSearch = inject<UniversalSearchInjectType>(UNIVERSAL_SEARCH_KEY)

    const showUniversalSearch = computed(() => {
        return universalSearch && universalSearch.isExpended.value
    })
</script>

<template>
    <nav class="nav">
        <UniversalSearch v-if="showUniversalSearch" />
        <ul v-else class="nav__list">
            <li v-for="item in navigationItems" :key="item.title" class="nav__item">
                <DropdownNavigation show-on-hover>
                    <template #activator="{ open }">
                        <div class="nav__link" :class="{ 'nav__link--active': open }">
                            {{ item.title }}
                        </div>
                    </template>
                    <template #dropdown="{ close }">
                        <HeaderDropdownNavigation>
                            <HeaderDropdownMenu :menu="item.items" @close="close" />
                        </HeaderDropdownNavigation>
                    </template>
                </DropdownNavigation>
            </li>
        </ul>
    </nav>
</template>

<style scoped lang="scss">
    .nav {
        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            height: 100%;
            display: flex;
            justify-content: center;
        }

        &__link {
            display: flex;
            align-items: center;
            height: 100%;
            color: var(--theme-text-color--header);
            font-weight: 500;
            padding: 0 1.25em;
            transition: color 350ms;

            &--active {
                //color: var(--theme-background-color);
                opacity: 0.5;
            }
        }
    }
</style>
