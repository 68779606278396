<template>
    <span class="error text-xs">
        <slot></slot>
    </span>
</template>

<style scoped lang="scss">
    .error {
        color: var(--theme-error-color);
    }
</style>
