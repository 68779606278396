<script setup lang="ts">
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import { RouteName } from '@/vars/RouteName'
    import { useI18n } from 'vue-i18n'
    import AccordionNavigation from '@/components/ui/navigations/AccordionNavigation.vue'
    import AnimatedArrowText from '@/components/ui/typographies/AnimatedArrowText.vue'
    import useJobInfiniteQuery, { JobList } from '@/libs/queries/useJobInfiniteQuery'
    import { computed } from 'vue'
    import QueryStateManager from '@/components/ui/queries/QueryStateManager.vue'
    import JobOfferInProgress from './JobOfferInProgress.vue'

    const { t } = useI18n()
    const {
        data: jobListApplied,
        isLoading: isLoadingJobApplied,
        isError: isErrorJobApplied,
        isSuccess: isSuccessJobApplied
    } = useJobInfiniteQuery(JobList.APPLIED)
    const {
        data: jobListProcessing,
        isLoading: isLoadingJobProcessing,
        isError: isErrorJobProcessing,
        isSuccess: isSuccessJobProcessing
    } = useJobInfiniteQuery(JobList.PROCESSING)

    const offersShown = computed(() => {
        return [
            ...(jobListApplied.value?.pages?.[0].data || []),
            ...(jobListProcessing.value?.pages?.[0].data || [])
        ].slice(0, 5)
    })
</script>

<template>
    <QueryStateManager
        :is-error="isErrorJobApplied || isErrorJobProcessing"
        :is-loading="isLoadingJobApplied || isLoadingJobProcessing"
        :is-success="isSuccessJobApplied || isSuccessJobProcessing"
        :is-empty="!(jobListApplied?.pages?.[0]?.data?.length || jobListProcessing?.pages?.[0]?.data?.length)"
    >
        <template #success>
            <CardWrapper class="card">
                <div class="card__inner">
                    <div class="card__row">
                        <AccordionNavigation class="card__accordion" :open-by-default="true">
                            <template #header>
                                <div class="card__title">
                                    {{ t('offers') }}
                                </div>
                            </template>
                            <template #default>
                                <div class="card__list">
                                    <RouterLink
                                        v-for="job in offersShown"
                                        :key="job.id"
                                        :to="{
                                            name: RouteName.JOB_SINGLE,
                                            params: { slug: job.id }
                                        }"
                                    >
                                        <JobOfferInProgress :job="job" />
                                    </RouterLink>
                                </div>
                                <div class="card__action">
                                    <AnimatedArrowText
                                        :to="{
                                            name: jobListProcessing?.pages?.[0]?.data?.length
                                                ? RouteName.JOB_LIST_ACCEPTED
                                                : RouteName.JOB_LIST_PENDING
                                        }"
                                        :translated-text="t('show_all')"
                                    />
                                </div>
                            </template>
                        </AccordionNavigation>
                    </div>
                </div>
            </CardWrapper>
        </template>
        <template #fallback>&nbsp;</template>
    </QueryStateManager>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .card {
        $block-selector: &;

        width: 100%;
        padding: 2em 0;
        margin: 0 1em 1.25em;
        overflow: hidden;
        max-width: 18.75em;

        @media (min-width: $breakpoints-lg) {
            margin: 0 0 1.25em;
        }

        &__row {
            margin-bottom: 1.5em;

            &:last-child {
                margin-bottom: 0;
            }

            &--center {
                text-align: center;
            }
        }

        &__inner {
            padding: 0 2em;

            &--large {
                padding: 0 1.125em;
            }
        }

        &__title {
            font-weight: 700;
            margin-bottom: 1em;
            font-size: 1em;

            &--label {
                font-weight: 700;
            }

            #{$block-selector}__accordion & {
                margin-bottom: 0;
            }
        }

        &__accordion {
            :deep(.accordion__body) {
                margin-top: 1em;
            }
        }

        &__list {
            display: flex;
            align-items: stretch;
            flex-direction: column;
            gap: 0.5em;
        }

        &__action {
            display: flex;
            justify-content: center;
            margin-top: 1em;
            font-size: 0.875em;
            font-weight: 600;

            &:deep(.page__prev) {
                margin-bottom: 0;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "offers": "Vos candidatures",
        "show_all": "Voir tous",
        "no_waiting_offer": "Vous n'avez pas encore d'offre en attente."
    },
    "en": {
        "offers": "Your applications",
        "show_all": "View all",
        "no_waiting_offer": "You don't have any pending offers yet."
    }
}
</i18n>
