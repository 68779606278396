<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import FormCheckbox from '@/components/ui/forms/FormCheckbox.v2.vue'

    const { t } = useI18n()
</script>

<template>
    <div class="flex flex-col items-start gap-2">
        <p class="mb-2 max-w-sm font-medium">{{ t('fields_to_sync') }}</p>
        <FormCheckbox name="import.picture">{{ t('import.picture') }}</FormCheckbox>
        <FormCheckbox name="import.position">{{ t('import.position') }}</FormCheckbox>
        <FormCheckbox name="import.shortBio">{{ t('import.bio') }}</FormCheckbox>
        <FormCheckbox name="import.experiences">{{ t('import.experiences') }}</FormCheckbox>
        <FormCheckbox name="import.location">{{ t('import.location') }}</FormCheckbox>
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "fields_to_sync": "Nous allons automatiquement synchroniser les informations suivantes avec votre profil Frenchfounders",
        "import": {
            "picture": "Votre photo",
            "position": "Votre titre",
            "bio": "Votre bio",
            "experiences": "Vos expériences",
            "location": "Votre localisation"
        }
    }
}
</i18n>
