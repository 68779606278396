<script setup lang="ts">
    import FormTextArea from '@/components/ui/forms/FormTextArea.vue'
    import { useField, useForm } from 'vee-validate'
    import { onMounted, ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'

    const props = withDefaults(
        defineProps<{
            formEl: {
                subject: string
                options?: {
                    placeholder?: string
                }
            }
            index: number | null
        }>(),
        {
            index: null
        }
    )

    const emit = defineEmits<{
        input: [value: string]
    }>()

    const { t } = useI18n()
    useForm()
    const placeholder = ref('')
    const nameFiled = ref(`comment-${props.index ? props.index.toString() : (Math.random() + 1).toString()}`)

    const { value } = useField<string>(nameFiled.value, undefined, { syncVModel: true })

    onMounted(() => {
        placeholder.value = props.formEl?.options?.placeholder || t('placeholder')
    })

    watch(value, () => {
        emit('input', value.value)
    })
</script>

<template>
    <div class="mb-2 mt-6">
        <div class="comment__label">{{ formEl.subject }}</div>
        <FormTextArea :name="nameFiled" :placeholder="placeholder" rows="4" />
    </div>
</template>

<style scoped lang="scss">
    .comment {
        &__sep {
            content: ' ';
            height: var(--scale-px);
            background: var(--theme-color-gray--02);
            margin-bottom: var(--scale-4);
            margin-top: var(--scale-4);
        }
        &__label {
            text-align: left;
            display: block;
            color: var(--theme-info-color);
            margin-bottom: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "placeholder": "Ce message sera uniquement envoyé à votre relationship manager."
    },
    "en": {
        "placeholder": "This message will only be sent to your relationship manager."
    }
}
</i18n>
