export enum VerticalPosition {
    TOP = 'top',
    CENTER = 'vcenter',
    BOTTOM = 'bottom'
}

export enum HorizontalPosition {
    LEFT = 'left',
    CENTER = 'hcenter',
    RIGHT = 'right'
}

export type Position = [VerticalPosition, HorizontalPosition]

export enum PopoverTheme {
    DEFAULT = 'default',
    DARK = 'dark'
}
