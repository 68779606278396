<script setup lang="ts">
    import { v4 as uuid4 } from 'uuid'
    import BaseLabel from '@/components/ui/forms/FormLabel.vue'
    import { useField } from 'vee-validate'
    import { computed, useAttrs, watch } from 'vue'
    import { SchemaOptions } from 'yup/lib/schema'
    import FormError from '@/components/ui/forms/FormError.vue'

    const { class: classAttr } = useAttrs()

    type ModelValue = string | number | string[] | null

    defineOptions({
        inheritAttrs: false // Disabling Attribute Inheritance on the root element template
    })

    const props = withDefaults(
        defineProps<{
            name?: string
            rules?: string | Record<string, unknown> | SchemaOptions<unknown>
            modelValue?: ModelValue
            error?: string
            hideLabel?: boolean
            placeholder?: string
        }>(),
        {
            rules: undefined,
            modelValue: undefined,
            iconName: undefined,
            error: '',
            taggableKeySeparator: '',
            options: () => [],
            placeholder: ''
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
    }>()

    const { errorMessage, handleChange, handleBlur, value, meta } = useField(props.name || uuid4(), props.rules, {
        initialValue: props.modelValue,
        standalone: !props.name,
        syncVModel: true
    })

    watch(
        () => props.modelValue,
        (value) => {
            handleChange(value)
        }
    )

    const isRequired = computed(() => {
        if (!props.rules) return false

        if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        }
        return Object.keys(props.rules).includes('required')
    })

    const onInputChange = (e: Event) => {
        const value: string | null = (e.target as HTMLInputElement).value

        handleChange(value)
        emit('update:modelValue', value)
    }
</script>

<template>
    <div
        class="field"
        :class="{
            [classAttr as string]: true
        }"
    >
        <div class="field__inner">
            <div v-if="$slots.default || $slots.help" class="field__labels">
                <BaseLabel v-show="!hideLabel" :required="isRequired">
                    <slot></slot>
                </BaseLabel>
                <BaseLabel v-if="$slots.help" class="field__help" type="help">
                    <slot name="help"></slot>
                </BaseLabel>
            </div>
            <div class="field__input-wrapper">
                <input
                    :id="name"
                    :name="name"
                    class="field__input"
                    :class="{
                        'field__input--error': error || (errorMessage && meta.touched)
                    }"
                    type="text"
                    :value="value"
                    :placeholder="placeholder"
                    @input="onInputChange"
                    @blur="handleBlur"
                />
                <div v-if="$slots.icon" class="field__icon">
                    <slot name="icon"></slot>
                </div>
            </div>
        </div>
        <div v-if="$slots.description" class="field__description mt-4 text-xs">
            <slot name="description"></slot>
        </div>
        <FormError v-if="error || (errorMessage && meta.touched)">
            {{ error || errorMessage }}
        </FormError>
    </div>
</template>

<style lang="scss" scoped>
    @import '@/libs/sass/vars';
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;

    .field {
        $block-selector: &;
        position: relative;

        &__labels {
            margin-bottom: 0.25em;
        }

        &__description {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: var(--theme-text-color);
        }

        &__inner {
            #{$block-selector}--inline & {
                display: flex;
                align-items: center;
            }
        }

        &__help {
            margin: 0.5em 0;

            #{$block-selector}--inline-labels & {
                margin: 0;
            }
        }

        &__input-wrapper {
            display: flex;
            align-items: stretch;
            color: var(--theme-text-color--secondary);
            border-bottom: solid var(--scale-px) var(--theme-text-color);
            justify-content: flex-end;
        }

        &__input {
            $placeholderColor: var(--theme-text-color);

            color: var(--theme-text-color);
            width: 100%;
            max-width: 100%;
            font-size: 0.875em;
            padding: 0.7em;
            padding-left: 0;
            border: transparent;
            outline: none;
            background-color: transparent;
            box-shadow: none;

            &:focus {
                outline: none;
            }

            &--select {
                position: relative;
                appearance: none;
                margin-right: 2em;
                padding-right: 2em;
                background-image: linear-gradient(45deg, transparent 50%, var(--theme-text-color) 50%),
                    linear-gradient(135deg, var(--theme-text-color) 50%, transparent 50%),
                    linear-gradient(to right, var(--theme-background-color), var(--theme-background-color));
                background-position:
                    calc(100% - 1.25em) calc(1em + 0.125em),
                    calc(100% - 1em) calc(1em + 0.125em),
                    100% 0;
                background-size:
                    0.3125em 0.3125em,
                    0.3125em 0.3125em,
                    2.5em 2.5em;
                background-repeat: no-repeat;

                #{$block-selector}--inline & {
                    margin-right: 0;
                }
            }

            // @TODO Warning : bad rendering for Windows users - find a cleaner way to handle placeholder color
            &--empty {
                color: $placeholderColor;
                font-weight: 300;
                opacity: 1;

                :deep(option) {
                    color: var(--theme-text-color);
                }
            }

            &--error {
                border-color: var(--theme-error-color);
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $placeholderColor;
                font-size: var(--scale-4);
                opacity: 1; /* Firefox */
            }
        }

        &__icon {
            font-size: var(--scale-4);
            color: var(--theme-text-color);
            align-items: center;
            display: flex;

            @media screen and (min-width: $breakpoints-md) {
                font-size: var(--scale-6);
            }
        }
    }
</style>
