<script setup lang="ts">
    import { computed, ref } from 'vue'

    const props = defineProps<{
        formEl: {
            subject: string
            options?: {
                choices?: { value: unknown; label: string; icon: string }[]
                multiple?: boolean
            }
        }
    }>()

    const emit = defineEmits<{
        choice: [value: unknown[]]
    }>()

    const selectedChoices = ref<unknown[]>([])
    const multipleEnabled = computed(() => {
        if (props.formEl && props.formEl.options && props.formEl.options.multiple) {
            return props.formEl.options.multiple
        } else {
            return false
        }
    })

    const getEmoji = (icon) => {
        switch (icon) {
            case 'clock':
                return '⏰'
            case 'cloud':
                return '💭'
            case 'question':
                return '❓'
            case 'check':
                return '✔️'
            case 'money-bag':
                return '💰'
            case '+1':
                return '👍'
            case 'waiting':
                return '🙄'
            case 'phone':
                return '📞'
            case 'expressionless':
                return '😑'
            default:
                return null
        }
    }

    const onSelect = (choice: unknown) => {
        if (multipleEnabled.value) {
            if (selectedChoices.value.includes(choice)) {
                // Remove
                selectedChoices.value = selectedChoices.value.filter((c) => c !== choice)
            } else {
                // Add
                selectedChoices.value.push(choice)
            }
        } else {
            selectedChoices.value = [choice]
        }

        emit('choice', selectedChoices.value)
    }
</script>

<template>
    <div class="mb-2 mt-2">
        <h3 class="question">{{ formEl.subject }}</h3>
        <div class="choices mt-4">
            <div
                v-for="(choice, i) in formEl.options?.choices"
                :key="i"
                class="choices__item"
                :class="{ 'choices__item--selected': selectedChoices.includes(choice.value) }"
                @click="onSelect(choice.value)"
            >
                <div
                    v-if="getEmoji(choice.icon)"
                    class="choices__item__icon"
                    :class="{ 'choices__item__icon--selected': selectedChoices.includes(choice.value) }"
                >
                    {{ getEmoji(choice.icon) }}
                </div>
                <div class="choices__item__text">{{ choice.label }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    $successColor: var(--theme-color-go--10);

    .question {
        display: block;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: var(----scale-2);
        line-height: 20px;
        text-align: center;
    }

    .choices {
        &__item {
            height: var(--scale-12);
            border-radius: var(--scale-2);
            border: var(--scale-px) solid var(--theme-border-color);
            display: flex;
            align-items: center;
            padding: var(--scale-8) var(--scale-4);
            margin-bottom: var(--scale-4);
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover,
            &:active {
                transform: translateY(-0.15em);
            }

            &:active {
                transform: translateY(0);
            }

            &--selected {
                border-color: var(--theme-text-color);
                background: var(--theme-color-flagship--10);
            }

            &__icon {
                font-size: var(--scale-4);
                margin-right: var(--scale-4);
                &--selected {
                    color: $successColor;
                    fill: $successColor;
                }
            }
            &__text {
                flex: 1;
                text-align: left;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
