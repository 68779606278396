import { ClubState } from '@/types/response/members-api/ClubState.type'
import { ResponseV3 } from '@/types/response/ResponseV3.type'
import { QueryName } from '@/vars/QueryAttr'
import { useQuery, UseQueryOptions } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import MemberApi from '@/libs/api/Member.api'
import { useAuthStore } from '@/libs/stores/auth.store'
import { toMilliseconds } from '@/libs/helpers/numbers'
import { computed } from 'vue'

type QueryReturnType = ResponseV3<ClubState>

export default function useClubStateQuery(
    opts: Omit<
        UseQueryOptions<QueryReturnType, AxiosError, QueryReturnType, [QueryName.CLUB_STATE]>,
        'queryFn' | 'queryKey'
    > = {}
) {
    const authStore = useAuthStore()

    // Obligé de faire une computed pour rendre ce isLoggedIn réactif, sinon il a pas l'air de se déclencher
    const enabled = computed(() => authStore.isLoggedIn)

    return useQuery({
        queryKey: [QueryName.CLUB_STATE],
        queryFn: () => MemberApi.getClubState(),
        enabled,
        staleTime: import.meta.env.DEV ? 0 : toMilliseconds({ minutes: 10 }),
        ...opts
    })
}
