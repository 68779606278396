<script setup lang="ts">
    import { ref, onMounted } from 'vue'
    import { useI18n } from 'vue-i18n'
    import SmartActionCard from '@/components/partials/network/smart-actions/SmartActionCard.vue'
    import { SmartAction } from '@/types/response/members-api/SmartActions.type'
    import ModalUpsellStakes from '@/components/partials/network/smart-actions/action-upsell-stakes/ModalUpsellStakes.vue'
    import { useSessionStorage } from '@vueuse/core'

    const props = defineProps<{
        action: SmartAction
    }>()

    const emit = defineEmits<{
        remove: [typeof props.action]
        click: []
    }>()

    const { t } = useI18n()

    const hasModalBeenClosed = useSessionStorage('hasCloseModalUpsell', false)
    const isModalVisible = ref(false)

    function onSubmittedStakes() {
        isModalVisible.value = false
        emit('remove', props.action)
    }

    defineExpose({
        name: 'SmartActionUpsellStakes',
        open: isModalVisible
    })

    onMounted(() => {
        const hasSubmittedStakes = props.action.key === 'stakes'
        isModalVisible.value = hasModalBeenClosed.value || !hasSubmittedStakes ? false : true
    })

    const handleModalClose = () => {
        hasModalBeenClosed.value = true
        isModalVisible.value = false
    }
</script>

<template>
    <div>
        <SmartActionCard
            :action="action"
            :infos="{ icon: 'outbound', title: t('title'), subtitle: t('subtitle') }"
            @click="
                () => {
                    isModalVisible = true
                }
            "
            @remove="$emit('remove', action)"
        />
        <ModalUpsellStakes v-model="isModalVisible" @finished="onSubmittedStakes" @before-close="handleModalClose" />
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "title": "Que recherchez-vous ?",
        "subtitle": "Complétez votre profil"
    },
    "en": {
        "title": "What are you looking for ?",
        "subtitle": "Complete your profile"
    }
}
</i18n>
