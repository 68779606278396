<script setup lang="ts">
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import { useI18n } from 'vue-i18n'
    import { RouterLink } from 'vue-router'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import google from '@/assets/images/homePage/footer/Google.svg'
    import apple from '@/assets/images/homePage/footer/Apple.svg'
    import FormField from '@/components/ui/forms/FormField.vue'
    import { ButtonType } from '@/vars/ButtonAttr'
    import logoFF from '@/assets/images/logos/logo_ff_small_dark.png'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { ref } from 'vue'
    import ZappierApi from '@/libs/api/Zappier.api'
    import { useMutation } from '@tanstack/vue-query'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import { useForm } from 'vee-validate'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { BusinessLineName } from '@/vars/AuthAttr'
    import { AboutRouteName, ClubRouteName, ProRouteName } from '@/vars/RouteName'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'

    const { t } = useI18n()
    const { toast } = useNotification()

    const email = ref('')
    const registerNewsletter = ref(false)
    const { hasBusinessLine } = useAuthStore()
    const { navigate } = useNavigationResolver()

    const { meta } = useForm()

    const { mutate, isPending } = useMutation({
        mutationFn: () => ZappierApi.postEmailNewsLetter(email.value),
        onSuccess: () => {
            toast({
                type: ToastType.SUCCESS,
                title: t('news.success')
            })
            registerNewsletter.value = true
        },
        onError: () => {
            toast({
                type: ToastType.ERROR,
                title: t('news.error_generic')
            })
        }
    })

    const onSubmit = () => {
        mutate()
        return
    }

    const onClickSpace = (space: 'club' | 'bs') => {
        if (space === 'club' && hasBusinessLine(BusinessLineName.LECLUB)) {
            navigate({ name: ClubRouteName.HOME })
        } else if (space === 'club' && !hasBusinessLine(BusinessLineName.LECLUB)) {
            navigate({ name: AboutRouteName.WEWEB_CLUB })
        } else if (space === 'bs' && hasBusinessLine(BusinessLineName.BUSINESS)) {
            navigate({ name: ProRouteName.HOME })
        } else if (space === 'bs' && !hasBusinessLine(BusinessLineName.BUSINESS)) {
            navigate({ name: AboutRouteName.WEWEB_BUSINESS_SOLUTIONS })
        }
    }
</script>

<template>
    <ContainerTemplate class="footer">
        <CardWrapper class="footer__container" shadowless>
            <div class="footer__content">
                <div class="footer__news">
                    <div class="footer__title title-3">{{ t('news.title') }}</div>
                    <form class="footer__news" @submit.prevent="onSubmit">
                        <div class="footer__form">
                            <FormField
                                v-model="email"
                                name="email-newsletter"
                                :rules="{ required: true, email: true }"
                                :placeholder="t('news.placeholder')"
                            >
                            </FormField>
                            <div class="footer__text text-xs">{{ t('news.subtitle') }}</div>
                        </div>
                        <BaseButton
                            :type="ButtonType.SUBMIT"
                            :disabled="!meta.valid || registerNewsletter"
                            :pending="isPending"
                        >
                            {{ registerNewsletter ? t('news.already_register') : t('news.button') }}
                        </BaseButton>
                    </form>
                </div>
                <div class="footer__private">
                    <div class="footer__title title-3">{{ t('private.title') }}</div>
                    <div>
                        <a href="#" class="footer__subtitle title-4" @click.prevent="() => onClickSpace('club')">
                            {{ t('private.subtitle_1') }}
                        </a>
                        <div class="footer__text text-sm">{{ t('private.text_1') }}</div>
                    </div>
                    <div>
                        <a href="#" class="footer__subtitle title-4" @click.prevent="() => onClickSpace('bs')">
                            {{ t('private.subtitle_2') }}
                        </a>
                        <div class="footer__text text-sm">{{ t('private.text_2') }}</div>
                    </div>
                </div>
                <div class="footer__app">
                    <div class="footer__title title-3">{{ t('app.title') }}</div>
                    <div class="footer__image">
                        <a href="https://getapp.frenchfounders.com/home"><img :src="google" /></a>
                        <a href="https://getapp.frenchfounders.com/home"><img :src="apple" /></a>
                    </div>
                </div>
            </div>
            <div class="footer__footer">
                <div class="footer__info">
                    <img class="footer__logo" :src="logoFF" />
                    <div class="footer__text text-xs">
                        {{ t('copyright', { year: new Date().getFullYear() }) }}
                    </div>
                    <a href="https://www.welcometothejungle.com/fr/companies/frenchfounders/jobs" class="text-xs">
                        {{ t('recrute') }}
                    </a>
                    <RouterLink :to="{ name: AboutRouteName.WEWEB_LEGAL_MENTIONS }" class="text-xs">
                        {{ t('legal') }}
                    </RouterLink>
                    <RouterLink :to="{ name: AboutRouteName.WEWEB_CGV }" class="text-xs">
                        {{ t('cgu') }}
                    </RouterLink>
                    <RouterLink :to="{ name: AboutRouteName.WEWEB_CGV_ENTREPRISE }" class="text-xs">
                        {{ t('cgu_entreprise') }}
                    </RouterLink>
                    <RouterLink :to="{ name: AboutRouteName.WEWEB_PRIVACY }" class="text-xs">
                        {{ t('confidential') }}
                    </RouterLink>
                </div>
                <div class="footer__contact">
                    <a href="https://www.linkedin.com/company/frenchfounders">
                        <BaseIcon legacy name="linkedin" />
                    </a>
                    <a href="https://www.instagram.com/frenchfounders_inc/">
                        <BaseIcon legacy name="instagram" />
                    </a>
                    <a href="https://twitter.com/frenchfounders">
                        <BaseIcon legacy name="x" />
                    </a>
                    <a href="https://www.facebook.com/Frenchfounders">
                        <BaseIcon legacy name="facebook" />
                    </a>
                </div>
            </div>
        </CardWrapper>
    </ContainerTemplate>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .footer {
        max-width: 78.8em;
        margin-top: var(--scale-20);
        margin-bottom: var(--scale-14);

        &__container {
            background-color: #f8f8fb;
            border-radius: 2.18em;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: var(--scale-10);
            padding: var(--scale-12) var(--scale-6) var(--scale-8) var(--scale-6);

            @media screen and (min-width: $breakpoints-lg) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: var(--scale-14) var(--scale-10) var(--scale-14) var(--scale-10);
                gap: var(--scale-30);
            }
        }

        &__footer {
            display: flex;
            flex-direction: row;
            padding: var(--scale-4) var(--scale-6) var(--scale-12) var(--scale-6);
            justify-content: space-between;
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: var(--scale-2);

            @media screen and (min-width: $breakpoints-md) {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: var(--scale-6);
            }
        }

        &__contact {
            display: none;

            @media screen and (min-width: $breakpoints-lg) {
                display: flex;
                flex-direction: row;
                gap: var(--scale-10);
                font-size: 1.3em;
            }
        }

        &__news {
            display: flex;
            flex-direction: column;
            gap: var(--scale-4);
        }

        &__private {
            display: flex;
            flex-direction: column;
            gap: var(--scale-4);
        }

        &__app {
            display: flex;
            flex-direction: column;
            gap: var(--scale-4);
        }

        &__form {
            display: flex;
            flex-direction: column;
            gap: var(--scale-2);
        }

        &__title {
            font-weight: 700;
            font-size: 1.3em;
        }

        &__subtitle {
            font-weight: 400;

            @media screen and (min-width: $breakpoints-lg) {
                white-space: nowrap;
            }
        }

        &__text {
            color: var(--theme-color-gray--03);
        }

        &__image {
            display: flex;
            flex-direction: column;
            gap: var(--scale-2);
            width: min-content;

            @media screen and (min-width: $breakpoints-lg) {
                gap: var(--scale-10);
            }
        }

        &__logo {
            display: none;

            @media screen and (min-width: $breakpoints-md) {
                display: inline;
                width: 2em;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "news": {
            "title": "Nos actualités",
            "placeholder": "Entrez votre adresse email",
            "subtitle": "Frenchfounders collecte et traite vos données personnelles pour mieux répondre à vos demandes. Vous pouvez à tout moment vous désinscrire via un lien de désabonnement dans notre newsletter.",
            "button": "S'inscrire",
            "already_register": "Vous êtes inscrit",
            "success": "Votre inscription a bien été prise en compte. Merci !",
            "error_generic": "Une erreur s'est produite, veuillez réessayer ultérieurement."
        },
        "private": {
            "title": "Nos espaces privés",
            "subtitle_1": "Le Club pour les dirigeants",
            "subtitle_2": "Les Business Solutions pour les entreprises",
            "text_1": "Partager de manière exclusive avec d’autres dirigeants et décideurs partout dans le monde !",
            "text_2": "Développer mon entreprise à l’international grâce à la force du réseau Frenchfounders !"
        },
        "app": {
            "title": "Notre application"
        },
        "copyright": "© {year} Frenchfounders",
        "recrute": "Recrutement",
        "legal": "Mentions légales",
        "cgu": "CGU",
        "cgu_entreprise": "CGU entreprises",
        "confidential": "Politique de confidentialité",
        "cookies": "Cookies"
    },
    "en": {
        "news": {
            "title": "Our news",
            "placeholder": "Enter your e-mail adress",
            "subtitle": "Frenchfounders collects and processes your personal data to better respond to your requests.You can unsubscribe at any time via an unsubscribe link in our newsletter.",
            "button": "Register",
            "already_register": "Your are registered",
            "success": "Your registration has been taken into account. Thanks !",
            "error_generic": "An error has occurred, please try again later."
        },
        "private": {
            "title": "Our private spaces",
            "subtitle_1": "Club for managers",
            "subtitle_2": "Business Solutions for companies",
            "text_1": "Share exclusively with other leaders and decision -makers all over the world!",
            "text_2": "Develop my business internationally thanks to the strength of the Frenchfounders network!"
        },
        "app": {
            "title": "Our application"
        },
        "copyright": "© {year} Frenchfounders",
        "recrute": "Recruitment",
        "legal": "Legal Notice",
        "cgu": "Terms",
        "cgu_entreprise": "Companies terms",
        "confidential": "Privacy Policy",
        "cookies": "Cookies"
    }
}
</i18n>
