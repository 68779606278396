<script setup lang="ts">
    import AlertDialog from '../dialogs/AlertDialog.vue'
    import { AlertStatus } from '@/vars/AlertAttr'
    import { computed } from 'vue'

    interface ResponseError {
        response: {
            data: {
                code: number
                message: string
            }
        }
    }

    const props = defineProps<{
        error?: unknown
    }>()

    const errorMessage = computed(() => {
        return (props.error as ResponseError)?.response?.data?.message || props.error
    })
</script>

<template>
    <AlertDialog :type="AlertStatus.ERROR">
        {{ errorMessage }}
    </AlertDialog>
</template>
