<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import { useI18n } from 'vue-i18n'
    import { ButtonType, ButtonTheme } from '@/vars/ButtonAttr'

    import { StorageKey } from '@/vars/StorageAttr'
    import SharedStorage from '@/libs/core/SharedStorage'

    const { locale } = useI18n({ useScope: 'global' })

    enum Locale {
        FR = 'fr',
        EN = 'en'
    }

    function changeLocale(newLang: Locale) {
        if (newLang !== locale.value) {
            SharedStorage.set(StorageKey.LOCALE, newLang)
            window.location.reload()
        }
    }
</script>

<template>
    <div v-for="(lang, index) in Object.values(Locale)" :key="lang" class="switcher">
        <BaseButton
            :type="ButtonType.BUTTON"
            :theme="ButtonTheme.DISCRET"
            class="switcher__button"
            @click="changeLocale(lang)"
        >
            {{ lang.toUpperCase() }}
        </BaseButton>
        <span v-if="Object.values(Locale).length !== index + 1" class="switcher__separator">|</span>
    </div>
</template>

<style scoped lang="scss">
    .switcher {
        display: inline-flex;
        gap: 1em;

        &__button {
            font-weight: 600;
        }

        &__separator {
            color: var(--theme-text-color--third);
            margin-right: 1em;
        }
    }
</style>
