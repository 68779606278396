import { useAuthStore } from '@/libs/stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'
import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
import { ClubRouteName, RouteName } from '@/vars/RouteName'
import AuthApi from '@/libs/api/Auth.api'

export function redirectToHomeIfAlreadyConnected() {
    const authStore = useAuthStore()
    const { navigate } = useNavigationResolver()

    if (!authStore.isLoggedIn) {
        return true
    }

    if (import.meta.env.VITE_ENABLE_FORCE_LOGIN === 'true') {
        AuthApi.forceLogin()
    }

    if (authStore.hasRole(RoleName.LECLUB)) {
        navigate({ name: ClubRouteName.HOME }, { replace: true })
    }

    navigate({ name: RouteName.HOME }, { replace: true })
}
