<script setup lang="ts">
    import { useI18n } from 'vue-i18n'

    const { t } = useI18n()
</script>

<template>
    <div class="headband headband--mentorship">
        <p class="headband__text text-sm" v-html="t('text.mentorship')"></p>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .headband {
        background-color: var(--theme-text-color);
        color: white;
        min-height: var(--scale-16);
        align-items: center;
        display: flex;
        justify-content: center;

        &--trial {
            background-color: var(--theme-color-ray--10);
            color: var(--theme-text-color);
        }

        &--mentorship {
            background-color: var(--theme-color-ray--10);
            color: var(--theme-text-color);
            font-weight: 500;
            min-height: var(--scale-12);
        }

        &__text {
            margin: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "text": "À l'occasion de la prochaine promotion de membres, nous mettons à l'honneur les <span style='color:#ff648c'>{honor}</span> de notre réseau. Pour en savoir plus, <a href='{link}' target='_blank' style='text-decoration: underline;font-weight: bold;'>cliquez ici</a>",
        "text.trial": "Du <span style='color: #df2b4d;'>31 octobre au 30 novembre</span>, Frenchfounders offre <span style='color: #df2b4d;'>un mois d’essai au Club</span> pour toute candidature soumise pendant cette période. Cette offre est uniquement valable pour une première expérience Club.",
        "text.mentorship": "Du <span style='color:#df2b4d'>8 janvier au 28 février</span>, Frenchfounders vous offre la possibilité de rejoindre son <span style='color:#df2b4d'>programme de mentorat</span> en devenant membre du Club."
    },
    "en": {
        "text": "On the occasion of the next promotion of members, we honor the <span style='color:#ff648c'>{honor}</span> in our network.To find out more, <a href='{link}' target='_blank' style='text-decoration: underline;'>click here</a>.",
        "text.trial": "From <span style='color: #df2b4d;'>October 31 to November 30</span>, Frenchfounders <span style='color: #df2b4d;'>offers a month's test</span> for any candidacy submitted during this period.This offer is only valid for a first club experience.",
        "text.mentorship": "From <span style='color:#df2b4d'>January 8 to February 28</span>, Frenchfounders offers you the opportunity to join its <span style='color:#df2b4d'>mentorship program</span> by becoming member of the Club."
    }
}
</i18n>
