<script setup lang="ts">
    import { computed, ref, StyleValue, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import gsap from 'gsap'
    import PopoverContent from '@/components/ui/navigations/PopoverContent.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import DropdownContent from '@/components/ui/navigations/dropdowns/DropdownContent.vue'
    import { useEventListener } from '@vueuse/core'
    import { AboutRouteName, ClubRouteName, ProRouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'

    const props = withDefaults(
        defineProps<{
            theme?: 'light' | 'dark' | 'glass'
        }>(),
        {
            theme: 'light'
        }
    )

    const { t } = useI18n()

    const menuItems = [
        {
            iconName: 'groups_3',
            title: t('club_private.title'),
            text: t('club_private.text'),
            to: { name: ClubRouteName.HOME }
        },
        {
            iconName: 'cases',
            title: t('business_solutions.title'),
            text: t('business_solutions.text'),
            to: { name: ProRouteName.HOME }
        },
        {
            iconName: 'account_balance',
            title: t('invest.title'),
            text: t('invest.text'),
            to: { name: AboutRouteName.WEWEB_INVESTMENT }
        },
        // { iconName: 'paid', title: t('lefonds.title'), text: t('lefonds.text'), href: '#' },
        // { iconName: 'school', title: t('academy.title'), text: t('academy.text'), href: '#' },
        {
            iconName: 'language',
            title: t('hire.title'),
            text: t('hire.text'),
            to: { name: AboutRouteName.WEWEB_RECRUTE }
        }
    ] as const

    const dropdown = ref<typeof DropdownContent>()
    const focusedItem = ref<{ x: number; y: number; width: number; height: number }>()

    useEventListener(window, 'resize', () => {
        focusedItem.value = undefined
    })

    const hoverEffectStyle = computed<StyleValue>(() => {
        if (!focusedItem.value) {
            return {}
        }
        return {
            opacity: 1,
            top: focusedItem.value.y + 'px',
            width: focusedItem.value.width + 'px',
            height: focusedItem.value.height + 'px'
        }
    })

    watch(dropdown, () => {
        if (dropdown.value) {
            gsap.from(`[data-dropdown-item]`, {
                x: 20,
                opacity: 0,
                stagger: 0.075,
                duration: 0.35,
                ease: 'power2.quad'
            })
            initListeners()
        } else {
            removeListeners()
        }
    })

    function initListeners() {
        const items = document.querySelectorAll<HTMLDivElement>('[data-dropdown-item]')
        items.forEach((item) => itemListener(item))
    }

    function itemListener(item: HTMLDivElement) {
        item.addEventListener('mouseenter', () => {
            focusedItem.value = {
                x: item.offsetLeft,
                y: item.offsetTop,
                height: item.offsetHeight,
                width: item.offsetWidth
            }
        })
    }

    function removeListeners() {
        focusedItem.value = undefined
        const items = document.querySelectorAll<HTMLDivElement>('[data-dropdown-item]')
        items.forEach((item) => itemListener(item))
    }

    const wrapperClasses = computed(() => {
        switch (props.theme) {
            case 'light':
            case 'dark':
                return `bg-nuage`
            default:
                return ''
        }
    })
</script>

<template>
    <PopoverContent
        class="switcher"
        :class="`switcher--${theme}`"
        :close-delay="500"
        :wrapper-class="[`rounded-xl overflow-hidden`, wrapperClasses]"
    >
        <template #action>
            <button
                type="button"
                class="flex h-10 w-10 items-center justify-center rounded-full backdrop-opacity-10 transition-all duration-200"
                :class="{ 'hover:bg-flagship-10': theme === 'dark', 'hover:bg-[#FFFFFF22]': theme === 'light' }"
            >
                <MaterialIcon
                    class="text-3xl"
                    :class="{ 'text-nuage': ['light', 'glass'].includes(theme), 'text-legacy': theme === 'dark' }"
                    name="apps"
                />
            </button>
        </template>
        <template #default>
            <div
                ref="dropdown"
                class="switcher__content !mt-6r pb-4"
                :class="{ 'switcher__content--glass': theme === 'glass' }"
            >
                <SpaRouterLink
                    v-for="item in menuItems"
                    :key="item.iconName"
                    class="z-20 w-[90vw] flex-shrink-0 sm:w-auto"
                    :to="item.to"
                    data-dropdown-item
                >
                    <div class="flex items-start gap-4 p-4">
                        <MaterialIcon
                            :name="item.iconName"
                            class="text-2xl text-royale"
                            :class="{ '!text-flagship': theme === 'glass' }"
                        />
                        <div class="flex-1">
                            <h4 class="font-bold">{{ item.title }}</h4>
                            <p class="text-xs">{{ item.text }}</p>
                        </div>
                    </div>
                </SpaRouterLink>
                <div class="switcher__focus-indicator" :style="hoverEffectStyle"></div>
            </div>
        </template>
    </PopoverContent>
</template>

<style lang="scss" scoped>
    @import '@/libs/sass/vars';
    .switcher {
        $block: &;
        backdrop-filter: none;

        &:deep(button) {
            outline: none;
        }

        &__content {
            @apply z-20 max-h-screen min-w-72 overflow-y-auto bg-nuage px-2;
            max-height: 90dvh;
            overflow-y: scroll;
            width: min-content !important;
            display: flex;
            flex-direction: column;

            #{$block}--glass & {
                @apply bg-transparent text-nuage;

                &::before {
                    content: '';
                    @apply absolute inset-0 z-0;
                    background: rgba(var(--theme-color-legacy--rgb), 0.2);
                    backdrop-filter: blur(2rem);
                }
            }

            @media screen and (min-width: $breakpoints-lg) {
                @apply min-w-96;
                padding-inline: var(--scale-6r) !important;
            }
        }

        &__focus-indicator {
            @apply absolute z-10 rounded-lg bg-flagship-10 opacity-0;
            transition: all 400ms cubic-bezier(0.24, -0.01, 0, 1.23);
            transition-delay: 100ms;
            backdrop-filter: blur(0rem);

            #{$block}--glass & {
                @apply bg-legacy;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "club_private": {
            "title": "Club privé",
            "text": "Partagez de manière exclusive avec d’autres dirigeants et décideurs partout dans le monde"
        },
        "invest": {
            "title": "L’Investissement",
            "text": "Découvrez l’investissement en startups ou levez des fonds"
        },
        "lefonds": {
            "title": "Le Fonds",
            "text": "Découvrez le fonds d’investissement early stage"
        },
        "academy": {
            "title": "Frenchfounders Academy",
            "text": "Inspirez-vous des enseignements de nos meilleurs experts pour vous développer à l’international"
        },
        "business_solutions": {
            "title": "Business Solutions",
            "text": "Développez votre entreprise à l’international, accélérez votre croissance, votre financement et vos recrutements dans le monde entier"
        },
        "hire": {
            "title": "Recrutement",
            "text": "Dénichez les meilleurs talents francophones à profils internationaux dans notre réseau."
        }
    },
    "en": {}
}
</i18n>
