import { computed, toRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { QueryName } from '@/vars/QueryAttr'
import CirclesApi from '@/libs/api/Circles.api'
import { useAuthStore } from '@/libs/stores/auth.store'
import { GetCirclesForm } from '@/types/forms/circles-api/GetCirclesForm.type'

export default function useGroupsQuery(params?: GetCirclesForm) {
    const authStore = useAuthStore()
    const reactiveParams = toRef(params)

    const isLoggedIn = computed(() => authStore.isLoggedIn)

    return useQuery({
        queryKey: [QueryName.CIRCLES, reactiveParams],
        queryFn: () => CirclesApi.getCircles(reactiveParams.value),
        enabled: isLoggedIn
    })
}
