import { Feedback } from '@/types/response/Feedback.type'
import Http from './ApiClient'

class FeedbackApi {
    static async getFeedback(feedbackId: string | number, feedbackStep?: string): Promise<Feedback> {
        const params = feedbackStep && { step: feedbackStep }
        const response = await Http.get(`/feedback/${feedbackId}`, {
            params
        })

        return response.data
    }

    static async getPendingFeedbackIdList(): Promise<{ id: number }[]> {
        const response = await Http.get(`/feedback`)
        return response.data
    }

    static async postFeedbackAnswers(
        feedbackId: string | number,
        feedbackAnswersObject: {
            form_step: number | undefined
            form_elements: { id: string; answer: boolean | number | null }[] | null
        }
    ): Promise<Feedback> {
        const response = await Http.post(`feedback/${feedbackId}`, { ...feedbackAnswersObject })

        return response.data
    }

    static async getFeedbackBack(feedbackId: string | number, feedbackStep: number | undefined): Promise<Feedback> {
        const response = await Http.get(`/feedback/${feedbackId}/back`, {
            params: {
                form_step: feedbackStep
            }
        })

        return response.data
    }

    static async feedbackPingAdmin(feedbackId: string | number): Promise<void> {
        const response = await Http.get(`/feedback/${feedbackId}/ping/admin`)
        return response.data
    }
}

export default FeedbackApi
