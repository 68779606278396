<script setup lang="ts">
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { UniversalSearchEventType } from '@/types/response/UniversalSearch.type'
    import { EventRouteName, ExternalRoute } from '@/vars/RouteName'
    import { useI18n } from 'vue-i18n'

    defineProps<{
        searchText: string
        events: UniversalSearchEventType[]
        count: number
    }>()

    const { t } = useI18n()
</script>

<template>
    <div v-show="count > 0" class="list">
        <h3 class="title-4">{{ t('title') }}</h3>
        <div>
            <div class="list__events">
                <SpaRouterLink
                    v-for="event in events"
                    :key="event.id"
                    :to="{ name: EventRouteName.EVENT_DETAILS, params: { id: event.id, slug: event.slug } }"
                    class="list__events__item"
                >
                    <img :src="event.image" class="list__events__item__picture" />
                    <div>
                        <div class="list__title">{{ event.title }}</div>
                        <div class="list__events__item__subtitle text-sm">
                            {{ event.startDate }}
                            <span v-if="event.subtitle"> - {{ event.subtitle }}</span>
                        </div>
                    </div>
                </SpaRouterLink>

                <div class="ml-2 mt-4">
                    <a class="list__more" :href="ExternalRoute.EVENTS(searchText)">
                        {{ t('show_more') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .list {
        display: grid;
        grid-template-columns: 8.75em 1fr;
        gap: var(--scale-4);

        &__title {
            font-weight: 600;
        }

        &__events {
            border-left: 0.1em solid var(--theme-color-gray--05);
            padding: 0 var(--scale-2);

            &__item {
                padding: var(--scale-2);
                display: grid;
                gap: var(--scale-4);
                grid-template-columns: auto auto;
                justify-content: left;
                align-items: center;

                &__picture {
                    height: 2.5em;
                }

                &__subtitle {
                    color: var(--theme-color-gray--03);
                }
            }
        }

        &__more {
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Événements",
        "show_more": "Voir tous les événements passés et à venir"
    },
    "en": {
        "title": "Events",
        "show_more": "See all past and future events"
    }
}
</i18n>
