<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { useAttrs } from 'vue'
    import { RouteLocationNamedRaw } from 'vue-router'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'

    defineProps<{
        to?: RouteLocationNamedRaw
        href?: string
        translatedText: string
    }>()

    const { t } = useI18n()
    const attrs = useAttrs()
</script>

<template>
    <SpaRouterLink v-if="to" :to="to" class="page__prev">
        {{ t(translatedText) }}
        <BaseIcon legacy name="arrow-right" class="page__prev-icon" />
    </SpaRouterLink>
    <a v-else :href="href" target="_blank" rel="noopener noreferrer" class="page__prev" v-bind="attrs">
        {{ t(translatedText) }}
        <BaseIcon legacy name="arrow-right" class="page__prev-icon" />
    </a>
</template>

<style scoped lang="scss">
    .page {
        $block-selector: &;
        &__prev {
            display: inline-flex;
            align-items: center;
            margin-bottom: 1.5em;
        }

        &__prev-icon {
            font-size: 1.5em;
            transition: transform 350ms;
            margin-left: 0.2em;

            #{$block-selector}__prev:hover & {
                transform: translateX(0.5em);
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "prev_button": "Voir les autres candidats",
        "edit_offer": "Modifier l'offre",
        "manage_offer": "Gérer l'offre",
        "preview_offer": "Prévisualiser l'offre",
        "download": "Télécharger le CV",
        "show_more": "En savoir plus"
    },
    "en": {
        "prev_button": "See the other candidates",
        "edit_offer": "Modify the offer",
        "manage_offer": "Manage the offer",
        "preview_offer": "Preview offer",
        "download": "Download the CV",
        "show_more": "Learn more"
    }
}
</i18n>
