import { useQuery } from '@tanstack/vue-query'
import { onMounted, ref } from 'vue'
import useNotification from './useNotification'
import { ToastType } from '@/vars/ToastAttr'
import { QueryName } from '@/vars/QueryAttr'
import { useI18n } from 'vue-i18n'
import { watchImmediate } from '@vueuse/core'

export default function useNewAppReleaseDetector() {
    const { snackbar } = useNotification()
    const enableNewAppReleaseLongPool = ref(false)
    const currentIndexBundleName = ref('')
    const { t } = useI18n({
        messages: {
            fr: {
                update_available: 'Une mise à jour est disponible',
                update: 'Mettre à jour'
            },
            en: {
                update_available: 'An update is available',
                update: 'Update'
            }
        }
    })

    onMounted(() => {
        document.head.querySelectorAll<HTMLScriptElement>('script[type="module"]')?.forEach((script) => {
            if (script.src.includes('/assets/index-')) {
                currentIndexBundleName.value = script.src?.split('/assets')?.[1]
                enableNewAppReleaseLongPool.value = true
            }
        })
    })

    const { data: html } = useQuery({
        refetchInterval: 120_000,
        enabled: enableNewAppReleaseLongPool,
        queryKey: [QueryName.RELEASE_DETECTOR],
        queryFn: async () => {
            const response = await fetch(window.location.href)

            if (!response.ok) {
                throw new Error(await response.text())
            }

            return await response.text()
        }
    })

    watchImmediate(html, () => {
        if (!html.value) {
            return null
        }

        if (
            currentIndexBundleName.value &&
            !html.value.includes(currentIndexBundleName.value) &&
            enableNewAppReleaseLongPool.value
        ) {
            enableNewAppReleaseLongPool.value = false
            snackbar({
                type: ToastType.SUCCESS,
                text: t('update_available'),
                duration: -1,
                action: {
                    text: t('update'),
                    handler() {
                        window.location.reload()
                    }
                }
            })
        }
    })
}
