<script setup lang="ts">
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import { Feedback } from '@/types/response/Feedback.type'
    import { computed, onMounted, ref } from 'vue'
    import StarRate from '@/components/ui/icons/StarRate.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { useI18n } from 'vue-i18n'
    import FeedbackForm from '@/libs/helpers/feedbackForm'
    import { useMutation } from '@tanstack/vue-query'
    import FeedbackApi from '@/libs/api/Feedback.api'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import FormPart from './form-part/FormPart.vue'
    import { formatDate } from '@/libs/helpers/dates'
    import { ModalSize } from '@/vars/ModalAttr'
    import { captureException } from '@sentry/vue'
    import ModalFinishStep from './ModalFinishStep.vue'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'

    const props = withDefaults(
        defineProps<{
            feedback: Feedback
            score?: number
        }>(),
        {
            score: 0
        }
    )

    const { t } = useI18n()
    const { toast } = useNotification()
    const showModal = ref(true)
    const rateValue = ref(props.score)
    const feedbackStatus = ref<string | null>(null)
    const form = ref<FeedbackForm | null>(null)
    const globalRate = ref<number | null>(null)

    const styleHeader = computed(() => {
        return {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgb(255, 255, 255)), url(${props.feedback.event?.picture})`
        }
    })

    const isFinished = computed(() => {
        return feedbackStatus.value === 'done'
    })

    const isFirstStep = computed(() => {
        if (form.value && form.value.formStep) {
            return form.value.formStep === 1
        } else {
            return true
        }
    })

    const handleFeedback = (feedback) => {
        if (feedback) {
            form.value = new FeedbackForm(feedback.form)
            feedbackStatus.value = feedback.status
        }
    }

    const onAnswer = (answer: { id: string; answer: number | boolean }) => {
        form.value?.setAnswer(answer.id, answer.answer)
    }

    const { mutate: postAnswerFeedback } = useMutation({
        mutationFn: async (params: {
            id: string | number
            feedbackAnswersObject: {
                form_step: number | undefined
                form_elements: { id: string; answer: boolean | number | null }[] | null
            }
        }) => {
            return await FeedbackApi.postFeedbackAnswers(params.id, params.feedbackAnswersObject)
        },
        onSuccess: (data) => {
            handleFeedback(data)
        },
        onError: (e) => {
            captureException(e)
            toast({
                type: ToastType.ERROR,
                title: t('toast.error')
            })
            showModal.value = false
        }
    })

    const sendAnswers = () => {
        globalRate.value = Number(form.value?.globalRateValue)
        if (form.value?.feedbackAnswersObject) {
            postAnswerFeedback({ id: props.feedback.id, feedbackAnswersObject: form.value.feedbackAnswersObject })
        }
    }

    const { mutate: goBack } = useMutation({
        mutationFn: async (params: { id: string | number; step: number | undefined }) => {
            return await FeedbackApi.getFeedbackBack(params.id, params.step)
        },
        onSuccess: (data) => {
            handleFeedback(data)
        },
        onError: (e) => {
            captureException(e)
            toast({
                type: ToastType.ERROR,
                title: t('toast.error')
            })
            showModal.value = false
        }
    })

    onMounted(() => {
        handleFeedback(props.feedback)
        if (rateValue.value) {
            const element = props.feedback.form.form_elements.find((formEl) =>
                formEl.type === 'global-rate' ? formEl : null
            )
            if (element) {
                form.value?.setAnswer(element.id, rateValue.value)
            }
        }
    })

    const dateEvent = computed(() => {
        if (!props.feedback?.event?.start_date) {
            return ''
        }
        return formatDate(props.feedback?.event?.start_date, { customFormat: 'dd/MM/yyyy' })
    })
</script>

<template>
    <BaseModal
        :model-value="showModal"
        :size="ModalSize.MEDIUM_XS"
        @update:model-value="($event) => (showModal = $event)"
    >
        <template v-if="!isFirstStep && !isFinished" #title>{{ feedback.event?.title }}</template>
        <ModalFinishStep v-if="isFinished" :text="t('thanks_finish_feedback')" @close="showModal = false" />

        <div v-else>
            <div class="modal__header" :style="styleHeader">
                <div v-if="isFirstStep" class="modal__header__categories text-sm">{{ feedback.event?.type }}</div>
            </div>
            <div class="modal__content" :class="{ 'modal__content--first-step': isFirstStep }">
                <div v-if="isFirstStep">
                    <div class="title-2">{{ feedback.event?.title }}</div>
                    <div class="mt-4 text-sm">{{ t('thanks_participation') }}</div>
                    <div class="modal__content__details">
                        <div
                            class="modal__content__details__container"
                            :class="{
                                'modal__content__details__container--no-location': !feedback.event?.location
                            }"
                        >
                            <div>
                                <BaseIcon name="calendar" />
                                <div>{{ dateEvent }}</div>
                            </div>
                            <div v-if="feedback.event?.location" class="modal__content__details__separator"></div>
                            <div v-if="feedback.event?.location">
                                <BaseIcon name="location_on" />
                                <div>{{ feedback.event?.location }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal__content__rating">
                    <div v-if="!isFirstStep && globalRate">
                        <div>{{ t('global_rate') }}</div>
                        <StarRate :vote="globalRate" is-editable @select="(value) => (globalRate = value)" />
                    </div>
                    <div v-if="feedback && form" class="mb-8">
                        <FormPart
                            v-for="(formEl, i) in form.questions"
                            :key="i"
                            class="mt-6"
                            :form="form"
                            :form-el="formEl"
                            :rating="rateValue"
                            :i="i"
                            @answer="onAnswer"
                            @submit="sendAnswers"
                        />
                    </div>
                    <BaseLink
                        v-if="form && form.formStep && form.formStep > 1 && !isFinished"
                        href="#"
                        @click="() => goBack({ id: feedback.id, step: form?.form?.form_step })"
                    >
                        {{ t('back') }}
                    </BaseLink>
                </div>
            </div>
        </div>
    </BaseModal>
</template>

<style scoped lang="scss">
    .modal {
        &__header {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: var(--scale-30);
            border-radius: var(--scale-2);
            padding-bottom: var(--scale-1);
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: var(--scale-2);

            &__categories {
                display: flex;
                margin-right: auto;
                margin-left: auto;
                justify-content: center;
                color: var(--theme-color-nuage);
                background-color: var(--theme-color-legacy);
                padding: var(--scale-2);
                border-radius: var(--scale-2);
                text-transform: uppercase;
                margin-bottom: var(--scale-4);
            }

            &__title {
                text-align: center;
                margin-bottom: var(--scale-4);
            }
        }

        &__content {
            margin-top: var(--scale-4);
            text-align: center;
            &--first-step {
                margin-top: var(--scale-20);
            }
            &__details {
                margin: var(--scale-4);
                display: flex;
                justify-content: center;
                text-align: center;
                gap: var(--scale-4);

                &__separator {
                    content: ' ';
                    width: var(--scale-px);
                    background-color: var(--theme-color-gray--02);
                    margin-left: var(--scale-4);
                }

                &__container {
                    display: grid;
                    grid-template-columns: 1fr auto 1fr;
                    gap: var(--scale-4);

                    &--no-location {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
            &__rating {
                margin-top: var(--scale-4);
            }
            &__finished {
                text-align: center;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "thanks_participation": "Merci d'avoir participé à cet événement",
        "global_rate": "Expérience globale",
        "back": "Retour",
        "thanks_finish_feedback": "Merci d'avoir répondu au feedback",
        "toast": {
            "error": "Une erreur s'est produite lors du traitement de votre feedback, veuillez réessayer ultérieurement."
        }
    }
}
</i18n>
