import { useQuery } from '@tanstack/vue-query'
import { QueryName } from '@/vars/QueryAttr'
import MemberApi from '@/libs/api/Member.api'
import { useAuthStore } from '@/libs/stores/auth.store'

export default function useGettingStartedTodoQuery(opts?: {
    enabled?: boolean
    staleTime?: number
    refetchOnMount?: boolean
}) {
    const authStore = useAuthStore()

    return useQuery({
        queryKey: [QueryName.GETTING_STARTED],
        queryFn: () => MemberApi.getStartedTodo(),
        enabled: () => {
            return authStore.isLoggedIn
        },
        ...opts
    })
}
