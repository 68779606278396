<script setup lang="ts">
    import { computed, ref } from 'vue'

    const BLOCK_CLASS = 'gutter'
    const group = ref<HTMLElement | null>(null)

    const props = withDefaults(
        defineProps<{
            tag?: string
            collapse?: boolean
            space?: string
            inline?: boolean
        }>(),
        {
            tag: 'div',
            space: '1.25'
        }
    )

    const spaceEm = computed(() => `${props.space}em`)

    const classList = computed(() => [
        BLOCK_CLASS,
        {
            [`${BLOCK_CLASS}--collapse`]: props.collapse,
            [`${BLOCK_CLASS}--inline`]: props.inline
        }
    ])
</script>

<template>
    <Component :is="tag" ref="group" :class="classList">
        <slot></slot>
    </Component>
</template>

<style scoped lang="scss">
    .gutter {
        $block-selector: &;
        display: inline-flex;
        flex-wrap: wrap;
        gap: v-bind('spaceEm');

        &--collapse {
            margin: 0;
            gap: 0;
        }

        &--inline {
            flex-wrap: nowrap;
            overflow-x: auto;
            max-width: 100%;
            margin: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &--collapse:deep() > * {
            margin: 0;

            &:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &--inline:deep() > * {
            flex-shrink: 0;
        }
    }
</style>
