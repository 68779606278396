import { InternalAxiosRequestConfig } from 'axios'

export async function checkSegmentAnonymousIdInterceptor(request: InternalAxiosRequestConfig) {
    let segmentAnonymousId = localStorage.getItem('ajs_anonymous_id')
    if (segmentAnonymousId) {
        segmentAnonymousId = segmentAnonymousId.replace(/"/g, '')
        request.headers['x-ff-aid'] = segmentAnonymousId
    }

    return request
}

export default checkSegmentAnonymousIdInterceptor
