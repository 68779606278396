import { HeaderNavigation } from '@/types/Header.type'
import { ProRouteName } from '@/vars/RouteName'
import messages from './i18n/main.json'
import i18n from '@/i18n'

i18n.global.mergeLocaleMessage('fr', messages.fr)
i18n.global.mergeLocaleMessage('en', messages.en)

const { t } = i18n.global

// export const items: HeaderNavigation[] = [
export const getNavigationItems = () => {
    const items: HeaderNavigation[] = []

    items.push(
        {
            label: t('nav.jobs'),
            route: { name: ProRouteName.JOBS }
        },
        {
            label: t('nav.connexions'),
            route: { name: ProRouteName.BUSINESS_CONNECTIONS }
        },
        {
            label: t('nav.sponsorship'),
            route: { name: ProRouteName.EVENTS }
        },
        {
            label: t('nav.fundraising'),
            route: { name: ProRouteName.FUNDRAISING }
        }
    )

    return items
    // ];
}
