<script setup lang="ts">
    import { computed, inject } from 'vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { useI18n } from 'vue-i18n'
    import { UNIVERSAL_SEARCH_KEY, UniversalSearchInjectType } from '@/vars/UniversalSearchAttr'

    type History = {
        snapshot: string
        timestamp: number
    }

    const universalSearch = inject<UniversalSearchInjectType>(UNIVERSAL_SEARCH_KEY)

    const { t } = useI18n()

    const onSelect = (oldSearch: History) => {
        universalSearch?.updateSearchText(oldSearch.snapshot)
    }

    const historyValues = computed(() => {
        return universalSearch?.history.value.filter((item) => item.snapshot !== '')
    })
</script>

<template>
    <ContainerTemplate>
        <div class="history">
            <div v-if="historyValues?.length === 0" class="history__empty">{{ t('no_history') }}</div>
            <div v-else>
                <div
                    v-for="oldSearch in historyValues"
                    :key="oldSearch.timestamp"
                    class="history__item"
                    @click="() => onSelect(oldSearch)"
                >
                    <BaseIcon name="history" />
                    {{ oldSearch.snapshot }}
                </div>
            </div>
        </div>
    </ContainerTemplate>
</template>

<style scoped lang="scss">
    .history {
        padding-inline: var(--scale-16);
        &__empty {
            text-align: center;
        }
        &__item {
            padding: var(--scale-2);
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: var(--scale-2);
            &:hover {
                background-color: var(--theme-color-gray--06);
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "no_history": "Vous n'avez pas d'historique de recherche"
    },
    "en": {
        "no_history": "You do not have a research history"
    }
}
</i18n>
