export function isTouchDevice() {
    /* 
    For developers:
    Be careful, in Chrome inspector tool,
    if you toggle the "device responsive mode", you need to refresh the page to update its value.
    */
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export function isInViewport(element: HTMLElement) {
    const rect = element.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}
