export function safeFrenchFoundersRedirect(link: string) {
    const regex =
        /^(https?:\/\/)?([a-z0-9-]+)?(\.dev|.+\.glados)?\.?(frenchfounders|checkout-stripe)\.com(:\d+|)?\/?(.+)?$/

    if (link === '/') {
        window.location.href = link
        return true
    } else if (link.match(regex)) {
        window.location.href = link
        return true
    } else {
        console.error('Unable to redirect to', link)
        return false
    }
}
