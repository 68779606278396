<script lang="ts" setup>
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { ClubRouteName } from '@/vars/RouteName'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import ModalContactUs from '../../common/__partials__/ModalContactUs.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import PartnerListFooter from '../../common/PartnerListFooter.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'

    const { t } = useI18n()
    const { redirectBounding } = useNavigationResolver()

    const links = [
        { label: t('trip_planner'), href: '/trips/add' },
        { label: t('our_team'), to: { name: ClubRouteName.FF_TEAM } },
        {
            label: t('privacy'),
            href: 'https://www.frenchfounders.com/page/privacy'
        },
        { label: t('terms'), href: 'https://www.frenchfounders.com/page/cgv' }
    ]

    const showModalConstactUs = ref(false)
</script>

<template>
    <footer class="footer">
        <ContainerTemplate>
            <PartnerListFooter class="footer__partners" dark />
            <div class="footer__links">
                <span v-for="(link, i) in links" :key="link.label">
                    <Component
                        :is="link?.to ? SpaRouterLink : 'a'"
                        class="footer__link"
                        v-bind="link?.to ? redirectBounding(link?.to) : { href: link.href }"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ link.label }}
                    </Component>
                    <span v-if="i < links.length - 1" class="footer__separator">|</span>
                </span>
            </div>
            <div class="footer__links">
                <a class="footer__link" data-remote="/contact/contact-us/layout_v3" @click="showModalConstactUs = true">
                    {{ t('contact_us') }}
                </a>
                &nbsp; | &nbsp;
                <a class="footer__link">
                    {{ t('follow_us') }}
                </a>
                <div class="footer__socials">
                    <a
                        class="footer__link footer__link--x"
                        href="https://twitter.com/frenchfounders"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BaseIcon name="x" />
                    </a>
                    <a
                        class="footer__link"
                        href="https://www.facebook.com/Frenchfounders"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BaseIcon name="facebook" />
                    </a>
                    <a
                        class="footer__link"
                        href="https://www.instagram.com/frenchfounders_inc/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BaseIcon name="instagram" />
                    </a>
                    <a
                        class="footer__link"
                        href="https://www.linkedin.com/company/frenchfounders"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BaseIcon name="linkedin" />
                    </a>
                    <a
                        class="footer__link"
                        href="https://www.youtube.com/channel/UCzx8IkRAiJvZarosJQbeNoQ"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BaseIcon name="youtube" />
                    </a>
                </div>
            </div>
        </ContainerTemplate>
        <ModalContactUs v-model="showModalConstactUs" />
    </footer>
</template>

<style lang="scss" scoped>
    .footer {
        background-color: var(--theme-color-legacy);
        padding-top: var(--scale-10);
        padding-bottom: var(--scale-4);

        &__partners {
            margin-bottom: var(--scale-14);
        }

        &__separator {
            margin: 0 var(--scale-1);
        }

        &__links {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white !important;
            text-align: center;
            margin-bottom: var(--scale-2);
        }

        &__link {
            color: white !important;
            margin: 0 var(--scale-2);
            display: inline-flex;
            cursor: pointer;
        }

        &__socials {
            display: inline-flex;
            align-items: center;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "trip_planner": "Trip Planner",
        "our_team": "Notre équipe",
        "privacy": "Politique de confidentialité",
        "terms": "CGV",
        "contact_us": "Contactez-nous",
        "follow_us": "Suivez-nous"
    },
    "en": {
        "trip_planner": "Trip Planner",
        "our_team": "Our team",
        "privacy": "Policy and privacy",
        "terms": "Terms",
        "contact_us": "Contact us",
        "follow_us": "Follow us"
    }
}
</i18n>
