<script setup lang="ts">
    import { ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import { ModalSize } from '@/vars/ModalAttr'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import UpsellStakesForm from './UpsellStakesForm.vue'
    import UpsellStakesGuide from '@/components/partials/network/smart-actions/action-upsell-stakes/UpsellStakesGuide.vue'
    import { useRoute } from 'vue-router'
    import { RouteName } from '@/vars/RouteName'

    enum Step {
        SELECTION,
        GUIDE
    }

    const isModalVisible = defineModel<boolean>()

    const emit = defineEmits<{
        (e: 'finished'): []
        (e: 'before-close'): void
    }>()

    const { t } = useI18n()
    const { analytics } = useSegment()
    const route = useRoute()

    const currentStep = ref(Step.SELECTION)

    watch(isModalVisible, () => {
        if (isModalVisible.value) {
            analytics.track('community:onboarding:upsell_stakes:show')
        } else if (!isModalVisible.value && currentStep.value === Step.GUIDE) {
            emit('finished')
        }
    })

    function onClose() {
        isModalVisible.value = false

        // Timeout pour permettre à l'animation de la modal de se faire
        setTimeout(() => {
            emit('finished')
        }, 300)
    }

    const handleSteps = () => {
        if (route.name === RouteName.HOME) {
            currentStep.value = Step.GUIDE
        } else {
            onClose()
        }
    }
</script>

<template>
    <BaseModal v-model="isModalVisible" :size="ModalSize.LARGE" @before-close="emit('before-close')">
        <template #title>{{ t('title') }}</template>
        <template #default>
            <Transition name="fade" mode="out-in">
                <UpsellStakesForm v-if="currentStep === Step.SELECTION" @submitted="handleSteps" />
                <UpsellStakesGuide v-else-if="currentStep === Step.GUIDE" @close="onClose" />
            </Transition>
        </template>
    </BaseModal>
</template>

<style lang="scss" scoped>
    .fade {
        &-enter-active,
        &-leave-active {
            transition: all 300ms ease-in-out;
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Que recherchez-vous sur Frenchfounders ?"
    },
    "en": {
        "title": "What are you looking for on Frenchfounders?"
    }
}
</i18n>
