import Http from './ApiClient'

class SegmentApi {
    static async sendTracks(event: string, data?: unknown): Promise<unknown> {
        if (!import.meta.env?.MODE || import.meta.env?.MODE === 'dev' || import.meta.env?.MODE === 'development') {
            // Do not remove console log - Used to keep track of skipped wayd events
            // eslint-disable-next-line no-console
            console.log('[DEBUG] Skipped wayd event:', event, data)
            return
        }

        const response = await Http.post('/v3/wayd', {
            event,
            data: JSON.stringify(data)
        })

        return response.data
    }
}

export default SegmentApi
