<template>
    <div class="card-body">
        <slot></slot>
    </div>
</template>

<style scoped lang="scss">
    .card-body {
        padding: var(--scale-6r);
    }
</style>
