import { ResponseV3 } from '@/types/API.type'
import { JobConfiguration } from '@/types/Job.type'
import { QueryName } from '@/vars/QueryAttr'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/vue-query'
import JobsApi from '../api/Jobs.api'

export const queryKey: QueryName[] = [QueryName.TALENT_CONFIGURATION]
export type QueryType = ResponseV3<JobConfiguration, null>

export default function useTalentConfigurationQuery(
    opts?: Omit<
        UseQueryOptions<
            QueryType,
            AxiosError,
            QueryType,
            QueryName[] // QueryName.TALENT_CONFIGURATION
        >,
        'queryFn' | 'queryKey'
    >
) {
    return useQuery({
        queryKey,
        queryFn: JobsApi.getCandidateConfiguration,
        staleTime: 10_000,
        ...opts
    })
}
