<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import FormTextArea from '@/components/ui/forms/FormTextArea.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import { useForm } from 'vee-validate'
    import { UpsellStakesForm } from '@/types/forms/member/UpsellStakesForm'
    import * as yup from 'yup'
    import { useMutation } from '@tanstack/vue-query'
    import MemberApi from '@/libs/api/Member.api'
    import { ToastType } from '@/vars/ToastAttr'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import useNotification from '@/libs/compositions/useNotification'
    import { StakeKey } from '@/types/UpsellStake.type'
    import useUpsellStake from '@/libs/compositions/useUpsellStake'

    type Section = {
        category: string
        label: string
        stakeKeys: StakeKey[]
    }

    const emit = defineEmits<{
        submitted: [form: UpsellStakesForm]
    }>()

    const { t } = useI18n()
    const { getIconName, getWordings } = useUpsellStake()
    const { analytics } = useSegment()
    const { toast } = useNotification()

    const { mutate: sendStakes, isPending: isSendingStakes } = useMutation({
        mutationFn: () => MemberApi.sendUpsellStakes(values),
        onError: (e) => {
            console.error(e)
            toast({
                type: ToastType.ERROR,
                title: t('error.generic.title'),
                text: t('error.generic.text')
            })
        },
        onSuccess: () => {
            analytics.track('community:onboarding:upsell_stakes:sent', values)
            emit('submitted', values)
            toast({
                type: ToastType.SUCCESS,
                title: t('form_sent.title'),
                text: t('form_sent.text')
            })
        }
    })

    const { values, meta, setFieldValue, handleSubmit } = useForm<UpsellStakesForm>({
        initialValues: {
            stakes: [],
            otherStake: ''
        },
        validationSchema: yup.object({
            stakes: yup
                .array()
                .when('otherStake', { is: (otherStake: string) => otherStake === '', then: (schema) => schema.min(1) }),
            otherStake: yup.string()
        })
    })

    const showOtherObjectiveInput = ref(false)

    const sections = computed<Section[]>(
        () =>
            [
                {
                    category: 'international',
                    label: t('international'),
                    stakeKeys: ['expatriation', 'knowledge']
                },
                {
                    category: 'network',
                    label: t('network'),
                    stakeKeys: ['expand_network', 'develop_business']
                },
                {
                    category: 'talents',
                    label: t('recruitement_and_jobs'),
                    stakeKeys: ['recruit', 'international_job']
                },
                {
                    category: 'invest',
                    label: t('invest'),
                    stakeKeys: ['invest', 'looking_for_funds']
                }
            ] as const
    )

    function toggleStake(stakeKey: StakeKey) {
        setFieldValue(
            'stakes',
            values.stakes.includes(stakeKey)
                ? values.stakes.filter((selectedStake) => selectedStake !== stakeKey)
                : [...values.stakes, stakeKey]
        )
    }

    const onSendStakes = handleSubmit(() => sendStakes())
</script>

<template>
    <div>
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <section
                v-for="section in sections"
                :key="section.label"
                class="section"
                :class="`section--${section.category}`"
            >
                <h2 class="title-4 mb-6r">{{ section.label }}</h2>

                <div class="flex flex-col gap-4">
                    <button
                        v-for="stakeKey in section.stakeKeys"
                        :key="stakeKey"
                        type="button"
                        class="stake flex items-center gap-2 rounded-lg bg-white p-4 text-left text-sm"
                        :class="[{ 'stake--selected': values.stakes.includes(stakeKey) }, `stake--${section.category}`]"
                        @click="toggleStake(stakeKey)"
                    >
                        <MaterialIcon
                            class="stake__icon title-3"
                            :name="values.stakes.includes(stakeKey) ? 'check' : getIconName(stakeKey)"
                        />
                        {{ getWordings(stakeKey).title }}
                    </button>
                </div>
            </section>
        </div>
        <div class="mt-6">
            <FormTextArea v-if="showOtherObjectiveInput" name="otherStake">
                {{ t('share_your_objectives') }}
            </FormTextArea>
            <BaseLink v-else class="text-center" @click="showOtherObjectiveInput = true">
                {{ t('other_objectives') }}
            </BaseLink>
        </div>
        <div class="mt-8 flex justify-center">
            <BaseButton class="!px-10" :disabled="!meta.valid" :pending="isSendingStakes" @click="onSendStakes">
                {{ t('send') }}
            </BaseButton>
        </div>
    </div>
</template>

<style scoped lang="scss">
    $transition: all 100ms ease-in-out;

    .section {
        $block: &;
        @apply rounded-lg bg-legacy-10 p-6;

        &--talents {
            @apply bg-go-10;
        }

        &--invest {
            @apply bg-ray-10;
        }

        &--international {
            @apply bg-ace-10;
        }

        &--network {
            @apply bg-flagship-10;
        }
    }

    .appear {
        &-enter-active,
        &-leave-active {
            transition: $transition;
        }
        &-enter-from,
        &-leave-to {
            opacity: 0;
            scale: 0;
        }
    }

    .stake {
        $stake: &;
        transition: $transition;

        &--selected {
            @apply text-white;
            background: var(--main-color, var(--theme-color-nuage));
        }

        &--talents {
            --main-color: var(--theme-color-go);
            --light-color: var(--theme-color-go--10);
        }

        &--invest {
            --main-color: var(--theme-color-ray);
            --light-color: var(--theme-color-ray--10);
        }

        &--international {
            --main-color: var(--theme-color-ace);
            --light-color: var(--theme-color-ace--10);
        }

        &--network {
            --main-color: var(--theme-color-flagship);
            --light-color: var(--theme-color-flagship--10);
        }

        &__icon {
            color: var(--main-color);

            #{$stake}--selected & {
                color: var(--theme-color-nuage);
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "recruitement_and_jobs": "Recrutement & Offres d'emploi",
        "invest": "Investissement",
        "international": "International",
        "network": "Réseau & Échanges",
        "other_objectives": "J'ai d'autres objectifs",
        "share_your_objectives": "Partagez-nous vos objectifs",
        "share_your_objectives.placeholder": "Que recherchez-vous sur Frenchfounders ? Qu'esperez-vous retrouver ?",
        "send": "Envoyer",
        "form_sent.title": "Merci d'avoir partagé vos objectifs",
        "form_sent.text": "Vous allons pouvoir vous guider au sein de Frenchfounders."
    },
    "en": {
        "recruitement_and_jobs": "Recruitment & Jobs",
        "invest": "Investment",
        "international": "International",
        "network": "Network & Exchanges",
        "other_objectives": "I have other objectives",
        "share_your_objectives": "Share with us your objectives",
        "share_your_objectives.placeholder": "Que recherchez-vous sur Frenchfounders ? Qu'esperez-vous retrouver ?",
        "send": "Send",
        "form_sent.title": "Thank you for sharing your goals",
        "form_sent.text": "We'll guide you through Frenchfounders."
    }
}
</i18n>
