<script setup lang="ts">
    import GoogleAuth from './GoogleAuth.vue'
    import LinkedinAuth from './LinkedinAuth.vue'
    import { useI18n } from 'vue-i18n'

    defineProps<{
        type: 'login' | 'register'
        message?: string
    }>()

    defineEmits<{
        (e: 'error', { status, message }: { status: number; message: string }): void
        (e: 'pending'): void
        (e: 'success'): void
    }>()

    const { t } = useI18n()
</script>

<template>
    <div class="auth">
        <div class="auth__or">
            <div v-if="message">
                {{ message }}
            </div>
            <div v-else class="auth__or-inner">
                {{ t('or-via') }}
            </div>
        </div>

        <div class="auth__social">
            <LinkedinAuth :type="type" />
            <GoogleAuth
                :type="type"
                @error="$emit('error', $event)"
                @success="$emit('success')"
                @pending="$emit('pending')"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .auth {
        &__social {
            display: flex;
            justify-content: center;
        }
        &__or {
            position: relative;
            text-align: center;
            width: 100%;
            margin-top: 1.25em;
            margin-bottom: 0.6em;
            @media only screen and (min-height: $breakpoints-lg) {
                margin-top: 2.1em;
                margin-bottom: 1.25em;
            }
            // &::before {
            //     content: '';
            //     display: block;
            //     width: 100%;
            //     position: absolute;
            //     top: 50%;
            //     left: 0;
            //     right: 0;
            //     height: 0.06em;
            //     transform: translateY(-50%);
            //     background-color: #e0e0e0;
            // }
        }
        &__or-inner {
            position: relative;
            display: inline-block;
            background: white;
            font-size: 0.9em;
            line-height: 1em;
            color: #bdbdbd;
            padding: 0 0.6em;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "linkedin": "Linkedin logo",
        "google": "Google logo",
        "or-via": "Ou via"
    },
    "en": {
        "linkedin": "Linkedin logo",
        "google": "Google logo",
        "or-via": "Or via"
    }
}
</i18n>
