import { ref, onMounted, onBeforeUnmount, Ref, computed, ComputedRef } from 'vue'

type Breakpoints = {
    xs: boolean
    sm: boolean
    md: boolean
    lg: boolean
    xl: boolean
    xxl: boolean
}

export default function useBreakpoints(): {
    isDesktop: ComputedRef<boolean>
    breakpoints: Ref<Breakpoints>
    isMobile: ComputedRef<boolean>
} {
    // value from _vars.scss
    const breakpointRanges = {
        xs: [0, 519],
        sm: [520, 767],
        md: [768, 1023],
        lg: [1024, 1199],
        xl: [1200, 1399],
        xxl: [1400, 9999]
    }

    const triggerScreenDetection = () => {
        const keys = Object.keys(breakpointRanges)
        const bp: Breakpoints = {
            xs: false,
            sm: false,
            md: false,
            lg: false,
            xl: false,
            xxl: false
        }

        const screenWidth = window?.visualViewport?.width || window.innerWidth

        for (const key of keys) {
            const minValue = breakpointRanges[key][0]
            const maxValue = breakpointRanges[key][1]

            bp[key] = screenWidth >= minValue && screenWidth <= maxValue
        }

        return bp
    }
    const breakpoints = ref<Breakpoints>({
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false
    })

    const isDesktop = computed(() => {
        return breakpoints.value.lg || breakpoints.value.xl || breakpoints.value.xxl
    })

    const isMobile = computed(() => !isDesktop.value)

    const handleResize = () => {
        breakpoints.value = triggerScreenDetection()
    }

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize)
    })

    onMounted(() => {
        breakpoints.value = triggerScreenDetection()
        window.addEventListener('resize', handleResize)
    })

    return {
        breakpoints,
        isDesktop,
        isMobile
    }
}
