<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import { nextTick, ref, watch } from 'vue'
    import gsap from 'gsap'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import { useMutation, useQuery } from '@tanstack/vue-query'
    import { QueryName } from '@/vars/QueryAttr'
    import MemberApi from '@/libs/api/Member.api'
    import CardLinkedinProfile from '@/components/partials/network/smart-actions/action-linkedin/CardLinkedinProfile.vue'
    import { ModalSize } from '@/vars/ModalAttr'
    import { LinkedInProfile } from '@/types/response/members-api/LinkedinProfiles.type'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import { useForm } from 'vee-validate'
    import { ImportLinkedinProfileForm } from '@/types/forms/member/ImportLinkedinProfile.type'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import FormInput from '@/components/ui/forms/FormInput.vue'
    import LinkedinImportFieldsForm from '@/components/partials/network/smart-actions/action-linkedin/LinkedinImportFieldsForm.vue'

    const isModalVisible = defineModel<boolean>()

    const emit = defineEmits<{
        finished: []
    }>()

    const { t } = useI18n()
    const { analytics } = useSegment()
    const { toast } = useNotification()

    const { data: linkedinProfileResult } = useQuery({
        queryKey: [QueryName.LINKED_IN_PROFILE_SUGGESTIONS],
        queryFn: () => MemberApi.getLinkedinProfile()
    })

    const {
        values: formValues,
        setFieldValue,
        meta
    } = useForm<ImportLinkedinProfileForm>({
        initialValues: {
            url: '',
            import: {
                picture: true,
                position: true,
                company: true,
                experiences: true,
                location: true,
                shortBio: true
            }
        }
    })

    const { mutate: importProfile, isPending: isImportingProfile } = useMutation({
        mutationFn: () => MemberApi.importLinkedinProfile(formValues),
        onSuccess: () => {
            emit('finished')
            isModalVisible.value = false
            toast({
                type: ToastType.SUCCESS,
                title: t('success.imported.title'),
                text: t('success.imported.text')
            })

            analytics.track('community:onboarding:linkedin_profile:import', { form: formValues })
        },
        onError: (err) => {
            console.error(err)
        }
    })

    const hasProfile = ref(false)
    const showManualForm = ref(false)
    const profilesWrapperEl = ref<HTMLDivElement>()
    const modalContentEl = ref<HTMLDivElement>()

    watch(isModalVisible, () => {
        if (isModalVisible.value) {
            analytics.track('community:onboarding:linkedin_profile:show')
        }
    })

    watch(
        linkedinProfileResult,
        () => {
            if (!linkedinProfileResult.value?.data.profile && !linkedinProfileResult.value?.data.suggestions?.length) {
                showManualForm.value = true
            }
        },
        { immediate: true }
    )

    function initForm() {
        setFieldValue('import.picture', true)
        setFieldValue('import.position', true)
        setFieldValue('import.company', true)
        setFieldValue('import.experiences', true)
        setFieldValue('import.location', true)
        setFieldValue('import.shortBio', true)
    }

    async function onShowManualForm() {
        showManualForm.value = true
        await nextTick()
        initForm()
    }

    function prefixWithHttps() {
        if (formValues.url && !formValues.url.startsWith('http')) {
            setFieldValue('url', 'https://' + formValues.url)
        }
    }

    function onSelectProfile({ profileUrl }: LinkedInProfile) {
        if (!modalContentEl.value) {
            return null
        }

        const clickedSuggestionEl = document.querySelector<HTMLDivElement>(`[data-url="${profileUrl}"]`)
        const notClickedSuggestions = modalContentEl.value.querySelectorAll(
            `[data-linkedin-card]:not([data-url="${profileUrl}"])`
        )

        if (!notClickedSuggestions.length) {
            return null
        }

        if (clickedSuggestionEl) {
            gsap.to(clickedSuggestionEl, {
                width: clickedSuggestionEl.clientWidth,
                duration: 3
            })
        }

        const timeline = gsap.timeline({
            defaults: { ease: 'power2', duration: 0.5 },
            onComplete: () => {
                hasProfile.value = true
            }
        })

        timeline
            .to(notClickedSuggestions, {
                opacity: 0,
                duration: 0.25
            })
            .to(notClickedSuggestions, {
                padding: 0,
                margin: 0,
                height: 0
            })
            .to(
                notClickedSuggestions,
                {
                    width: 0
                },
                '-=.25'
            )

        if (profilesWrapperEl.value) {
            timeline.to(
                profilesWrapperEl.value,
                {
                    gap: 0
                },
                '<'
            )
        }

        setFieldValue('url', profileUrl)
    }
</script>

<template>
    <BaseModal v-model="isModalVisible" :size="ModalSize.LARGE">
        <template #title>{{ t('link_linkedin_profile') }}</template>
        <template #default>
            <p class="mx-auto mb-10r max-w-md text-center">{{ t('subtitle') }}</p>
            <section ref="modalContentEl">
                <h2 class="title-4 mb-4r">{{ t('select_linkedin_profile') }}</h2>
                <Transition name="appear" mode="out-in">
                    <div v-if="showManualForm">
                        <FormInput
                            type="url"
                            placeholder="https://..."
                            class="mb-4r"
                            name="url"
                            rules="required|url"
                            @blur="prefixWithHttps"
                        >
                            {{ t('link_to_profile') }}
                        </FormInput>
                        <LinkedinImportFieldsForm />
                    </div>
                    <div v-else ref="profilesWrapperEl" class="flex flex-wrap gap-2">
                        <CardLinkedinProfile
                            v-for="profile in linkedinProfileResult?.data.suggestions || []"
                            :key="profile.profileUrl"
                            class="w-[30%]"
                            :profile="profile"
                            data-linkedin-card
                            :data-url="profile.profileUrl"
                            @click="onSelectProfile"
                        />
                        <Transition name="appear" appear>
                            <LinkedinImportFieldsForm v-if="hasProfile" class="mx-4 flex-1" />
                        </Transition>
                    </div>
                </Transition>

                <BaseLink v-if="!showManualForm" class="mt-4r" @click="onShowManualForm">
                    {{ t('none_of_theses_profile_are_mine') }}
                </BaseLink>
            </section>
        </template>
        <template #footer>
            <div class="flex justify-center">
                <BaseButton :pending="isImportingProfile" :disabled="!meta.valid" @click="importProfile">
                    {{ t('import_infos') }}
                </BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<style scoped lang="scss">
    .appear {
        &-enter-active,
        &-leave-active {
            transition: all 350ms ease-in-out;
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
            translate: 0 5%;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "link_linkedin_profile": "Liez votre compte Linkedin",
        "subtitle": "En liant votre compte Linkedin nous serons en mesure d’intégrer vos expériences, votre photo ou votre situation actuelle.",
        "select_linkedin_profile": "Sélectionnez votre profil Linkedin",
        "select_linkedin_profile_in_list": "Sélectionnez votre profil Linkedin dans la liste",
        "import_infos": "Importer mes informations",
        "link_to_profile": "Lien vers votre profil LinkedIn",
        "none_of_theses_profile_are_mine": "Aucun de ces profils n’est le mien",
        "success": {
            "imported": {
                "title": "Profil en cours d'import",
                "text": "Nous importons les données de votre profil LinkedIn que vous pourrez retrouver dans votre profil Frenchfounders."
            }
        }
    }
}
</i18n>
