<script setup lang="ts">
    import BaseLabel from '@/components/ui/forms/FormLabel.vue'
    import { Option } from '@/libs/helpers/form'
    import { useField } from 'vee-validate'
    import { computed, useAttrs, watch } from 'vue'
    import { SchemaOptions } from 'yup/lib/schema'
    import FormError from './FormError.vue'
    import FormMultiselect from './FormFieldPartials/FormMultiselect.vue'
    import FormSelect from './FormFieldPartials/FormSelect.vue'
    import FormInput from './FormFieldPartials/FormInput.vue'
    import FormTextArea from './FormFieldPartials/FormTextArea.vue'
    import { CommonProps } from '@/types/FormField.type'

    export type Suffix = {
        id: string
        name: string
    }

    type ModelValue = string | number | string[] | null

    const attrs = useAttrs()
    const props = withDefaults(
        defineProps<{
            name: string
            inline?: boolean
            rules?: string | Record<string, unknown> | SchemaOptions<unknown>
            type?: 'textarea' | 'tel' | string
            modelValue?: ModelValue
            error?: string
            phonePrefix?: string
            hideLabel?: boolean
            inlineLabels?: boolean
            options?: Option[]
            multiple?: boolean
            group?: boolean
            search?: boolean
            taggable?: boolean
            suffix?: string
            suffixLabel?: string
            suffixOptions?: Suffix[]
        }>(),
        {
            type: 'text',
            rules: undefined,
            modelValue: undefined,
            error: '',
            phonePrefix: '+358',
            taggableKeySeparator: '',
            options: () => [],
            suffixOptions: () => [] as Suffix[],
            suffix: '',
            suffixLabel: ''
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
        (e: 'update:phone-prefix', value: string): void
        (e: 'update:suffix', value: string): void
    }>()

    const { errorMessage, handleChange, handleBlur, value, meta } = useField(props.name, props.rules, {
        initialValue: props.modelValue,
        syncVModel: true
    })

    watch(
        () => props.modelValue,
        (value) => {
            handleChange(value)
        }
    )

    const isRequired = computed(() => {
        if (!props.rules) return false

        if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        }
        return Object.keys(props.rules).includes('required')
    })

    const commonProps = computed<CommonProps>(() => ({
        name: props.name,
        modelValue: props.modelValue,
        error: props.error,
        handleChange: handleChange,
        handleBlur: handleBlur,
        errorMessage: errorMessage.value,
        value: value.value,
        meta: meta
    }))
</script>

<template>
    <div
        class="field"
        :class="{
            'field--inline': inline,
            'field--inline-labels': inlineLabels,
            'field--disabled': attrs.disabled || attrs.disabled === ''
        }"
    >
        <div class="field__inner">
            <div
                v-if="$slots.default || $slots.help || $slots.hint"
                class="field__labels"
                :class="{ 'field__labels--hint': $slots.hint }"
            >
                <BaseLabel v-show="!hideLabel" :required="isRequired">
                    <slot></slot>
                </BaseLabel>
                <div v-if="$slots.hint" class="field__hint">
                    <slot name="hint"></slot>
                </div>
                <BaseLabel v-if="$slots.help" class="field__help" type="help">
                    <slot name="help"></slot>
                </BaseLabel>
            </div>
            <FormTextArea
                v-if="type === 'textarea'"
                v-bind="{ ...commonProps, ...attrs }"
                @update:model-value="$emit('update:modelValue', $event)"
            />
            <FormSelect
                v-else-if="type === 'select'"
                v-bind="{ ...commonProps, ...attrs }"
                @update:model-value="emit('update:modelValue', value)"
            >
                <template #options>
                    <slot name="options"> </slot>
                </template>
            </FormSelect>
            <FormMultiselect
                v-else-if="type === 'multiselect'"
                v-bind="{ ...commonProps, ...attrs }"
                :taggable="taggable"
                :options="options"
                :multiple="multiple"
                :group="group"
                :search="search"
                @update:model-value="$emit('update:modelValue', $event)"
            />
            <div
                v-else
                class="field__input-wrapper"
                :class="{ 'field__input-wrapper--disabled': attrs.disabled || attrs.disabled === '' }"
            >
                <FormInput
                    v-bind="{ ...commonProps, ...attrs }"
                    :type="type"
                    :phone-prefix="phonePrefix"
                    :suffix="suffix"
                    :suffix-label="suffixLabel"
                    :suffix-options="suffixOptions"
                    @update:suffix="$emit('update:suffix', $event)"
                    @update:model-value="$emit('update:modelValue', $event)"
                    @update:phone-prefix="$emit('update:phone-prefix', $event)"
                />
            </div>
        </div>
        <div v-if="$slots.description" class="field__description">
            <slot name="description"></slot>
        </div>
        <FormError v-if="error || (errorMessage && meta.touched)">
            {{ error || errorMessage }}
        </FormError>
    </div>
</template>

<style lang="scss" scoped>
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;

    .field {
        $block-selector: &;
        position: relative;

        &__labels {
            margin-bottom: 0.25em;

            #{$block-selector}--inline-labels & {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }

            #{$block-selector}--inline & {
                margin-right: 0.625em;
                margin-bottom: 0;
                flex-shrink: 0;
            }

            &--hint {
                display: flex;
                gap: 0.2em;
                align-items: center;
            }
        }

        &__description {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            color: var(--theme-text-color--secondary);
            font-size: 0.625em;
        }

        &__inner {
            #{$block-selector}--inline & {
                display: flex;
                align-items: center;
            }
        }

        &__help {
            margin: 0.5em 0;

            #{$block-selector}--inline-labels & {
                margin: 0;
            }
        }

        &__input-wrapper {
            display: flex;
            align-items: stretch;
            color: var(--theme-text-color--secondary);
            border-radius: 0.375em;

            #{$block-selector}--inline & {
                flex: 1;
            }

            &--disabled {
                background-color: var(--theme-color-gray--05);
            }
        }

        &__hint {
            color: var(--theme-text-color--third);
            font-size: 0.625em;
        }
    }
</style>
