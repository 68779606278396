import { Price } from '@/types/Price.type'

export type ClubState = {
    apply?: {
        status: ApplyStatus
        batchDate: string
        plan: string
        createdAt: string
        currency: string
    }
    membership?: {
        canRenew: boolean
        canApply: boolean
    } & (RunningMembership | FutureMembership | LostMembership | ShortLostMembership | PausedMembership)
}

export enum StatusMembership {
    FUTURE = 'future', // Le membership n'a pas encore démarré et il n'est plus lost car il a fait un nouvel apply
    RUNNING = 'running', // Abonnement en cours
    SHORT_LOST = 'short-lost', // Abonnement lost depuis moins de 30 jours
    LOST = 'lost', // Abonnement lost depuis plus de 30 jours
    PAUSED = 'paused' // Abonnement en pause ( considérer comme lost )
}

export enum ApplyStatus {
    NEW = 'new', // Apply commencé mais pas terminé
    WAITING_VALIDATION = 'waiting_for_validation',
    DONE = 'done', // Validé pour enter dans le club
    REFUSED = 'refused',
    PAYMENT_FAIL = 'payment_failed'
}

type RunningMembership = {
    startDate: string
    status: StatusMembership.RUNNING
    endDate: string
    price: Price
    subscriptionPausedDate: string
}
type FutureMembership = {
    status: StatusMembership.FUTURE
}

type LostMembership = {
    status: StatusMembership.LOST
    endDate: string
    canApplyAt: string
}

type ShortLostMembership = {
    status: StatusMembership.SHORT_LOST
    endDate: string
    canRenewUntil: string
}

type PausedMembership = {
    status: StatusMembership.PAUSED
}
