<script setup lang="ts">
    import { computed } from 'vue'
    import usePasswordSecurity from '@/libs/compositions/usePasswordSecurity'
    import MaterialIcon from '../icons/MaterialIcon.vue'
    import OverlayPopover from '@/components/ui/overlays/OverlayPopover.vue'
    import { VerticalPosition, HorizontalPosition, PopoverTheme } from '@/types/Popover.type'
    import { useI18n } from 'vue-i18n'

    const props = defineProps<{
        password: string
    }>()

    const pass = computed(() => {
        return props.password
    })
    const { level, percentageSecurity, lengthRule, containsSpecialRule, containsNumberRule } = usePasswordSecurity(pass)
    const { t } = useI18n()

    const textInformation = computed(() => {
        if (level.value === 'strong') {
            return t('strong')
        } else if (level.value === 'medium') {
            return t('medium')
        } else {
            return t('weak')
        }
    })
</script>

<template>
    <div class="security-bar">
        <div class="security-bar__content">
            <div class="security-bar__progress">
                <div
                    class="security-bar__progress-inner"
                    :class="{
                        'security-bar__progress-inner--ok': level === 'strong',
                        'security-bar__progress-inner--ray': level === 'medium',
                        'security-bar__progress-inner--error': level === 'low'
                    }"
                    :style="{ width: `${percentageSecurity}%` }"
                />
            </div>
            <OverlayPopover
                hover-trigger
                hide-action
                :position="[VerticalPosition.BOTTOM, HorizontalPosition.CENTER]"
                class="max-w-4"
                :theme="PopoverTheme.DARK"
            >
                <template #default>
                    <MaterialIcon name="info" class="security-bar__info" />
                </template>
                <template #title>{{ t('popover.title') }}</template>
                <template #text>
                    <ul class="p-2">
                        <li>
                            <MaterialIcon v-if="lengthRule" name="done" />
                            <MaterialIcon v-else name="close" />
                            {{ t('popover.rules.length') }}
                        </li>
                        <li class="mt-2">
                            <MaterialIcon v-if="containsSpecialRule" name="done" />
                            <MaterialIcon v-else name="close" />
                            {{ t('popover.rules.special') }}
                        </li>
                        <li class="mt-2">
                            <MaterialIcon v-if="containsNumberRule" name="done" />
                            <MaterialIcon v-else name="close" />
                            {{ t('popover.rules.number') }}
                        </li>
                    </ul>
                </template>
            </OverlayPopover>
        </div>

        <div v-if="password" class="text-xs">{{ textInformation }}</div>
    </div>
</template>

<style scoped lang="scss">
    .security-bar {
        &__content {
            display: flex;
            gap: var(--scale-2);
            align-items: center;
        }

        &__progress {
            position: relative;
            display: block;
            height: 0.7em;
            width: 100%;
            border-radius: 99em;
            overflow: hidden;
            padding: 0.125em;
            display: flex;
            font-size: 1em;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--theme-primary-gradient);
                opacity: 0.07;
            }
        }
        &__progress-inner {
            position: relative;
            display: block;
            background: var(--theme-primary-gradient);
            border-radius: 99em;
            transition: width 350ms;

            &--ok {
                background-color: var(--theme-color-ok);
            }
            &--error {
                background-color: var(--theme-color-error);
            }
            &--ray {
                background-color: var(--theme-color-ray);
            }
        }

        &__info {
            cursor: pointer;
            max-width: 1em;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "strong": "Votre mot de passe est sécurisé",
        "medium": "Votre mot de passe n'est pas assez sécurisé",
        "weak": "Votre mot de passe est trop faible",
        "popover": {
            "title": "Protection des mots de passe",
            "rules": {
                "length": "Votre mot de passse doit contenir 10 caractères minimum.",
                "special": "Votre mot de passe doit contenir au minimum 1 caractère spécial.",
                "number": "(optionnel) Contenir au minimum 1 chiffre."
            }
        }
    },
    "en": {
        "strong": "Your password is secure",
        "medium": "Your password is not secure enough",
        "weak": "Your password is too weak",
        "popover": {
            "title": "Protection des mots de passe",
            "rules": {
                "length": "Your password must contain 10 characters minimum.",
                "special": "Your password must contain at least 1 special character.",
                "number": "(optional) Contain at least 1 figure."
            }
        }
    }
}
</i18n>
