<script setup lang="ts">
    withDefaults(
        defineProps<{
            count: number
        }>(),
        {
            count: 20
        }
    )

    const getStyle = () => {
        return {
            top: 0,
            left: Math.floor(Math.random() * (400 - -400) + -400) + 'px',
            animationDelay: Math.random() * (0.8 - 0.2) + 0.2 + 's',
            animationDuration: Math.floor(Math.random() * (10 - 2) + 2) + 's'
        }
    }
</script>

<template>
    <div class="relative overflow-hidden">
        <slot />
        <div v-for="meteor in count" :key="meteor" class="animate-meteor-effect meteor" :style="getStyle()"></div>
    </div>
</template>

<style scoped lang="scss">
    .animate-meteor-effect {
        animation: meteor 5s linear infinite;
    }

    .meteor {
        @apply absolute left-1/2 top-1/2 h-0.5 w-0.5 rotate-[215deg] rounded-[9999px] bg-gray-500 shadow-[0_0_0_1px_#ffffff10] before:absolute before:top-1/2 before:h-[1px] before:w-[50px] before:-translate-y-[50%] before:transform before:bg-gradient-to-r before:from-[#64748b] before:to-transparent before:content-[''];
    }

    @keyframes meteor {
        0% {
            transform: rotate(215deg) translateX(0);
            opacity: 1;
        }

        70% {
            opacity: 1;
        }
        100% {
            transform: rotate(215deg) translateX(-500px);
            opacity: 0;
        }
    }
</style>
