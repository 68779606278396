import { defineRule, configure } from 'vee-validate'
import { email, max, min, required, url, regex, confirmed, min_value, max_value } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'

const en = {
    code: 'en',
    messages: {
        _default: 'The is not valid',
        alpha: 'The field may only contain alphabetic characters',
        alpha_num: 'The field may only contain alpha-numeric characters',
        alpha_dash: 'The field may contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'The field may only contain alphabetic characters as well as spaces',
        between: 'The field must be between 0:{min} and 1:{max}',
        confirmed: 'The field confirmation does not match',
        digits: 'The field must be numeric and exactly contain 0:{length} digits',
        dimensions: 'The field must be 0:{width} pixels by 1:{height} pixels',
        email: 'The field must be a valid email',
        excluded: 'The field is not a valid value',
        ext: 'The field is not a valid file',
        image: 'The field must be an image',
        integer: 'The field must be an integer',
        length: 'The field must be 0:{length} long',
        max_value: 'The field must be 0:{max} or less',
        max: 'The field may not be greater than 0:{length} characters',
        mimes: 'The field must have a valid file type',
        min_value: 'The field must be 0:{min} or more',
        min: 'The field must be at least 0:{length} characters',
        numeric: 'The field may only contain numeric characters',
        one_of: 'The field is not a valid value',
        regex: 'The field format is invalid',
        required_if: 'The field is required',
        required: 'The field is required',
        size: 'The field size must be less than 0:{size}KB',
        url: 'The field is not a valid URL'
    }
}

const fr = {
    code: 'fr',
    messages: {
        _default: 'Le champ est invalide',
        alpha: 'Le champ ne peut contenir que des lettres',
        alpha_num: 'Le champ ne peut contenir que des caractères alpha-numériques',
        alpha_dash: 'Le champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés',
        alpha_spaces: 'Le champ ne peut contenir que des lettres ou des espaces',
        between: 'Le champ doit être compris entre 0:{min} et 1:{max}',
        confirmed: 'Le champ ne correspond pas',
        digits: 'Le champ doit être un nombre entier de 0:{length} chiffres',
        dimensions: 'Le champ doit avoir une taille de 0:{width} pixels par 1:{height} pixels',
        email: 'Le champ doit être une adresse e-mail valide',
        excluded: 'Le champ doit être une valeur valide',
        ext: 'Le champ doit être un fichier valide',
        image: 'Le champ doit être une image',
        integer: 'Le champ doit être un entier',
        length: 'Le champ doit contenir 0:{length} caractères',
        max_value: 'Le champ doit avoir une valeur de 0:{max} ou moins',
        max: 'Le champ ne peut pas contenir plus de 0:{length} caractères',
        mimes: 'Le champ doit avoir un type MIME valide',
        min_value: 'Le champ doit avoir une valeur de 0:{min} ou plus',
        min: 'Le champ doit contenir au minimum 0:{length} caractères',
        numeric: 'Le champ ne peut contenir que des chiffres',
        one_of: 'Le champ doit être une valeur valide',
        regex: 'Le champ est invalide',
        required: 'Le champ est obligatoire',
        required_if: 'Le champ est obligatoire lorsque {target} possède cette valeur',
        size: 'Le champ doit avoir un poids inférieur à 0:{size}KB'
    }
}

// Vee-validate rules
defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('max', max)
defineRule('url', url)
defineRule('regex', regex)
defineRule('min_value', min_value)
defineRule('max_value', max_value)
defineRule('confirmed', confirmed)

configure({
    generateMessage: localize({
        en: {
            messages: {
                ...en.messages
            }
        },
        fr: {
            messages: {
                ...fr.messages
            }
        }
    })
})

setLocale('fr')
