<script setup lang="ts">
    import { v4 as uuid4 } from 'uuid'
    import BaseLabel from '@/components/ui/forms/FormLabel.vue'
    import { useField } from 'vee-validate'
    import { computed, useAttrs, watch } from 'vue'
    import { SchemaOptions } from 'yup/lib/schema'
    import FormError from './FormError.vue'

    const attrs = useAttrs()

    type ModelValue = string | string[] | null

    const props = withDefaults(
        defineProps<{
            name?: string
            inline?: boolean
            rules?: string | Record<string, unknown> | SchemaOptions<unknown>
            modelValue?: ModelValue
            error?: string
            hideLabel?: boolean
            inlineLabels?: boolean
            maxlength?: number
            testId?: string
            disabled?: boolean
        }>(),
        {
            rules: undefined,
            modelValue: undefined,
            error: ''
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
    }>()

    const { errorMessage, handleChange, handleBlur, value, meta } = useField(props.name || uuid4(), props.rules, {
        initialValue: props.modelValue,
        syncVModel: true
    })

    watch(
        () => props.modelValue,
        (value) => {
            handleChange(value)
        }
    )

    const isRequired = computed(() => {
        if (!props.rules) return false

        if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        }
        return Object.keys(props.rules).includes('required')
    })

    const onInputChange = (e: Event) => {
        const value: string | null = (e.target as HTMLInputElement).value
        handleChange(value)
        emit('update:modelValue', value)
    }
</script>

<template>
    <div
        :data-field-error="error || errorMessage || undefined"
        class="field"
        :class="{
            'field--inline': inline,
            'field--inline-labels': inlineLabels,
            'field--disabled': attrs.disabled || attrs.disabled === ''
        }"
    >
        <div class="field__inner">
            <div v-if="$slots.default || $slots.help" class="field__labels">
                <BaseLabel v-show="!hideLabel" :required="isRequired">
                    <slot></slot>
                </BaseLabel>
                <BaseLabel v-if="$slots.help" class="field__help text-xs" type="help">
                    <slot name="help"></slot>
                </BaseLabel>
            </div>
            <textarea
                :disabled="disabled"
                :name="name"
                class="field__input"
                :class="{
                    'field__input--error': error || (errorMessage && meta.touched),
                    'cursor-not-allowed': disabled
                }"
                v-bind="attrs"
                :value="value as string"
                :data-testId="testId"
                @input="onInputChange"
                @blur="handleBlur"
            ></textarea>
        </div>
        <div v-if="$slots.description || maxlength" class="field__description text-xs">
            <slot v-if="maxlength" name="description">
                <span :class="{ 'field__description-error': value && value?.length > maxlength }">
                    {{ value ? value.length : '0' }}/{{ maxlength }}
                </span>
            </slot>
            <slot v-else name="description"></slot>
        </div>
        <FormError v-if="error || (errorMessage && meta.touched)">
            {{ error || errorMessage }}
        </FormError>
    </div>
</template>

<style lang="scss" scoped>
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;

    .field {
        $block-selector: &;
        position: relative;

        &__labels {
            margin-bottom: 0.25em;

            #{$block-selector}--inline-labels & {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }

            #{$block-selector}--inline & {
                margin-right: 0.625em;
                margin-bottom: 0;
                flex-shrink: 0;
            }
        }

        &__description {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            color: var(--theme-color-gray--03);
        }

        &__description-error {
            color: var(--theme-color-error);
        }

        &__inner {
            #{$block-selector}--inline & {
                display: flex;
                align-items: center;
            }
        }

        &__help {
            margin: 0.5em 0;
            color: var(--theme-color-gray--03);

            #{$block-selector}--inline-labels & {
                margin: 0;
            }
        }

        &__input {
            $placeholderColor: var(--theme-color-gray--03);

            color: var(--theme-text-color);
            width: 100%;
            max-width: 100%;
            font-size: 0.875em;
            border-radius: $inputRadius;
            padding: 0.7em;
            border: solid 1px var(--theme-color-gray--04);
            outline: none;
            background-color: var(--theme-background-color);
            line-height: 1.3em;
            resize: vertical;
            min-height: 2.8em;

            &:focus {
                border-color: var(--theme-text-color);
            }

            &--error {
                border-color: var(--theme-error-color);
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $placeholderColor;
                font-weight: 300;
                opacity: 1; /* Firefox */
            }

            #{$block-selector}--disabled & {
                opacity: 0.5;
                cursor: not-allowed;
            }

            @media (hover: hover) {
                &[type='date']::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }
            }
        }
    }
</style>
