/**
 * Stringify toutes les locations pour les envoyer à l'API.
 * Se base sur le fait qu'un objet contiene "formatted_address"
 */
export function stringifyLocations(object: Record<string, unknown>) {
    try {
        if (!object || typeof object !== 'object') {
            return object
        }

        // Copie de l'objet pour éviter de le modifier directement
        object = JSON.parse(JSON.stringify(object))

        if (object && object['formatted_address']) {
            return JSON.stringify(object)
        }

        for (const [key, value] of Object.entries(object)) {
            if (value && typeof value === 'object') {
                if (Array.isArray(value)) {
                    object[key] = value.map(stringifyLocations)
                } else if (value && value['formatted_address']) {
                    object[key] = JSON.stringify(value)
                } else if (value) {
                    object[key] = stringifyLocations(value as Record<string, unknown>)
                }
            }
        }

        return object
    } catch (error) {
        console.error('Error in stringifyLocations:', error)
        return object
    }
}
