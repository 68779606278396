import { Ref } from 'vue'

export default function useCssColor(el: Ref<HTMLElement | null>) {
    const getCssColorValue = (cssVar: string) => {
        if (!el.value) {
            return ''
        }

        return getComputedStyle(el.value).getPropertyValue(cssVar).trim()
    }

    return {
        getCssColorValue
    }
}
