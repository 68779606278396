import { Job } from '@/types/Job.type'
import { MetaPager, ResponseV3 } from '@/types/response/ResponseV3.type'
import { QueryName } from '@/vars/QueryAttr'
import { RouteName } from '@/vars/RouteName'
import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/vue-query'
import { AxiosError } from 'axios'
import JobsApi from '../api/Jobs.api'

export enum JobList {
    SELECTED = 'selected',
    APPLIED = 'applied',
    PROCESSING = 'processing',
    PROCESSED = 'processed',
    DECLINED = 'declined'
}

export type QueryType = ResponseV3<Job[], MetaPager>

const fetchJobs = (jobListType: JobList, { pageParam = 1, ...opt }) => {
    return JobsApi.getJobs({
        limit: opt.limit || undefined,
        page: pageParam,
        status: jobListType
    })
}

export function getJobStatusForRouteName(routeName: RouteName) {
    switch (routeName) {
        case RouteName.JOB_LIST_PENDING:
            return JobList.APPLIED
        case RouteName.JOB_LIST_ACCEPTED:
            return JobList.PROCESSING
        case RouteName.JOB_LIST_PROCESSED:
            return JobList.PROCESSED
        case RouteName.JOB_LIST_DECLINED:
            return JobList.DECLINED
        case RouteName.JOB_LIST_SELECTION:
        default:
            return JobList.SELECTED
    }
}

export default function useJobInfiniteQuery(
    jobListType: JobList,
    anonymous = false,
    opts: Omit<
        UseInfiniteQueryOptions<QueryType, AxiosError, QueryType, (QueryName | JobList | string)[]>,
        'queryFn' | 'queryKey' | 'getNextPageParam'
    > = {}
) {
    return useInfiniteQuery({
        ...opts,
        queryKey: [QueryName.JOBS_LIST, jobListType, anonymous ? 'anonymous' : 'completed'],
        queryFn: (opt) =>
            fetchJobs(
                jobListType,
                anonymous
                    ? {
                          ...opt,
                          limit: 5
                      }
                    : opt
            ),
        getNextPageParam: (lastPage) => lastPage?.meta?.pager?.nextPage
    })
}
