<script setup lang="ts">
    import { computed, type Component } from 'vue'
    import { SpaName } from '@/vars/SpaAttr'
    import { RouteLocation, RouteLocationNamedRaw } from 'vue-router'
    import SpaRouterLink from '../../navigations/SpaRouterLink.vue'

    export type CardTheme = 'go' | 'ace' | 'flagship' | 'default'

    const spaRunning = import.meta.env.VITE_SPA_RUNNING

    const props = withDefaults(
        defineProps<{
            tag?: string | Component
            shadowless?: boolean
            important?: boolean
            reverse?: boolean
            bordered?: boolean
            theme?: CardTheme
            to?: RouteLocation | RouteLocationNamedRaw
        }>(),
        {
            theme: 'default',
            to: undefined
        }
    )

    defineEmits<{
        (event: 'click', value: unknown): void
    }>()

    const determinedTag = computed(() => {
        if (props.tag) {
            return props.tag
        }

        if (props.to) {
            return SpaRouterLink
        }

        return 'div'
    })
</script>

<template>
    <Component
        :is="determinedTag"
        class="card"
        :class="{
            shadow: !shadowless && spaRunning !== SpaName.LECLUB,
            'card--shadow': !shadowless && spaRunning === SpaName.LECLUB,
            'card--important': important,
            'card--reverse': reverse,
            'card--bordered': bordered,
            [`card--${theme}`]: true
        }"
        :to="to"
        @click="$emit('click', $event)"
    >
        <slot></slot>
    </Component>
</template>

<style scoped lang="scss">
    .card {
        background-color: var(--theme-background-color);
        color: var(--theme-text-color);
        border-radius: 0.5em;

        &--shadow {
            box-shadow: 0 0 0.1875em var(--theme-shadow-color);
        }

        &--important {
            background-color: var(--theme-background-color--important);
        }

        &--reverse {
            background-color: var(--theme-text-color);
            color: var(--theme-background-color);
        }

        &--bordered {
            border: solid var(--scale-px) var(--theme-border-color);
            box-shadow: none;
        }

        &--go {
            border: solid var(--scale-px) var(--theme-color-go);
            box-shadow: none;
        }

        &--ace {
            border: solid var(--scale-px) var(--theme-color-ace);
            box-shadow: none;
        }

        &--flagship {
            border: solid var(--scale-px) var(--theme-color-flagship);
            box-shadow: none;
        }
    }
</style>
