import gsap from 'gsap'
import { ref } from 'vue'

export default function useAnimation() {
    const isOpening = ref(false)
    const isClosing = ref(false)

    const fadeInUp = (elements: gsap.TweenTarget, duration = 1.5, stagger = 0.3) => {
        gsap.from(elements, {
            opacity: 0,
            y: 100,
            duration,
            stagger,
            ease: 'power4',
            overflow: 'hidden'
        })
    }

    const open = (element: gsap.TweenTarget, duration = 1) => {
        if (isClosing.value) {
            gsap.killTweensOf(element)
            isClosing.value = false
        }

        if (!isOpening.value) {
            isOpening.value = true
            gsap.fromTo(
                element,
                {
                    opacity: 0,
                    height: 0,
                    duration,
                    ease: 'power4',
                    overflow: 'hidden'
                },
                {
                    opacity: 1,
                    height: 'auto',
                    duration,
                    ease: 'power4',
                    onComplete: () => {
                        gsap.set(element, { overflow: 'visible' })
                        isOpening.value = false
                    }
                }
            )
        }
    }

    const close = (element: gsap.TweenTarget, duration = 1.5) => {
        return new Promise((resolve) => {
            if (isOpening.value) {
                gsap.killTweensOf(element)
                isOpening.value = false
            }

            if (!isClosing.value) {
                isClosing.value = true
                gsap.to(element, {
                    opacity: 0,
                    height: 0,
                    duration,
                    ease: 'power4',
                    overflow: 'hidden',
                    padding: 0,
                    margin: 0,
                    onComplete: () => {
                        isClosing.value = false
                        resolve(true)
                    }
                })
            }
        })
    }

    return {
        fadeInUp,
        open,
        close
    }
}
