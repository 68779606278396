<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import lottie, { AnimationItem } from 'lottie-web'

    const props = withDefaults(
        defineProps<{
            loop?: boolean
            autoplay?: boolean
            path: string
            delay?: number
            renderer?: 'svg'
        }>(),
        {
            renderer: 'svg',
            delay: 0
        }
    )

    const emit = defineEmits<{
        completed: []
    }>()

    const player = ref<HTMLDivElement | null>(null)
    const animation = ref<AnimationItem | null>(null)

    onMounted(() => {
        if (!player.value) {
            console.warn('No container found for lottie player')
            return null
        }
        animation.value = lottie.loadAnimation({
            container: player.value, // the dom element that will contain the animation
            renderer: props.renderer,
            loop: props.loop,
            autoplay: false, // Autoplay à false pour permettre
            path: props.path // the path to the animation json
        })

        animation.value.addEventListener('complete', () => {
            emit('completed')
        })

        if (props.autoplay && animation.value) {
            setTimeout(() => {
                animation.value?.play?.()
            }, props.delay)
        }
    })

    const play = () => {
        animation.value?.play?.()
    }

    defineExpose({
        play
    })
</script>

<template>
    <div ref="player"></div>
</template>
