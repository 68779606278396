export enum AuthAttr {
    JWT_TOKEN = 'jwtToken',
    USER_ME = 'user_me',
    ORIGINAL_TOKEN = 'originalToken'
}

export enum RoleName {
    ADMIN = 'ROLE_ADMIN',
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    TESTER = 'ROLE_TESTER',
    COMMUNITY = 'ROLE_BL_COMMUNITY',
    LECLUB = 'ROLE_BL_LECLUB',
    LECLUB_EXPERT = 'ROLE_BL_LECLUB_EXPERT',
    LECLUB_EXPERT_LOST = 'ROLE_BL_LECLUB_EXPERT_LOST',
    LECLUB_PARTNER = 'ROLE_BL_LECLUB_PARTNER',
    LECLUB_PARTNER_CONTACT = 'ROLE_BL_LECLUB_PARTNER_CONTACT',
    LECLUB_PARTNER_LOST = 'ROLE_BL_LECLUB_PARTNER_LOST',
    LECLUB_PROXIMITY = 'ROLE_BL_LECLUB_PROXIMITY',
    LECLUB_WORLDWIDE = 'ROLE_BL_LECLUB_WORLDWIDE',
    LECLUB_LOST = 'ROLE_BL_LECLUB_LOST',
    LESTALENTS = 'ROLE_BL_LESTALENTS',
    LESTALENTS_LOST = 'ROLE_BL_LESTALENTS_LOST',
    INVESTORSCLUB = 'ROLE_BL_IC',
    INVESTORSCLUB_LOST = 'ROLE_BL_IC_LOST',
    RETAIL = 'ROLE_BL_RETAIL',
    RETAIL_LOST = 'ROLE_BL_RETAIL_LOST',
    LEFONDS = 'ROLE_BL_LEFONDS',
    LEFONDS_LOST = 'ROLE_BL_LEFONDS_LOST',
    LESSUMMITS = 'ROLE_BL_LESSUMMITS',
    LESSUMMITS_LOST = 'ROLE_BL_LESSUMMITS_LOST',
    BUSINESS_SOLUTIONS = 'ROLE_BL_BUSINESS_SOLUTIONS',
    BUSINESS_SOLUTIONS_LOST = 'ROLE_BL_BUSINESS_SOLUTIONS_LOST',
    ROLE_TEAM_OWNER = 'ROLE_TEAM_OWNER',
    ROLE_PREVIOUS_ADMIN = 'IS_IMPERSONATOR',
    FEATURE_WORKSPACE = 'ROLE_FEATURE_WORKSPACE',
    FEATURE_TRIP_PLANNER = 'ROLE_FEATURE_TRIPPLANNER',
    FEATURE_MER_AS_ADMIN = 'ROLE_FEATURE_MER_AS_ADMIN',
    FEATURE_FUTURE_LEADERS_PASS = 'ROLE_FEATURE_FUTURE_LEADERS_PASS'
}

export enum BusinessLineName {
    LECLUB = 'LECLUB', // membre leclub
    NETWORK = 'COMMUNITY', // membre network (qui a fini son onboarding
    BUSINESS = 'BS', // a une team pour accéder à Business Solution
    IC = 'IC', // a un membership IC
    POOL = 'POOL' // accepte de recevoir des propositions de jobs (optin via le contrat d'engagement ou profil candidat)
}
