<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import BaseButton from '@/components/ui/actions/BaseButton.v2.vue'
    import useClubStateQuery from '@/libs/queries/useClubStateQuery'
    import { DateTime } from 'luxon'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import { formatDate } from '@/libs/helpers/dates'
    import SharedStorage from '@/libs/core/SharedStorage'
    import { useSegment } from '@/libs/vendors/segment/segment'
    import { StorageKey } from '@/vars/StorageAttr'
    import { RouteName } from '@/vars/RouteName'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { StatusMembership } from '@/types/response/members-api/ClubState.type'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'

    const DAYS_BEFORE_LAST_REMINDER = 10
    const ONE_YEAR_IN_DAYS = 365
    const DAYS_UNTIL_NEXT_REMINDER = 10

    const { t } = useI18n()
    const authStore = useAuthStore()
    const { analytics } = useSegment()
    const { data: clubState } = useClubStateQuery()
    const { navigate } = useNavigationResolver()

    const isModalVisible = ref(false)

    const daysBeforeUserCannotRenew = computed(() => {
        return clubState.value?.data.membership?.status === StatusMembership.SHORT_LOST
            ? Math.abs(
                  Math.floor(DateTime.fromISO(clubState.value.data.membership.canRenewUntil).diffNow(['days']).days)
              )
            : 0
    })

    const isLastReminder = computed(() => {
        return daysBeforeUserCannotRenew.value > 0 && daysBeforeUserCannotRenew.value < DAYS_BEFORE_LAST_REMINDER
    })

    watch([clubState], () => {
        if (
            authStore.isLoggedIn &&
            clubState.value?.data?.membership?.status === StatusMembership.SHORT_LOST &&
            clubState.value?.data?.membership?.canRenew
        ) {
            const hasReminderAlreadyBeenDisplayedRecently = !!SharedStorage.get(StorageKey.LOST_REMINDER)
            isModalVisible.value = !hasReminderAlreadyBeenDisplayedRecently
        }
    })

    const onRemindMeLater = (isLastReminder = false) => {
        analytics.track(isLastReminder ? 'global:club_lost_modal:no_thanks' : 'global:club_lost_modal:remind_me', {
            daysLeft: daysBeforeUserCannotRenew.value
        })

        SharedStorage.set(
            StorageKey.LOST_REMINDER,
            { lastSkipReminderDate: new Date().toISOString() },
            isLastReminder ? ONE_YEAR_IN_DAYS : DAYS_UNTIL_NEXT_REMINDER
        )
        isModalVisible.value = false
    }

    const onRenew = () => {
        analytics.track('global:club_lost_modal:renew', { daysLeft: daysBeforeUserCannotRenew.value })
        navigate({ name: RouteName.ACCOUNT_MEMBERSHIP })
        isModalVisible.value = false
    }
</script>

<template>
    <BaseModal v-model="isModalVisible" class="modal">
        <template #title><span v-safe-html="t('title', { count: daysBeforeUserCannotRenew })"></span></template>
        <template #default>
            <div class="modal__content">
                <p
                    v-safe-html="
                        t(isLastReminder ? 'description.last_reminder' : 'description', {
                            renewalDate:
                                clubState?.data.membership && 'canRenewUntil' in clubState.data.membership
                                    ? formatDate(clubState.data.membership.canRenewUntil)
                                    : ''
                        })
                    "
                    class="mb-10r"
                ></p>
                <div class="modal__footer">
                    <BaseLink v-if="isLastReminder" class="modal__link" @click="onRemindMeLater(true)">
                        {{ t('no_thanks') }}
                    </BaseLink>
                    <BaseLink v-else class="modal__link" test-id="warning-lost-remind-later" @click="onRemindMeLater">
                        {{ t('remind_me_later') }}
                    </BaseLink>
                    <BaseButton
                        :theme="ButtonTheme.SECONDARY"
                        :to="{ name: RouteName.ACCOUNT_MEMBERSHIP }"
                        @click="onRenew"
                    >
                        {{ t('renew') }}
                    </BaseButton>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<style scoped lang="scss">
    .modal {
        &__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--scale-8r);
        }

        &__link {
            font-weight: 500;
            font-size: 1rem;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Encore <span style='color: var(--theme-color-error)'>{count} jour</span> pour renouveler <br/> votre membership Club | Encore <span style='color: var(--theme-color-error)'>{count} jours</span> pour renouveler <br/> votre membership Club",
        "description": "Votre membership Club a expiré. Continuez à bénéficier de vos avantages du Club privé en renouvelant votre abonnement avant le <span style='font-weight: 700'>{renewalDate}</span>. Passé ce délai, vous devrez attendre 1 an avant de pouvoir redevenir membre du Club.",
        "description.last_reminder": "Continuez à bénéficier de vos avantages du Club privé en renouvelant votre abonnement avant le <span style='font-weight: 700'>{renewalDate}</span>. Passé ce délai, vous devrez attendre 1 an avant de pouvoir redevenir membre du Club.",
        "remind_me_later": "Me le rappeler plus tard",
        "no_thanks": "Non merci",
        "renew": "Renouveler"
    },
    "en": {
        "title": "Still <span style='color: var(--theme-color-error)'>{count} day</span> to renew <br/> your Club membership | Still <span style='color: var(--theme-color-error)'>{count} days</span> to renew <br/> your Club membership",
        "description": "Your Club membership has expired. Continue to benefit from your Private Club advantages by renewing your membership before the <span style='font-weight: 700'>{renewalDate}</span>. After this date, you will have to wait 1 year before you can become a Club member again.",
        "description.last_reminder": "Continue to benefit from your private Club advantages by renewing your membership before <span style='font-weight: 700'>{renewalDate}</span>. After this date, you will have to wait 1 year before you can become a Club member again.",
        "remind_me_later": "Remind me later",
        "no_thanks": "No, thanks",
        "renew": "Renew"
    }
}
</i18n>
