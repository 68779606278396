import { computed, onMounted } from 'vue'
import { loadScript } from '../helpers/load-scripts'
import { useAuthStore } from '../stores/auth.store'
import { RoleName } from '@/vars/AuthAttr'

export default function useNSM() {
    const authStore = useAuthStore()
    const PRIVATE_URL = import.meta.env.VITE_PRIVATE_BASE_URL

    const canUseNSM = computed(() => {
        return !import.meta.env.DEV && authStore.hasRole(RoleName.ADMIN)
    })

    onMounted(async () => {
        if (canUseNSM.value) {
            await loadScript(`${PRIVATE_URL}/nsm/js`, false, { async: true })
        }
    })
}
