<script setup lang="ts">
    import { RouteLocationRaw } from 'vue-router'
    import { RouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useI18n } from 'vue-i18n'

    export type Breadcrumb = {
        label: string
        to?: RouteLocationRaw
        loading?: boolean
    }[]

    defineProps<{
        breadcrumb: Breadcrumb
    }>()

    const { t } = useI18n()
</script>

<template>
    <nav class="hidden md:block">
        <ol role="list" class="flex items-center space-x-4">
            <li>
                <div>
                    <SpaRouterLink :to="{ name: RouteName.HOME }" class="text-gray-400 hover:text-gray-500">
                        <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                fill-rule="evenodd"
                                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                                clip-rule="evenodd"
                            />
                        </svg>
                        <span class="sr-only">{{ t('home') }}</span>
                    </SpaRouterLink>
                </div>
            </li>
            <template v-for="route in breadcrumb" :key="route.label">
                <li>
                    <div class="flex items-center">
                        <svg
                            class="h-5 w-5 flex-shrink-0 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clip-rule="evenodd"
                            />
                        </svg>
                        <Component
                            :is="route.to ? 'RouterLink' : 'span'"
                            v-placeholder="{
                                loading: route.loading,
                                options: {
                                    width: 100
                                }
                            }"
                            class="breadcrumb__text"
                            :to="route.to"
                        >
                            {{ route.label }}
                        </Component>
                    </div>
                </li>
            </template>
        </ol>
    </nav>
</template>

<style lang="scss" scoped>
    .breadcrumb {
        &__text {
            @apply ml-4 text-sm font-medium text-gray-500 hover:text-gray-700;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "home": "Accueil"
    },
    "en": {
        "home": "Home"
    }
}
</i18n>
