export function vRenderWhenChildren(el: HTMLElement) {
    const allowedEmptyTags = ['EMBED', 'IMG', 'INPUT', 'PICTURE', 'VIDEO']
    const children = el.childNodes

    if (children.length > 0) {
        for (let i = 0; i < children.length; i++) {
            if (allowedEmptyTags.includes(children[i].nodeName)) {
                return false
            }
        }
    }

    if (el.textContent?.trim() == '') {
        el.style.display = 'none'
    }
}
