import { RouteName } from '@/vars/RouteName'
import { RouteContext } from '@/types/Router.type'

export async function redirectToPodcastIfSlugHasPodcast({ to, next, router }: RouteContext) {
    if (to.params?.slug.toString().startsWith('podcast-')) {
        const slug = to.params.slug.toString().split('podcast-')[1]
        return router.push({ name: RouteName.PODCAST_SINGLE, params: { slug: slug } })
    }
    return next()
}
