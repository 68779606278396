<script setup lang="ts">
    import { computed } from 'vue'
    import { TagType } from '@/vars/TagAttr'
    import MaterialIcon from '../icons/MaterialIcon.vue'

    const props = withDefaults(
        defineProps<{
            type?: TagType
            canRemove?: 'left' | 'right'
        }>(),
        {
            type: TagType.INFO,
            canRemove: undefined
        }
    )

    defineEmits<{
        remove: []
    }>()

    const classes = computed(() => {
        return ['badge-mondrian', `badge-mondrian--${props.type}`]
    })
</script>

<template>
    <div :class="classes">
        <div class="badge-mondrian__inner text-xs" :class="{ 'badge-mondrian__inner--reverse': canRemove === 'left' }">
            <slot></slot>
            <MaterialIcon v-if="canRemove" name="close" class="cursor-pointer text-base" @click="$emit('remove')" />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .badge-mondrian {
        display: inline-block;
        font-size: 0.75em;
        font-weight: 600;
        padding: var(--scale-1) var(--scale-2);
        color: var(--theme-color-text);
        border-radius: 0.33em;

        &__inner {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            gap: var(--scale-2);

            &--reverse {
                flex-direction: row-reverse;
            }
        }

        &__icon {
            cursor: pointer;
        }

        &--info {
            background-color: var(--theme-color-flagship--10);
        }

        &--error {
            background-color: var(--theme-color-ace--10);
        }

        &--alert {
            background-color: var(--theme-color-ray--10);
        }

        &--success {
            background-color: var(--theme-color-go--10);
        }

        &--neutral {
            background-color: var(--theme-color-gray--06);
        }
    }
</style>
