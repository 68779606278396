<script setup lang="ts">
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import useApplyInformationsQuery from '@/libs/queries/useApplyInformationsQuery'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { RouteName } from '@/vars/RouteName'

    const { data: applyInfos } = useApplyInformationsQuery()
</script>

<template>
    <header class="header">
        <RouterLink :to="{ name: RouteName.HOME }">
            <LogoMedia />
        </RouterLink>
        <template v-if="applyInfos?.data.apply?.partnership?.logo">
            <BaseIcon legacy class="header__logo-separator" name="cross"></BaseIcon>
            <img
                class="header__partner-logo"
                :src="applyInfos?.data.apply?.partnership?.logo"
                :alt="applyInfos?.data.apply?.partnership?.company.name"
            />
        </template>
    </header>
</template>

<style scoped lang="scss">
    .header {
        background: var(--theme-text-color);
        padding: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8.125em;

        :deep(.logo) {
            max-width: 15em;
            object-fit: contain;
        }

        &__logo-separator {
            display: inline-block;
            margin: 0 2em;
            color: white;
        }

        &__partner-logo {
            max-width: 15em;
            object-fit: contain;
            max-height: 5em;
        }
    }
</style>
