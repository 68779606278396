<script setup lang="ts">
    import { ref } from 'vue'
    import PasswordSecurityBar from '@/components/ui/indicators/PasswordSecurityBar.vue'
    import FormInput from './FormInput.vue'
    import usePasswordSecurity from '@/libs/compositions/usePasswordSecurity'

    //eslint ne comprends pas qu'on passe props avec le v-bind
    /* eslint-disable vue/no-unused-properties */
    const props = withDefaults(
        defineProps<{
            name?: string
            inline?: boolean
            error?: string
            hideLabel?: boolean
            inlineLabels?: boolean
            disabled?: boolean
            testId?: string
            placeholder?: string
            hideSecurity?: boolean
        }>(),
        {
            error: '',
            testId: ''
        }
    )

    defineEmits<{
        (e: 'update:modelValue', value?: string): void
    }>()

    const password = ref('')
    const typeInput = ref<'password' | 'text'>('password')

    const { rules } = usePasswordSecurity(password)

    const onClickIcon = () => {
        if (typeInput.value === 'password') {
            typeInput.value = 'text'
        } else {
            typeInput.value = 'password'
        }
    }
</script>

<template>
    <div class="form-password-input">
        <FormInput
            v-bind="props"
            :rules="rules"
            :type="typeInput"
            :icon="typeInput === 'text' ? 'visibility_off' : 'visibility'"
            icon-class="form-password-input__icon"
            :data-testid="testId"
            :placeholder="placeholder"
            @update:model-value="
                (value) => {
                    password = value as string
                    $emit('update:modelValue', password)
                }
            "
            @on-click-icon="onClickIcon"
        >
            <template v-if="$slots.help" #help>
                <slot name="help" />
            </template>
            <template v-if="$slots.description" #description>
                <slot name="description" />
            </template>
            <template #default>
                <slot></slot>
            </template>
        </FormInput>
        <PasswordSecurityBar
            v-if="password && !hideSecurity"
            :password="password"
            :data-testid="testId ? `${testId}-progressBar` : ''"
            class="mt-1"
        />
    </div>
</template>

<style scoped lang="scss">
    .form-password-input {
        &__icon {
            cursor: pointer;
        }
    }
</style>
