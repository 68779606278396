import { DateTime, DateTimeFormatOptions, Zone } from 'luxon'

export function formatDate(
    date: Date | string,
    options?: {
        format?: DateTimeFormatOptions
        locale?: string
        customFormat?: string
        zone?: string | Zone
        useZoneFromDate?: boolean
    }
) {
    let luxonDate =
        typeof date === 'string'
            ? DateTime.fromISO(date, { setZone: options?.useZoneFromDate })
            : DateTime.fromJSDate(date)

    if (options?.zone) {
        luxonDate = luxonDate.setZone(options.zone)
    }

    if (options?.customFormat) {
        if (options?.locale) {
            luxonDate = luxonDate.setLocale(options?.locale)
        }
        return luxonDate.toFormat(options.customFormat)
    } else {
        return luxonDate.toLocaleString(options?.format, {
            locale: options?.locale
        })
    }
}
