import { VisitingCardMessageForm } from '@/types/forms/messaging-api/VisitingCardMessageForm.type'
import http from './ApiClient'
import { EkkoFile } from '@/types/Ekko.type'

export default class MessagingApi {
    static async uploadFile(fileToUpload: File): Promise<{ success: boolean; file: EkkoFile }> {
        const form = new FormData()
        form.append('file', fileToUpload)

        const response = await http.post(import.meta.env.VITE_PRIVATE_BASE_URL + `/ekko/files/upload`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json'
            }
        })

        return response.data
    }

    static async sendVisitingCard(form: VisitingCardMessageForm) {
        const response = await http.post(`/v3/messaging/visiting-card`, form)
        return response.data
    }

    static async removeChannel(channelId: string) {
        const response = await http.delete(import.meta.env.VITE_PRIVATE_BASE_URL + `/ekko/channels/${channelId}`)
        return response.data
    }
}
