import { findContext } from '@/libs/middlewares/auth'
import { RouteRecordRaw } from 'vue-router'
import { AuthRouteName } from '@/vars/RouteName'
import EmptyLayout from '@/components/layouts/EmptyLayout.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/login-and-redirect',
        name: AuthRouteName.LOGIN_AND_REDIRECT,
        component: () => import('@/views/auth/login-and-redirect/LoginAndRedirectPage.vue'),
        meta: {
            layout: EmptyLayout
        }
    },
    {
        path: '/oauth/linkedin',
        meta: {
            context: findContext
        },
        name: AuthRouteName.OAUTH_LINKEDIN,
        component: () => import('@/views/auth/linkedin/OAuthPageLinkedin.vue')
    }
]

export default routes
