<script lang="ts" setup>
    import { computed, ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useI18n } from 'vue-i18n'
    import MenuItem from '@/components/layouts/network/PublicLayout/partials/MenuItem.vue'
    import BemTransition from '@/components/ui/transitions/BemTransition.vue'
    import { MainNavigationItem, MenuCategory } from '@/libs/compositions/usePublicHeaderNavigation'
    import { StorageKey } from '@/vars/StorageAttr'
    import { Persona } from '@/types/Persona.type'
    import { RouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import SharedStorage from '@/libs/core/SharedStorage'

    const props = defineProps<{
        headerHeight: string
        menuToOpen?: MainNavigationItem
    }>()

    const emit = defineEmits<{
        close: []
    }>()

    const { t } = useI18n()
    const router = useRouter()

    const selectedCategoryKey = ref<string>(Persona.ENTREPRENEUR)
    const selectedCategory = computed(() => {
        if (!props.menuToOpen) {
            return null
        }

        return props.menuToOpen.categories.find((category) => category.key === selectedCategoryKey.value)
    })

    const showAside = computed(() => {
        return props.menuToOpen?.categories.length && props.menuToOpen?.categories.length > 1
    })

    watch(
        () => props.menuToOpen,
        () => {
            document.body.style.overflow = props.menuToOpen ? 'hidden' : 'initial'

            if (!selectedCategory.value && props.menuToOpen) {
                // Si on retourne sur le menu lié au personas, on se met par défaut sur le persona choisi.
                selectedCategoryKey.value =
                    props.menuToOpen.key === 'you_are'
                        ? SharedStorage.get(StorageKey.PERSONA_SELECTED) || Persona.ENTREPRENEUR
                        : props.menuToOpen.categories[0].key
            }
        }
    )

    const onClickCategory = (category: MenuCategory) => {
        if (props.menuToOpen?.key !== 'you_are') {
            return null
        }

        switch (category.key) {
            case Persona.CEO:
                router.push({ name: RouteName.HOME_CEO })
                break
            case Persona.ENTREPRENEUR:
                router.push({ name: RouteName.HOME_ENTREPRENEUR })
                break
            case Persona.MANAGER:
                router.push({ name: RouteName.HOME_MANAGER })
                break
            case Persona.YOUNG_TALENT:
                router.push({ name: RouteName.HOME_TALENT })
                break
            default:
                break
        }

        emit('close')
    }
</script>

<template>
    <div class="menu" :class="{ 'menu--open': menuToOpen }">
        <BemTransition name="fade-menu">
            <menu v-if="menuToOpen" class="menu__content" :class="{ 'menu__content--no-aside': !showAside }">
                <ul v-if="showAside" class="menu__categories">
                    <li class="menu__category font-bold">{{ t('i_am') }}</li>
                    <li
                        v-for="category in menuToOpen.categories"
                        :key="category.key"
                        class="menu__category menu__category--selectable"
                        :class="{ 'menu__category--active': selectedCategoryKey === category.key }"
                        @mouseover="selectedCategoryKey = category.key"
                        @click="onClickCategory(category)"
                    >
                        {{ category.label }}
                    </li>
                </ul>
                <div class="menu__entries">
                    <h4 class="mb-8 font-bold">
                        {{ menuToOpen.key === 'you_are' ? t('i_want') : '&nbsp;' }}
                    </h4>
                    <div v-if="selectedCategory" class="menu__list">
                        <Component
                            :is="menuItem?.to ? SpaRouterLink : 'a'"
                            v-for="menuItem in selectedCategory.items"
                            :key="menuItem.text"
                            class="menu__link"
                            v-bind="{
                                ...(menuItem.to && { to: menuItem?.to }),
                                ...(menuItem.href && { href: menuItem?.href })
                            }"
                        >
                            <MenuItem
                                data-menu-item
                                :title="menuItem.title"
                                :icon-name="menuItem.iconName"
                                :text="menuItem.text"
                                :disabled="menuItem.disabled"
                            />
                        </Component>
                    </div>
                </div>
            </menu>
        </BemTransition>
        <Teleport to="#app">
            <BemTransition name="fade-menu-background">
                <div v-if="menuToOpen" class="menu__background"></div>
            </BemTransition>
        </Teleport>
    </div>
</template>

<style lang="scss" scoped>
    $transitionDuration: 250ms;
    $asideWidth: 12em;

    .menu {
        $block: &;

        &__background {
            content: ' ';
            position: absolute;
            top: v-bind('headerHeight');
            left: 0;
            right: 0;
            bottom: calc(v-bind('headerHeight') * -1);
            backdrop-filter: blur(3.125em);
            z-index: 1;
        }

        &__link {
            height: 100%;
        }

        &__content {
            position: absolute;
            top: -1em;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: row;
            background-color: var(--theme-color-nuage);
            box-shadow: 0 1em 2em 0em rgba(var(--theme-color-flagship--rgb), 0.15);
            height: min-content;
            transform: translateY(v-bind('headerHeight'));
            transition-property: opacity, transform;
            transition-duration: $transitionDuration;
            transition-timing-function: ease-out;
            z-index: 100;
            border-radius: 0.5em;
            overflow: hidden;

            &--no-aside {
                margin-left: $asideWidth;
            }
        }

        &__categories {
            background-color: var(--theme-color-flagship--10);
            list-style: none;
            min-width: $asideWidth;
        }

        &__entries {
            flex: 1;
            padding: var(--scale-4) var(--scale-10);
            padding-bottom: var(--scale-8);
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: var(--scale-4);
            align-items: flex-start;
        }

        &__category {
            padding: var(--scale-4) var(--scale-8);
            transition: all 0.1s ease-in-out;

            &--selectable {
                cursor: pointer;

                &:hover {
                    background-color: var(--theme-color-legacy--10);
                }
            }

            &--active {
                position: relative;
                color: var(--theme-primary-text-color) !important;
                background-color: var(--theme-color-legacy) !important;
                border-radius: 0.25em;

                &::before {
                    $size: 0.5em;

                    transition: all 0.1s ease-in-out;
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-width: $size 0 $size #{$size * 1.2};
                    border-color: transparent transparent transparent var(--theme-color-legacy);
                    border-style: solid;
                    transform: translate(100%, 0%);
                }
            }
        }
    }

    .fade-menu-background {
        &--enter-active,
        &--leave-active {
            transition-timing-function: ease-in-out;
            transition-property: opacity;
            transition-duration: $transitionDuration;
        }

        &--enter-from,
        &--leave-to {
            opacity: 0;
        }
    }

    .fade-menu {
        &--enter-active,
        &--leave-active {
            transition-timing-function: ease-in-out;
            transition-property: all;
            transition-duration: $transitionDuration;
        }

        &--enter-from,
        &--leave-to {
            opacity: 0;
            transform: translateY(4em) scale(0.975);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "i_am": "Je suis",
        "i_want": "J'ai envie de ..."
    },
    "en": {
        "i_am": "I am",
        "i_want": "I want to ..."
    }
}
</i18n>
