import { HeaderNavigation } from '@/types/Header.type'
import { ProRouteName, RouteName } from '@/vars/RouteName'
import messages from './i18n/profile.json'
import { useAuthStore } from '@/libs/stores/auth.store'
import { useTeamStore } from '@/libs/stores/team.store'
import { RoleName } from '@/vars/AuthAttr'
import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
import i18n from '@/i18n'

i18n.global.mergeLocaleMessage('fr', messages.fr)
i18n.global.mergeLocaleMessage('en', messages.en)

const { t } = i18n.global
const adminUrl = import.meta.env.VITE_ADMIN_BASE_URL
export const getNavigationItems = () => {
    const { navigate } = useNavigationResolver()

    const store = useAuthStore()
    const teamStore = useTeamStore()
    const myTeam = teamStore?.myTeam || null

    const items: HeaderNavigation[] = []

    if (store.hasRole(RoleName.ADMIN)) {
        items.push({
            label: t('nav.admin'),
            children: [
                {
                    label: t('nav.admin_dashboard'),
                    href: `${adminUrl}/dashboard`
                }
            ]
        })
    }

    items.push(
        {
            label: myTeam?.company?.name || t('nav.company'),
            icon: myTeam?.company?.logo || undefined,
            children: [
                {
                    label: t('nav.home'),
                    route: { name: ProRouteName.HOME }
                },
                {
                    label: t('nav.company_settings'),
                    route: { name: ProRouteName.MY_COMPANY_PROFILE }
                }
            ]
        },
        {
            label: t('nav.account'),
            children: [
                {
                    label: t('nav.login_details'),
                    action() {
                        navigate({ name: RouteName.ACCOUNT_MEMBERSHIP })
                    }
                }
            ]
        },
        {
            special: {
                key: 'locale_selector'
            }
        }
    )

    // Is on "switch to user" mode
    if (store.isOnSwitchTo) {
        items.push({
            children: [
                {
                    label: t('nav.back_admin_user'),
                    action: () => {
                        store.exitSwitchToUser()
                    }
                },
                {
                    label: t('nav.logout'),
                    action() {
                        store.logout()
                    }
                }
            ]
        })
    } else {
        items.push({
            children: [
                {
                    label: t('nav.logout'),
                    action() {
                        store.logout()
                    }
                }
            ]
        })
    }

    return items
}
