export enum NotificationType {
    EVENT_INVITATION_ACCEPTED = 'event_invitation_accepted',
    EVENT_VIP = 'event_vip',
    EVENT_UPDATE = 'event_update',
    EVENT_CONFIRMATION = 'event_confirmation',
    EVENT_PARTICIPATION_ACCEPTED = 'event_participation_accepted',
    FEEDBACK_EVENT = 'feedback_event',
    FEEDBACK_RELATIONSHIP = 'feedback_relationship',
    RELATIONSHIP = 'relationship',
    RELATIONSHIP_CC = 'relationship_cc',
    SPONSORSHIP = 'sponsorship',
    MESSAGE = 'message'
}
