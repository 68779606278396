<script setup lang="ts">
    import { useRoute } from 'vue-router'
    import PublicHeader from '@/components/layouts/network/headers/public-header/PublicHeader.vue'
    import BatchHeadband from '@/components/partials/apply/leclub/banners/BatchHeadband.vue'
    import FooterPublic from './network/footer-public/FooterPublic.vue'
    import useLayout from '@/libs/compositions/useLayout'

    defineProps<{
        asSlot?: boolean
    }>()

    const route = useRoute()
    const { props, showOnlyContent } = useLayout()
</script>

<template>
    <BatchHeadband v-if="props?.showBatchBanner" />
    <PublicHeader v-if="!showOnlyContent" theme="dark" />
    <main class="layout__main" :class="{ 'layout__main--grid-background': route.meta.hasGridBackground }">
        <slot v-if="asSlot" class="layout__content" />
        <RouterView v-else v-slot="{ Component: ViewComponent }" class="layout__router">
            <Transition name="page-">
                <Component :is="ViewComponent" class="layout__content" />
            </Transition>
        </RouterView>
    </main>
    <FooterPublic v-if="!showOnlyContent" />
</template>

<style scoped lang="scss">
    @mixin grid-pattern {
        height: 70vh;
        transform: translateY(-12em);
        z-index: 0;
    }

    .layout {
        &__header {
            z-index: 1;
        }

        &__content {
            position: relative;
            z-index: 100;
        }

        &__main {
            position: relative;
            z-index: 0;
            background-color: var(--theme-color-nuage);
            --theme-slider-background--rgb: var(--theme-background-color--important--rgb);

            &--grid-background {
                &::before {
                    content: '';
                    background-image: url('@/assets/patterns/squares.svg');
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    @include grid-pattern;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: radial-gradient(circle at 50% -75%, transparent -100%, white 95%);
                    @include grid-pattern;
                }
            }
        }

        &__router {
            min-height: 80dvh;
        }
    }
</style>
