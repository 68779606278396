<script setup lang="ts">
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { inject, watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useField } from 'vee-validate'
    import { useDebounceFn } from '@vueuse/core'
    import { UNIVERSAL_SEARCH_KEY, UniversalSearchInjectType } from '@/vars/UniversalSearchAttr'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { ClubRouteName } from '@/vars/RouteName'

    const { navigate } = useNavigationResolver()
    const universalSearch = inject<UniversalSearchInjectType>(UNIVERSAL_SEARCH_KEY)

    watch(
        () => universalSearch?.searchText.value,
        () => {
            if (searchText.value !== universalSearch?.searchText.value) {
                handleChange(universalSearch?.searchText.value)
                sendSearch()
            }
        }
    )
    const { t } = useI18n()

    const { handleChange, value: searchText } = useField('search', undefined, {
        initialValue: universalSearch?.searchText || '',
        standalone: true,
        syncVModel: true
    })

    const onInputChange = (e: Event) => {
        const value: string | null = (e.target as HTMLInputElement).value
        handleChange(value)
        sendSearch()
    }

    const sendSearch = useDebounceFn(() => {
        universalSearch?.updateSearchText(searchText.value)
    }, 600)

    function submitSearch() {
        if (searchText.value) {
            universalSearch?.updateIsExpended(false)
            return navigate({
                name: ClubRouteName.UNIVERSAL_SEARCH,
                query: { search: searchText.value }
            })
        }
    }

    const closeSearch = () => {
        universalSearch?.updateIsExpended(false)
    }
</script>

<template>
    <div>
        <form class="search search--open" @submit.prevent="submitSearch">
            <ContainerTemplate class="search__container">
                <div class="search__group">
                    <label for="header_search" class="search__button search__button--first">
                        <BaseIcon name="search" class="search__icon" />
                    </label>
                    <input
                        id="header_search"
                        :value="searchText"
                        name="search"
                        type="text"
                        class="search__input"
                        :placeholder="t('placeholder')"
                        autocomplete="off"
                        @input="onInputChange"
                    />
                    <BaseButton
                        :theme="ButtonTheme.DISCRET"
                        class="search__button search__button--last"
                        @click="closeSearch"
                    >
                        <BaseIcon name="close" class="search__icon" />
                    </BaseButton>
                </div>
            </ContainerTemplate>
        </form>
    </div>
</template>

<style scoped lang="scss">
    .search {
        $block-selector: &;
        z-index: 1;
        top: 0;
        right: 0;
        transform: none;
        transition: 350ms;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: transparent;

        &__icon {
            @apply flex;
            font-size: 1.75em;
        }

        &__activator {
            height: 100%;
            display: flex;
            align-items: center;
            transition: opacity 150ms;

            &--open {
                opacity: 0;
            }
        }

        &__container {
            position: relative;
        }

        &__group {
            position: relative;
            display: flex;
            align-items: center;
            background: var(--theme-background-color);
            color: var(--theme-text-color);
            height: 2.5em;
            border-radius: 0.25em;
            padding: 0 var(--scale-4);
            overflow: hidden;
            width: 0;
            transition: all 350ms;
            margin: 0 auto;

            #{$block-selector}--open & {
                width: 100%;
            }
        }

        &__input {
            width: 100%;
            padding: 0 var(--scale-4);
            height: 100%;
            margin: 0;
            background: none;
            border: none;
            line-height: 2.5em;
            box-shadow: none;
            border-radius: 0;
            font-size: 0.9em;
        }

        &__button {
            // position: absolute;
            // top: 50%;
            font-size: 0.75rem;
            // width: 2.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            // transform: translateY(-50%);

            &--first {
                pointer-events: none;
                left: 0;
            }

            &--last {
                right: 0;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "placeholder": "Entrez un nom de membre, un mot clé, une ville ..."
    }
}
</i18n>
