<script setup lang="ts">
    import { ref } from 'vue'
    import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import { useProfileNetworkNavigation } from '@/libs/navigations/network/profileNavigation'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import AsideNavigation from './partials/AsideNavigation.v2.vue'
    import FooterPublic from '@/components/layouts/network/footer-public/FooterPublic.vue'
    import useBreadcrumb from '@/libs/compositions/useBreadcrumb'
    import Breadcrumb from '@/components/ui/basics/BaseBreadcrumb.v2.vue'
    import useLayout from '@/libs/compositions/useLayout'
    import BatchHeadband from '@/components/partials/apply/leclub/banners/BatchHeadband.vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { RouteName } from '@/vars/RouteName'
    import SmartActions from '@/components/partials/network/smart-actions/SmartActions.vue'
    import CitySwitcherControlLayout from '../headers/controls/CitySwitcherControlLayout.vue'
    import HeaderProfileNavigation from '@/components/layouts/common/header/navigations/profile/HeaderProfileNavigation.vue'
    import AppSwitcher from '@/components/layouts/network/AppSwitcher.vue'
    import GettingStartedActions from '@/components/partials/network/GettingStartedActions.vue'
    import { SpaName } from '@/vars/SpaAttr'
    import { computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { useScroll } from '@vueuse/core'

    const profileNavigation = useProfileNetworkNavigation()
    const { breadcrumb } = useBreadcrumb()
    const { props, showOnlyContent } = useLayout()
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const route = useRoute()

    const sidebarOpen = ref(false)
    const scrollableRef = ref<HTMLElement | null>(null)
    const { y } = useScroll(scrollableRef)
    const showTopFade = computed(() => y.value > 0)

    const showStartActions = computed(() => {
        return spaRunning === SpaName.LENETWORK && route.name !== RouteName.HOME
    })
</script>

<template>
    <div class="layout">
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full"
                    >
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0"
                            >
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                                        <span class="sr-only">Close sidebar</span>
                                        <MaterialIcon name="close" class="title-2 mt-4 text-nuage" />
                                    </button>
                                </div>
                            </TransitionChild>
                            <!-- Sidebar component, swap this element with another sidebar if you like -->
                            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                <LogoMedia class="w-32 pb-10 pt-8" dark />
                                <AsideNavigation @click-link="sidebarOpen = false" />
                                <SmartActions theme="compact" />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div v-if="!showOnlyContent" class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex h-full flex-col overflow-x-visible border-r border-gray-200 bg-white px-4 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                    <SpaRouterLink :to="{ name: RouteName.HOME }">
                        <LogoMedia class="w-24" dark />
                    </SpaRouterLink>
                </div>
                <div class="mb-8 mt-4">
                    <CitySwitcherControlLayout />
                </div>
                <div class="layout__desktop-wrapper relative">
                    <div v-if="showTopFade" class="layout__desktop-fade layout__desktop-fade--top" />
                    <div
                        ref="scrollableRef"
                        class="layout__desktop-content flex h-full flex-col gap-6 overflow-y-auto pb-8"
                    >
                        <AsideNavigation />
                        <SmartActions theme="compact" />
                        <GettingStartedActions v-if="showStartActions" class="pb-4" compact />
                    </div>
                </div>
            </div>
        </div>

        <div class="layout__main-content" :class="{ 'layout__main-content--only-content': showOnlyContent }">
            <BatchHeadband v-if="props?.showBatchBanner" />
            <div
                v-if="!showOnlyContent"
                class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
            >
                <button
                    type="button"
                    class="-m-2.5 p-2.5 flex items-center text-gray-700 lg:hidden"
                    @click="sidebarOpen = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <MaterialIcon name="menu" class="title-2" />
                    <LogoMedia dark class="ml-4 max-h-6" />
                </button>

                <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <div class="flex-1">
                        <div id="topbar" class="flex h-full items-center">
                            <Breadcrumb v-if="breadcrumb" :breadcrumb="breadcrumb" />
                        </div>
                    </div>
                    <div class="flex items-center gap-x-4">
                        <AppSwitcher theme="dark" />
                        <HeaderProfileNavigation :profile="profileNavigation.items.value" no-space />
                    </div>
                </div>
            </div>

            <main class="relative min-h-screen bg-gray-7">
                <slot v-if="$slots.default" />
                <RouterView v-else v-slot="{ Component: ViewComponent }">
                    <Transition name="page-">
                        <Component :is="ViewComponent" class="layout__content" />
                    </Transition>
                </RouterView>
            </main>
            <FooterPublic v-if="!showOnlyContent" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '@/libs/sass/vars';

    .layout {
        &__desktop-content::-webkit-scrollbar {
            display: none;
        }

        &__desktop-wrapper {
            position: relative;
            height: 100%;
            overflow-y: hidden;
        }

        &__desktop-content {
            overflow-y: auto;
            position: relative;
            height: 100%;
        }

        &__desktop-fade {
            position: absolute;
            left: 0;
            right: 0;
            height: 1rem;
            pointer-events: none;
            z-index: 1;
        }

        &__desktop-fade--top {
            top: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        }

        &__main-content {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $breakpoints-lg) {
                padding-left: 16rem;
            }

            &--only-content {
                padding-left: 0;
            }
        }
    }
</style>
