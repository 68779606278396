<script setup lang="ts">
    import { computed } from 'vue'

    const props = defineProps<{
        name: string
    }>()

    const normalizedName = computed(() => {
        try {
            return props.name.replaceAll('_', '-')
        } catch {
            // Pour les vieux navigateurs
            return props.name.replace('_', '-')
        }
    })
</script>

<template>
    <span class="icon" :class="`icon-${normalizedName}`"></span>
</template>

<style scoped lang="scss">
    .icon {
        display: inline-flex;
        align-items: center;
    }
</style>
