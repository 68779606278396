import { RouteRecordRaw } from 'vue-router'
import { BenefitRouteName } from '@/vars/RouteName'
import { requiredAuthentification } from '@/libs/middlewares/auth'

const routes: RouteRecordRaw[] = [
    {
        path: '/benefits',
        name: BenefitRouteName.BENEFITS,
        component: () => import('@/views/common/benefits/benefits-list/BenefitListPage.vue'),
        meta: {
            middleware: [requiredAuthentification]
        }
    },
    {
        path: '/benefits/:benefitSlug',
        name: BenefitRouteName.BENEFITS_SINGLE,
        component: () => import('@/views/common/benefits/benefits-details/BenefitDetailsPage.vue')
    }
]

export default routes
