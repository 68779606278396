import { RouteRecordRaw } from 'vue-router'
import { LetterManagementRouteName } from '@/vars/RouteName'
import { SpaName } from '@/vars/SpaAttr'
import fallbackRouteForAnonymous from '@/libs/middlewares/fallbackRouteForAnonymous'

const routes: RouteRecordRaw[] = [
    {
        path: '/lettre-de-nos-dirigeants',
        name: LetterManagementRouteName.LETTER,
        component: () => import('@/views/common/letter-management/LetterManagement.vue'),
        meta: {
            middleware: [fallbackRouteForAnonymous(SpaName.LENETWORK)]
        }
    }
]

export default routes
