<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import { useAppStore } from '@/libs/stores/app.store'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useI18n } from 'vue-i18n'

    const store = useAppStore()
    const { t } = useI18n()

    function reload() {
        window.location.reload()
    }
</script>

<template>
    <BaseModal v-model="store.inMaintenance" persistent>
        <template #image>
            <img src="@/assets/images/illustrations/empty-state.svg" alt="" class="sub__image" width="77" />
        </template>
        <template #title>
            {{ t('title') }}
        </template>
        <p class="refresh__description">
            {{ t('description') }}
        </p>
        <div class="refresh__button">
            <BaseButton :theme="ButtonTheme.PRIMARY" @click="reload">
                {{ t('refresh') }}
            </BaseButton>
        </div>
    </BaseModal>
</template>

<style scoped lang="scss">
    .refresh {
        text-align: center;
        padding: 0 3.125em;
        max-width: 43.75em;

        &__logo {
            margin: 0 auto 1em;
            max-width: 9.375em;
        }

        &__description {
            text-align: center;
            margin-bottom: 1em;
        }

        &__button {
            display: flex;
            justify-content: center;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Frenchfounders est en maintenance. Merci de rééssayer dans quelques minutes.",
        "description": "Si le problème persiste, veuillez contacter l'administrateur",
        "refresh": "Rafraichir la page"
    }
}
</i18n>
