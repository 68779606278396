<script setup lang="ts">
    import StarRate from '@/components/ui/icons/StarRate.vue'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import { useI18n } from 'vue-i18n'

    withDefaults(
        defineProps<{
            members: { id?: string | number; picture?: string; full_name?: string }[]
            globalRate: number | null
        }>(),
        {
            members: () => [],
            globalRate: 0
        }
    )
    const { t } = useI18n()
</script>

<template>
    <div class="header mb-8">
        <div class="header__members">
            <div v-for="member in members.filter((m) => Boolean(m?.id))" :key="member?.id" class="header__member">
                <AvatarMedia
                    v-if="member.picture"
                    :src="member.picture"
                    :alt="member?.full_name || ''"
                    :size="AvatarSize.NORMAL"
                />
            </div>
        </div>
        <div v-if="globalRate" class="header__rate">
            <div class="header__question mt-4">
                {{ t('conversation_interesting') }}
            </div>
            <div>
                <StarRate :vote="globalRate"></StarRate>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .header {
        &__members {
            display: flex;
            gap: var(--scale-2);
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            padding: var(--scale-4);
            margin-top: var(--scale-4);
            padding-bottom: 0em;
        }
        &__member {
            margin: 0 var(--scale-2);
        }
        &__rate {
            background-color: var(--theme-background-color);
        }
        &__question {
            display: block;
            margin-bottom: var(--scale-2);
            max-width: 80%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "conversation_interesting": "Pertinence de la conversation"
    }
}
</i18n>
