import { RouteRecordRaw } from 'vue-router'
import { checkBussinessLineRedirection } from '@/libs/middlewares/auth'
import { CompaniesRouteName } from '@/vars/RouteName'
import { SpaName } from '@/vars/SpaAttr'
import fallbackRouteForAnonymous from '@/libs/middlewares/fallbackRouteForAnonymous'
import { Lang } from '@/types/Lang.type'

const routes: RouteRecordRaw[] = [
    {
        path: '/annuaire-entreprises',
        name: CompaniesRouteName.COMPANY_HOME,
        redirect: {
            name: CompaniesRouteName.COMPANY_SEARCH,
            params: {
                location: 'new-york'
            }
        }
    },
    {
        path: '/annuaire-entreprises/:location/:categoryParent?/:categoryChildrens?',
        name: CompaniesRouteName.COMPANY_SEARCH,
        component: () => import('@/views/companies/company-home/CompanyHome.vue'),
        meta: {
            lang: Lang.FR,
            onlyContentOnApp: true,
            hideWhatsNew: true
        }
    },
    {
        path: '/:businessLine?/companies',
        redirect: {
            name: CompaniesRouteName.COMPANY_HOME
        }
    },
    {
        path: '/:businessLine?/companies/search',
        redirect: {
            name: CompaniesRouteName.COMPANY_HOME
        }
    },
    {
        path: '/:businessLine?/companies/details/:slug',
        component: () => import('@/views/companies/company-details-page/CompanyDetailsPage.vue'),
        meta: {
            middleware: [checkBussinessLineRedirection, fallbackRouteForAnonymous(SpaName.LENETWORK)],
            onlyContentOnApp: true,
            hideWhatsNew: true
        },
        redirect: {
            name: CompaniesRouteName.COMPANY_DETAILS
        },
        children: [
            {
                path: 'info',
                name: CompaniesRouteName.COMPANY_DETAILS,
                component: () =>
                    import(
                        '@/views/companies/company-details-page/tabs/tab-enterprise/CompanyDetailsInfoTabEnterprise.vue'
                    )
            },

            {
                path: 'jobs',
                name: CompaniesRouteName.COMPANY_DETAILS_JOBS,
                component: () =>
                    import('@/views/companies/company-details-page/tabs/tab-jobs/CompanyDetailsInfoTabJobs.vue')
            }
        ]
    }
]

export default routes
