<script setup lang="ts">
    import { computed } from 'vue'
    import { getNavigationItems as getClubMainNavigationItems } from '@/libs/navigations/leclub/mainNavigation'
    import { useProfileNavigation as useProfileClubNavigation } from '@/libs/navigations/leclub/profileNavigation'
    import { useMainNavigationItems as useMainNetworkNavigationItems } from '@/libs/navigations/network/mainNavigation'
    import { useProfileNetworkNavigation as useProfileNetworkNavigation } from '@/libs/navigations/network/profileNavigation'
    import { getNavigationItems as getProMainNavigationItems } from '@/libs/navigations/pro/mainNavigation'
    import { getNavigationItems as getProProfileNavigationItems } from '@/libs/navigations/pro/profileNavigation'
    import { SpaName } from '@/vars/SpaAttr'
    import { useRouter } from 'vue-router'
    import { RouteName } from '@/vars/RouteName'

    const activeApp = import.meta.env.VITE_SPA_RUNNING
    const router = useRouter()
    const { items: mainNetworkNavigationItems } = useMainNetworkNavigationItems()

    const { items: profileNetworkNavigation } = useProfileNetworkNavigation()
    const { items: profileClubNavigation } = useProfileClubNavigation()

    const navigationItems = computed(() => {
        switch (activeApp) {
            case SpaName.LECLUB:
                return getClubMainNavigationItems()
            case SpaName.LENETWORK:
                if (
                    router.currentRoute.value.matched.length > 0 &&
                    router.currentRoute.value.matched[0].path === router.resolve({ name: RouteName.ONBOARDING }).path
                ) {
                    return []
                } else {
                    return mainNetworkNavigationItems.value
                }
            case SpaName.PRO:
                return getProMainNavigationItems()
            default:
                return []
        }
    })

    const profileNavigation = computed(() => {
        switch (activeApp) {
            case SpaName.LECLUB:
                return profileClubNavigation.value
            case SpaName.LENETWORK:
                return profileNetworkNavigation.value
            case SpaName.PRO:
                return getProProfileNavigationItems()
            default:
                return []
        }
    })
</script>

<template>
    <slot v-bind="{ navigationItems, profileNavigation }"></slot>
</template>
