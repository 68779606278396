import { ref } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { ToastType } from '@/vars/ToastAttr'

type ToastConfig = {
    id?: number
    type: ToastType
    title?: string
    text?: string
    duration?: number
}

type SnackbarConfig = {
    id?: number
    type?: ToastType
    text: string
    duration?: number
    action: {
        text: string
        handler: () => unknown
    }
}

export default function useNotification() {
    const lastNotificationId = ref<number | null>(null)

    const toast = (toastConfig: ToastConfig) => {
        notify({ id: Date.now(), ...toastConfig })
    }

    const snackbar = (snackbarConfig: SnackbarConfig) => {
        notify({
            id: snackbarConfig.id || Date.now(),
            title: '',
            text: snackbarConfig.text,
            type: snackbarConfig.type,
            duration: snackbarConfig?.duration === Infinity ? -1 : snackbarConfig.duration,
            data: {
                action: snackbarConfig.action
            }
        })
    }

    /**
     * Close a notification, if no `notificationId` is given, the last notification is closed.
     * @param notificationId
     */
    const closeNotification = (notificationId: number) => {
        if (notificationId === lastNotificationId.value) {
            lastNotificationId.value = null
        }

        notify.close(notificationId)
    }

    return {
        toast,
        snackbar,
        closeNotification
    }
}
