import { FeedbackFormElement, FeedbackFormType } from '@/types/response/Feedback.type'

type Answer = {
    id: string
    answer: boolean | number | null
}

class FeedbackForm {
    form!: FeedbackFormType | null
    questions!: FeedbackFormElement[] | null
    answers!: Answer[] | null
    formStep!: number | null

    constructor(form: FeedbackFormType) {
        if (form) {
            this.setForm(form)
        } else {
            this.form = null
            this.questions = null
            this.answers = null
            this.formStep = null
        }
    }

    get feedbackAnswersObject() {
        const notRespondedEvent = this.questions?.find(
            (question: FeedbackFormElement) => question.key === 'post_event.button.not_participated'
        )
        const notRespondedRelationship = this.questions?.find(
            (question: FeedbackFormElement) => question.key === 'post_relationship.button.not_met'
        )
        const notResponded = this.answers?.find(
            (answer: Answer) =>
                (notRespondedEvent && answer.id === notRespondedEvent.id && answer.answer === true) ||
                (notRespondedRelationship && answer.id === notRespondedRelationship.id && answer.answer === true)
        )
        if (notResponded && notResponded.id) {
            const globalRate = this.questions?.find((question: FeedbackFormElement) => question.type === 'global-rate')
            if (globalRate && this.answers) {
                this.answers = this.answers.map((answer: Answer) => {
                    if (answer.id === globalRate.id) {
                        answer.answer = 0
                    }
                    return answer
                })
            }
        }

        return {
            form_step: this.form?.form_step,
            form_elements: this.answers
        }
    }

    get globalRateValue() {
        const globalRate = this.questions?.find((question: FeedbackFormElement) => question.type === 'global-rate')

        const notRespondedEvent = this.questions?.find(
            (question: FeedbackFormElement) => question.key === 'post_event.button.not_participated'
        )
        const notRespondedRelationship = this.questions?.find(
            (question: FeedbackFormElement) => question.key === 'post_relationship.button.not_met'
        )
        const notResponded = this.answers?.find(
            (answer: Answer) =>
                (notRespondedEvent && answer.id === notRespondedEvent.id && answer.answer === true) ||
                (notRespondedRelationship && answer.id === notRespondedRelationship.id && answer.answer === true)
        )
        if (notResponded && notResponded.id) {
            return null
        }

        if (globalRate && globalRate.id) {
            const answerGlobalRate = this.answers?.find((answer: Answer) => answer.id === globalRate.id)
            if (answerGlobalRate) {
                return answerGlobalRate.answer
            }
        }

        return null
    }

    setForm(form: FeedbackFormType) {
        this.form = form
        this.questions = form.form_elements
        this.formStep = form.form_step
        if (this.questions) {
            this.answers = this.questions.map((el: FeedbackFormElement) => ({
                id: el.id,
                answer: null
            }))
        }
    }

    setAnswer(id: string, answer: number | boolean) {
        if (!this.answers) {
            return null
        }
        this.answers = this.answers.map((answerEl: Answer) => {
            if (answerEl.id === id) {
                answerEl.answer = answer
            }

            return answerEl
        })
    }
}

export default FeedbackForm
