import { reactive } from 'vue'
import SegmentApi from '@/libs/api/Segment.api'

export const useSegment = () => {
    const track = (event: string, data?: unknown) => {
        try {
            SegmentApi.sendTracks(event, data)
        } catch (e) {
            console.error(e)
        }
    }
    return reactive({
        analytics: { track }
    })
}
