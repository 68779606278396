<script setup lang="ts">
    import BaseModal from '@/components/ui/basics/BaseModal.v2.vue'
    import { Feedback } from '@/types/response/Feedback.type'
    import { computed, onMounted, ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import FeedbackForm from '@/libs/helpers/feedbackForm'
    import { useMutation } from '@tanstack/vue-query'
    import FeedbackApi from '@/libs/api/Feedback.api'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import FormPart from '../form-part/FormPart.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { formatDate } from '@/libs/helpers/dates'
    import BusinessConnectionHeader from './partials/BusinessConnectionHeader.vue'
    import ProfileMemberHeader from './partials/ProfileMemberHeader.vue'
    import RelationHeader from './partials/RelationHeader.vue'
    import { ProRouteName } from '@/vars/RouteName'
    import { captureException } from '@sentry/vue'
    import { ModalSize } from '@/vars/ModalAttr'
    import ModalFinishStep from '../ModalFinishStep.vue'
    import AvatarMediaV2 from '@/components/ui/medias/AvatarMedia.vue'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'

    const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL

    const props = withDefaults(
        defineProps<{
            feedback: Feedback
            score?: number
        }>(),
        {
            score: 0
        }
    )

    const { t } = useI18n()
    const { toast } = useNotification()
    const authStore = useAuthStore()
    const showModal = ref(true)
    const rateValue = ref(props.score)
    const feedbackStatus = ref<string | null>(null)
    const form = ref<FeedbackForm | null>(null)
    const globalRate = ref<number | null>(null)

    const isFinished = computed(() => {
        return feedbackStatus.value === 'done'
    })

    const memberId = computed(() => {
        return authStore.authentifiedUser && authStore.authentifiedUser.idff
    })

    const members = computed(() => {
        if (!props.feedback?.relationship_establishment) {
            return []
        }
        return [
            props.feedback.relationship_establishment.second_member,
            props.feedback.relationship_establishment.first_member
        ]
    })

    const isFirstStep = computed(() => {
        if (form.value && form.value.formStep) {
            return form.value.formStep === 1
        } else {
            return true
        }
    })

    const memberToShow = computed(() => {
        if (memberId.value && props.feedback && props.feedback.relationship_establishment) {
            return memberId.value === props.feedback.relationship_establishment?.first_member?.idff
                ? props.feedback.relationship_establishment.second_member
                : props.feedback.relationship_establishment.first_member
        }

        return null
    })

    const modalConfirmation = computed(() => {
        if (form.value && form.value.questions && form.value.questions.length) {
            const modal = form.value.questions[0]

            if (modal.type === 'modal_confirmation') {
                return {
                    title: modal.subject,
                    message: modal?.options?.message,
                    options: modal?.options
                }
            }
        }

        return null
    })

    const modalContact = computed(() => {
        if (form.value && form.value.questions && form.value.questions.length) {
            const modal = form.value.questions[0]

            if (modal.type === 'modal_relationship_contact') {
                return modal
            }
        }

        return null
    })

    const dateConnection = computed(() => {
        if (!dateConnectionPure.value) {
            return null
        }

        return t('date', { date: dateConnectionPure.value })
    })

    const dateConnectionPure = computed(() => {
        if (!props.feedback?.relationship_establishment) {
            return null
        }

        const date = formatDate(
            props.feedback.relationship_establishment.business_connection_rdv_date ||
                props.feedback.relationship_establishment.scheduled_at ||
                props.feedback.relationship_establishment.created_at,
            {
                customFormat: 'dd MMMM',
                locale: 'fr'
            }
        )

        return date
    })

    const handleFeedback = (feedback) => {
        if (feedback) {
            form.value = new FeedbackForm(feedback.form)
            feedbackStatus.value = feedback.status
        }
    }

    const onAnswer = (answer) => {
        form.value?.setAnswer(answer.id, answer.answer)
    }

    const { mutate: postAnswerFeedback } = useMutation({
        mutationFn: async (params: {
            id: string | number
            feedbackAnswersObject: {
                form_step: number | undefined
                form_elements: { id: string; answer: boolean | number | null }[] | null
            }
        }) => {
            return await FeedbackApi.postFeedbackAnswers(params.id, params.feedbackAnswersObject)
        },
        onSuccess: (data) => {
            handleFeedback(data)
        },
        onError: (e) => {
            captureException(e)
            toast({
                type: ToastType.ERROR,
                title: t('toast.error')
            })
            showModal.value = false
        }
    })

    const sendAnswers = () => {
        globalRate.value = Number(form.value?.globalRateValue)
        if (form.value?.feedbackAnswersObject) {
            postAnswerFeedback({ id: props.feedback.id, feedbackAnswersObject: form.value.feedbackAnswersObject })
        }
    }

    const { mutate: goBack } = useMutation({
        mutationFn: async (params: { id: string | number; step: number | undefined }) => {
            return await FeedbackApi.getFeedbackBack(params.id, params.step)
        },
        onSuccess: (data) => {
            handleFeedback(data)
        },
        onError: () => {
            toast({
                type: ToastType.ERROR,
                title: t('toast.error')
            })
            showModal.value = false
        }
    })

    const { mutate: pingAdmin, isSuccess: pingSuccess } = useMutation({
        mutationFn: async () => {
            return await FeedbackApi.feedbackPingAdmin(props.feedback.id)
        },
        onError: (e) => {
            captureException(e)
            toast({
                type: ToastType.ERROR,
                title: t('toast.error')
            })
            showModal.value = false
        }
    })

    onMounted(() => {
        handleFeedback(props.feedback)

        if (rateValue.value) {
            const element = props.feedback.form.form_elements.find((formEl) =>
                formEl.type === 'global-rate' ? formEl : null
            )
            if (element) {
                form.value?.setAnswer(element.id, rateValue.value)
            }
        }
    })

    const onContact = () => {
        if (!memberToShow.value || !props.feedback.relationship_establishment) {
            return null
        }

        window.location.href = `${privateUrl}/messaging/open_channel/${memberToShow.value.idff}/${props.feedback.relationship_establishment.sendbird_channel_url}`
    }

    const onSeeProfile = () => {
        if (!memberToShow.value) {
            return null
        }

        showModal.value = false

        window.location.href = `${privateUrl}/member/${memberToShow.value.idff}`
    }

    const hasHeader = computed(() => {
        return memberToShow.value && (isFirstStep.value || modalContact.value)
    })
</script>

<template>
    <BaseModal
        :model-value="showModal"
        no-padding
        :size="ModalSize.MEDIUM_XS"
        @update:model-value="($event) => (showModal = $event)"
    >
        <div
            class="modal__header"
            :class="{
                'modal__header--legacy': hasHeader
            }"
        >
            <Transition v-if="hasHeader" name="fade" :duration="100" class="modal__header-avatar-container">
                <AvatarMediaV2
                    class="modal__header-avatar"
                    :src="memberToShow?.picture"
                    :alt="memberToShow?.full_name"
                />
            </Transition>

            <div
                v-if="!modalConfirmation && !pingSuccess"
                class="'modal__header-info"
                :class="{
                    'modal__header-info--reset-margin': hasHeader
                }"
            >
                <BusinessConnectionHeader
                    v-if="
                        (memberToShow &&
                            (isFirstStep || modalContact) &&
                            feedback.form.type === 'business_connection_for_partner_contact') ||
                        feedback.form.type === 'business_connection_rdv_for_partner_contact'
                    "
                    :my-first-name="authStore.authentifiedUser?.first_name"
                    :connection-date="dateConnectionPure"
                    :connected-member-full-name="memberToShow?.full_name"
                    :connected-member-company="memberToShow?.company.name"
                    :form-type="feedback.form.type"
                />
                <ProfileMemberHeader
                    v-else-if="memberToShow && (isFirstStep || modalContact)"
                    :member-to-show="memberToShow"
                    :date-connection="dateConnection"
                />

                <RelationHeader v-else :members="members" :global-rate="globalRate" />
            </div>
        </div>

        <template v-if="modalConfirmation" #title>{{ modalConfirmation.title }}</template>
        <ModalFinishStep
            v-if="modalConfirmation"
            :text="modalConfirmation.message"
            :options="modalConfirmation?.options"
            :relationship-establishment="feedback.relationship_establishment"
            @close="showModal = false"
        />

        <ModalFinishStep
            v-if="pingSuccess"
            :relationship-establishment="feedback.relationship_establishment"
            :text="$t('ping_success', { admin: feedback.relationship_establishment?.admin.first_name })"
            @close="showModal = false"
        />

        <div v-if="!modalConfirmation && !pingSuccess" class="modal__content">
            <div v-if="modalContact" class="modal-content__form">
                <div class="title-4">{{ modalContact.subject }}</div>
            </div>

            <div>
                <div v-if="feedback && form" class="mb-4">
                    <FormPart
                        v-for="(formEl, i) in form.questions"
                        :key="i"
                        :form="form"
                        :form-el="formEl"
                        :rating="score"
                        :i="i"
                        @answer="onAnswer"
                        @see-profile="onSeeProfile"
                        @ping-admin="pingAdmin"
                        @submit="sendAnswers"
                        @contact="onContact"
                    />
                </div>
                <BaseLink
                    v-if="form && form.formStep && form.formStep > 1 && !isFinished"
                    href="#"
                    @click="() => goBack({ id: feedback.id, step: form?.form?.form_step })"
                >
                    {{ t('back') }}
                </BaseLink>
            </div>

            <div
                v-if="
                    (memberToShow &&
                        (isFirstStep || modalContact) &&
                        feedback.form.type === 'business_connection_for_partner_contact') ||
                    feedback.form.type === 'business_connection_rdv_for_partner_contact'
                "
                class="modal-content__link-container mt-2"
            >
                <BaseLink :to="{ name: ProRouteName.BUSINESS_CONNECTIONS }">
                    {{ t('go_business_solution') }}
                </BaseLink>
            </div>
        </div>
    </BaseModal>
</template>

<style scoped lang="scss">
    $avatarSize: var(--scale-20);
    .modal {
        &__header {
            background: var(--theme-color-nuage);
            text-align: center;
            border-radius: var(--scale-2) var(--scale-2) 0 0;

            &--legacy {
                background: var(--theme-color-legacy);
            }
        }
        &__header-info {
            &--reset-margin {
                margin-top: calc($avatarSize * -1);
            }
        }
        &__header-avatar-container {
            display: flex;
            justify-content: center;
        }
        &__header-avatar {
            transform: translate(0%, -50%);
            z-index: 9;
        }

        &__content {
            text-align: center;
            padding: var(--scale-6);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "date": "Le {date}",
        "go_business_solution": "Gérer dans mon espace business solutions",
        "ping_success": "Merci pour votre feedback. {admin} va être informé(e) de votre demande.",
        "back": "Retour",
        "toast": {
            "error": "Une erreur s'est produite lors du traitement de votre feedback, veuillez réessayer ultérieurement."
        }
    }
}
</i18n>
