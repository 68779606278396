<script setup lang="ts">
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import SmartActionCard from '@/components/partials/network/smart-actions/SmartActionCard.vue'
    import { SmartAction } from '@/types/response/members-api/SmartActions.type'
    import ModalLinkedinProfile from '@/components/partials/network/smart-actions/action-linkedin/ModalLinkedinProfile.vue'

    const props = defineProps<{
        action: SmartAction
    }>()

    const emit = defineEmits<{
        remove: [action: typeof props.action, isCompleted?: boolean]
        click: []
    }>()

    const { t } = useI18n()

    const isModalVisible = ref(false)

    defineExpose({
        name: 'SmartActionLinkedin',
        open: isModalVisible
    })
</script>

<template>
    <div>
        <SmartActionCard
            :action="action"
            :infos="{ icon: 'linkedin', title: t('title'), subtitle: t('subtitle') }"
            use-custom-icon
            @click="
                () => {
                    isModalVisible = true
                    emit('click')
                }
            "
            @remove="$emit('remove', action, false)"
        />
        <ModalLinkedinProfile v-model="isModalVisible" @finished="$emit('remove', action, true)" />
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "title": "Créez votre profil en 1 clic",
        "subtitle": "Connectez votre Linkedin"
    },
    "en": {
        "title": "Create your profile in 1 clic",
        "subtitle": "Connect your Linkedin profile"
    }
}
</i18n>
