import { ref } from 'vue'

// Commune à toutes les utilisations du hook
const isOpened = ref(false)

export default function useMenuNavigationState() {
    return {
        isOpened
    }
}
