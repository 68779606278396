import Cookies from 'js-cookie'

export default class SharedStorage {
    static get(key: string) {
        const cookie = Cookies.get(key)

        return (cookie && JSON.parse(cookie)) || null
    }

    static set(key: string, value: string | object, expires?: number | Date) {
        // expires : number in days
        Cookies.set(key, JSON.stringify(value), {
            expires,
            domain: import.meta.env.VITE_COOKIE_DOMAIN
        })
    }

    static delete(key: string) {
        Cookies.remove(key, { domain: import.meta.env.VITE_COOKIE_DOMAIN })
    }
}
