import { Breadcrumb } from '@/components/ui/basics/BaseBreadcrumb.v2.vue'
import { ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

// On met la ref en dehors de la composition pour qu'elle soit commune à tous les appels de la composition.
const breadcrumb = ref<Breadcrumb | null>(null)

export default function useBreadcrumb() {
    onBeforeRouteLeave(() => {
        breadcrumb.value = null
    })

    return {
        breadcrumb
    }
}
