import { regexContainSpecial, regexContainNumber } from '@/libs/helpers/string'
import { computed, Ref } from 'vue'

const MIN_LENGTH_PASSWORD = 10

export default function usePasswordSecurity(password: Ref<string>) {
    const level = computed<'strong' | 'medium' | 'low'>(() => {
        if (percentageSecurity.value > 70) {
            return 'strong'
        } else if (percentageSecurity.value > 40 && percentageSecurity.value < 70) {
            return 'medium'
        } else {
            return 'low'
        }
    })

    const lengthRule = computed(() => {
        return password.value.length >= MIN_LENGTH_PASSWORD
    })
    const containsSpecialRule = computed(() => {
        return regexContainSpecial.test(password.value)
    })
    const containsNumberRule = computed(() => {
        return regexContainNumber.test(password.value)
    })

    const percentageSecurity = computed<number>(() => {
        let value = 0
        if (password.value) {
            if (lengthRule.value) {
                value += 30
            } else {
                value += password.value.length * 2
            }

            if (password.value.length > 12) {
                value += 20
            }

            if (containsSpecialRule.value) {
                value += 30
            }

            if (containsNumberRule.value) {
                value += 20
            }
        }
        return value
    })

    return {
        percentageSecurity,
        rules: import.meta.env.DEV
            ? { required: true }
            : {
                  required: true,
                  min: MIN_LENGTH_PASSWORD,
                  regex: regexContainSpecial
              },
        level,
        lengthRule,
        containsSpecialRule,
        containsNumberRule
    }
}
