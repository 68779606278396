<script setup lang="ts">
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { useI18n } from 'vue-i18n'
    import RegisterModal from '../../register/RegisterModal.vue'
    import { ref } from 'vue'

    const showRegisterModal = ref(false)

    const { t } = useI18n()
</script>

<template>
    <CardWrapper class="register">
        <div class="register__inner register__inner--large">
            <CardWrapper class="register__card">
                <div>{{ t('title') }}</div>
            </CardWrapper>
        </div>
        <div class="register__content">
            <div class="register__content--subtitle">{{ t('subtitle') }}</div>
            <BaseButton :theme="ButtonTheme.PRIMARY" @click="showRegisterModal = true">
                {{ t('register') }}
            </BaseButton>
        </div>
        <RegisterModal
            :model-value="showRegisterModal"
            from="job"
            @update:model-value="showRegisterModal = false"
            @success="showRegisterModal = false"
        />
    </CardWrapper>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .register {
        $block-selector: &;

        position: relative;
        width: 100%;
        padding-top: 5.625em;
        padding-bottom: var(--scale-4);

        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: top;
        margin: 0 1em 1.25em;
        max-width: 18.75em;
        overflow: hidden;

        &::after {
            content: ' ';
            position: absolute;
            background-image: url('@/assets/patterns/abstract.svg');
            background-color: var(--theme-color-legacy);
            top: 0;
            left: 0;
            right: 0;
            height: 8rem;
            border-bottom-left-radius: 10%;
            border-bottom-right-radius: 10%;
        }

        @media (min-width: $breakpoints-lg) {
            margin: 0 0 1.25em;
        }

        &__content {
            position: relative;
            z-index: 1;
            padding: 0 2em;
            padding-bottom: var(--scale-4);
            text-align: center;

            &--subtitle {
                font-size: 0.875em;
                padding-bottom: var(--scale-1);
                color: var(--theme-text-color--secondary);
                margin-bottom: var(--scale-4r);
            }
        }

        &__inner {
            position: relative;
            z-index: 1;

            &--large {
                padding: 0 1.125em;
            }
        }

        &__card {
            font-weight: 700;
            font-size: 1.125em;
            text-align: center;
            padding: var(--scale-4);
            margin-bottom: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "register": "S'inscrire",
        "title": "Confiez nous votre job de rêve !",
        "subtitle": "Rejoignez le réseau Frenchfounders et son talent pool pour accéder à des opportunités personnalisées."
    }
}
</i18n>
