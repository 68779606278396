import { findContext, requiredAuthentification } from '@/libs/middlewares/auth'
import { InboxRouteName } from '@/vars/RouteName'
import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '/inbox',
        name: InboxRouteName.INBOX,
        component: () => import('@/views/common/inbox/InboxPage.vue'),
        meta: {
            context: findContext,
            middleware: [requiredAuthentification]
        }
    }
]

export default routes
