<script setup lang="ts">
    import { useTheme } from '@/libs/compositions/useTheme'
    import { ThemeName } from '@/vars/ThemeAttr'
    import { onMounted, ref } from 'vue'

    withDefaults(
        defineProps<{
            type?: 'default' | 'help'
            required?: boolean
        }>(),
        {
            type: 'default'
        }
    )

    const { theme } = useTheme()
    const label = ref<HTMLElement | null>(null)
    const removeAsterisk = ref(false)

    onMounted(() => {
        if (label.value && label.value.innerText.includes('*')) {
            removeAsterisk.value = true
        }
    })
</script>

<template>
    <label
        class="label text-xs"
        :class="{
            'label--help': type === 'help',
            'label--mondrian': theme === ThemeName.MONDRIAN
        }"
    >
        <slot ref="label"></slot>
        <span v-if="required && !removeAsterisk" class="label__asterisk">*</span>
    </label>
</template>

<style lang="scss" scoped>
    .label {
        $block: &;
        display: block;

        &--help {
            font-weight: 300;
            font-size: 0.75em;
        }

        &__asterisk {
            color: var(--theme-error-color);
            margin-left: 0.2em;

            #{$block}--mondrian & {
                color: var(--theme-color-error);
            }
        }
    }
</style>
