<script setup lang="ts">
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'

    const emit = defineEmits<{
        (e: 'close'): void
    }>()
</script>

<template>
    <div class="dropdown">
        <div class="dropdown__overlay" @click="emit('close')"></div>
        <ContainerTemplate class="dropdown__container" @click.stop>
            <slot></slot>
        </ContainerTemplate>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        background: var(--theme-background-color);
        color: var(--theme-text-color);
        padding: 1.25em 0;
        @apply shadow;

        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            height: 1.25em;
            left: 0;
            right: 0;
        }

        &__overlay {
            position: fixed;
            z-index: -2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--theme-text-color);
            opacity: 0.8;

            @media only screen and (min-width: $breakpoints-lg) {
                display: none;
            }
        }
    }
</style>
