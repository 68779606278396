<script setup lang="ts">
    import InfiniteLoader from '@/components/ui/loaders/InfiniteLoader.vue'
    import BemTransition from '@/components/ui/transitions/BemTransition.vue'
    import { useTheme } from '@/libs/compositions/useTheme'
    import { ThemeName } from '@/vars/ThemeAttr'
    import { ButtonSize, ButtonTheme, ButtonType } from '@/vars/ButtonAttr'
    import { computed, useAttrs } from 'vue'
    import { RouteLocationNamedRaw, RouteLocationRaw, useRouter } from 'vue-router'
    import BaseButtonV2 from '../actions/BaseButton.v2.vue'
    /**
     * @deprecated
     */

    const BLOCK_CLASS = 'button'

    const router = useRouter()
    const attrs = useAttrs()
    const { theme: themeUse } = useTheme()
    const props = defineProps<{
        tag?: string
        type?: ButtonType.BUTTON | ButtonType.SUBMIT
        theme?: ButtonTheme
        size?: ButtonSize
        to?: RouteLocationRaw
        href?: string
        disabled?: boolean
        pending?: boolean
        block?: boolean
        bordered?: boolean
        reverse?: boolean
        rounded?: boolean
        hover?: boolean
        notCenter?: boolean
        isPill?: boolean
        isSelected?: boolean
        isPro?: boolean
        testId?: string
    }>()

    const tag = computed(
        () =>
            props.tag ||
            (props.disabled
                ? ButtonType.BUTTON
                : props.to
                  ? ButtonType.ROUTER_LINK
                  : props.href
                    ? ButtonType.LINK
                    : ButtonType.BUTTON)
    )

    const typeAttr = computed(() => {
        if (props.type && [ButtonType.BUTTON, ButtonType.SUBMIT].includes(props.type)) {
            return props.type
        }

        if (!props.href && !props.to) {
            return ButtonType.BUTTON
        }

        return undefined
    })

    const classList = computed(() => [
        BLOCK_CLASS,
        props.theme && `${BLOCK_CLASS}--${props.theme}`,
        props.size && `${BLOCK_CLASS}--${props.size}`,
        {
            [`${BLOCK_CLASS}--disabled`]: props.disabled,
            [`${BLOCK_CLASS}--pending`]: props.pending,
            [`${BLOCK_CLASS}--block`]: props.block,
            [`${BLOCK_CLASS}--reverse`]: props.reverse,
            [`${BLOCK_CLASS}--border`]: props.bordered,
            [`${BLOCK_CLASS}--rounded`]: props.rounded,
            [`${BLOCK_CLASS}--hovered`]: props.hover,
            [`${BLOCK_CLASS}--not-center`]: props.notCenter,
            [`${BLOCK_CLASS}--pill`]: props.isPill,
            [`${BLOCK_CLASS}--pill-pro`]: props.isPro,
            [`${BLOCK_CLASS}--selected`]: props.isSelected && !props.isPro,
            [`${BLOCK_CLASS}--selected-pro`]: props.isSelected && props.isPro
        }
    ])

    const boundings = computed(() => {
        const href = props.href ? props.href : props.to ? router.resolve(props.to).href : undefined
        return {
            ...attrs,
            disabled: props.disabled || undefined,
            to: props.to,
            href,
            type: typeAttr.value,
            class: [attrs.class, ...classList.value]
        }
    })

    const themeMappping = computed(() => {
        switch (props?.theme) {
            case ButtonTheme.PRIMARY:
            case ButtonTheme.GHOST:
            case ButtonTheme.SECONDARY:
            case ButtonTheme.DISCRET:
            case ButtonTheme.TERTIARY: {
                return props.theme
            }
            case ButtonTheme.SIMPLE: {
                return ButtonTheme.SECONDARY
            }
            default: {
                return ButtonTheme.PRIMARY
            }
        }
    })
</script>

<template>
    <template v-if="themeUse === ThemeName.MONDRIAN">
        <BaseButtonV2
            :theme="themeMappping"
            :href="props?.href"
            :to="props?.to ? (props?.to as RouteLocationNamedRaw) : undefined"
            :disabled="props?.disabled || undefined"
            :pending="props?.pending"
            :type="props?.type"
            :block="props?.block"
            :test-id="testId"
            v-bind="attrs"
        >
            <slot></slot>
        </BaseButtonV2>
    </template>
    <template v-else>
        <Component :is="tag" v-bind="boundings" :data-testid="testId">
            <span :class="`${BLOCK_CLASS}__inner`">
                <slot></slot>
            </span>
            <BemTransition :name="BLOCK_CLASS">
                <span v-if="pending" :class="`${BLOCK_CLASS}__loader`">
                    <slot name="loader">
                        <InfiniteLoader />
                    </slot>
                </span>
            </BemTransition>
        </Component>
    </template>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/mixins/button.scss';

    .button {
        $block-selector: &;
        --button-text-color: var(--theme-background-color);
        --button-background-color: var(--theme-text-color);
        --button-border-color: transparent;
        --button-is-gradient: false;
        --button-gradient-fallback: null;

        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: var(--button-background-color);
        color: var(--button-text-color);
        text-align: center;
        text-decoration: none;
        font-family: inherit;
        font-style: normal;
        font-size: 0.875rem;
        font-weight: 500;
        vertical-align: middle;
        box-shadow: none;
        border-radius: 0.375em;
        border: 1px solid var(--button-border-color);
        padding: 0.75rem 1em;
        overflow: hidden;

        &:not([disabled]):not(#{$block-selector}--disabled) {
            cursor: pointer;
        }

        @include hover($block-selector) {
            background: var(--button-text-color);

            @if var(--button-is-gradient) {
                color: var(--button-gradient-fallback);
                border-color: var(--button-gradient-fallback);
            } @else {
                color: var(--button-background-color);
                border-color: var(--button-background-color);
            }
        }

        // transition
        &--enter-active,
        &--leave-active {
            transition-property: opacity, transform;
            transition-duration: 150ms;
        }

        &--enter-from,
        &--leave-to {
            opacity: 0;
            transform: translateY(100%);
        }

        &--enter-to,
        &--leave-from {
            opacity: 1;
            transform: translateY(0);
        }

        // themes
        &--discret {
            padding: 0;
            color: inherit;
            background: none;
            border-radius: 0;
            border: 0;
            line-height: 1.5;
            font-weight: normal;
            font-size: inherit;
        }

        &--pill {
            --background-color: transparent;
            --text-color: currentColor;
            --border-color: currentColor;

            display: inline-block;
            font-size: 0.875em;
            border: 1px solid var(--border-color);
            color: var(--text-color);
            padding: 0.5em 1.75em;
            transition: all 150ms;
            background: var(--background-color);
            border-radius: 99em;

            &:hover {
                transform: translateY(-0.15em);
            }

            &:active {
                box-shadow: none;
                transform: translateY(0);
            }
        }

        &--pro {
            font-weight: 600;
            --background-color: var(--theme-placeholder-color);
            --text-color: rgba(var(--theme-text-color--rgb), 0.5);
            --border-color: currentColor;
        }

        &--royale {
            font-weight: 600;
            --button-background-color: rgba(var(--theme-background-color--blue--royale--rgb));
            --button-text-color: var(--theme-primary-text-color);
            --button-border-color: currentColor;
        }

        &--selected-pro {
            --background-color: var(--theme-border-color);
            --text-color: var(--theme-text-color);
            --border-color: currentColor;
        }

        &--selected {
            --text-color: var(--theme-primary-color);
            --border-color: var(--theme-primary-color);
        }

        &--primary {
            --button-background-color: var(--theme-primary-gradient);
            --button-text-color: var(--theme-primary-text-color);
            --button-gradient-fallback: var(--theme-primary-color);
            --button-is-gradient: true;
            --button-border-color: transparent;
        }

        &--simple {
            --button-background-color: var(--theme-button-color);
            --button-text-color: var(--theme-button-text-color);
            --button-gradient-fallback: var(--theme-button-color);
            --button-is-gradient: false;
            --button-border-color: transparent;
        }

        &--success {
            --button-background-color: var(--theme-success-color);
            --button-text-color: var(--theme-primary-text-color);
            --button-gradient-fallback: var(--theme-success-color);
            --button-is-gradient: true;
            --button-border-color: transparent;
        }

        &--reverse {
            background: var(--button-text-color);

            @if var(--button-is-gradient) {
                color: var(--button-gradient-fallback);
                border-color: var(--button-gradient-fallback);
            } @else {
                color: var(--button-background-color);
                border-color: var(--button-background-color);
            }

            @include hover($block-selector) {
                background: var(--button-background-color);
                color: var(--button-text-color);
                border-color: transparent;
            }
        }

        // sizes
        &--small {
            padding: 0.75em 1.25em;
            font-size: 0.875em;
        }

        // other styles
        &--block {
            width: 100%;
        }

        &--border {
            border-color: var(--button-text-color);

            @include hover($block-selector) {
                border-color: var(--button-text-color);
            }
        }

        &--not-center {
            justify-content: flex-start;
            text-align: initial;
        }

        &--disabled {
            cursor: not-allowed;
            opacity: 0.35;
            --button-background-color: var(--theme-text-color--secondary);
        }

        &--pending {
            cursor: wait;
        }

        &--rounded {
            border-radius: 99em;
        }

        &__inner {
            position: relative;
            z-index: 1;
            transition: all 150ms;

            #{$block-selector}--block & {
                width: 100%;
            }

            #{$block-selector}--pending & {
                color: transparent;
                fill: transparent;
            }
        }

        &__loader {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: wait;
        }
    }
</style>
