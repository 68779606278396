<script setup lang="ts">
    import { useRouter } from 'vue-router'
    import { useI18n } from 'vue-i18n'
    import LocalStorage from '@/libs/core/LocalStorage'
    import { AuthRouteName } from '@/vars/RouteName'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'

    const props = withDefaults(
        defineProps<{
            type: 'register' | 'login'
            size?: 'small' | 'large'
        }>(),
        {
            size: 'small'
        }
    )

    const { t } = useI18n()
    const router = useRouter()

    const scope = 'r_liteprofile r_emailaddress'

    function getRedirectUri() {
        const fullPath = router.resolve({
            name: AuthRouteName.OAUTH_LINKEDIN,
            query: {
                action: props.type
            }
        }).fullPath

        return encodeURIComponent(window.location.origin + fullPath)
    }

    function onClick() {
        LocalStorage.set('linkedinOAuthRedirect', window.location.href)
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
            import.meta.env.VITE_APP_LINKEDIN_AUTH_CLIENT_ID
        }&redirect_uri=${getRedirectUri()}&scope=${scope}`
    }
</script>

<template>
    <div class="linkedin" :class="[size === 'large' ? 'linkedin--large' : 'linkedin--small']" @click="onClick">
        <img v-if="size === 'small'" src="@/assets/images/logos/linkedin.png" :alt="t('logo')" class="linkedin__logo" />
        <BaseIcon v-if="size === 'large'" class="linkedin__logo-large title-4" name="linkedin2" />
        <span v-if="size === 'large'" class="text-small"> {{ t('login') }}</span>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    $linkedinColor: #007eb7;

    .linkedin {
        &--small {
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            border: 0;
            margin: 0 var(--scale-2);
            background-color: $linkedinColor;
            cursor: pointer;

            :hover {
                background-color: adjust-color($linkedinColor, $whiteness: 60%);
                border-radius: 50%;
            }
        }

        &__logo {
            width: 2.5rem;
        }

        &__logo-large {
            margin-right: var(--scale-4);
            margin-left: var(--scale-2);
        }

        &--large {
            @apply rounded;
            display: flex;
            width: auto;
            background-color: initial;
            min-height: 2.8125em;
            border: var(--scale-px) solid;
            border-color: var(--theme-border-color);
            margin: 0;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-bottom: var(--scale-4);
            padding: var(--scale-2);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "logo": "Linkedin logo",
        "register_errors_linkedin": "Une erreur est survenue avec LinkedIn, veuillez réessayer.",
        "register_errors_social409": "L'e-mail rattaché à votre compte est déjà utilisé sur Frenchfounders",
        "login": "Se connecter avec Linkedin"
    },
    "en": {
        "logo": "Linkedin logo",
        "register_errors_linkedin": "An error occurred with LinkedIn, please try again.",
        "register_errors_social409": "The email associated with your account is already used on Frenchfounders.",
        "login": "Connect withLinkedin"
    }
}
</i18n>
