import Http from './ApiClient'
import { Meta } from '@/types/API.type'
import { ResponseV3 } from '@/types/response/ResponseV3.type'
import {
    CircleSingle,
    CircleEvent,
    CircleMember,
    CircleMemberMeta,
    Circle,
    CircleNetworkingSession
} from '@/types/response/circles-api/GetCircles'
import { GetCirclesForm } from '@/types/forms/circles-api/GetCirclesForm.type'
import { GenericAbortSignal } from 'axios'
import { CircleInviteLink } from '@/types/response/circles-api/GetCirclelnviteLinks'
import { ModerationStatus } from '@/types/forms/circles-api/ModerationStatus.type'
import { ModerationMember } from '@/types/response/circles-api/ModerationMember.type'

class CirclesApi {
    static async getCircles(getCirclesForm?: GetCirclesForm): Promise<ResponseV3<Circle[]>> {
        const response = await Http.get('/v3/circles', {
            params: getCirclesForm
        })

        return response.data
    }
    static async getCircle(slug: string): Promise<ResponseV3<CircleSingle, { statusCode: number }>> {
        const response = await Http.get(`/v3/circles/${slug}`)
        // Append response code to metas
        response.data.meta.statusCode = response.status
        return response.data
    }

    static async getCircleEvents(
        slug: string,
        params: {
            page: number
            type: 'upcoming' | 'past'
        }
    ): Promise<ResponseV3<CircleEvent[], Meta>> {
        const response = await Http.get(`/v3/circles/${slug}/events`, {
            params
        })

        return response.data
    }

    static async getCircleNetworkingSessions(
        slug: string,
        params: {
            page: number
            type: 'upcoming' | 'past'
        }
    ): Promise<ResponseV3<CircleNetworkingSession[], Meta>> {
        const response = await Http.get(`/v3/circles/${slug}/networking-sessions`, {
            params
        })

        return response.data
    }

    static async getCircleMembers(
        slug: string,
        params: { page: number }
    ): Promise<ResponseV3<CircleMember[], CircleMemberMeta>> {
        const response = await Http.get(`/v3/circles/${slug}/members/`, {
            params
        })

        return response.data
    }

    static async getModerationMembers(
        form: { clubId: string; status: ModerationStatus },
        params: { page: number }
    ): Promise<ResponseV3<ModerationMember[], CircleMemberMeta>> {
        const response = await Http.get(`/v3/circles/moderation/${form.clubId}`, {
            params: { ...params, status: form.status }
        })

        return response.data
    }

    static async moderateCircleMembers(
        circleId: number | string,
        details: {
            status: 'ACCEPTED' | 'REFUSED'
            message?: string
            memberId: string
        }
    ): Promise<unknown> {
        const response = await Http.patch(`/v3/circles/moderation/${circleId}`, details)

        return response.data
    }

    static async searchCircleMembers(
        id: number | string,
        search: string,
        axiosOpts?: { signal?: GenericAbortSignal }
    ): Promise<ResponseV3<CircleMember[], CircleMemberMeta>> {
        const response = await Http.post(
            `/v3/circles/${id}/members`,
            { filters: { search: { name: search } } },
            { signal: axiosOpts?.signal }
        )

        return response.data
    }

    static async joinCircle(slug: string, message?: string): Promise<unknown> {
        const response = await Http.post(`/v3/circles/${slug}/join`, { message: message })

        return response.data
    }

    static async createInviteLink(circleId: string): Promise<ResponseV3<CircleInviteLink>> {
        const response = await Http.post(`/v3/circles/invite-links`, { circle: circleId })

        return response.data
    }

    static async verifyInviteLink(token: string): Promise<ResponseV3<CircleInviteLink>> {
        const response = await Http.get(`/v3/circles/invite-links/${token}`)

        return response.data
    }
}

export default CirclesApi
