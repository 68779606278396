<script setup lang="ts">
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { RouteName, ProRouteName, ClubRouteName } from '@/vars/RouteName'
    import { BusinessLineName } from '@/vars/AuthAttr'
    import usePublicHeaderNavigation from '@/libs/compositions/usePublicHeaderNavigation'
    import { ref } from 'vue'
    import { RouteLocationNamedRaw } from 'vue-router'
    import DropdownContent from '@/components/ui/navigations/dropdowns/DropdownContent.vue'
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import { useNavigationResolver } from '@/libs/compositions/useNavigationResolver'
    import { SpaName } from '@/vars/SpaAttr'

    const authStore = useAuthStore()
    const { universeNavigation } = usePublicHeaderNavigation()
    const { navigate } = useNavigationResolver()

    const currentSpa = import.meta.env.VITE_SPA_RUNNING

    export type Link = {
        icon?: string
        text: string
        href?: string
        to?: RouteLocationNamedRaw
    }

    type Space = 'bs' | 'club'

    defineEmits<{
        'close-menu': [space: Space]
        mouseenter: [e: Event]
    }>()

    const hoverSpace = ref<string | null>(null)

    const onClickSpace = (space: string) => {
        if (space === 'club' && authStore.hasBusinessLine(BusinessLineName.LECLUB)) {
            navigate({ name: ClubRouteName.HOME })
        } else if (space === 'bs' && authStore.hasBusinessLine(BusinessLineName.BUSINESS)) {
            navigate({ name: ProRouteName.HOME })
        } else {
            if (authStore.isLoggedIn) {
                navigate({ name: RouteName.HOME })
            } else {
                navigate({ name: RouteName.LOGIN })
            }
        }
    }

    const isActive = (space: string) => {
        let spa: SpaName | null = null

        switch (space) {
            case 'club':
                spa = SpaName.LECLUB
                break
            case 'bs':
                spa = SpaName.PRO
                break
            default: {
                spa = authStore.isLoggedIn ? SpaName.LENETWORK : null
            }
        }

        return spa === currentSpa
    }
</script>

<template>
    <div
        class="header-navigation text-xs"
        :class="[`header-navigation--${currentSpa}`]"
        @mouseenter="$emit('mouseenter', $event)"
    >
        <template v-for="(space, i) in universeNavigation" :key="space.key">
            <DropdownNavigation
                show-on-hover
                class="header-navigation__profile"
                @open="hoverSpace = space.key"
                @close="hoverSpace = null"
            >
                <template #activator>
                    <Component
                        :is="space.hasRole ? SpaRouterLink : 'div'"
                        :to="space.hasRole ? space.to : null"
                        class="header-navigation__activator"
                        :class="{
                            'header-navigation__activator--active': isActive(space.key)
                        }"
                        @click="onClickSpace(space.key)"
                    >
                        <span class="spaces__title">{{ space.label }}</span>
                    </Component>
                </template>
                <template #dropdown>
                    <DropdownContent
                        v-if="!space.hasRole"
                        class="header-navigation__content"
                        :class="`header-navigation__content--${space.key}`"
                        :align="universeNavigation[i + 1]?.key ? 'right' : 'left'"
                    >
                        <section class="header-navigation__space">
                            <div class="header-navigation__head mb-6 text-sm">
                                {{ space.text }}
                            </div>
                            <div class="header-navigation__items">
                                <Component
                                    :is="link.to ? SpaRouterLink : 'a'"
                                    v-for="link in space.items"
                                    :key="link.href"
                                    :to="link.to"
                                    :href="link.href"
                                    class="header-navigation__item"
                                >
                                    <div>{{ link.label }}</div>
                                </Component>
                            </div>
                        </section>
                    </DropdownContent>
                </template>
            </DropdownNavigation>
            <div v-if="universeNavigation[i + 1]" class="header-navigation__separator"></div>
        </template>
    </div>
</template>

<style scoped lang="scss">
    .header-navigation {
        display: flex;
        gap: var(--scale-4);
        justify-content: end;
        align-items: end;

        &--lenetwork {
            position: relative;
            width: 100%;
            z-index: 50;
            @apply bg-nuage px-10;
        }

        &__separator {
            width: var(--scale-px);
            margin-top: var(--scale-4);
            background-color: var(--theme-color-gray--04);
        }

        &__activator {
            border-top: solid 0.25em transparent;
            padding-top: var(--scale-4);

            &--active {
                border-top: solid 0.25em var(--theme-color-royale);
            }
        }

        &__content {
            padding: var(--scale-4);
            margin-top: var(--scale-6);
            color: var(--theme-text-color);
            width: 22em;

            &--bs {
                width: 22em;
            }
        }

        &__profile {
            position: relative;
        }

        &__title {
            font-size: 1.125em;
            font-weight: 500;
        }

        &__separator {
            height: 1.125em;
            background-color: var(--theme-color-gray--05);
            width: var(--scale-px);
        }

        &__items {
            display: flex;
            flex-direction: column;
            gap: var(--scale-4);
        }

        &__item {
            display: flex;
            align-items: center;
            gap: var(--scale-2);
            cursor: pointer;
            font-weight: 500;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "public": "Réseau public"
    },
    "en": {
        "public": "Public Network"
    }
}
</i18n>
