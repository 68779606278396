import { RegisterTracking } from '@/types/Auth.type'

export function withTracking(form: Record<string, unknown>): RegisterTracking {
    const queryParams = new URLSearchParams(window.location.search)

    let redirectUrl: string | undefined = undefined

    for (const [queryParamKey, queryParamValue] of queryParams) {
        if (queryParamKey === 'redirect' || queryParamKey === 'redirectUrl') {
            redirectUrl = queryParamValue
        }
    }

    const utmList = Object.entries(window.sessionStorage).reduce(
        (utmObject, [storageKey, storageValue]) => {
            if (storageKey.startsWith('utm_')) {
                utmObject[storageKey.replace('utm_', '')] = storageValue
            }

            return utmObject
        },
        {} as Record<string, string>
    )

    return {
        ...form,
        source: {
            redirectUrl,
            currentUrl: ['/login', '/register'].includes(window.location.pathname) ? undefined : window.location.href,
            utm: utmList
        }
    }
}
