<script setup lang="ts">
    import LogoTikehau from '@/assets/images/logos/partners/tikehau_light.svg'
    import LogoContentSquare from '@/assets/images/logos/partners/Contentsquare_light.svg'
    import LogoCooley from '@/assets/images/logos/partners/cooley_light.svg'
    import LogoBPI from '@/assets/images/logos/partners/bpi_light.svg'
    import LogoBNP from '@/assets/images/logos/partners/BNP_WM_light.svg'

    type Partner = {
        key: string
        src: string
        href: string
    }

    defineProps<{
        dark?: boolean
    }>()

    const partners: { primary: Partner[]; secondary: Partner[] } = {
        primary: [
            {
                key: 'tikehau',
                src: LogoTikehau,
                href: 'https://www.tikehaucapital.com/fr/'
            },
            {
                key: 'bpi',
                src: LogoBPI,
                href: 'https://www.bpifrance.fr/'
            },
            {
                key: 'bnp',
                src: LogoBNP,
                href: 'https://wealthmanagement.bnpparibas/fr.html'
            }
        ],
        secondary: [
            {
                key: 'contentsquare',
                src: LogoContentSquare,
                href: 'https://contentsquare.com/'
            },
            {
                key: 'cooley',
                src: LogoCooley,
                href: 'https://www.cooley.com/'
            }
        ]
    }
</script>

<template>
    <div class="partners" :class="{ 'partners--dark': dark }">
        <div class="partners__list">
            <a
                v-for="partner in partners.primary"
                :key="partner.key"
                :href="partner.href"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    class="partners__item"
                    :class="`partners__item--${partner.key}`"
                    :src="partner.src"
                    loading="lazy"
                />
            </a>
        </div>

        <div class="partners__list mt-8">
            <a
                v-for="partner in partners.secondary"
                :key="partner.key"
                :href="partner.href"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    class="partners__item"
                    :class="`partners__item--${partner.key}`"
                    :src="partner.src"
                    loading="lazy"
                />
            </a>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .partners {
        $block: &;
        background-color: var(--theme-color-flagship--10);

        &--dark {
            background-color: var(--theme-color-legacy);
        }

        &__list {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            gap: var(--scale-14);
        }
        &__item {
            filter: invert(50%);
            height: 1.5em;

            #{$block}--dark & {
                filter: none;
            }

            &--bpi {
                height: 1.875em;
            }

            &--contentsquare {
                height: 2.125em;
            }
        }
    }
</style>
