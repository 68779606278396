<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue'
    import { useUrlSearchParams } from '@vueuse/core'
    import { useMutation, useQuery } from '@tanstack/vue-query'
    import { QueryName } from '@/vars/QueryAttr'
    import FeedbackApi from '@/libs/api/Feedback.api'
    import ModalFeedBackEvent from './partials/ModalFeedBackEvent.vue'
    import ModalFeedBackRelationship from './partials/feedback-relationship/ModalFeedBackRelationship.vue'
    import { useRoute } from 'vue-router'

    const params = useUrlSearchParams('history')
    const route = useRoute()
    const paramFeedbackId = ref('')
    const paramFeedbackScore = ref('0')

    onMounted(() => {
        if (params && params.__fb) {
            paramFeedbackId.value = params.__fb as string
            paramFeedbackScore.value = (params.__fbs as string) || '0'
        }
        findFeedbackInQuery()
    })

    watch(
        () => route.query,
        () => {
            if (route.query.__fb) {
                paramFeedbackId.value = route.query.__fb as string
                paramFeedbackScore.value = (route.query.__fbs as string) || '0'
                findFeedbackInQuery()
            }
        }
    )

    const findFeedbackInQuery = () => {
        if (paramFeedbackId.value) {
            getFeedback({ id: paramFeedbackId.value, step: paramFeedbackScore.value })
        } else {
            refetch()
        }
    }

    const { data: feedback, mutate: getFeedback } = useMutation({
        mutationFn: (params: { id: string; step?: string }) => {
            if (params.step) {
                return FeedbackApi.getFeedback(params.id, params.step)
            } else {
                return FeedbackApi.getFeedback(params.id)
            }
        }
    })

    const { data: feedbacks, refetch } = useQuery({
        queryKey: [QueryName.FEEDBACK_PENDING],
        queryFn: FeedbackApi.getPendingFeedbackIdList,
        enabled: false
    })

    watch(feedbacks, () => {
        if (feedbacks.value && feedbacks.value.length > 0) {
            getFeedback({ id: feedbacks.value[0].id.toString() })
        }
    })
</script>

<template>
    <ModalFeedBackEvent v-if="feedback && feedback.event" :feedback="feedback" :score="Number(paramFeedbackScore)" />
    <ModalFeedBackRelationship
        v-if="feedback && feedback.relationship_establishment"
        :feedback="feedback"
        :score="Number(paramFeedbackScore)"
    />
</template>

<i18n lang="json">
{
    "fr": {},
    "en": {}
}
</i18n>
