<script setup lang="ts">
    import PhoneCountryPrefixList from '@/vars/PhoneCountryPrefixList'
    import { FieldMeta } from 'vee-validate'
    import { computed, useAttrs } from 'vue'
    import BaseIcon from '../../basics/BaseIcon.vue'
    import { Suffix } from '../FormField.vue'

    type ModelValue = string | number | string[] | null | undefined

    const attrs = useAttrs()

    const props = withDefaults(
        defineProps<{
            name: string
            type?: 'textarea' | 'tel' | string
            phonePrefix?: string
            error?: string
            handleBlur: (e?: Event | undefined) => void
            handleChange: (e: unknown, shouldValidate?: boolean | undefined) => void
            errorMessage?: string
            suffix?: string
            suffixOptions?: Suffix[]
            suffixLabel?: string
            meta: FieldMeta<
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
            >
            value:
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
        }>(),
        {
            value: null,
            type: 'text',
            error: '',
            errorMessage: '',
            phonePrefix: '+358',
            suffix: '',
            suffixLabel: '',
            options: () => [],
            suffixOptions: () => []
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
        (e: 'update:suffix', suffix?: string): void
        (e: 'update:phone-prefix', value: string): void
    }>()

    const isInputError = computed(() => Boolean(props.error || (props.errorMessage && props.meta.touched)))

    const onInputChange = (e: Event) => {
        let value: string | null = (e.target as HTMLInputElement).value
        if (props.type === 'date' && value === '') {
            value = null
        }
        props.handleChange(value)
        emit('update:modelValue', value)
    }

    const onChangePhonePrefix = (event: Event) => {
        const selectEl = event.target as HTMLSelectElement
        const value = selectEl.value

        emit('update:phone-prefix', value)
    }

    const onChangeSuffix = (e: Event) => {
        const target = e.target as HTMLSelectElement
        emit('update:suffix', target.selectedOptions[0].value)
    }
</script>

<template>
    <div v-if="type === 'tel'" class="field__phone-prefix">
        {{ phonePrefix }}
        <BaseIcon legacy class="field__phone-prefix-icon" name="chevron-down" />
        <select class="field__phone-prefix-select" @input="onChangePhonePrefix">
            <option
                v-for="country in PhoneCountryPrefixList"
                :key="country.countryCode"
                :value="country.phonePrefix"
                :selected="phonePrefix === country.phonePrefix"
            >
                {{ country.countryName }} ({{ country.phonePrefix }})
            </option>
        </select>
    </div>
    <input
        :id="name"
        :name="name"
        class="field__input"
        :class="{
            'field__input--phone': type === 'tel',
            'field__input--suffixable': !!suffixOptions?.length,
            'field__input--error': isInputError
        }"
        :type="type"
        :value="value"
        v-bind="attrs"
        @input="onInputChange"
        @blur="handleBlur"
    />
    <div v-if="suffixOptions.length > 0" class="field__suffix-wrapper">
        <select class="field__suffix" :value="suffix" @change="onChangeSuffix">
            <option value="" disabled>
                {{ suffixLabel }}
            </option>
            <option v-for="opt in suffixOptions" :key="opt.id" :value="opt.id">
                {{ opt.name }}
            </option>
        </select>
        <i class="field__suffix-arrow icon-chevron-down"></i>
    </div>
</template>

<style lang="scss" scoped>
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;
    $placeholderColor: var(--theme-color-gray--04);

    .field {
        $block-selector: &;
        position: relative;

        &__phone-prefix {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--theme-text-color);
            font-size: 0.875em;
            padding: 0 0.75em;
            font-weight: bold;
            color: var(--theme-background-color);
            border-top-left-radius: $inputRadius;
            border-bottom-left-radius: $inputRadius;
            min-width: $phonePrefixWidth;
            text-align: center;
        }

        &__phone-prefix-icon {
            margin-left: 0.5em;
            font-size: 0.75em;
        }

        &__phone-prefix-select {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            max-width: $phonePrefixWidth;
        }

        &__suffix-wrapper {
            display: flex;
            align-items: center;
            border: solid 1px $placeholderColor;
            border-top-right-radius: $inputRadius;
            border-bottom-right-radius: $inputRadius;
            border-left: none;
        }

        &__suffix {
            border: none;
            height: 100%;
            appearance: none;
            padding: 0 1.25em;
            font-size: 0.875em;
            font-weight: 600;
            color: var(--theme-text-color);
            z-index: 1;
            background-color: transparent;
            outline: none;
        }

        &__suffix-arrow {
            font-size: 0.75em;
            font-weight: bold;
            color: var(--theme-text-color);
            margin-right: 1em;
            margin-left: -1em;
            z-index: 0;
        }

        &__input {
            color: var(--theme-text-color);
            width: 100%;
            max-width: 100%;
            font-size: 0.875em;
            border-radius: $inputRadius;
            padding: 0.7em;
            border: solid 1px $placeholderColor;
            outline-color: rgba(var(--theme-text-color--rgb), 0.5);
            background-color: var(--theme-background-color);

            &--phone {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &--suffixable {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &--error {
                border-color: var(--theme-error-color);
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $placeholderColor;
                font-weight: 300;
                opacity: 1; /* Firefox */
            }

            #{$block-selector}--disabled & {
                opacity: 0.5;
                cursor: not-allowed;
            }

            @media (hover: hover) {
                &[type='date']::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }
            }
        }
    }
</style>
