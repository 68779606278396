import { toMilliseconds } from '@/libs/helpers/numbers'
import axios, { AxiosError } from 'axios'

function debouncePromise(promisedFn: () => unknown, timeMs: number) {
    return new Promise((resolve, reject) => {
        setTimeout(async () => {
            try {
                const result = await promisedFn()
                resolve(result)
            } catch (e) {
                reject(e)
            }
        }, timeMs)
    })
}

export default async function corsPreventionWithReload(error: AxiosError) {
    if (error.config?.params?.__retry) {
        throw error
    }

    if (error.config) {
        if (typeof error.response === 'undefined' && error.code !== 'ECONNABORTED') {
            if (!error.config.params) {
                error.config.params = {}
            }

            error.config.params.__retry = true

            return debouncePromise(
                async () => {
                    if (error.config) {
                        return await axios.request(error.config)
                    }
                },
                toMilliseconds({ seconds: 1 })
            )
        }
    }

    return Promise.reject(error)
}
