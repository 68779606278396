<script setup lang="ts">
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { useI18n } from 'vue-i18n'
    import { SpaName } from '@/vars/SpaAttr'

    const authStore = useAuthStore()
    const { t } = useI18n()
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
</script>

<template>
    <div v-if="authStore.isOnSwitchTo" class="bar" :class="{ 'bar--network': spaRunning === SpaName.LENETWORK }">
        {{ t('on_switch_to', { fullName: authStore.authentifiedUser?.full_name }) }}

        <span class="bar__exit" @click="authStore.exitSwitchToUser">
            {{ t('exit') }}
        </span>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .bar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75em;
        font-weight: 600;
        padding: var(--scale-2);
        background-color: var(--theme-color-ray);
        z-index: 1;

        &--network {
            @media screen and (min-width: $breakpoints-lg) {
                padding-left: 20rem;
            }
        }

        &__exit {
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "on_switch_to": "Vous êtes actuellement connecté en 'switch to' sur le compte de {fullName}",
        "exit": "Retour à mon compte"
    }
}
</i18n>
