<script setup lang="ts">
    import { AlertStatus } from '@/vars/AlertAttr'
    import { computed } from 'vue'
    import BaseIcon from '../basics/BaseIcon.vue'
    import CardBody from '../wrappers/cards/CardBody.vue'
    import CardWrapper from '../wrappers/cards/CardWrapper.vue'
    import ErrorIllstration from '@/assets/images/illustrations/error_mondrian.svg'

    const props = withDefaults(
        defineProps<{
            type?: AlertStatus
            icon?: string
        }>(),
        { icon: undefined, type: undefined }
    )

    const iconName = computed(() => {
        if (props.icon) return props.icon

        switch (props.type) {
            case AlertStatus.ERROR:
                return 'danger'
            case AlertStatus.INFO:
                return 'help'
            default:
                return undefined
        }
    })

    const typeAttr = computed(() => props.type || AlertStatus.INFO)
</script>

<template>
    <CardWrapper class="alert" :class="`alert--${typeAttr}`">
        <CardBody class="alert__body">
            <div v-if="type === AlertStatus.ERROR">
                <img :src="ErrorIllstration" />
            </div>
            <div v-else-if="iconName" class="alert__sign">
                <BaseIcon legacy class="alert__icon" :name="iconName" />
            </div>
            <div class="alert__message">
                <slot></slot>
            </div>
        </CardBody>
    </CardWrapper>
</template>

<style scoped lang="scss">
    .alert {
        $block-selector: &;

        &__body {
            display: flex;
            align-items: center;
        }

        &__sign {
            position: relative;
            width: 3.75em;
            height: 3.75em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin-right: 1.1875em;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 50%;
                opacity: 0.1;

                #{$block-selector}--info & {
                    background: var(--theme-primary-gradient);
                }

                #{$block-selector}--error & {
                    background: var(--theme-error-color);
                }
            }
        }

        &__icon {
            font-size: 1.5em;

            #{$block-selector}--info & {
                background: var(--theme-primary-gradient);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            #{$block-selector}--error & {
                color: var(--theme-error-color);
            }
        }

        &__message {
            font-size: 1.125em;
            font-weight: 300;
        }
    }
</style>
