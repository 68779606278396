<script setup lang="ts">
    import JobBoardOverviewCard from '@/components/partials/jobs/cards/JobBoardOverviewCard.vue'
    import JobProfileCard from '@/components/partials/jobs/cards/JobProfileCard.vue'
    import JobRegisterCard from '@/components/partials/jobs/cards/JobRegisterCard.vue'
    import IdentityAsideTemplate from '@/components/templates/IdentityAsideTemplate.vue'
    import useCandidateMeQuery from '@/libs/queries/useCandidateMeQuery'
    import useTalentConfigurationQuery from '@/libs/queries/useTalentConfigurationQuery'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { computed } from 'vue'
    import JobCompanionInformations from '@/components/partials/jobs/components/JobCompanionInformations.vue'
    import AsideLayout from '../AsideLayout/AsideLayout.v2.vue'
    import ContainerTemplate from '@/components/templates/ContainerTemplate.vue'

    const store = useAuthStore()

    const isConnected = computed(() => {
        return store.isLoggedIn
    })

    const { data: profile } = useCandidateMeQuery({ enabled: isConnected.value })
    const { data: config } = useTalentConfigurationQuery()
</script>

<template>
    <AsideLayout>
        <IdentityAsideTemplate>
            <template #aside>
                <div v-if="isConnected">
                    <JobProfileCard v-if="profile" :profile="profile.data" :config="config?.data" />
                    <JobBoardOverviewCard v-if="profile" :profile="profile.data" :config="config?.data" />
                </div>

                <JobRegisterCard v-else />
            </template>
            <template #default>
                <RouterView />
            </template>
        </IdentityAsideTemplate>
        <hr class="separator" />
        <ContainerTemplate>
            <JobCompanionInformations />
        </ContainerTemplate>
    </AsideLayout>
</template>

<style lang="scss" scoped>
    @import '@/libs/sass/vars';
    .separator {
        margin-bottom: 2.25em;
        border: 0;
        height: 1px;
        width: 100%;
        background-color: var(--theme-border-color);
    }
</style>

<i18n lang="json"></i18n>
