<script setup lang="ts">
    import { FieldMeta } from 'vee-validate'
    import { useAttrs, watch } from 'vue'
    type ModelValue = string | number | string[] | null
    const attrs = useAttrs()
    const props = withDefaults(
        defineProps<{
            name: string
            modelValue?: ModelValue
            error?: string
            handleBlur: (e?: Event | undefined) => void
            handleChange: (e: unknown, shouldValidate?: boolean | undefined) => void
            errorMessage?: string
            meta: FieldMeta<
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
            >
            value:
                | string
                | number
                | string[]
                | (string & string[])
                | (number & string[])
                | (string[] & string)
                | (string[] & number)
                | null
        }>(),
        {
            value: null,
            modelValue: '',
            errorMessage: '',
            error: '',
            options: () => []
        }
    )
    const emit = defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
    }>()
    watch(
        () => props.modelValue,
        (value) => {
            props.handleChange(value)
        }
    )
    const onInputChange = (e: Event) => {
        const value: string | null = (e.target as HTMLInputElement).value
        props.handleChange(value)
        emit('update:modelValue', value)
    }
</script>

<template>
    <textarea
        :name="name"
        class="field__input"
        :class="{
            'field__input--error': error || (errorMessage && meta.touched)
        }"
        v-bind="attrs"
        :value="value as string"
        @input="onInputChange"
        @blur="handleBlur"
    ></textarea>
</template>

<style lang="scss" scoped>
    $inputRadius: 0.25rem;
    $phonePrefixWidth: 5em;
    .field__input {
        $placeholderColor: var(--theme-color-gray--04);
        color: var(--theme-text-color);
        width: 100%;
        max-width: 100%;
        font-size: 0.875em;
        border-radius: $inputRadius;
        padding: 0.7em;
        border: solid 1px $placeholderColor;
        outline-color: rgba(var(--theme-text-color--rgb), 0.5);
        background-color: var(--theme-background-color);
        &--error {
            border-color: var(--theme-error-color);
        }
        @media (hover: hover) {
            &[type='date']::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }
        }
    }
</style>
