<script setup lang="ts">
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { useI18n } from 'vue-i18n'
    import { shortenString } from '@/libs/helpers/string'
    import { ClubRouteName } from '@/vars/RouteName'
    import { ResourceType } from '@/vars/ResourceAttr'
    import { UniversalSearchArticleType } from '@/types/response/UniversalSearch.type'

    defineProps<{
        resources: UniversalSearchArticleType[]
        count: number
    }>()

    const { t } = useI18n()

    const redirectResource = (resource: UniversalSearchArticleType) => {
        if (resource.type === ResourceType.ARTICLE) {
            return { name: ClubRouteName.ARTICLE_SINGLE, params: { id: resource.slug } }
        }
        return { name: ClubRouteName.VIDEO_SINGLE, params: { slug: resource.slug } }
    }
</script>

<template>
    <div v-show="count > 0" class="list">
        <h3 class="title-4">{{ t('title') }}</h3>
        <div>
            <div class="list__resources">
                <SpaRouterLink
                    v-for="resource in resources?.slice(0, 3)"
                    :key="resource.id"
                    :to="redirectResource(resource)"
                    class="list__item"
                >
                    <div>
                        <div class="list__title">{{ resource.title }}</div>
                        <div class="list__subtitle text-sm">
                            {{ resource.publicationDate.replaceAll('/', '-') }}
                            <span>{{ resource.summary && shortenString(resource.summary, 60) }}</span>
                        </div>
                    </div>
                </SpaRouterLink>

                <div class="ml-2 mt-4">
                    <SpaRouterLink class="list__more" :to="{ name: ClubRouteName.ARTICLE_LIST }">
                        {{ t('show_more') }}
                    </SpaRouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .list {
        display: grid;
        grid-template-columns: 8.75em 1fr;
        gap: var(--scale-4);

        &__title {
            font-weight: 600;
        }

        &__resources {
            border-left: 0.1em solid var(--theme-color-gray--05);
            padding: 0 var(--scale-2);
        }

        &__item {
            padding: var(--scale-2);
            display: flex;
            gap: var(--scale-2);

            justify-content: left;
            align-items: center;
        }

        &__subtitle {
            color: var(--theme-color-gray--03);
            display: flex;
            gap: var(--scale-2);
            flex-wrap: wrap;
        }

        &__more {
            font-weight: 600;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": "Ressources",
        "show_more": "Voir toutes les ressources"
    },
    "en": {
        "title": "Resources",
        "show_more": "See all Resources"
    }
}
</i18n>
